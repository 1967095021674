import { MasterTheArchService } from './../../../../../api/services/master-the-arch.service';
import { BiddingHeaderAndDetail } from './../../../../../api/models/bidding-header-and-detail';
import { SummaryBiddingService } from './../../../../../api/services/summary-bidding.service';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MasterSourcingTeam, MasterPurGroup, VChembiMstVendorMaster, RequestForPriceListBiddingHeaderAndDetail, MasterTheArchPurGroupMember } from 'src/app/api/models';
import { MasterService } from '../../../../../api/services';
import * as moment from 'moment';

@Component({
  selector: 'app-searching-summary',
  templateUrl: './searching-summary.component.html',
  styleUrls: ['./searching-summary.component.css']
})
export class SearchingSummaryComponent implements OnInit {

  isDataLoading: boolean = true;
  searchingSummaryCriteriaForm: FormGroup;
  searchList: RequestForPriceListBiddingHeaderAndDetail[] = [];

  deadlineWarning: boolean[] = [];

  sourcingTeamList: MasterSourcingTeam[] = [];
  purchasingGroupsList: MasterPurGroup[] = [];
  purGroupList: MasterTheArchPurGroupMember[] = [];
  vendorList: VChembiMstVendorMaster[] = [];

  constructor(
    private fb: FormBuilder,
    private masterService: MasterService,
    private summaryBiddingService: SummaryBiddingService,
    private masterTheArch: MasterTheArchService
  ) {
    this.searchingSummaryCriteriaForm = this.fb.group({
      sourcingTeam: [[]],
      purchasingGroups: [[]],
      biddingNo: "",
      status: [[]],
      biddingCreateFromDate: "",
      biddingCreateToDate: "",
      vendor: [[]],
    })


  }

  async ngOnInit(): Promise<void> {
    await this.getSourcingTeam();
    await this.getPurchasingGroups();
    await this.getVendor();
    await this.getPurGroupFromTheArch();
    this.isDataLoading = false;

  }

  async getSourcingTeam() {
    await this.masterService.apiMasterGetSourcingTeamGet$Json()
      .toPromise()
      .then(
        response => {
          this.sourcingTeamList = response
        }
      )
      .catch(
        error => {

        }
      )
  }

  async getPurchasingGroups() {
    await this.masterService.apiMasterGetPurGroupGet$Json()
      .toPromise()
      .then(
        response => {
          this.purchasingGroupsList = response
        }
      )
      .catch(
        error => {

        }
      )

  }

  async getVendor() {
    await this.masterService.apiMasterGetVendorGet$Json()
      .toPromise()
      .then(
        response => {
          this.vendorList = response
        }
      )
      .catch(
        error => {

        }
      )
  }

  async getPurGroupFromTheArch() {
    this.masterTheArch.apiMasterTheArchGetPurGroupMemberActiveGet$Json()
      .toPromise()
      .then(
        response => {
          this.purGroupList = response;
        }
      )
  }

  async onClickSearch() {
    this.isDataLoading = true;
    await this.summaryBiddingService.apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Json({
      startDate: new Date(this.searchingSummaryCriteriaForm.value.biddingCreateFromDate).toJSON(),
      endDate: new Date(this.searchingSummaryCriteriaForm.value.biddingCreateToDate).toJSON(),
    })
      .toPromise()
      .then(
        response => {
          this.searchList = [];
          if (this.searchingSummaryCriteriaForm.value.purchasingGroups.length === 0 &&
            this.searchingSummaryCriteriaForm.value.vendor.length === 0 &&
            this.searchingSummaryCriteriaForm.value.status.length === 0 &&
            this.searchingSummaryCriteriaForm.value.sourcingTeam.length === 0 &&
            this.searchingSummaryCriteriaForm.value.biddingNo === "") {

            this.searchList = response;
          }
          else {
            if (this.searchingSummaryCriteriaForm.value.biddingNo !== "") {
              var biddingNo: string = this.searchingSummaryCriteriaForm.value.biddingNo;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              response.forEach(function (item) {
                if (item.header?.docNo?.toLowerCase().startsWith(biddingNo.toLowerCase())) {
                  tempFilterList.push(item);
                  return;
                }
              })

              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })

            }

            if (this.searchingSummaryCriteriaForm.value.sourcingTeam.length > 0) {

              var so: string[] = this.searchingSummaryCriteriaForm.value.sourcingTeam;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              response.forEach(function (item) {
                for (var key in so) {
                  if (item.header?.sourcingTeam === so[key]) {
                    tempFilterList.push(item);
                    return;
                  }
                }


              })

              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })

            }

            if (this.searchingSummaryCriteriaForm.value.purchasingGroups.length > 0) {
              var p: string[] = this.searchingSummaryCriteriaForm.value.purchasingGroups;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              tempFilterList = response.filter(function (item) {
                for (var key in p) {
                  if (item.header?.sourcingPurchasingGroups === p[key]) {
                    return true;
                  }
                }

                return false;
              })

              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })
            }

            if (this.searchingSummaryCriteriaForm.value.status.length > 0) {
              var s: string[] = this.searchingSummaryCriteriaForm.value.status;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              tempFilterList = response.filter(function (item) {
                for (var key in s) {
                  if (item.header?.status === s[key]) {
                    return true;
                  }
                }

                return false;
              })

              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })
            }


            if (this.searchingSummaryCriteriaForm.value.vendor.length > 0) {

              var v: string[] = this.searchingSummaryCriteriaForm.value.vendor;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              response.forEach(function (item) {
                for (let i = 0; i < item.detail?.length!; i++) {
                  for (var key in v) {
                    if (item.detail![i].vendorName === v[key]) {
                      tempFilterList.push(item);
                      return;
                    }
                  }
                }

              })

              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })

            }

            this.searchList = Array.from(new Set(this.searchList.map(item => item.header?.pkbiddingHeader)))
              .map(pk => {
                return this.searchList.find(item => item.header?.pkbiddingHeader === pk)!;
              })

          }

          this.searchList = this.searchList.sort((a, b) => {
            return (moment(a.header?.createDate).diff(moment(b.header?.createDate), "days"));
          }).reverse();

          //เปลี่ยนสี Deadline ถ้า Deadline - ปัจจุบัน <= 3 วัน
          this.searchList.forEach(item => {
            if (moment(item.header?.deadline).diff(moment(), "days") <= 3) {
              this.deadlineWarning.push(true);
            }
            else {
              this.deadlineWarning.push(false);
            }

          })

        }
      )
      .catch(
        error => {

        }

      )
    this.isDataLoading = false;
  }

}
