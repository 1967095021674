<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" nzTip="Loading..." [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-header">
        <nz-row>
          <nz-card class="card">
            <nz-breadcrumb>
              <nz-breadcrumb-item>
                <a routerLink="../home">
                  <span nz-icon nzType="home"></span>
                  <span>Home</span>
                </a>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <span>Work Station</span>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <span>Bidding worksheet</span>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <a>
                  <span>Memo</span>
                </a>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <a>
                  <span>New Memo</span>
                </a>
              </nz-breadcrumb-item>
            </nz-breadcrumb>
          </nz-card>
        </nz-row>

        <nz-row>
          <nz-card class="card">
            <app-create-new-memo-steps></app-create-new-memo-steps>
          </nz-card>
        </nz-row>
      </div>


      <div class="panel-content" *ngIf="!isDataLoading">

        <div nz-row class="collapse-row">
          <div id="ref-memo" nz-col nzSpan="24" class="collapse-col">
            <app-create-new-memo-ref-memo [refMemoForm]="refMemoForm"></app-create-new-memo-ref-memo>
          </div>
        </div>

        <div nz-row class="collapse-row">
          <div id="sourcing-contract" nz-col nzSpan="24" class="collapse-col">
            <app-create-new-memo-sourcing-contract [sourcingContractForm]="sourcingContractForm"
              [purchasingGroupsMemberList]="purchasingGroupsMemberList" [sourcingTeamList]="purchasingGroupsMemberList">
            </app-create-new-memo-sourcing-contract>
          </div>

        </div>

        <div nz-row class="collapse-row">
          <div id="detail" nz-col nzSpan="24" class="collapse-col">
            <app-create-new-memo-detail [memoDetailForm]="memoDetailForm"></app-create-new-memo-detail>
          </div>
        </div>

        <div nz-row class="collapse-row">
          <div id="summary-bidding" nz-col nzSpan="24" class="collapse-col">
            <app-create-new-memo-summary-bidding></app-create-new-memo-summary-bidding>
          </div>
        </div>

        <div nz-row class="collapse-row">
          <div id="attachment" nz-col nzSpan="24" class="collapse-col">
            <app-create-new-memo-attachment></app-create-new-memo-attachment>
          </div>
        </div>

        <div nz-row class="collapse-row">
          <div id="attachment" nz-col nzSpan="24" class="collapse-col">
            <app-create-new-memo-approver [approverForm]="approverForm"></app-create-new-memo-approver>
          </div>
        </div>

        <div nz-row class="collapse-row">

          <div id="action-panel" nz-col nzSpan="24" class="collapse-col">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Action Panel'" [nzActive]="true">


                <form nz-form [formGroup]="actionForm">
                  <div nz-row class="content-row">

                    <div nz-col [nzXs]="24" [nzSm]="24">

                      <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6">Remark</nz-form-label>
                        <nz-form-control [nzXs]="12" [nzSm]="12">
                          <textarea nz-input placeholder="Note" [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                            formControlName="remark"></textarea>
                        </nz-form-control>
                      </nz-form-item>

                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24">
                      <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">
                        <div class="div-button-delete" style="padding: 2%">
                          <button class="btn-delete" nz-button nzType="primary" nzDanger (click)="onDeleteDraft()">
                            Delete Draft
                          </button>
                        </div>

                        <div class="div-button-save" style="padding: 2%">
                          <button nzSize="default" class="btn-cancel" nz-button nzType="default"
                            (click)="showSaveConfirm()">
                            Save Draft
                          </button>
                        </div>


                        <div class="div-button-submit" style="padding: 2%">
                          <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit"
                            (click)="showSubmitConfirm()">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </nz-collapse-panel>
            </nz-collapse>

          </div>

          <div nz-row class="collapse-row">
            <div id="action-log" nz-col nzSpan="24" class="collapse-col">

            </div>

          </div>

        </div>


      </div>

    </div>

  </nz-spin>
</nz-layout>
