<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Email Config'" [nzActive]="true">
      <div nz-row>
        <div nz-col [nzXs]="24" [nzSm]="24">

          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6">Number Of Notify</nz-form-label>
            <nz-form-control [nzXs]="6" [nzSm]="8">
              <nz-input-number-group [nzSuffix]="'Time(s)'" style="width: 200px;">
                <nz-input-number [nzMin]="1" [nzDisabled]="true" [(ngModel)]="this.biddingInfo.detail!.noOfNotify">
                </nz-input-number>
              </nz-input-number-group>
            </nz-form-control>
            <nz-form-control [nzXs]="6" [nzSm]="12">


            </nz-form-control>

          </nz-form-item>
        </div>

        <div nz-col [nzXs]="24" [nzSm]="24">

          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6">Notify Email</nz-form-label>
            <nz-form-control [nzXs]="6" [nzSm]="18">
              <nz-select style="width: 200px;" [(ngModel)]="notifySubject">
                <nz-option *ngFor="let item of notifyEmailSubjectList" [nzLabel]="item.value!" [nzValue]="item.value!">
                </nz-option>
              </nz-select>

              <button class="btn-preview" nz-button nzType="dashed" (click)="onPreviewEmail(tplContent)">
                Preview
              </button>

              <ng-template>
                <div id="action-panel" nz-col nzSpan="24" class="collapse-col">
                  <nz-collapse>
                    <nz-collapse-panel [nzHeader]="'Edit Email'" [nzActive]="true">
                      <nz-tabset [nzAnimated]="false">
                        <nz-tab nzTitle="TH">
                          <ckeditor [data]="emailTemplateTH" [(ngModel)]="emailTemplateTH"></ckeditor>
                        </nz-tab>

                        <nz-tab nzTitle="ENG">
                          <ckeditor [data]="emailTemplateENG" [(ngModel)]="emailTemplateENG"></ckeditor>
                        </nz-tab>
                      </nz-tabset>

                    </nz-collapse-panel>
                  </nz-collapse>
                </div>
              </ng-template>
              <button class="btn-send" nz-button nzType="default" (click)="sendEmail()">
                Send Email
              </button>
            </nz-form-control>


          </nz-form-item>
        </div>

        <div nz-col [nzXs]="24" [nzSm]="24">
          <nz-card>

            <nz-table nzTemplateMode nzBordered>
              <thead>

                <tr>
                  <th style="width: 5px;">
                    <button nz-button class="btn-add" nzType="default" [nzSize]="'small'" (click)="addChildVendor()">
                      <i nz-icon nzType="plus" nzTheme="outline" class="icon-add">
                      </i>
                    </button>
                  </th>
                  <th style="width: 5px;">
                    <nz-row>
                      <nz-col>
                        Send Email
                      </nz-col>
                    </nz-row>
                    <button nz-button class="btn-checkall" nzType="default" [nzSize]="'small'" (click)="checkAll()">
                      <i nz-icon nzType="check" nzTheme="outline" class="icon-check">
                      </i>
                    </button>
                    <button nz-button class="btn-uncheck" nzType="default" [nzSize]="'small'" (click)="unCheckAll()">
                      <i nz-icon nzType="close" nzTheme="outline" class="icon-uncheck">
                      </i>
                    </button>
                  </th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of vendorEmailList; let j = index">
                  <td>
                    <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                      nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteChildVendor(j)" nzDanger>
                      <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                      </i>
                    </button>

                    <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'"
                      (click)="modalEditConfig(tplTitle, tplContent, tplFooter, j)">

                      <ng-template #tplTitle>
                        <span>Edit Config</span>
                      </ng-template>

                      <ng-template #tplContent>
                        <div nz-row class="content-row">

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Email</nz-form-label>
                              <nz-form-control [nzSm]="12">
                                <input nz-input type="text" [(ngModel)]="changedEmail"
                                  [ngModelOptions]="{standalone: true}" style="width: 350px;">
                              </nz-form-control>

                            </nz-form-item>
                          </div>


                        </div>

                      </ng-template>

                      <ng-template #tplFooter let-ref="modalRef">
                        <button nz-button nzDanger (click)="modalDestroy()">Cancel</button>
                        <button nz-button nzType="primary" (click)="onChangeEmail(j)">Save</button>
                      </ng-template>

                      <i nz-icon nzType="edit" nzTheme="outline" class="icon-edit">
                      </i>

                    </button>
                  </td>
                  <td style="text-align: center;">
                    <label nz-checkbox [nzValue]="data.SendEmail" [(nzChecked)]="data.SendEmail"
                      (nzCheckedChange)="onCheckSendEmailChange()"></label>
                  </td>
                  <td>{{ data.Email }}</td>
                </tr>

              </tbody>
            </nz-table>

          </nz-card>
        </div>


      </div>
    </nz-collapse-panel>
  </nz-collapse>

  <ng-template #tplContent let-params>
    <nz-tabset [nzAnimated]="false">
      <nz-tab nzTitle="TH">
        <html>

        <head>
          <style>
            .preview-table  {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }

            .preview-table th, .preview-table td{
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

            /* .preview-table tr:nth-child(even) {
              background-color: #ede4e5;
            } */
          </style>
        </head>

        <body>
          <div>
            <h4>เรียน แผนกการตลาด/ฝ่ายขาย,</h4>

            <br>

            <div>
              <div [innerHTML]="emailTemplateTH">

              </div>
            </div>

            <br>

            <table class="preview-table">
              <tr>
                <th>No.</th>
                <th>Material Code</th>
                <th>Item Name</th>
                <th>Desciption</th>
                <th>Unit</th>
                <th>Quantity</th>
              </tr>
              <tr *ngFor="let item of biddingInfo.itemList">
                <td>{{item.no}}</td>
                <td>{{item.matCode}}</td>
                <td>{{item.itemName}}</td>
                <td>{{item.description}}</td>
                <td>{{item.unit}}</td>
                <td>{{item.quantity | number}}</td>
              </tr>

            </table>

            <br>
            <a href="">คลิกเพื่อเปิดเอกสาร</a>
            <br>

            <p>&emsp; หากต้องการข้อมูลเพิ่มเติมโปรดติดต่อ {{params.Name}}, E-Mail:
              {{params.Email}}, Tel. {{params.TelNo}}
            </p>
            <p>
              <strong>หมายเหตุ: บริษัท เอสซีจีเคมิคอลส์ จำกัด
                ขอสงวนสิทธิ์ในการพิจารณาการสั่งซื้อตามที่บริษัทเห็นสมควรแต่เพียงผู้เดียว​
              </strong>
            </p>
            <br>
            <p>
              <strong>
                ขอแสดงความนับถือ
              </strong>
            </p>
            <img width="200" height="50" src="https://image.makewebeasy.net/makeweb/0/oF1Vfw37q/DefaultData/SCGC.jpg">

            <br>
            <br>
            <p>This service was provided by Request for Quotation System, SCG Chemicals Your comments and
              suggestions
              are
              welcome. © 2018 SCG Chemicals PLC, All Rights Reserved.
            </p>
          </div>
        </body>


        </html>
      </nz-tab>

      <nz-tab nzTitle="ENG">
        <html>

        <head>
          <style>
            .preview-table  {
              font-family: arial, sans-serif;
              border-collapse: collapse;
              width: 100%;
            }

            .preview-table th, .preview-table td{
              border: 1px solid #dddddd;
              text-align: left;
              padding: 8px;
            }

            /* .preview-table tr:nth-child(even) {
              background-color: #ede4e5;
            } */
          </style>
        </head>

        <body>
          <div>
            <h4>Dear Sale/Marketing Department,</h4>

            <br>

            <div>
              <div [innerHTML]="emailTemplateENG">

              </div>
            </div>

            <br>
            <table class="preview-table">
              <tr>
                <th>No.</th>
                <th>Material Code</th>
                <th>Item Name</th>
                <th>Desciption</th>
                <th>Unit</th>
                <th>Quantity</th>
              </tr>
              <tr *ngFor="let item of biddingInfo.itemList">
                <td>{{item.no}}</td>
                <td>{{item.matCode}}</td>
                <td>{{item.itemName}}</td>
                <td>{{item.description}}</td>
                <td>{{item.unit}}</td>
                <td>{{item.quantity | number}}</td>
              </tr>

            </table>

            <br>
            <a href="">Click to open this document</a>
            <br>

            <p>&emsp; If you require further information, please contact {{params.Name}}, E-Mail:
              {{params.Email}}, Tel. {{params.TelNo}}0
            </p>
            <p>
              <strong>Remark: SCG Chemicals PLC reserves the right to award to the bidder that presents the
                best value
                to
                the
                company as determined solely by the company in its absolute discretion
              </strong>
            </p>

            <br>
            <p>
              <strong>
                Yours Sincerely,
              </strong>
            </p>

            <img width="200" height="50" src="https://image.makewebeasy.net/makeweb/0/oF1Vfw37q/DefaultData/SCGC.jpg">
            <br>
            <br>
            <p>This service was provided by Request for Quotation System, SCG Chemicals Your comments and
              suggestions
              are
              welcome. © 2018 SCG Chemicals PLC, All Rights Reserved.</p>
          </div>
        </body>


        </html>
      </nz-tab>
    </nz-tabset>

  </ng-template>

</nz-layout>
