import { Component, Input, OnInit } from '@angular/core';
import { BiddingAllInfoAndLog } from '../../../../../../../../api/models';

@Component({
  selector: 'app-new-bidding-header',
  templateUrl: './new-bidding-header.component.html',
  styleUrls: ['./new-bidding-header.component.css']
})
export class NewBiddingHeaderComponent implements OnInit {

  @Input() biddingAllInfo!: BiddingAllInfoAndLog;
  @Input() steps: number = 0;
  documentNumber: string = '';
  documentStatus: string = "";
  documentCreatedDate!: Date;
  documentCreator: string = "";

  constructor() { }

  ngOnInit(): void {
    this.documentNumber = this.biddingAllInfo.header?.docNo!;
    this.documentStatus = this.biddingAllInfo.header?.status!;
    this.documentCreatedDate = new Date(this.biddingAllInfo.header?.createDate!);
    this.documentCreator = this.biddingAllInfo.header?.creatorName!;

  }

}
