import { MasterService } from './../../../../../api/services/master.service';
import { MasterSourcingTeam } from './../../../../../api/models/master-sourcing-team';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { RcfAllInfo } from '../../../../../api/models';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-sourcing-team',
  templateUrl: './sourcing-team.component.html',
  styleUrls: ['./sourcing-team.component.css']
})
export class SourcingTeamComponent implements OnInit {

  @Input() requestContractAllInfo!: RcfAllInfo[];
  @Input() steps!:number;
  @Output() sourcingDataChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  sourcingTeamForm!: FormGroup;
  sourcingTeamList: MasterSourcingTeam[] = [];
  selectedSourcingTeam: any;
  isLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private masterService: MasterService,
    private modal:NzModalService) {
    this.sourcingTeamForm = this.fb.group({
      sourcingTeam: [null],
      sourcingTeamDepartment: [null],
    })
  }

  ngOnInit(): void {
    this.getSourcingTeamFromMaster();
    this.updateData(this.sourcingTeamForm);

  }

  async ngOnChanges(change: SimpleChanges) {
    if (change.hasOwnProperty("requestContractAllInfo") && change.requestContractAllInfo.currentValue.length != 0) {
      this.selectedSourcingTeam = this.sourcingTeamList.findIndex(item => item.sourcingTeam == change.requestContractAllInfo.currentValue[0].sourcingTeamName);
      this.sourcingTeamForm.controls['sourcingTeam'].setValue(change.requestContractAllInfo.currentValue[0].sourcingTeamName);
      this.sourcingTeamForm.controls['sourcingTeamDepartment'].setValue(change.requestContractAllInfo.currentValue[0].sourcingTeamDepartment);
      this.updateData(this.sourcingTeamForm);
    }

  }

  async getSourcingTeamFromMaster()
  {
    this.masterService.apiMasterGetSourcingTeamGet$Json$Response()
      .subscribe({
        next:(response) => {
          this.sourcingTeamList = response.body;
          if(this.requestContractAllInfo.length != 0)
          {
            this.selectedSourcingTeam = this.sourcingTeamList.findIndex(item => item.sourcingTeam == this.requestContractAllInfo[0].sourcingTeamName);
            this.sourcingTeamForm.controls['sourcingTeam'].setValue(this.requestContractAllInfo[0].sourcingTeamName);
            this.sourcingTeamForm.controls['sourcingTeamDepartment'].setValue(this.requestContractAllInfo[0].sourcingTeamDepartment);
            this.updateData(this.sourcingTeamForm);
          }
        },
        error:(err) => {

          this.modal.error({
            nzTitle: "Server Error",
            nzContent: "Please try again leter",
            nzClosable: false,
            nzOkText: "Close"
          })
          console.log("[Sourcing Team] ERROR getSourcingTeamfromMaster: ",err);
        },
      })
  }

  updateData(form: FormGroup)
  {
    this.sourcingDataChange.emit(form);
  }

  selectSourcingTeam(value: number)
  {
    this.sourcingTeamForm.patchValue({
      sourcingTeam: this.sourcingTeamList[value].sourcingTeam,
      sourcingTeamDepartment: this.sourcingTeamList[value].department,
    })

    this.updateData(this.sourcingTeamForm);
  }
}
