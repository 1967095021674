<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Add Attachment'" [nzActive]="true">
      <div nz-row>
        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>TOR Attachment</nz-form-label>

            <nz-form-control [nzXs]="6" [nzSm]="12">
              <nz-upload nzType="drag" [nzMultiple]="true" (nzChange)="onUploadChange($event)"
                [nzAccept]="attachmentFileType" [nzCustomRequest]="onUploadFile" [nzHeaders]="setMediaUploadHeaders"
                [nzBeforeUpload]="beforeUpload" [nzShowUploadList]="false" [nzData]="fileData"
                [nzSize]="attachmentFileSizeInByte" [nzOpenFileDialogOnClick]="true" [nzMultiple]="true"
                [nzDisabled]="steps != 0">
                <p class="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p class="ant-upload-hint">Limit file size {{attachmentFileSizeInByte/1e+6}} MB</p>
              </nz-upload>
            </nz-form-control>

            <i class="icon-tips" nz-icon nzType="question-circle" nzTheme="twotone" nz-tooltip
              [nzTooltipTitle]="contractTypeTips"></i>
            <ng-template #contractTypeTips>
              <div class="tips">
                <p>
                  เอกสารที่กำหนดขอบเขตและรายละเอียดของการจัดหาพัสดุ/งานจ้าง
                  หรือข้อกำหนด (Specifications) ของสินค้า/งานจ้าง
                </p>
              </div>
            </ng-template>
          </nz-form-item>
        </div>

        <!-- <nz-table class="table-technicalcommittee" #techTable nzTableLayout="fixed" [nzShowPagination]="false" nzBordered
        [nzData]="listOfTechCommit" [nzScroll]="{x:'800px', y:'500px'}" [nzFrontPagination]="false"> -->

        <div class="col" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
          <nz-table class="table-tor" #fileListTORTable nzBordered [nzData]="fileListTOR" [nzShowPagination]="false"
            [nzFrontPagination]="false" [nzScroll]="{x:'800px', y: '500px'}">
            <thead>
              <tr>
                <th nzWidth="20px">Action</th>
                <th nzWidth="80px">File Name</th>
                <th nzWidth="30px">File Size</th>
                <th nzWidth="60px">Modify Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fileListTOR" class="editable-row">
                <td style="text-align: center;" class="td-action">
                  <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                    nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteItem(data.fileID, data.fileGroup)" nzDanger
                    [disabled]="steps != 0">
                    <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                    </i>
                  </button>
                </td>
                <td>
                  <a href="{{ data.fileDownload }}">{{ data.fileName }}</a>
                </td>
                <td>{{ data.fileSizeinByte | number}} MB</td>
                <td>{{ data.lastModified | date:"dd MMM yyyy HH:mm"}}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>

        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6">Other Attachment</nz-form-label>
            <nz-form-control [nzXs]="6" [nzSm]="12">
              <nz-upload nzType="drag" [nzMultiple]="true" (nzChange)="onUploadChange($event)"
                [nzAccept]="attachmentFileType" [nzCustomRequest]="onUploadFileOther"
                [nzHeaders]="setMediaUploadHeaders" [nzBeforeUpload]="beforeUpload" [nzShowUploadList]="false"
                [nzData]="fileData" [nzSize]="attachmentFileSizeInByte" [nzOpenFileDialogOnClick]="true"
                [nzMultiple]="true" [nzDisabled]="steps != 0">
                <p class="ant-upload-text">
                  Click or drag file to this area to upload
                </p>
                <p class="ant-upload-hint">Limit file size {{attachmentFileSizeInByte/1e+6}} MB</p>
              </nz-upload>
            </nz-form-control>
          </nz-form-item>
        </div>

        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
          <nz-table class="table-other" #fileListOtherTable nzBordered [nzData]="fileListOther"
            [nzFrontPagination]="false" [nzShowPagination]="false" [nzScroll]="{x:'800px', y:'500px'}">
            <thead>
              <tr>
                <th nzWidth="30px">Action</th>
                <th nzWidth="80px">File Name</th>
                <th nzWidth="30px">File Size</th>
                <th nzWidth="60px">Modify Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fileListOther" class="editable-row">
                <td style="text-align: center;" class="td-action">
                  <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                    nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteItem(data.fileID, data.fileGroup)" nzDanger
                    [disabled]="steps != 0">
                    <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                    </i>
                  </button>
                </td>
                <td>
                  <a href="{{ data.fileDownload }}">{{ data.fileName }}</a>
                </td>
                <td>{{ data.fileSizeinByte | number}} MB</td>
                <td>{{ data.lastModified | date:"dd MMM yyyy HH:mm"}}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
      </div>
    </nz-collapse-panel>
  </nz-collapse>
</nz-layout>
