import { RequestForPriceListBiddingHeaderAndDetail } from './../../../../../../../../api/models/request-for-price-list-bidding-header-and-detail';
import { BiddingHeader } from './../../../../../../../../api/models/bidding-header';
import { BiddingDetail } from './../../../../../../../../api/models/bidding-detail';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-searching-price-list-result',
  templateUrl: './searching-price-list-result.component.html',
  styleUrls: ['./searching-price-list-result.component.css']
})
export class SearchingPriceListResultComponent implements OnInit {

  @Input() searchList: RequestForPriceListBiddingHeaderAndDetail[] = [];
  @Input() deadlineWarning: boolean[] = [];

  expandSet = new Set<number>();

  //เรียงวันที่
  sortDateFn = (a: RequestForPriceListBiddingHeaderAndDetail, b: RequestForPriceListBiddingHeaderAndDetail) => {
    return (moment(a.header?.createDate).diff(moment(b.header?.createDate), "days"));
  }

  //กด + ใน table
  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }

  constructor() { }

  ngOnInit(): void {

  }



}
