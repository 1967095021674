import { NzStatusType } from 'ng-zorro-antd/steps';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-step',
  templateUrl: './step.component.html',
  styleUrls: ['./step.component.css']
})
export class StepComponent implements OnInit {

  @Input() steps!:number;
  @Input() status!: NzStatusType;
  @Output() updateStep: EventEmitter<number> = new EventEmitter<number>();

  stepsStatus:any = "";
  constructor() {
    // this.steps = 0;
  }

  ngOnInit(): void {
  }

  async ngOnChanges(change: SimpleChanges){
    // console.log("[steps] ",change);
    // console.log("step ",this.steps);
    if(change.status != undefined)
    {
      if(change.status.currentValue == "error")
      {
        this.stepsStatus = 'error';
      }
    }
  }

}
