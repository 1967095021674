import { SummaryBiddingFile } from './../../../../../../../api/models/summary-bidding-file';
import { BiddingFile } from './../../../../../../../api/models/bidding-file';
import { async } from '@angular/core/testing';
import { AzureBlobService } from './../../../../../../../api/services/azure-blob.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterConfig, SummaryBiddingAllInfo } from './../../../../../../../../app/api/models';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Guid } from 'guid-typescript';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-summary-attachment',
  templateUrl: './summary-attachment.component.html',
  styleUrls: ['./summary-attachment.component.css']
})
export class SummaryAttachmentComponent implements OnInit {

  @Input() biddingAllInfo!: SummaryBiddingAllInfo;
  @Input() steps: number = 0;
  @Input() biddingConfig: MasterConfig[] = [];
  @Output() summaryAttachmentDataChange = new EventEmitter<SummaryBiddingFile[]>();

  totalFile: number[] = [];
  summaryAttachment: SummaryBiddingFile[] = [];
  attachment: BiddingFile[] = [];
  documentFileSizeInByte: number = 0;
  documentFileType: string = "";

  constructor(
    private azureBlobService: AzureBlobService,
    private msg: NzMessageService,
    private modal: NzModalService) {

    }

  async ngOnInit(): Promise<void> {

    this.summaryAttachment = [];
    this.documentFileSizeInByte = Number(this.biddingConfig[this.biddingConfig.findIndex(item => item.key = "DocumentFileSizeInByte")].value);
    this.documentFileType = JSON.parse("[" + this.biddingConfig[this.biddingConfig.findIndex(item => item.key == "DocumentFileType")].value! + "]");

    //Get link download file
    this.biddingAllInfo.file!.forEach(async item => {
      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: item.fileId!,
        fileName: item.fileName!,
      })
        .toPromise()
        .then(
          response => {

            this.attachment = [...this.attachment, {
              fileName: item.fileName,
              fileId: item.fileId,
              fileDownloadLink: response.url,
              fileSizeInByte: item.fileSizeInByte,
              lastModifiedDate: item.lastModifiedDate,
            }]
          }
        )
        .catch(
          error => {

          }
        )

    })

    this.biddingAllInfo.vendorBiddingInfo?.forEach(item => {
      let tempTotalFile = 0;
      item.fileList?.forEach(async file => {
        tempTotalFile += 1;
        await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
          fileID: file.fileId!,
          fileName: file.fileName!,
        })
          .toPromise()
          .then(
            response => {
              file.fileDownloadLink = response.url;
            }
          )
          .catch(
            error => {

            }
          )
      })

      this.totalFile.push(tempTotalFile);
    })

  }

  async onUploadChange({ file, fileList }: NzUploadChangeParam): Promise<void> {
    const status = file.status;
    let msgLoad;
    if (status !== 'uploading') {
      console.log(file, fileList);

    }

    if (status === 'uploading') {
      msgLoad = this.msg.loading(`${file.name} uploading.`, { nzDuration: 0 });
    }

    if (status === 'done') {

      this.msg.remove();
      let response = JSON.parse(file.response);

      console.log("response: ", file.response);
      var objRes: BiddingFile = {
        fileId: response.fileID,
        fileGroup: "biddingAttachment",
        fileName: response.fileName,
        fileSizeInByte: response.fileSizeinByte,
        lastModifiedDate: response.lastModified,
      }

      objRes.lastModifiedDate = new Date().toJSON();

      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: objRes.fileId!,
        fileName: objRes.fileName!,
      })
        .toPromise()
        .then(
          response => {
            if (response.url != null) {
              objRes.fileDownloadLink = response.url;
            }
          }

        )
        .catch(
          err => {
            console.log("Get Downlink Failed");
          })

      this.attachment = [
        ...this.attachment,
        objRes
      ]
      this.msg.remove();
      this.msg.success(`${file.name} file uploaded successfully.`);
      console.log("Created file list");

    }
    else if (status === 'error') {
      // this.msg.remove(this.msgLoad);
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  async downloadFile(fileName: string, fileID: string) {
    console.log("FileName: ", fileName, " FileId ", fileID);

    await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: fileID,
      fileName: fileName,
    }).toPromise().then(
      response => {
        console.log("link : ", response.url);
      }
    )

    return;
  }


  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    console.log("Before Upload");
    console.log("File Type: ", file.type);

    let isFileTypeValid: boolean = false;
    for (let i = 0; i < this.documentFileType[0].length; i++) {
      if (file.type == this.documentFileType[0][i]) {
        isFileTypeValid = true;
        break;
      }
    }

    if (isFileTypeValid == false) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "Invalid File Type"
      });

      return false;
    }

    if (file.size! > this.documentFileSizeInByte) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "File size limit < 20 MB"
      });

      return false;
    }

    return true;
  }

  onUploadFile = (item: NzUploadXHRArgs) => {

    const formData = new FormData();
    formData.append('file', item.file as any); // tslint:disable-next-line:no-any

    return this.azureBlobService.apiAzureBlobUploadBlobPut$Response({
      body: {
        files: item.file as any,
      },

    }).subscribe((e: HttpEvent<any>) => {
      if (e.type === HttpEventType.UploadProgress) {
        if (e.total! > 0) {
          (e as any).percent = e.loaded / e.total! * 100; // tslint:disable-next-line:no-any
        }
        item.onProgress!(e, item.file);
      } else if (e instanceof HttpResponse) { /* success */
        console.log("FILE TOR UPLOADED");
        item.onSuccess!(e.body, item.file, e);

      }
    }, (err) => { /* error */
      item.onError!(err, item.file);
    });


  }

  fileData = (item: NzUploadFile) => {
    item.uid = Guid.create().toString();

    return item;
  }


  deleteItem(fileId: string) {
    console.log(fileId);

    this.attachment = this.attachment.filter(function (item) {
      return item.fileId !== fileId;
    })


  }

}
