<nz-layout class="app-layout">

  <nz-row>
    <nz-col nzSpan="24">
      <nz-steps [nzCurrent]="steps" [nzStatus]="status">

        <nz-step nzTitle="New Bidding"></nz-step>

        <nz-step nzTitle="Wait for vendor submit price" ></nz-step>

        <nz-step nzTitle="Wait for summary" ></nz-step>

        <nz-step nzTitle="Finish"></nz-step>

      </nz-steps>
    </nz-col>
  </nz-row>

</nz-layout>
