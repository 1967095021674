<nz-layout class="content" >
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Edit Email'" [nzActive]="true">
      <nz-tabset [nzAnimated]="false" [nzType]="'card'">
        <nz-tab nzTitle="TH" [nzForceRender]="true">
          <ckeditor [data]="emailTemplateTH" [config]="config"  [readOnly]="steps != 0" [(ngModel)]="emailTemplateTH" [disabled]="steps != 0"></ckeditor>
        </nz-tab>

        <nz-tab nzTitle="ENG" [nzForceRender]="true">
          <ckeditor [data]="emailTemplateENG" [config]="config" [readOnly]="steps != 0" [(ngModel)]="emailTemplateENG" [disabled]="steps != 0">
          </ckeditor>
        </nz-tab>
      </nz-tabset>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
