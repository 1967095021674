<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Item List'" [nzActive]="true">
      <div nz-row>
        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Item List</nz-form-label>

            <nz-form-control [nzXs]="6" [nzSm]="12">

              <nz-upload nzType="drag" [nzAccept]="acceptedFile" nzAction="" [nzShowUploadList]="false"
                [nzBeforeUpload]="beforeUpload" [nzDisabled]="steps !=0 ">

                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <!-- <p class="ant-upload-hint">Limit file size 10MB </p> -->
              </nz-upload>

            </nz-form-control>

          </nz-form-item>
        </div>


        <div class="col" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <button nz-button class="btn-dowload" nzType="primary" (click)="onDownloadTemplate()">
            Download Template
          </button>

          <nz-table class="table-itemlist" #itemlistTable [nzShowPagination]="false" [nzBordered]="true"
            [nzScroll]="{ x: '800px', y: '800px' }" [nzData]="itemlistTableData" [nzFrontPagination]="false">
            <thead>
              <tr>
                <th nzWidth="30px">No.</th>
                <th nzWidth="80px">Mat Code</th>
                <th nzWidth="100px">Item Name</th>
                <th nzWidth="150px">Desciption</th>
                <!-- <th nzWidth="175px">Unit</th> -->
                <th nzWidth="100px">Quantity</th>
                <th nzWidth="100px">Note</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of itemlistTable.data;index as i">
                <td>{{data.no}}</td>
                <td>{{data.matCode}}</td>
                <td>{{data.itemName}}</td>
                <td>
                  <textarea nz-input [readOnly]="true" [disabled]="true" [(ngModel)]="data.description"
                    style="resize: none;" rows="4">
                  </textarea>
                </td>
                <td class="td-quantity">
                  <nz-input-number-group nzCompact>
                    <nz-input-number nz-input [nzReadOnly]="true" [(ngModel)]="data.quantity"
                      [nzFormatter]="formatterNumber" [disabled]="true" style="width:70%;"></nz-input-number>
                    <input nz-input [readOnly]="true" [disabled]="true" [(ngModel)]="data.unit" style="width:30%;" />
                  </nz-input-number-group>
                </td>
                <td>
                  <textarea nz-input [readOnly]="true" [disabled]="true" [(ngModel)]="data.note"
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }">
                  </textarea>
                </td>
              </tr>
            </tbody>
          </nz-table>
        </div>

      </div>
    </nz-collapse-panel>
  </nz-collapse>

</nz-layout>
