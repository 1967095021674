<nz-layout class="app-layout">
  <nz-sider nzCollapsible class="app-sider-container" [nzWidth]="250" [nzCollapsed]="isSiderCollapsed" (nzCollapsedChange)="onCollapsedChange($event)">

    <div class="page">

      <div class="sidebar-logo">
        <!-- <span>CMS</span> -->
        <span>
          <a href={{webUrl}}>CMS</a>
        </span>
      </div>

      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed" *ngIf="userRole != 'Vendor' ">

        <li nz-menu-item [nzMatchRouter]="true">
          <i nz-icon nzType="home" nzTheme="outline"></i>
          <span routerLink="home"><a>Home</a></span>
        </li>

        <li nz-menu-item [nzMatchRouter]="true">
          <i nz-icon nzType="pie-chart"></i>
          <span routerLink="user-manual"><a>User Manual</a></span>
        </li>

        <li nz-submenu nzTitle="Work Station" nzIcon="robot" [(nzOpen)]="openSubMenu.WorkStation"
          (nzOpenChange)="openHandler('WorkStation')"
          *ngIf="userRole == 'Procurement Admin' || userRole == 'Procurement User' || userRole == 'Normal User'">
          <ul>
            <li nz-menu-item [nzMatchRouter]="true">
              <a routerLink="work-station/dashboard">Dashboard</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a routerLink="work-station/request-new-contract">Request new contract</a>
            </li>
            <li nz-submenu nzTitle="Bidding Worksheet"
              [nzOpen]="subMenu_BiddingWorksheet"
              *ngIf="userRole == 'Procurement Admin' || userRole == 'Procurement User'">
              <ul>
                <li nz-menu-item [nzMatchRouter]="true">
                  <a routerLink="work-station/bidding-worksheet/request-for-price-list">Request for price list</a>
                </li>
                <li nz-menu-item [nzMatchRouter]="true">
                  <a routerLink="work-station/bidding-worksheet/searching-summary">Summary bidding</a>
                </li>
                <li nz-menu-item [nzMatchRouter]="true">
                  <a routerLink="work-station/bidding-worksheet/memo">Memo</a>
                </li>

              </ul>
            </li>

          </ul>
        </li>

        <li nz-submenu nzTitle="Contract List" nzIcon="desktop" [(nzOpen)]="openSubMenu.ContractList"
          (nzOpenChange)="openHandler('ContractList')"
          *ngIf="userRole == 'Procurement Admin' || userRole == 'Procurement User' || userRole == 'Normal User'">

          <ul>
            <li nz-menu-item>
              <a href="https://scgchem-scms.scg.com/Dashboard">Contract Header</a>
            </li>
            <li nz-menu-item>
              <a href="https://scgchem-scms.scg.com/DashboardItem">Contract Item</a>
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="Manage Contract" nzIcon="user" [(nzOpen)]="openSubMenu.ManageContract"
          (nzOpenChange)="openHandler('ManageContract')"
          *ngIf="userRole == 'Procurement Admin' || userRole == 'Procurement User'">
          <ul>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/Contract_List_MyTask">My task List</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/Contract_List_MyRequest">My request</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/Contract_Add">Add New Contract</a>
            </li>
          </ul>
        </li>

        <li nz-submenu nzTitle="A & P" nzIcon="team" [(nzOpen)]="openSubMenu.AP" (nzOpenChange)="openHandler('AP')"
          *ngIf="userRole == 'Procurement Admin' || userRole == 'Procurement User'">
          <ul>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/Dashboard">Analysis</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/Performance">Performance</a>
            </li>
          </ul>
        </li>

        <li nz-menu-item>
          <i nz-icon nzType="file" nzTheme="outline"></i>
          <span>Template</span>
        </li>

        <li nz-submenu nzTitle="Admin" nzIcon="team" [(nzOpen)]="openSubMenu.Admin"
          (nzOpenChange)="openHandler('Admin')" *ngIf="userRole === 'Procurement Admin'">
          <ul>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/Role">Role</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/DownloadForSAP">Download for SAP</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/DownloadAllContract">All Contact</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/SearchLogHeader">Search Log Header</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/SearchLogItem">Search Log Item</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a routerLink="configuration">Configuration</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a routerLink="log">Log</a>
            </li>
            <li nz-menu-item [nzMatchRouter]="true">
              <a href="https://scgchem-scms.scg.com/UploadUserManual"><span>Upload User Manual</span></a>

            </li>
          </ul>
        </li>

      </ul>

    </div>

  </nz-sider>

  <nz-layout class="app-layout-container" [ngStyle]="{'padding-left': padding}">

    <nz-header [ngStyle]="{'width': width}">
      <div class="app-header">
        <nz-row class="app-header-content" [nzAlign]="'middle'" [nzJustify]="'end'">
          <nz-col>
            <button nz-button nz-popover nzPopoverTitle="Your Profile"
              nzPopoverPlacement="bottomRight" [nzPopoverContent]="contentProfile" nzType="text"
              *ngIf="userRole != 'Vendor'" style="color: #fff;">

              <div class="button-profile">
                <span class="profile-icon">
                  <nz-avatar nzIcon="user"></nz-avatar>
                </span>
                <span>
                  {{this.userFullName}}
                </span>
              </div>

            </button>
          </nz-col>
        </nz-row>

      </div>
    </nz-header>

    <nz-content class="content">
      <div class="inner-content">
        <router-outlet></router-outlet>
      </div>
    </nz-content>

    <nz-footer></nz-footer>
  </nz-layout>

</nz-layout>

<ng-template #contentProfile>
  <div class="profile">

    <nz-row class="profile-content" [nzGutter]="[24, 16]">
      <nz-col [nzSm]="24">
        Username: <strong>{{userDetailSSO.email.split("@")[0]}}</strong>
      </nz-col>
      <nz-col [nzSm]="24">
        Title: <strong>{{userDetailSSO.information.title}}</strong>
      </nz-col>
      <nz-col [nzSm]="24">
        Role: <strong><i>{{userRole}}</i></strong>
      </nz-col>
    </nz-row>

    <nz-divider></nz-divider>
    <nz-row class="profile-footer" [nzAlign]="'middle'" [nzJustify]="'center'">
      <button nzSize="default" class="btn-logout" nz-button nzType="primary" nzDanger (click)="logOut()">
        <span nz-icon nzType="logout" nzTheme="outline"></span>
        Logout
      </button>

    </nz-row>
  </div>
</ng-template>
