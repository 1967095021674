import { DatePipe } from '@angular/common';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { AzureBlobService } from 'src/app/api/services';
import { AuthService } from 'src/app/services/auth.service';
import { BiddingFile, SummaryBiddingAllInfo } from '../../../../../../../api/models';

@Component({
  selector: 'app-create-new-memo-attachment',
  templateUrl: './create-new-memo-attachment.component.html',
  styleUrls: ['./create-new-memo-attachment.component.css']
})
export class CreateNewMemoAttachmentComponent implements OnInit {

  @Input() biddingAllInfo!: SummaryBiddingAllInfo;
  @Output() documentFileListChange = new EventEmitter<BiddingFile[]>();

  attachment: BiddingFile[] = [];

  url: string = "/api/AzureBlob/UploadBlob";
  acceptedFile: string[] = ["image/jpeg,image/jpg,image/png,image/bmp,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/zip,application/x-7z-compressed,text/csv,application/vnd.ms-outloo,application/pdf"];
  acceptedFileType: string = "image/jpeg,image/jpg,image/png,image/bmp,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/zip,application/x-7z-compressed,text/csv,application/vnd.ms-outloo,application/pdf";
  fileListTOR: BiddingFile[] = [];

  documentFileSizeInByte: number = 0;
  documentFileType: string = "";


  constructor(
    private azureBlobService: AzureBlobService,
    private datepipe: DatePipe,
    private modal: NzModalService,
    private msg: NzMessageService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  async onUploadChange({ file, fileList }: NzUploadChangeParam): Promise<void> {
    const status = file.status;

    if (status !== 'uploading') {
      console.log(file, fileList);

    }

    if (status === 'uploading') {
      const msgLoad = this.msg.loading(`${file.name} uploading.`, { nzDuration: 0 });
    }

    if (status === 'done') {

      // this.msg.remove(this.msgLoad);
      // this.msg.success(`${file.name} file uploaded successfully.`);
      let response = JSON.parse(file.response);

      console.log("response: ", file.response);
      var objRes: BiddingFile = {
        fileId: response.fileID,
        fileGroup: "BiddingDocumentTOR",
        fileName: response.fileName,
        fileSizeInByte: response.fileSizeinByte,
        lastModifiedDate: response.lastModified,
        modifiedByName: this.authService.currectUserFullName()
      }

      objRes.lastModifiedDate = this.datepipe.transform(objRes.lastModifiedDate, "yyyy-MM-dd HH:mm")!;
      // objRes.fileGroup = this.fileUploadGroup;
      // objRes.fileSizeinByte = objRes.fileSizeInByte! / 1000000;
      // objRes.lastModified = this.datepipe.transform(objRes.lastModified.split("T")[0],"yyyy-MM-dd " + new Date().toLocaleString('en-GB').split(',')[1])!;
      // objRes.lastModified = this.datepipe.transform(objRes.lastModified, "yyyy-MM-dd HH:mm")!;

      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: objRes.fileId!,
        fileName: objRes.fileName!,
      }).toPromise()
        .then(
          response => {
            if (response.url != null) {
              objRes.fileDownloadLink = response.url;
            }
          }

        )
        .catch(
          err => {
            console.log("Get Downlink Failed");
        })

        console.log(objRes.lastModifiedDate);



    }
    else if (status === 'error') {
      this.msg.remove();
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  async downloadFile(fileName: string, fileID: string) {
    console.log("FileName: ", fileName, " FileId ", fileID);

    await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: fileID,
      fileName: fileName,
    }).toPromise().then(
      response => {
        console.log("link : ", response.url);
      }
    )

    return;
  }


  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    console.log("Before Upload");
    console.log("File Type: ", file.type);

    let isFileTypeValid: boolean = false;
    for (let i = 0; i < this.documentFileType[0].length; i++) {
      if (file.type == this.documentFileType[0][i]) {
        isFileTypeValid = true;
        break;
      }
    }

    if (isFileTypeValid == false) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "Invalid File Type"
      });

      return false;
    }

    if (file.size! > this.documentFileSizeInByte) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "File size limit < 20 MB"
      });

      return false;
    }

    return true;
  }

  onUploadFile = (item: NzUploadXHRArgs) => {

    const formData = new FormData();
    formData.append('file', item.file as any); // tslint:disable-next-line:no-any

    return this.azureBlobService.apiAzureBlobUploadBlobPut$Response({
      body: {
        files: item.file as any,
      },

    }).subscribe((e: HttpEvent<any>) => {
      if (e.type === HttpEventType.UploadProgress) {
        if (e.total! > 0) {
          (e as any).percent = e.loaded / e.total! * 100; // tslint:disable-next-line:no-any
        }
        // To process the upload progress bar, you must specify the `percent` attribute to indicate progress.
        item.onProgress!(e, item.file);
      } else if (e instanceof HttpResponse) { /* success */
        console.log("FILE DOCUMENT TOR UPLOADED");
        item.onSuccess!(e.body, item.file, e);
      }
    }, (err) => { /* error */
      item.onError!(err, item.file);
    });


  }

  fileData = (item: NzUploadFile) => {
    item.uid = Guid.create().toString();

    return item;
  }


  deleteItem(fileId: string) {
    console.log(fileId);

    this.fileListTOR =  this.fileListTOR.filter(function(item) {
      return item.fileId !== fileId;
    })

    this.documentFileListChange.emit(this.fileListTOR);

  }

}
