<nz-layout class="content">
    <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Summary Price'" [nzActive]="true">
            <div nz-row>

                <div nz-col [nzXs]="24" [nzSm]="24">
                    <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6">Export</nz-form-label>
                        <nz-form-control [nzXs]="6" [nzSm]="1">

                            <button nz-button (click)="exportSummaryPriceToJPEG()">
                                <i nz-icon nzType="picture" nzTheme="outline"></i>
                                JPEG
                            </button>
                        </nz-form-control>

                        <nz-form-label [nzXs]="6" [nzSm]="1" nzNoColon></nz-form-label>
                        <nz-form-control [nzXs]="6" [nzSm]="1">
                            <button nz-button (click)="exportSummaryPriceToExcel()">
                                <i nz-icon nzType="table" nzTheme="outline"></i>
                                Excel
                            </button>
                        </nz-form-control>

                    </nz-form-item>
                </div>

                <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
                    <nz-table class="summaryPrice" id="summaryPrice" [nzShowPagination]="false"
                        [nzScroll]="{x: '800px'}" nzTableLayout="fixed" #groupingTable
                        [nzData]="biddingAllInfo.vendorBiddingInfo!"
                        [nzBordered]="true">
                        <thead>
                            <tr>
                                <th nzLeft rowspan="2" nzWidth="150px">Material Code</th>
                                <th nzWidth="175px" rowspan="2">Quantity</th>
                                <th nzWidth="75px" rowspan="2">Unit</th>

                                <!-- Vendor Name -->
                                <th *ngFor="let item of biddingAllInfo.vendorBiddingInfo;index as number" colSpan="3"
                                    style="background-color: cornsilk;"> {{item.detail!.vendorName}}
                                </th>

                            </tr>
                            <tr>

                                <ng-container *ngFor="let item of groupingTable.data;index as i">
                                    <th style="background-color: #FED299;" rowspan="1">ราคาครั้งก่อน (บาท)</th>
                                    <th style="background-color: #81D8DA;" rowspan="1">ราคาเสนอครั้งแรก (บาท)</th>
                                    <th style="background-color: #ACE4AC;" rowspan="1">ราคาเสนอครั้งสุดท้าย (บาท)</th>
                                </ng-container>

                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of itemList;index as i">
                                <td style="text-align: left !important;">{{ data.matCode }}</td>
                                <td style="text-align: right !important;">
                                    {{data.quantity | number:'1.2-2'}}
                                </td>
                                <td>{{data.unit}}</td>
                                <!-- Vendor -->
                                <ng-container *ngFor="let item of groupingTable.data;index as j">
                                    <!-- Last Price -->
                                    <td *ngIf="item.itemList![i].rejectReason === '-'"
                                        style="background-color: #FED299; text-align: right !important;">
                                        {{item.itemList![i].lastPrice | number:'1.2-2'}}
                                    </td>
                                    <td *ngIf="item.itemList![i].rejectReason !== '-'" style="background-color: black;">

                                    </td>

                                    <!-- First Price -->
                                    <td *ngIf="item.itemList![i].rejectReason === '-'"
                                        style="background-color: #81D8DA; text-align: right !important;">
                                        {{item.itemList![i].pricePerUnit | number:'1.2-2'}}
                                    </td>
                                    <td *ngIf="item.itemList![i].rejectReason !== '-'" style="background-color: black;">

                                    </td>

                                    <!-- Final Price -->
                                    <td *ngIf="item.itemList![i].rejectReason === '-'"
                                        style="background-color: #ACE4AC; text-align: right !important;">
                                        {{summaryCost[j].item[i].finalPricePerUnit | number:'1.2-2'}}
                                    </td>
                                    <td *ngIf="item.itemList![i].rejectReason !== '-'" style="background-color: black;">

                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>

                    </nz-table>
                </div>


            </div>
        </nz-collapse-panel>
    </nz-collapse>

</nz-layout>
