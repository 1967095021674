import { VendorBiddingInfo } from './../../../../../../../../api/models/vendor-bidding-info';
import { MasterTheArchCountry } from './../../../../../../../../api/models/master-the-arch-country';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterCurrency, VChembiMstPaymentTerm, MasterIncoterm1, BiddingDetail } from 'src/app/api/models';
import { differenceInCalendarDays, setHours } from 'date-fns';
import { FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { NzStatus } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'app-vendor-detail',
  templateUrl: './vendor-detail.component.html',
  styleUrls: ['./vendor-detail.component.css']
})
export class VendorDetailComponent implements OnInit {

  @Input() isSubmitted: boolean = false;
  @Input() biddingInfo: VendorBiddingInfo = {};
  @Input() currencyList: MasterCurrency[] = [];
  @Input() paymentTermList: VChembiMstPaymentTerm[] = [];
  @Input() incotermList: MasterIncoterm1[] = [];
  @Input() biddingDetailForm!: FormGroup;
  @Input() quotationValidityStatus: NzStatus = "";

  @Output() biddingDetailChange = new EventEmitter<BiddingDetail>();
  constructor() {
    // this.biddingDetail = this.biddingInfo.detail!;
  }

  biddingDetail: BiddingDetail = {};
  contractPeriod: Date[] = [];
  quotationValidityRange: Date[] = [];
  quotationValidityDate!: Date;
  // disabledDate = (current: Date): boolean =>
  //   differenceInCalendarDays(new Date(this.biddingInfo.detail?.quotationValidityFromDate!), new Date(this.biddingInfo.detail?.quotationValidityToDate!)) < 0;

  // disabledQuotationDate = (current: Date): boolean => current <= new Date(this.biddingInfo.header?.quotationValidityFrom!) || current >= new Date(this.biddingInfo.header?.quotationValidityTo!);
  disabledContractPeroidDate = (current: Date): boolean => current < new Date();
  disabledQuotationDate = (current: Date): boolean =>  moment(current).add(1, "days").toDate() < new Date(this.biddingInfo.header?.quotationValidityFrom!) || moment(current).subtract(1, "days").toDate() > new Date(this.biddingInfo.header?.quotationValidityTo!);

  // && current > new Date(this.biddingInfo.detail?.quotationValidityToDate!)
  ngOnInit(): void {

    if(this.isSubmitted)
    {
      this.quotationValidityDate = new Date(this.biddingInfo.detail?.quotationValidityDate!);
    }

    this.contractPeriod[0] = new Date(this.biddingInfo.header?.contractPeriodStart!);
    this.contractPeriod[1] = new Date(this.biddingInfo.header?.contractPeriodEnd!);
    this.biddingDetail = this.biddingInfo.detail!;
    this.quotationValidityRange[0] = new Date(this.biddingInfo.header?.quotationValidityFrom!);
    this.quotationValidityRange[1] = new Date(this.biddingInfo.header?.quotationValidityTo!);

    this.biddingDetailForm.patchValue({
      contractPeriodOfferStart: new Date(this.biddingInfo.header?.contractPeriodStart!),
      contractPeriodOfferEnd: new Date(this.biddingInfo.header?.contractPeriodEnd!),
      incoTerm: this.biddingInfo.header?.incoterm,
      paymentTerm: this.biddingInfo.header?.paymentTerm,
    })

    if(this.biddingDetail.quotationValidityDate)
    {
      this.quotationValidityDate = new Date(this.biddingDetail.quotationValidityDate);

      this.biddingDetailForm.patchValue({
        quotationValidityDate: new Date(this.biddingDetail.quotationValidityDate)
      })
    }
    // this.quotationValidity = new Date(this.biddingInfo.detail?.quotationValidityToDate!);
    // console.log(this.biddingDetail);
  }

  onChange(result: Date[]): void {
    this.biddingDetailForm.patchValue({
      contractPeriodOfferStart: new Date(result[0]).toJSON(),
      contractPeriodOfferEnd: new Date(result[1]).toJSON()
    })
  }

  onChangeQuotationValidity(result: Date): void {
    this.biddingDetailForm.patchValue({
      quotationValidityDate: result
    })
  }

  onBiddingDetailFormChange(form: FormGroup)
  {
    console.log("",form);
  }

}


