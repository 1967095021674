<nz-layout class="content" >
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Activity Log'" [nzActive]="false">
      <nz-table nzBordered class="table-log" [nzData]="logData" [nzShowPagination]="false" [nzFrontPagination]="false" [nzScroll]="{x: '1000px'}">
        <thead>
          <tr>
            <th nzWidth="100px">Name</th>
            <th nzWidth="60px">Action Date</th>
            <th nzWidth="100px">Activity</th>
            <th nzWidth="100px">Action</th>
            <th nzWidth="150px">Remark</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let data of logData">
            <td>{{data.editedByName}}</td>
            <td>{{data.editedDate | date:"d MMM yyyy HH:mm"}}</td>
            <td>{{data.activity}}</td>
            <td>{{data.action}}</td>
            <td>{{data.remark}}</td>
          </tr>
        </tbody>
      </nz-table>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
