import { AuthService } from 'src/app/services/auth.service';
import { RcfAllInfo } from './../../../../../api/models/rcf-all-info';
import { async } from '@angular/core/testing';
import { MdmGetMemberInfoResponseData } from './../../../../../api/models/mdm-get-member-info-response-data';
import { MdmService } from './../../../../../api/services/mdm.service';
import { MdmGetMemberInfoResponse } from './../../../../../api/models/mdm-get-member-info-response';
import { UserDetailMDM, MDMGetMemberInfoResponseData } from './../../../../../models/UserDetailMDM';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, NgForm, Validators, FormControl } from '@angular/forms';
import { UserDetailSSO } from './../../../../../models/UserDetailSSO';
import { RCF, RequestInformation } from './../../rcf.models';
import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-requester-info',
  templateUrl: './requester-info.component.html',
  styleUrls: ['./requester-info.component.css'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }]
})
export class RequesterInfoComponent implements OnInit {

  @Input() steps!: number;
  @Input() requestContractAllInfo!: RcfAllInfo[];
  @Input() isSubmitted: boolean = false;
  @Output() requesterDataChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() requesterDetailMDMChange = new EventEmitter<MdmGetMemberInfoResponseData>();
  @Output() requesterDataValid = new EventEmitter<boolean>();

  requesterInfoForm!: FormGroup;
  // requester: MdmGetMemberInfoResponseData[] = [];
  requester: any;
  // selectedValue: any;
  defaultRequester: any;
  selectedRequester: any;
  optionList: MdmGetMemberInfoResponseData[] = [];
  isLoading: boolean = false;

  rcf_form!: RCF;
  user_detail!: UserDetailSSO;
  user_detail_MDM!: MdmGetMemberInfoResponse;
  // user_detail_MDM!: MdmGetMemberInfoResponse;
  date!: string;
  dateFormat: string | null;
  validatorForm: boolean = false;
  isDisabled: boolean = true;
  constructor(
    private fb: FormBuilder,
    private parent: FormGroupDirective,
    private datepipe: DatePipe,
    private mdmService: MdmService,
    private route: ActivatedRoute,
    private auth:AuthService,
  ) {
    this.user_detail = JSON.parse(localStorage.getItem("user_data") || "{}");
    this.date = new Date().toLocaleString('en-GB').split(',')[1]
    this.dateFormat = this.datepipe.transform(new Date(), "dd MMM yyyy" + this.date);
    const pk = this.route.snapshot.queryParamMap.get("pk");

    if (pk === null) {
      this.getMemberInfo();
    }

    this.requesterInfoForm = this.fb.group({
      creater: [this.auth.currectUserFullName(), [Validators.required]],
      create_date: [this.dateFormat],
      requester: [this.auth.currectUserFullName()],
      position: [""],
      section: [""],
      department: [this.user_detail.information.department],
      division: [""],
      company: [this.user_detail.information.company],
      cost_center: [""],
      contact: [""],
      tel: ["", [Validators.pattern(/((0)(\d{1,2}\-?\d{3}\-?\d{4}))/), Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
      email: ["", [Validators.email, Validators.required]],
    })

  }

  onCheckPhonenumber(value: string) {

  }

  ngOnInit(): void {
    this.updateData(this.requesterInfoForm);
  }

  async ngOnChanges(change: SimpleChanges) {
    if (this.isSubmitted == true && change.isSubmitted.isFirstChange() == false) {
      this.requesterInfoForm.controls['tel'].markAsTouched();
      this.requesterInfoForm.controls['tel'].updateValueAndValidity();
    }
    if (this.steps != 0) {
      this.isDisabled = false;
    }
    if (change.hasOwnProperty("requestContractAllInfo") && change.requestContractAllInfo.currentValue.length != 0) {

      // let dateFormat = change.requestContractAllInfo.currentValue[0].createdDate.split('T')[0] + " " + change.requestContractAllInfo.currentValue[0].createdDate.split('T')[1];
      this.dateFormat = this.datepipe.transform(new Date(change.requestContractAllInfo.currentValue[0].createdDate), "d MMM yyyy HH:mm");
      this.requesterInfoForm.patchValue({
        create_date: this.datepipe.transform(new Date(change.requestContractAllInfo.currentValue[0].createdDate), "d MMM yyyy HH:mm"),
        requester: change.requestContractAllInfo.currentValue[0].requesterName,
        position: change.requestContractAllInfo.currentValue[0].requesterPosition,
        section: change.requestContractAllInfo.currentValue[0].requesterSection,
        department: change.requestContractAllInfo.currentValue[0].requesterDepartment,
        division: change.requestContractAllInfo.currentValue[0].requesterDivision,
        cost_center: change.requestContractAllInfo.currentValue[0].requesterCostCenter,
        contact: [''],
        tel: change.requestContractAllInfo.currentValue[0].requesterTelNo,
        email: change.requestContractAllInfo.currentValue[0].requesterEmail,
      });

      if (change.requestContractAllInfo.currentValue[0].status == "Return" || change.requestContractAllInfo.currentValue[0].status == "Draft") {
        this.getMemberInfo();
      }
      else {
        this.requesterInfoForm.disable();
      }


      this.selectedRequester = [change.requestContractAllInfo.currentValue[0].requesterName];
      this.updateData(this.requesterInfoForm);
    }


  }

  async getMemberInfo() {

    await this.mdmService.apiMdmGetMemberInfoByEmailPost$Json$Response({
      email: this.user_detail.email
    })
      .toPromise()
      .then(
        response => {
          if (response.ok) {
            this.user_detail_MDM = response.body;
            console.log("MDM : ", this.user_detail_MDM);

            if (this.user_detail_MDM.responseData?.length != 0) {
              this.requesterInfoForm.patchValue({
                position: this.user_detail_MDM.responseData![0].positionName,
                department: this.user_detail_MDM.responseData![0].depName,
                section: this.user_detail_MDM.responseData![0].secName,
                division: this.user_detail_MDM.responseData![0].divName,
                cost_center: this.user_detail_MDM.responseData![0].costCenterDept,
                company: this.user_detail_MDM.responseData![0].companyName,
                email: this.user_detail_MDM.responseData![0].email
              });

              this.selectedRequester = [this.user_detail_MDM.responseData![0].eFullName];
              this.requesterDetailMDMChange.emit(this.user_detail_MDM.responseData![0]);
            }
            else {
              console.log("NO MDM INFO");

              this.user_detail_MDM.responseData = [];

              this.requesterInfoForm.patchValue({
                position: [this.user_detail.information.title],
                department: [this.user_detail.information.department],
                company: [this.user_detail.information.company],
              });


              // this.user_detail_MDM.responseData.push(nObj[0]);
              // this.requesterDetailMDMChange.emit(nObj[0]);
              this.selectedRequester = [this.user_detail.information.first_name + " " + this.user_detail.information.last_name];
            }

          }

        })
      .catch(
        error => {
          console.log("Error on GetMDM Info: ", error);
        }
      )

  }

  selectRequester(event: number) {
    this.requester = null;

    if (event !== null) {
      this.selectedRequester = [this.optionList[event].eFullName];
      this.requesterInfoForm.patchValue({
        requester: this.optionList[event].eFullName,
        position: this.optionList[event].positionName,
        section: this.optionList[event].secName,
        department: this.optionList[event].depName,
        division: this.optionList[event].divName,
        company: this.optionList[event].companyName,
        cost_center: this.optionList[event].costCenterDept,
        email: this.optionList[event].email,
      });
      this.requesterDetailMDMChange.emit(this.optionList[event]);
      this.updateData(this.requesterInfoForm);
    }

    // console.log("Selcted Value: ", this.selectedValue);
  }

  async onSearch(event: string) {
    this.optionList = [];
    if (event.length >= 3) {
      this.isLoading = true;

      let searchingValueTemp: MdmGetMemberInfoResponseData[] = [];

      await this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
        name: event
      }).toPromise().then(
        response => {
          if (response.ok) {
            this.optionList = [];
            for (let i = 0; i < response.body.responseData!?.length; i++) {
              this.optionList.push(response.body.responseData![i]);
            }

          }
        }
      )
    }

    // this.optionList = [...new Map(this.optionList.map(item => [item.e_FullName, item])).values()]
    this.isLoading = false;
  }

  updateData(event: FormGroup) {
    // this.requesterDataValid.emit(this.requesterInfoForm.valid);
    // console.log("[reqInfoForm] ",event.value);
    this.requesterDataChange.emit(event);
    this.requesterDataValid.emit(this.validatorForm);
    // console.log("[Update Data From Info] isValid: ",this.requesterInfoForm.valid);
  }

}
