<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Conclusion'" [nzActive]="true">
      <form nz-form [formGroup]="conclusionForm" (change)="updateData(conclusionForm.value)">
        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="12" [nzSm]="12" [nzNoColon]="true">
                หน่วยงานจัดหาได้ทำการวิเคราะห์ข้อมูลความเป็นไปได้ในการจัดทำสัญญาแล้ว</nz-form-label>
            </nz-form-item>

          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>สรุปผลดังนี้</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="24" [nzMd]="24">
                <nz-radio-group formControlName="isConclusionsAccept" (ngModelChange)="onConclusionChange()"
                  [nzDisabled]="steps != 2">
                  <label nz-radio [nzValue]="true">สามารถจัดทำสัญญาได้ </label>

                  <!-- <div nz-col nzPush="3" class="checkBox">
                    <nz-checkbox-group style="display: block;" [(ngModel)]="conclusion_reason_select" [ngModelOptions]="{standalone: true}" (nzOnChange)="onCheckboxChange(conclusion_reason_select)"></nz-checkbox-group>
                  </div> -->

                  <div nz-col nzPush="3" *ngIf="conclusionForm.get('isConclusionsAccept')?.value == true">
                    <nz-checkbox-wrapper ngDefaultControl [(ngModel)]="conclusion_reason_select"
                      [ngModelOptions]="{standalone: true}" (nzOnChange)="onCheckboxChange($event)">
                      <div nz-col *ngFor="let option of conclusionOption">
                        <label nz-checkbox [nzValue]="option.value" [(nzChecked)]="option.checked"
                          (nzCheckedChange)="onCheckOther(option.value!)"
                          [nzDisabled]="steps != 2">{{option.label}}</label>
                      </div>

                      <div nz-col>
                        <textarea [hidden]="!isCheckOther" nz-input [(ngModel)]="conclusionOther"
                          (ngModelChange)="onConclusionOtherChange($event)" [ngModelOptions]="{standalone: true}"
                          [disabled]="steps != 2" [nzAutosize]="{ minRows: 3, maxRows: 10 }" style="width: 400px;">

                        </textarea>
                      </div>

                    </nz-checkbox-wrapper>
                  </div>

                  <label nz-radio [nzValue]="false">ไม่สามารถจัดทำสัญญาได้ เนื่องจาก</label>
                  <textarea nz-input formControlName="conclusionDeniedReason"
                    [hidden]="conclusionForm.controls['isConclusionsAccept'].value != false" style="width: 400px;"
                    (change)="onConclusionReasonChange($event)" [nzAutosize]="{ minRows: 3, maxRows: 10 }">
                  </textarea>
                </nz-radio-group>
              </nz-form-control>

            </nz-form-item>
          </div>

        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
