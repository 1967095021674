import { NzStatus } from 'ng-zorro-antd/core/types';
import { ItemListStatus } from './component/model/itemlistStatus';
import { VendorBiddingService } from './../../../../../../api/services/vendor-bidding.service';
import { VendorBiddingInfo } from './../../../../../../api/models/vendor-bidding-info';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BiddingItemList } from './../../../../../../api/models/bidding-item-list';
import { MasterConfig } from './../../../../../../api/models/master-config';
import { ConfigurationService } from './../../../../../../api/services/configuration.service';
import { MasterSimpleValue } from './../../../../../../api/models/master-simple-value';
import { AuthService } from './../../../../../../services/auth.service';
import { MasterTheArchCountry } from './../../../../../../api/models/master-the-arch-country';
import { MasterTheArchService } from './../../../../../../api/services/master-the-arch.service';
import { async } from '@angular/core/testing';
import { Router, ActivatedRoute } from '@angular/router';
import { NewBiddingService } from './../../../../../../api/services/new-bidding.service';
import { MasterService } from './../../../../../../api/services/master.service';
import { Component, OnInit } from '@angular/core';
import { BiddingDetail, BiddingFile, MasterCurrency, MasterIncoterm1, VChembiMstPaymentTerm } from 'src/app/api/models';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-vendor-bidding',
  templateUrl: './vendor-bidding.component.html',
  styleUrls: ['./vendor-bidding.component.css']
})
export class VendorBiddingComponent implements OnInit {

  isDataLoading: boolean = true;
  biddingInfo!: VendorBiddingInfo;

  actionForm: FormGroup;

  rejectReasonList: MasterSimpleValue[] = [];
  notifyEmailSubjectList: MasterSimpleValue[] = [];
  currencyList: MasterCurrency[] = [];
  paymentTermList: VChembiMstPaymentTerm[] = [];
  incotermList: MasterIncoterm1[] = [];
  countryList: MasterTheArchCountry[] = [];

  biddingConfig: MasterConfig[] = [];

  biddingItemlist: BiddingItemList[] = [];
  biddingDetailList: BiddingDetail = {};
  biddingAttachment: BiddingFile[] = [];

  itemlistStatus: ItemListStatus[] = [];
  quotationValidityStatus: NzStatus = "";

  biddingDetailForm: FormGroup;
  userRole: string = "";
  docNo: string = "";
  vendorName: string = "";
  remark: string = "";
  isSubmitted: boolean = false;

  constructor(private masterService: MasterService,
    private newBiddingService: NewBiddingService,
    private masterTheArchService: MasterTheArchService,
    private vendorBiddingService: VendorBiddingService,
    private configService: ConfigurationService,
    private route: ActivatedRoute,
    private router: Router,
    private modal: NzModalService,
    private authService: AuthService,
    private fb: FormBuilder
  ) {
    this.actionForm = this.fb.group({
      remark: "",
    })

    this.biddingDetailForm = this.fb.group({
      incoTerm: "",
      paymentTerm: "",
      contractPeriodOfferStart: "",
      contractPeriodOfferEnd: "",
      quotationValidityDate: [""],
    })

  }

  async ngOnInit(): Promise<void> {

    this.isDataLoading = true;
    this.userRole = this.authService.currentUserRole();
    const uniqlink = this.route.snapshot.queryParamMap.get('pklink');
    const pkDetail = this.route.snapshot.queryParamMap.get('pkDetail');

    if (uniqlink !== null) {

      await this.getBiddingDetailAndItemListByUniqLink(uniqlink);
      await this.getCurrencyData();
      await this.getPaymentTermData();
      await this.getIncoTermData();
      await this.getCountryDataFromTheArch();
      await this.getSimpleValueData()
      await this.getBiddingConfig();

    }
    else if (pkDetail !== null) {
      await this.getBiddingDetailAndItemList(pkDetail);
      await this.getCurrencyData();
      await this.getPaymentTermData();
      await this.getIncoTermData();
      await this.getCountryDataFromTheArch();
      await this.getSimpleValueData()
      await this.getBiddingConfig();
    }
    else {
      this.router.navigate(["**"]);
    }

    this.isDataLoading = false;

    console.log("[Init] Vendor Link: ", window.location.search.split("=")[1]);
    console.log("Start Bidding");
  }

  async getBiddingDetail(pklink: string) {
    await this.newBiddingService.apiNewBiddingRequestReadBiddingDetailGet$Json({
      pkBiddingDetail: pklink
    }).toPromise()
      .then(
        response => {
          console.log("", response);

        }
      ).catch(
        error => {
          console.log("", error);

          this.router.navigate(["**"]);
        }
      )
  }

  async getBiddingHeader(pk: string) {
    await this.newBiddingService.apiNewBiddingRequestReadBiddingHeaderGet$Json({
      pkBiddingHeader: pk
    }).toPromise()
      .then(
        response => {
          console.log("", response);
        }
      )
      .catch(
        error => {
          console.log("", error);
        }
      )
  }

  async getBiddingDetailAndItemListByUniqLink(uniqLink: string) {
    await this.vendorBiddingService.apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Json({
      UniqLink: uniqLink
    }).toPromise()
      .then(
        response => {
          this.biddingInfo = response;
          this.docNo = this.biddingInfo.header?.docNo!;
          this.vendorName = this.biddingInfo.detail?.vendorName!;
          this.biddingInfo.detail?.status === "Submitted" ? this.isSubmitted = true : this.isSubmitted = false;

          this.biddingInfo.itemList?.forEach(item => {
            let tempCheck: ItemListStatus = {
              brand: {
                status: ""
              },
              currency: {
                status: ""
              },
              deliveryTimes: {
                status: ""
              },
              minimumOrderQuantity: {
                status: ""
              },
              pricePerUnit: {
                status: ""
              },
              sourceOfOrigin: {
                status: ""
              },
              total: {
                status: ""
              }
            };

            this.itemlistStatus.push(tempCheck);
          })
          console.log("bidding Info: ", this.biddingInfo);
        }
      )
      .catch(
        error => {
          this.router.navigate(["**"]);
        }
      )

  }

  async getBiddingDetailAndItemList(pkDetail: string) {
    await this.vendorBiddingService.apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Json({
      pkBiddingDetail: pkDetail
    }).toPromise()
      .then(
        response => {
          this.biddingInfo = response;
          this.docNo = this.biddingInfo.header?.docNo!;
          this.vendorName = this.biddingInfo.detail?.vendorName!;
          this.biddingInfo.detail?.status === "Submitted" ? this.isSubmitted = true : this.isSubmitted = false;

          this.biddingInfo.itemList?.forEach(item => {
            let tempCheck: ItemListStatus = {
              brand: {
                status: ""
              },
              currency: {
                status: ""
              },
              deliveryTimes: {
                status: ""
              },
              minimumOrderQuantity: {
                status: ""
              },
              pricePerUnit: {
                status: ""
              },
              sourceOfOrigin: {
                status: ""
              },
              total: {
                status: ""
              }
            };

            this.itemlistStatus.push(tempCheck);
          })

          console.log("bidding Info: ", this.biddingInfo);
        }
      )
      .catch(
        error => {
          this.router.navigate(["**"]);
        }
      )

  }

  async getCurrencyData() {
    await this.masterService.apiMasterGetCurrencyGet$Json()
      .toPromise()
      .then(
        response => {
          this.currencyList = response;

          for (let i = 0; i < this.currencyList.length; i++) {
            this.currencyList[i].currency = this.currencyList[i].currency?.trimEnd();
          }

        }
      )
      .catch(
        error => {
          console.log("[Vendor] Error on GetCurrency: ", error);

          this.modal.error({
            nzTitle: "Server Error",
            nzContent: "Cannot get data from server",
            nzOkText: null
          })
        }
      )
  }

  async getPaymentTermData() {
    await this.masterService.apiMasterGetPaymentTermGet$Json()
      .toPromise()
      .then(
        response => {
          this.paymentTermList = response;
        }
      )
      .catch(
        error => {
          console.log("[Vendor] Error on GetPayment: ", error);

          this.modal.error({
            nzTitle: "Server Error",
            nzContent: "Cannot get data from server",
            nzOkText: null
          })
        }
      )

  }

  async getIncoTermData() {
    await this.masterService.apiMasterGetIncotermGet$Json()
      .toPromise()
      .then(
        response => {
          this.incotermList = response;
        }
      )
      .catch(
        error => {
          console.log("[Vendor] Error on GetIncoTerm: ", error);

          this.modal.error({
            nzTitle: "Server Error",
            nzContent: "Cannot get data from server",
            nzOkText: null
          })
        }
      )
  }

  async getCountryDataFromTheArch() {
    await this.masterTheArchService.apiMasterTheArchGetCountryGet$Json()
      .toPromise()
      .then(
        response => {
          this.countryList = response;
        }
      )
      .catch(
        error => {
          console.log("[Vendor] Error on GetCountryFromTheArch ", error);

          this.modal.error({
            nzTitle: "Server Error",
            nzContent: "Cannot get data from server",
            nzOkText: null
          })
        }
      )
  }

  async getSimpleValueData() {
    await this.masterService.apiMasterGetSimpleValueByGroupGet$Json({
      groupName: "Bidding",
    }).toPromise()
      .then(
        response => {
          var simpleValue: MasterSimpleValue[] = response;

          this.notifyEmailSubjectList = simpleValue.filter(item => item.key == "NotifyEmailSubject");
          this.rejectReasonList = simpleValue.filter(item => item.key == "RejectReason");

        }
      )
      .catch(
        error => {
          console.log("[Vendor] Error on GetSimpleValue", error);

          this.modal.error({
            nzTitle: "Server Error",
            nzContent: "Cannot get data from server",
            nzOkText: null
          })
        }
      )
  }

  async getBiddingConfig() {
    await this.configService.apiConfigurationGetAllConfigByGroupGet$Json({
      Group: "Bidding"
    }).toPromise()
      .then(
        response => {
          this.biddingConfig = response;
        }
      )
      .catch(
        error => {
          console.log("[Vendor] Error on GetConfig ", error);

          this.modal.error({
            nzTitle: "Server Error",
            nzContent: "Cannot get data from server",
            nzOkText: null
          })
        }
      )
  }

  onBiddingItemlistChange(itemlist: BiddingItemList[]) {
    this.biddingItemlist = itemlist;
    // console.log("onBiddingItemlist: ", itemlist);
  }

  onBiddingDetailChange(detail: BiddingDetail) {
    this.biddingDetailList = detail;
    // console.log("onBiddingDetailChange: ", detail);
  }

  onBiddingAttachmentChange(attachment: BiddingFile[]) {
    this.biddingAttachment = attachment;
    //  console.log("onBiddingAttachmentChange: ", attachment);
  }

  onDeleteDraft() {

  }

  onPreviewEmail() {

  }

  showSaveConfirm() {
    const modelSave = this.modal.confirm({
      nzTitle: 'Save',
      nzContent: 'Do you want to save this form?',
      nzOkText: 'Save',
      nzCancelText: 'Cancel',
      nzOkLoading: false,
      nzOnOk: () => new Promise(async (resolve, reject) => {

        modelSave.updateConfig({
          nzOkLoading: true,
        })

        this.biddingDetailList.remark = this.actionForm.get("remark")?.value;

        this.biddingAttachment.forEach(item => {
          item.lastModifiedDate = new Date(item.lastModifiedDate!).toJSON();
        })

        this.biddingDetailList = this.biddingInfo.detail!;

        if (this.biddingInfo.header?.isQuotationValidity) {
          if (this.biddingDetailForm.value.quotationValidityDate !== "") {
            this.biddingDetailList.quotationValidityDate = new Date(this.biddingDetailForm.value.quotationValidityDate).toJSON();
          }
          else {
            this.biddingDetailList.quotationValidityDate = new Date().toJSON();
          }
        }
        else {
          this.biddingDetailList.quotationValidityDate = new Date().toJSON();
        }

        console.log("Bidding Header: ", this.biddingInfo.header);
        console.log("Bidding Detail: ", this.biddingDetailList);
        console.log("Bidding Attachment: ", this.biddingAttachment);
        console.log("Bidding Itemlist: ", this.biddingItemlist);

        this.vendorBiddingService.apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost({
          body: {
            action: "Save",
            detail: this.biddingDetailList,
            files: this.biddingAttachment,
            itemList: this.biddingItemlist
          }
        }).toPromise()
          .then(
            response => {

              modelSave.updateConfig({
                nzOkLoading: false,
              })

              modelSave.close();

              this.modal.success({
                nzTitle: "Complete",
                nzContent: "Save successfully",
                nzOkText: "Close",
                nzOnOk: () => {
                  console.log("Submit: ", this.authService.currentUserRole());

                }
              })

            }
          )
          .catch(
            error => {

              modelSave.updateConfig({
                nzOkLoading: false,
              })

              modelSave.close();

              this.modal.error({
                nzTitle: "Error",
                nzContent: "Server Error, Please try again later.",
                nzOkText: "Close",
              })
              console.log(error);
            })
      }
      )
    })
  }

  showSubmitConfirm() {
    const modelSubmit = this.modal.confirm({
      nzTitle: 'Submit',
      nzContent: 'Do you want to submit this form?',
      nzOkText: 'Submit',
      nzCancelText: 'Cancel',
      nzOkLoading: false,
      nzOnOk: () => new Promise(async (resolve, reject) => {

        modelSubmit.updateConfig({
          nzOkLoading: true,
        })

        if (this.biddingInfo.header?.isQuotationValidity) {

          if (this.biddingDetailForm.value.quotationValidityDate === "") {

            modelSubmit.updateConfig({
              nzOkLoading: false,
            })

            modelSubmit.close();

            this.modal.error({
              nzTitle: "Error",
              nzContent: "Please select Quotation validity date",
              nzCancelText: null,
              nzOnOk: () => {
                document.getElementById("detail")?.scrollIntoView();
              }
            })

            this.quotationValidityStatus = "error";
            return;
          }
        }
        this.itemlistStatus = [];

        this.biddingItemlist.forEach(item => {

          if (item.rejectReason === "-") {
            if (true) {

              let tempCheck: ItemListStatus = {
                brand: {
                  status: this.biddingInfo.header?.isBrand === true ? (item.brand ? "" : "error") : ""
                },
                currency: {
                  status: item.currency ? "" : "error"
                },
                deliveryTimes: {
                  status: item.deliveryTimes ? "" : "error",
                },
                minimumOrderQuantity: {
                  status: this.biddingInfo.header?.isMinimumOrderQuantity === true ? (item.minimumOrderQuantity ? "" : "error") : "",
                },
                pricePerUnit: {
                  status: item.pricePerUnit || item.pricePerUnit === 0 ? "" : "error",
                },
                sourceOfOrigin: {
                  status: this.biddingInfo.header?.isSourceOfOrigin === true ? (item.sourceOfOrigin ? "" : "error") : "",
                },
                total: {
                  status: item.total ? "" : "error",
                }
              };

              this.itemlistStatus.push(tempCheck);

            }

          }
          else {
            let tempCheck: ItemListStatus = {
              brand: {
                status: ""
              },
              currency: {
                status: ""
              },
              deliveryTimes: {
                status: ""
              },
              minimumOrderQuantity: {
                status: ""
              },
              pricePerUnit: {
                status: ""
              },
              sourceOfOrigin: {
                status: ""
              },
              total: {
                status: ""
              }
            };

            this.itemlistStatus.push(tempCheck);
          }

        });

        let isItemListValid: boolean = true;
        this.itemlistStatus.forEach(item => {

          if (item.brand.status == "error" ||
            item.currency.status == "error" ||
            item.deliveryTimes.status == "error" ||
            item.minimumOrderQuantity.status == "error" ||
            item.pricePerUnit.status == "error" ||
            item.sourceOfOrigin.status == "error" ||
            item.total.status == "error") {

            isItemListValid = false;
          }
        })

        if (!isItemListValid) {

          modelSubmit.updateConfig({
            nzOkLoading: false,
          })

          modelSubmit.close();

          this.modal.warning({
            nzTitle: "Error",
            nzContent: "Please fill empty item list",
            nzOkText: "Close",
            nzOnOk: () => {
              document.getElementById("item-list")?.scrollIntoView();
            }
          })

          return;
        }


        this.biddingDetailList.remark = this.actionForm.get("remark")?.value;

        this.biddingAttachment.forEach(item => {
          item.lastModifiedDate = new Date(item.lastModifiedDate!).toJSON();
        })

        this.biddingDetailList = this.biddingInfo.detail!;

        // this.biddingDetailList.quotationValidityDate = this.biddingDetailForm.get("quotationValidityDate")?.value
        if (this.biddingInfo.header?.isQuotationValidity) {
          if (this.biddingDetailForm.value.quotationValidityDate !== "") {
            this.biddingDetailList.quotationValidityDate = new Date(this.biddingDetailForm.value.quotationValidityDate).toJSON();
          }
          else {
            this.biddingDetailList.quotationValidityDate = new Date().toJSON();
          }
        }
        else {
          this.biddingDetailList.quotationValidityDate = new Date().toJSON();
        }

        console.log("Bidding Header: ", this.biddingInfo.header);
        console.log("Bidding Detail: ", this.biddingDetailList);
        console.log("Bidding Attachment: ", this.biddingAttachment);
        console.log("Bidding Itemlist: ", this.biddingItemlist);

        this.vendorBiddingService.apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost({
          body: {
            action: "Submit",
            detail: this.biddingDetailList,
            files: this.biddingAttachment,
            itemList: this.biddingItemlist
          }
        }).toPromise()
          .then(
            response => {

              modelSubmit.updateConfig({
                nzOkLoading: false,
              })

              this.modal.success({
                nzTitle: "Complete",
                nzContent: "Submitted successfully",
                nzOnOk: () => {
                  if (this.authService.currentUserRole() === "Vendor") {

                    modelSubmit.updateConfig({
                      nzOkLoading: false,
                    })

                    modelSubmit.close();

                    this.router
                      .navigate(['../bidding'], {
                        queryParams: { pklink: this.biddingInfo.detail?.uniqLink },
                      })
                      .then(() => {
                        window.location.reload();
                      })

                  }
                  else {

                    modelSubmit.updateConfig({
                      nzOkLoading: false,
                    })

                    modelSubmit.close();

                    this.router
                      .navigate(['/work-station/bidding-worksheet/bidding-detail'], {
                        queryParams: { pkDetail: this.biddingInfo.detail?.pkbiddingDetail },
                      })
                      .then(() => {
                        window.location.reload();
                      })

                  }
                }
              })
            }
          )
          .catch(
            error => {
              console.log(error);

              modelSubmit.updateConfig({
                nzOkLoading: false,
              })

              modelSubmit.close();

              this.modal.error({
                nzTitle: "Error",
                nzContent: "Server Error, Please try again later.",
                nzOkText: "Close",
              })
              console.log(error);

            })
      }
      )
    })
  }
}
