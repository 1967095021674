<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" nzTip="Loading..." [nzSize]="'large'">

    <div nz-row class="row">
      <nz-card *ngIf="!isDataLoading">
        <app-vendor-bidding-header [biddingInfo]="biddingInfo"> </app-vendor-bidding-header>
      </nz-card>

      <div nz-col id="detail" nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
        <app-vendor-detail
          [currencyList]="currencyList"
          [paymentTermList]="paymentTermList"
          [incotermList]="incotermList"
          [biddingInfo]="biddingInfo"
          [biddingDetailForm]="biddingDetailForm"
          (biddingDetailChange)="onBiddingDetailChange($event)"
          [isSubmitted]="isSubmitted"
          [quotationValidityStatus]="quotationValidityStatus"
        ></app-vendor-detail>
      </div>


      <div class="panel-content">
        <div nz-row class="collapse-row">
          <div nz-col id="item-list" nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-vendor-item-list
              [biddingInfo]="biddingInfo"
              [countryList]="countryList"
              [currencyList]="currencyList"
              [rejectReasonList]="rejectReasonList"
              [isSubmitted]="isSubmitted"
              [itemlistStatus]="itemlistStatus"
              (biddingItemlistDataChange)="onBiddingItemlistChange($event)"
              >
            </app-vendor-item-list>
          </div>
        </div>


        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col" >
            <app-vendor-attachment
              [biddingConfig]="biddingConfig"
              [biddingInfo]="biddingInfo"
              [isSubmitted]="isSubmitted"
              (biddingAttachmentChanged)="onBiddingAttachmentChange($event)"
              >
            </app-vendor-attachment>
          </div>
        </div>

        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col" *ngIf="userRole != 'Vendor'">
            <app-vendor-email-config
              [biddingInfo]="biddingInfo"
              [notifyEmailSubjectList]="notifyEmailSubjectList"
              [biddingConfig]="biddingConfig"
              >
            </app-vendor-email-config>
          </div>
        </div>

        <div nz-row class="collapse-row">

          <div id="action-panel" nz-col nzSpan="24" class="collapse-col">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Action Panel'" [nzActive]="true">

                <form nz-form [formGroup]="actionForm">
                  <div nz-row class="content-row">

                    <div nz-col [nzXs]="24" [nzSm]="24">

                      <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6"
                          >Remark</nz-form-label
                        >
                        <nz-form-control [nzXs]="12" [nzSm]="12">
                          <textarea
                            nz-input
                            placeholder="Note"
                            [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                            formControlName="remark"
                          ></textarea>
                        </nz-form-control>
                      </nz-form-item>

                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24">
                      <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">

                        <div class="div-button-save" style="padding: 2%">
                          <button nzSize="default" class="btn-cancel" nz-button nzType="default" [disabled]="isSubmitted"
                            (click)="showSaveConfirm()">
                            Save Draft
                          </button>
                        </div>

                        <div class="div-button-submit" style="padding: 2%">
                          <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit" [disabled]="isSubmitted"
                            (click)="showSubmitConfirm()">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </nz-collapse-panel>
            </nz-collapse>

          </div>


        </div>
      </div>

    </div>


  </nz-spin>
</nz-layout>

