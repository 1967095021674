<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Attachment'" [nzActive]="true">

      <div nz-row [nzGutter]="[16, 16]">
        <div nz-col [nzSpan]="12" *ngFor="let item of biddingAllInfo.vendorBiddingInfo!;index as i">
          <nz-card nzTitle="{{item.detail!.vendorName}}">
            <nz-table class="table-tor" #attachmentFileListTable [nzBordered]="true"
              [nzData]="biddingAllInfo.vendorBiddingInfo![i].fileList!" [nzShowPagination]="false"
              [nzScroll]="{x: '300px', y: '200px'}">
              <thead>
                <tr>
                  <th nzWidth="100px">Attachment</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let file of biddingAllInfo.vendorBiddingInfo![i].fileList;index as j" class="editable-row">
                  <td>
                    <a href="{{file.fileDownloadLink}}" target="_blank">{{file.fileName}}</a>
                  </td>
              </tbody>
            </nz-table>

          </nz-card>

        </div>

        <nz-divider></nz-divider>

        <!-- <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Attachment</nz-form-label>

            <nz-form-control [nzXs]="6" [nzSm]="12">

              <nz-upload
                nzType="drag"
                [nzMultiple]="true"
                (nzChange)="onUploadChange($event)"
                [nzAccept]="documentFileType"
                [nzBeforeUpload]="beforeUpload"
                [nzCustomRequest]="onUploadFile"
                [nzShowUploadList]="false"
                [nzData]="fileData"
                [nzSize]="documentFileSizeInByte"
                [nzOpenFileDialogOnClick]="true"
                [nzMultiple]="true"
                [nzDisabled]="steps !== 0"
                >


                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <p class="ant-upload-hint">Limit file size {{documentFileSizeInByte/1e+6}}MB </p>
              </nz-upload>
            </nz-form-control>

            <i class="icon-tips" nz-icon nzType="question-circle" nzTheme="twotone" nz-tooltip [nzTooltipTitle]="contractTypeTips"></i>
            <ng-template #contractTypeTips >
              <div class="tips">
                <p> TOR : [This is Meaning of TOR]</p>
              </div>
            </ng-template>

          </nz-form-item>
        </div> -->


        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
          <nz-table class="table-tor" #fileList [nzBordered]="true" [nzData]="attachment" [nzScroll]="{x:'1000px'}">
            <thead>
              <tr>
                <th nzWidth="20px">Action</th>
                <th nzWidth="80px">File Name</th>
                <th nzWidth="30px">File Size</th>
                <th nzWidth="60px">Modify Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fileList.data;index as i" class="editable-row">
                <td>
                  <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                    nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteItem(data.fileId!)" nzDanger
                    [disabled]="true">

                    <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                    </i>
                  </button>
                </td>
                <td> <a href="{{data.fileDownloadLink}}" target="_blank">{{data.fileName}}</a></td>
                <td>{{data.fileSizeInByte!/1e+6 | number}} MB</td>
                <td>{{data.lastModifiedDate | date:"d MMM yyyy"}}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>
        <!-- <div class="col" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-table class="table-tor" #fileListTORTable nzBordered>
            <thead>
              <tr>
                <th style="width: 5%;">Action</th>
                <th>File Name</th>
                <th>File Size</th>
                <th>Modify Date</th>
                <th>By Vendor</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fileListTORTable.data;index as i" class="editable-row">
                <td>
                  <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                    nzPopconfirmTitle="Sure to delete?" nzDanger>

                    <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                    </i>
                  </button>
                </td>
                <td> <a href="">{{data.fileName}}</a></td>
                <td>{{data.fileSizeInByte}} MB</td>
                <td>{{data.lastModifiedDate}}</td>
                <td>{{data.Vendor}}</td>
              </tr>
            </tbody>
          </nz-table>
        </div> -->

      </div>

      <div nz-row>
        <!-- <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>TOR Attachment</nz-form-label>

            <nz-form-control [nzXs]="6" [nzSm]="12">

              <nz-upload
                nzType="drag"
                [nzMultiple]="true"
                (nzChange)="onUploadChange($event)"
                [nzAccept]="documentFileType"
                [nzBeforeUpload]="beforeUpload"
                [nzCustomRequest]="onUploadFile"
                [nzShowUploadList]="false"
                [nzData]="fileData"
                [nzSize]="documentFileSizeInByte"
                [nzOpenFileDialogOnClick]="true"
                [nzMultiple]="true"
                [nzDisabled]="steps !== 0"
                >


                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <p class="ant-upload-hint">Limit file size {{documentFileSizeInByte/1e+6}}MB </p>
              </nz-upload>
            </nz-form-control>

            <i class="icon-tips" nz-icon nzType="question-circle" nzTheme="twotone" nz-tooltip [nzTooltipTitle]="contractTypeTips"></i>
            <ng-template #contractTypeTips >
              <div class="tips">
                <p> TOR : [This is Meaning of TOR]</p>
              </div>
            </ng-template>

          </nz-form-item>
        </div> -->

        <!-- <div nz-row>
          <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
            <nz-table class="table-tor" #fileList nzBordered [nzData]="attachment" [nzScroll]="{x:'1000px'}">
              <thead>
                <tr>
                  <th nzWidth="20px">Action</th>
                  <th nzWidth="80px">File Name</th>
                  <th nzWidth="30px">File Size</th>
                  <th nzWidth="60px">Modify Date</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of fileList.data;index as i" class="editable-row">
                  <td>
                    <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                      nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteItem(data.fileId!)" nzDanger
                      [disabled]="steps != 0">

                      <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                      </i>
                    </button>
                  </td>
                  <td> <a href="{{data.fileDownloadLink}}" target="_blank">{{data.fileName}}</a></td>
                  <td>{{data.fileSizeInByte!/1e+6 | number}} MB</td>
                  <td>{{data.lastModifiedDate | date:"d MMM yyyy"}}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>
        </div> -->

      </div>

    </nz-collapse-panel>
  </nz-collapse>

</nz-layout>
