import { ActivatedRoute } from '@angular/router';
import { BiddingLog } from './../../../../../../../../api/models/bidding-log';
import { Component, Input, OnInit } from '@angular/core';
import { BiddingAllInfoAndLog } from '../../../../../../../../api/models';

@Component({
  selector: 'app-new-bidding-action-log',
  templateUrl: './new-bidding-action-log.component.html',
  styleUrls: ['./new-bidding-action-log.component.css']
})
export class NewBiddingActionLogComponent implements OnInit {

  @Input() biddingAllInfo!: BiddingAllInfoAndLog;

  biddingLogData: BiddingLog[] = [];
  constructor(
    private route:ActivatedRoute) { }

  ngOnInit(): void {
    const pkDetail = this.route.snapshot.queryParamMap.get('pkHeader');

    if (pkDetail) {
      this.biddingLogData = this.biddingAllInfo.log!;
    }

  }

}
