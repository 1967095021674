/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MdmGetApprovalDelegationUserResponse } from '../models/mdm-get-approval-delegation-user-response';
import { MdmGetApprovalListResponse } from '../models/mdm-get-approval-list-response';
import { MdmGetMemberAndRoleResponse } from '../models/mdm-get-member-and-role-response';
import { MdmGetMemberInfoResponse } from '../models/mdm-get-member-info-response';

@Injectable({
  providedIn: 'root',
})
export class MdmService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMdmGetTokenPost
   */
  static readonly ApiMdmGetTokenPostPath = '/api/MDM/GetToken';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetTokenPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetTokenPost$Response(params?: {
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetTokenPostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetTokenPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetTokenPost(params?: {
  }): Observable<void> {

    return this.apiMdmGetTokenPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiMdmGetMemberInfoByStartWithNamePost
   */
  static readonly ApiMdmGetMemberInfoByStartWithNamePostPath = '/api/MDM/GetMemberInfoByStartWithName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetMemberInfoByStartWithNamePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByStartWithNamePost$Plain$Response(params?: {
    name?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetMemberInfoByStartWithNamePostPath, 'post');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetMemberInfoByStartWithNamePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByStartWithNamePost$Plain(params?: {
    name?: string;
  }): Observable<MdmGetMemberInfoResponse> {

    return this.apiMdmGetMemberInfoByStartWithNamePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberInfoResponse>) => r.body as MdmGetMemberInfoResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetMemberInfoByStartWithNamePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByStartWithNamePost$Json$Response(params?: {
    name?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetMemberInfoByStartWithNamePostPath, 'post');
    if (params) {
      rb.query('name', params.name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetMemberInfoByStartWithNamePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByStartWithNamePost$Json(params?: {
    name?: string;
  }): Observable<MdmGetMemberInfoResponse> {

    return this.apiMdmGetMemberInfoByStartWithNamePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberInfoResponse>) => r.body as MdmGetMemberInfoResponse)
    );
  }

  /**
   * Path part for operation apiMdmGetMemberInfoByEmailPost
   */
  static readonly ApiMdmGetMemberInfoByEmailPostPath = '/api/MDM/GetMemberInfoByEmail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetMemberInfoByEmailPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByEmailPost$Plain$Response(params?: {
    email?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetMemberInfoByEmailPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetMemberInfoByEmailPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByEmailPost$Plain(params?: {
    email?: string;
  }): Observable<MdmGetMemberInfoResponse> {

    return this.apiMdmGetMemberInfoByEmailPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberInfoResponse>) => r.body as MdmGetMemberInfoResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetMemberInfoByEmailPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByEmailPost$Json$Response(params?: {
    email?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetMemberInfoByEmailPostPath, 'post');
    if (params) {
      rb.query('email', params.email, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetMemberInfoByEmailPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberInfoByEmailPost$Json(params?: {
    email?: string;
  }): Observable<MdmGetMemberInfoResponse> {

    return this.apiMdmGetMemberInfoByEmailPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberInfoResponse>) => r.body as MdmGetMemberInfoResponse)
    );
  }

  /**
   * Path part for operation apiMdmGetMemberByOrgIdPost
   */
  static readonly ApiMdmGetMemberByOrgIdPostPath = '/api/MDM/GetMemberByOrgID';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetMemberByOrgIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberByOrgIdPost$Plain$Response(params?: {
    orgID?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetMemberByOrgIdPostPath, 'post');
    if (params) {
      rb.query('orgID', params.orgID, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetMemberByOrgIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberByOrgIdPost$Plain(params?: {
    orgID?: string;
  }): Observable<MdmGetMemberInfoResponse> {

    return this.apiMdmGetMemberByOrgIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberInfoResponse>) => r.body as MdmGetMemberInfoResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmGetMemberByOrgIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberByOrgIdPost$Json$Response(params?: {
    orgID?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberInfoResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmGetMemberByOrgIdPostPath, 'post');
    if (params) {
      rb.query('orgID', params.orgID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberInfoResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmGetMemberByOrgIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmGetMemberByOrgIdPost$Json(params?: {
    orgID?: string;
  }): Observable<MdmGetMemberInfoResponse> {

    return this.apiMdmGetMemberByOrgIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberInfoResponse>) => r.body as MdmGetMemberInfoResponse)
    );
  }

  /**
   * Path part for operation apiMdmApprovalandDelegationListByOrganizationIdPost
   */
  static readonly ApiMdmApprovalandDelegationListByOrganizationIdPostPath = '/api/MDM/ApprovalandDelegationListByOrganizationId';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmApprovalandDelegationListByOrganizationIdPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalandDelegationListByOrganizationIdPost$Plain$Response(params?: {
    orgID?: string;
  }): Observable<StrictHttpResponse<MdmGetApprovalListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmApprovalandDelegationListByOrganizationIdPostPath, 'post');
    if (params) {
      rb.query('orgID', params.orgID, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetApprovalListResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmApprovalandDelegationListByOrganizationIdPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalandDelegationListByOrganizationIdPost$Plain(params?: {
    orgID?: string;
  }): Observable<MdmGetApprovalListResponse> {

    return this.apiMdmApprovalandDelegationListByOrganizationIdPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetApprovalListResponse>) => r.body as MdmGetApprovalListResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmApprovalandDelegationListByOrganizationIdPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalandDelegationListByOrganizationIdPost$Json$Response(params?: {
    orgID?: string;
  }): Observable<StrictHttpResponse<MdmGetApprovalListResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmApprovalandDelegationListByOrganizationIdPostPath, 'post');
    if (params) {
      rb.query('orgID', params.orgID, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetApprovalListResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmApprovalandDelegationListByOrganizationIdPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalandDelegationListByOrganizationIdPost$Json(params?: {
    orgID?: string;
  }): Observable<MdmGetApprovalListResponse> {

    return this.apiMdmApprovalandDelegationListByOrganizationIdPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetApprovalListResponse>) => r.body as MdmGetApprovalListResponse)
    );
  }

  /**
   * Path part for operation apiMdmMembersAndRolesInfoByAdAccountPost
   */
  static readonly ApiMdmMembersAndRolesInfoByAdAccountPostPath = '/api/MDM/MembersAndRolesInfoByADAccount';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmMembersAndRolesInfoByAdAccountPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMembersAndRolesInfoByAdAccountPost$Plain$Response(params?: {
    domain?: string;
    adAccount?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberAndRoleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmMembersAndRolesInfoByAdAccountPostPath, 'post');
    if (params) {
      rb.query('domain', params.domain, {});
      rb.query('adAccount', params.adAccount, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberAndRoleResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmMembersAndRolesInfoByAdAccountPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMembersAndRolesInfoByAdAccountPost$Plain(params?: {
    domain?: string;
    adAccount?: string;
  }): Observable<MdmGetMemberAndRoleResponse> {

    return this.apiMdmMembersAndRolesInfoByAdAccountPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberAndRoleResponse>) => r.body as MdmGetMemberAndRoleResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmMembersAndRolesInfoByAdAccountPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMembersAndRolesInfoByAdAccountPost$Json$Response(params?: {
    domain?: string;
    adAccount?: string;
  }): Observable<StrictHttpResponse<MdmGetMemberAndRoleResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmMembersAndRolesInfoByAdAccountPostPath, 'post');
    if (params) {
      rb.query('domain', params.domain, {});
      rb.query('adAccount', params.adAccount, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetMemberAndRoleResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmMembersAndRolesInfoByAdAccountPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmMembersAndRolesInfoByAdAccountPost$Json(params?: {
    domain?: string;
    adAccount?: string;
  }): Observable<MdmGetMemberAndRoleResponse> {

    return this.apiMdmMembersAndRolesInfoByAdAccountPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetMemberAndRoleResponse>) => r.body as MdmGetMemberAndRoleResponse)
    );
  }

  /**
   * Path part for operation apiMdmApprovalDelegationUserPost
   */
  static readonly ApiMdmApprovalDelegationUserPostPath = '/api/MDM/ApprovalDelegationUser';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmApprovalDelegationUserPost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalDelegationUserPost$Plain$Response(params?: {
    adAdcount?: string;
    orgId?: string;
    budget?: number;
  }): Observable<StrictHttpResponse<MdmGetApprovalDelegationUserResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmApprovalDelegationUserPostPath, 'post');
    if (params) {
      rb.query('adAdcount', params.adAdcount, {});
      rb.query('orgId', params.orgId, {});
      rb.query('budget', params.budget, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetApprovalDelegationUserResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmApprovalDelegationUserPost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalDelegationUserPost$Plain(params?: {
    adAdcount?: string;
    orgId?: string;
    budget?: number;
  }): Observable<MdmGetApprovalDelegationUserResponse> {

    return this.apiMdmApprovalDelegationUserPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetApprovalDelegationUserResponse>) => r.body as MdmGetApprovalDelegationUserResponse)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMdmApprovalDelegationUserPost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalDelegationUserPost$Json$Response(params?: {
    adAdcount?: string;
    orgId?: string;
    budget?: number;
  }): Observable<StrictHttpResponse<MdmGetApprovalDelegationUserResponse>> {

    const rb = new RequestBuilder(this.rootUrl, MdmService.ApiMdmApprovalDelegationUserPostPath, 'post');
    if (params) {
      rb.query('adAdcount', params.adAdcount, {});
      rb.query('orgId', params.orgId, {});
      rb.query('budget', params.budget, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MdmGetApprovalDelegationUserResponse>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMdmApprovalDelegationUserPost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMdmApprovalDelegationUserPost$Json(params?: {
    adAdcount?: string;
    orgId?: string;
    budget?: number;
  }): Observable<MdmGetApprovalDelegationUserResponse> {

    return this.apiMdmApprovalDelegationUserPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MdmGetApprovalDelegationUserResponse>) => r.body as MdmGetApprovalDelegationUserResponse)
    );
  }

}
