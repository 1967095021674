/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { Conclusion } from '../models/conclusion';
import { FileProperties } from '../models/file-properties';
import { RcfAllInfo } from '../models/rcf-all-info';
import { Rcffile } from '../models/rcffile';
import { Rcflog } from '../models/rcflog';
import { RequestContractAll } from '../models/request-contract-all';
import { RequestContractDocNoAndLog } from '../models/request-contract-doc-no-and-log';

@Injectable({
  providedIn: 'root',
})
export class RequestContractAllService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRcfAllServiceCreateRcfPost
   */
  static readonly ApiRcfAllServiceCreateRcfPostPath = '/api/RCFAllService/CreateRCF';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceCreateRcfPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceCreateRcfPost$Response(params?: {
    body?: RequestContractAll
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceCreateRcfPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceCreateRcfPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceCreateRcfPost(params?: {
    body?: RequestContractAll
  }): Observable<void> {

    return this.apiRcfAllServiceCreateRcfPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceUpdateNewRequestContractPost
   */
  static readonly ApiRcfAllServiceUpdateNewRequestContractPostPath = '/api/RCFAllService/UpdateNewRequestContract';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceUpdateNewRequestContractPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceUpdateNewRequestContractPost$Response(params?: {
    PKRCFHeader?: string;
    body?: Conclusion
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceUpdateNewRequestContractPostPath, 'post');
    if (params) {
      rb.query('PKRCFHeader', params.PKRCFHeader, {});
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceUpdateNewRequestContractPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceUpdateNewRequestContractPost(params?: {
    PKRCFHeader?: string;
    body?: Conclusion
  }): Observable<void> {

    return this.apiRcfAllServiceUpdateNewRequestContractPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceUploadFileListPost
   */
  static readonly ApiRcfAllServiceUploadFileListPostPath = '/api/RCFAllService/UploadFileList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceUploadFileListPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceUploadFileListPost$Response(params?: {
    body?: FileProperties
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceUploadFileListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceUploadFileListPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceUploadFileListPost(params?: {
    body?: FileProperties
  }): Observable<void> {

    return this.apiRcfAllServiceUploadFileListPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceCreateFileListPost
   */
  static readonly ApiRcfAllServiceCreateFileListPostPath = '/api/RCFAllService/CreateFileList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceCreateFileListPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceCreateFileListPost$Response(params?: {
    body?: Rcffile
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceCreateFileListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceCreateFileListPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiRcfAllServiceCreateFileListPost(params?: {
    body?: Rcffile
  }): Observable<void> {

    return this.apiRcfAllServiceCreateFileListPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceGetAllRequestInfoByFkGet
   */
  static readonly ApiRcfAllServiceGetAllRequestInfoByFkGetPath = '/api/RCFAllService/GetAllRequestInfoByFK';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceGetAllRequestInfoByFkGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllRequestInfoByFkGet$Plain$Response(params?: {
    PK?: string;
  }): Observable<StrictHttpResponse<Array<RcfAllInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceGetAllRequestInfoByFkGetPath, 'get');
    if (params) {
      rb.query('PK', params.PK, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RcfAllInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceGetAllRequestInfoByFkGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllRequestInfoByFkGet$Plain(params?: {
    PK?: string;
  }): Observable<Array<RcfAllInfo>> {

    return this.apiRcfAllServiceGetAllRequestInfoByFkGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RcfAllInfo>>) => r.body as Array<RcfAllInfo>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceGetAllRequestInfoByFkGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllRequestInfoByFkGet$Json$Response(params?: {
    PK?: string;
  }): Observable<StrictHttpResponse<Array<RcfAllInfo>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceGetAllRequestInfoByFkGetPath, 'get');
    if (params) {
      rb.query('PK', params.PK, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RcfAllInfo>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceGetAllRequestInfoByFkGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllRequestInfoByFkGet$Json(params?: {
    PK?: string;
  }): Observable<Array<RcfAllInfo>> {

    return this.apiRcfAllServiceGetAllRequestInfoByFkGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RcfAllInfo>>) => r.body as Array<RcfAllInfo>)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceGetLogByRcfPkGet
   */
  static readonly ApiRcfAllServiceGetLogByRcfPkGetPath = '/api/RCFAllService/GetLogByRCFPk';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceGetLogByRcfPkGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetLogByRcfPkGet$Plain$Response(params?: {
    RCFPk?: string;
  }): Observable<StrictHttpResponse<Array<Rcflog>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceGetLogByRcfPkGetPath, 'get');
    if (params) {
      rb.query('RCFPk', params.RCFPk, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rcflog>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceGetLogByRcfPkGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetLogByRcfPkGet$Plain(params?: {
    RCFPk?: string;
  }): Observable<Array<Rcflog>> {

    return this.apiRcfAllServiceGetLogByRcfPkGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rcflog>>) => r.body as Array<Rcflog>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceGetLogByRcfPkGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetLogByRcfPkGet$Json$Response(params?: {
    RCFPk?: string;
  }): Observable<StrictHttpResponse<Array<Rcflog>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceGetLogByRcfPkGetPath, 'get');
    if (params) {
      rb.query('RCFPk', params.RCFPk, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<Rcflog>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceGetLogByRcfPkGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetLogByRcfPkGet$Json(params?: {
    RCFPk?: string;
  }): Observable<Array<Rcflog>> {

    return this.apiRcfAllServiceGetLogByRcfPkGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<Rcflog>>) => r.body as Array<Rcflog>)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPost
   */
  static readonly ApiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPostPath = '/api/RCFAllService/DeleteRCFDraftByPKRCFHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPost$Response(params?: {
    PKRCFHeader?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPostPath, 'post');
    if (params) {
      rb.query('PKRCFHeader', params.PKRCFHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPost(params?: {
    PKRCFHeader?: string;
  }): Observable<void> {

    return this.apiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceDisablePlantByPkrcfHeaderPost
   */
  static readonly ApiRcfAllServiceDisablePlantByPkrcfHeaderPostPath = '/api/RCFAllService/DisablePlantByPKRCFHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceDisablePlantByPkrcfHeaderPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceDisablePlantByPkrcfHeaderPost$Response(params?: {
    PKRCFHeader?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceDisablePlantByPkrcfHeaderPostPath, 'post');
    if (params) {
      rb.query('PKRCFHeader', params.PKRCFHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceDisablePlantByPkrcfHeaderPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceDisablePlantByPkrcfHeaderPost(params?: {
    PKRCFHeader?: string;
  }): Observable<void> {

    return this.apiRcfAllServiceDisablePlantByPkrcfHeaderPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiRcfAllServiceGetAllLogGet
   */
  static readonly ApiRcfAllServiceGetAllLogGetPath = '/api/RCFAllService/GetAllLog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceGetAllLogGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllLogGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RequestContractDocNoAndLog>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceGetAllLogGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestContractDocNoAndLog>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceGetAllLogGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllLogGet$Plain(params?: {
  }): Observable<Array<RequestContractDocNoAndLog>> {

    return this.apiRcfAllServiceGetAllLogGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestContractDocNoAndLog>>) => r.body as Array<RequestContractDocNoAndLog>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRcfAllServiceGetAllLogGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllLogGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<RequestContractDocNoAndLog>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestContractAllService.ApiRcfAllServiceGetAllLogGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestContractDocNoAndLog>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRcfAllServiceGetAllLogGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRcfAllServiceGetAllLogGet$Json(params?: {
  }): Observable<Array<RequestContractDocNoAndLog>> {

    return this.apiRcfAllServiceGetAllLogGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestContractDocNoAndLog>>) => r.body as Array<RequestContractDocNoAndLog>)
    );
  }

}
