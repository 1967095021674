<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Sourcing Contract'" [nzActive]="true">
      <form nz-form [formGroup]="memoDetailForm">

        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>Project Name</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">

                <nz-select>
                  <nz-option></nz-option>
                </nz-select>

              </nz-form-control>
            </nz-form-item>

          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>Detail Template</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">

                <nz-select>
                  <nz-option></nz-option>
                </nz-select>

              </nz-form-control>
            </nz-form-item>

          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>Detail</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="14" [nzErrorTip]="errorTpl">
                <ckeditor></ckeditor>
              </nz-form-control>

            </nz-form-item>

          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>Note</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <textarea nz-input [nzAutosize]="{ minRows: 5, maxRows: 10 }" ></textarea>
              </nz-form-control>

            </nz-form-item>

          </div>

        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">* Require</ng-container>
  <ng-container *ngIf="control.hasError('email')">* Invalid Email</ng-container>
  <ng-container *ngIf="control.hasError('pattern') || control.hasError('minlength') || control.hasError('maxlength')">*
    Invalid Phone Number</ng-container>
</ng-template>
