<nz-layout class="app-layout">

  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Item List Detail'" [nzActive]="true">
      <div nz-row class="row">

        <div class="panel-content">
          <div nz-row class="collapse-row">

            <nz-divider></nz-divider>

            <div nz-col [nzXs]="24" [nzSm]="24">
              <nz-form-item>
                <nz-form-label [nzXs]="6" [nzSm]="6">Currency</nz-form-label>
                <nz-form-control [nzXs]="6" [nzSm]="4">
                  <input nz-input [value]="'THB'" [disabled]="true">
                </nz-form-control>

                <nz-form-label [nzXs]="6" [nzSm]="1" [nzNoColon]="true"></nz-form-label>
                <nz-form-control [nzXs]="6" [nzSm]="1">
                  <button nz-button (click)="onViewExchangeRate(tplContent)">Current Exchange Rate</button>
                </nz-form-control>

              </nz-form-item>
            </div>

            <div nz-col nzSpan="24" class="collapse-col">

              <!-- <nz-card> -->
              <nz-table #groupingTable [nzData]="biddingAllInfo.vendorBiddingInfo!" [nzBordered]="true"
                [nzShowPagination]="false" [nzScroll]="{x: '7000px', y: '600px' }" nzTableLayout="fixed">
                <thead>
                  <tr>
                    <th nzLeft rowspan="2" nzWidth="60px">No.</th>
                    <th nzLeft rowspan="2" nzWidth="150px">Material Code</th>
                    <th nzLeft rowspan="2" nzWidth="200px">Item Name</th>
                    <th rowspan="2" nzWidth="250px">Description</th>
                    <th nzWidth="175px" rowspan="2">Quantity</th>
                    <th nzWidth="200px" rowspan="2">Last price</th>
                    <th nzWidth="200px" rowspan="2">Average last price</th>

                    <!-- Vendor Name -->
                    <th *ngFor="let item of biddingAllInfo.vendorBiddingInfo;index as number" colSpan="3"
                      style="background-color: cornsilk;">{{item.detail!.vendorName}} ({{number+1}})
                    </th>

                    <!-- Award Vendor -->
                    <ng-container *ngFor="let item of biddingAllInfo.vendorBiddingInfo;index as i">
                      <th [style.background-color]="colorCode[i]" rowSpan="2">Award
                        Vendor {{i+1}}
                      </th>
                      <th style="background-color: #C4E2F7;" colSpan="2">CA</th>
                      <th style="background-color: #C5F1E8;" colSpan="2">CR</th>
                    </ng-container>

                    <th style="background-color: #9FD1F5;" colSpan="2">Total CA</th>
                    <th style="background-color: #9BF1DF;" colSpan="2">Total CR</th>
                  </tr>
                  <tr>

                    <ng-container *ngFor="let item of groupingTable.data;index as i">
                      <th style="background-color: #FED299;" rowspan="1">ราคาครั้งก่อน</th>
                      <th style="background-color: #81D8DA;" rowspan="1">First unit price</th>
                      <th style="background-color: #ACE4AC;" rowspan="1">

                        <div nz-row [nzAlign]="'middle'" [nzJustify]="'center'">
                          <div nz-col>Final unit price</div>
                        </div>

                        <button nz-button style="margin-right: 5px;" (click)="redoFinalPrice(i)" [disabled]="biddingAllInfo.header?.status === 'Finish'">
                          <i nz-icon nzType="redo" nzTheme="outline"></i>
                        </button>

                      </th>
                    </ng-container>

                    <!-- CA CR By Vendor -->
                    <ng-container *ngFor="let item of groupingTable.data">
                      <th style="background-color: #F9C5BE;" rowspan="1">Value</th>
                      <th style="background-color: #F3CBD9;" rowspan="1">%</th>
                      <th style="background-color: #F9C5BE;" rowspan="1">Value</th>
                      <th style="background-color: #F3CBD9;" rowspan="1">%</th>
                    </ng-container>

                    <!-- Grand Total CA CR -->
                    <th style="background-color: #E7A596;" rowspan="1">Value</th>
                    <th style="background-color: #E7A7B7;" rowspan="1">%</th>
                    <th style="background-color: #E7A596;" rowspan="1">Value</th>
                    <th style="background-color: #E7A7B7;" rowspan="1">%</th>

                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of itemList;index as i">
                    <td nzLeft>{{ data.no }}</td>
                    <td nzLeft>{{ data.matCode }}</td>
                    <td nzLeft>{{ data.itemName }}</td>
                    <td>
                      <textarea nz-input [readOnly]="true" [(ngModel)]="data.description" [disabled]="true"
                        [nzAutosize]="{ minRows: 3, maxRows: 6 }">
                      </textarea>
                    </td>
                    <td>
                      <nz-input-number-group nzCompact>
                        <nz-input-number nz-input [nzReadOnly]="true" [(ngModel)]="data.quantity"
                          [nzFormatter]="formatterNumber" style="width:60%;" [disabled]="true"></nz-input-number>
                        <input nz-input [readOnly]="true" [disabled]="true" [(ngModel)]="data.unit"
                          style="width:40%;" />
                      </nz-input-number-group>
                    </td>
                    <!-- Last Price by Contract -->
                    <td>
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">{{biddingItemListLastPriceAndAvgPrice[i].lastPrice |
                          number:'1.2-2'}}</nz-col>
                        <nz-col [nzSpan]="10">THB</nz-col>
                      </div>
                    </td>
                    <!-- Avg Last Price -->
                    <td>
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">{{biddingItemListLastPriceAndAvgPrice[i].averageLastPice |
                          number:'1.2-2'}}
                        </nz-col>
                        <nz-col [nzSpan]="10">THB</nz-col>
                      </div>
                    </td>
                    <!-- Vendor -->
                    <ng-container *ngFor="let item of groupingTable.data;index as j">
                      <!-- Last Price -->
                      <td style="background-color: #FED299;">
                        {{item.itemList![i].lastPrice | number:'1.2-2'}} THB
                      </td>
                      <!-- First Price -->
                      <td style="background-color: #81D8DA;">
                        <ng-container *ngIf="item.itemList![i].rejectReason !== '-'">
                          <nz-tag [nzColor]="'red'">Rejected : {{item.itemList![i].rejectReason}}</nz-tag>
                        </ng-container>
                        <ng-container *ngIf="item.itemList![i].rejectReason === '-'">
                          <div nz-row [nzGutter]="24">
                            <nz-col [nzSpan]="14">{{item.itemList![i].pricePerUnit | number:'1.2-2'}}</nz-col>
                            <nz-col [nzSpan]="10"> THB </nz-col>
                          </div>
                        </ng-container>
                      </td>
                      <!-- Final Price -->
                      <td style="background-color: #ACE4AC;">

                        <nz-input-number-group nzCompact>
                          <nz-input-number [nzMin]="0" [nzMax]="1000000" [nzStep]="1" nzPlaceHolder="Price"
                            [nzDisabled]="item.itemList![i].rejectReason !== '-' || biddingAllInfo.header?.status === 'Finish'" [nzPrecision]="2"
                            [(ngModel)]="summaryCost[j].item[i].finalPricePerUnit"
                            (ngModelChange)="onItemListValueChange(j, i)" [nzFormatter]="formatterNumber"
                            style="width: 70%;">
                          </nz-input-number>
                          <input nz-input [readOnly]="true" [disabled]="true" [value]="'THB'" style="width:30%;" />
                        </nz-input-number-group>
                      </td>
                    </ng-container>
                    <ng-container *ngFor="let item of biddingAllInfo.vendorBiddingInfo;index as k">
                      <!-- Award Vendor-->
                      <td [style.background-color]="colorCode[k]">

                        <nz-input-number-group nzCompact>
                          <nz-input-number [nzMin]="0" [nzMax]="100" [nzStep]="1" nz-tooltip nzTooltipTrigger="focus"
                            nzTooltipPlacement="topLeft" nzOverlayClassName="numeric-input"
                            [nzDisabled]="item.itemList![i].rejectReason !== '-' || biddingAllInfo.header?.status === 'Finish'"
                            [(ngModel)]="summaryCost[k].item[i].allocation"
                            [nzTooltipTitle]="'Left '+summaryCost[k].item[i].allocationLeft+' %'"
                            nzPlaceHolder="Allowcation" [(ngModel)]="summaryCost[k].item[i].allocation"
                            [nzStatus]="summaryCost[k].item[i].allocationStatus!" (ngModelChange)="onChangeValue(k, i)"
                            style="width: 70%;">
                          </nz-input-number>
                          <input nz-input [readOnly]="true" [disabled]="true" [value]="'%'" style="width:30%;" />
                        </nz-input-number-group>
                      </td>
                      <!-- CA Value-->
                      <td style="background-color: #F9C5BE;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            {{summaryCost[k].item[i].costAvoidanceValue | number:'1.2-2'}}
                          </nz-col>
                          <nz-col [nzSpan]="10">THB</nz-col>
                        </div>
                      </td>
                      <!-- CA % -->
                      <td style="background-color: #F3CBD9;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            {{summaryCost[k].item[i].costAvoidancePercent | number:'1.2-2'}}
                          </nz-col>
                          <nz-col [nzSpan]="10">%</nz-col>
                        </div>

                      </td>
                      <!-- CR Value -->
                      <td style="background-color: #F9C5BE;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            {{summaryCost[k].item[i].costReductionValue | number:'1.2-2'}}
                          </nz-col>
                          <nz-col [nzSpan]="10">THB</nz-col>
                        </div>
                      </td>
                      <!-- CR % -->
                      <td style="background-color: #F3CBD9;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            {{summaryCost[k].item[i].costReductionPercent | number:'1.2-2'}}
                          </nz-col>
                          <nz-col [nzSpan]="10">%</nz-col>
                        </div>
                      </td>
                    </ng-container>
                    <!-- Total CA Value-->
                    <td style="background-color: #E7A596;">
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          {{totalCAandCR[i].totalCAValue | number:'1.2-2'}}
                        </nz-col>
                        <nz-col [nzSpan]="10">THB</nz-col>
                      </div>
                    </td>
                    <!-- Total CA %-->
                    <td style="background-color: #E7A7B7;">
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          {{totalCAandCR[i].totalCAPercent | number:'1.2-2'}}
                        </nz-col>
                        <nz-col [nzSpan]="10">%</nz-col>
                      </div>
                    </td>
                    <!-- Total CR Value-->
                    <td style="background-color: #E7A596;">
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          {{totalCAandCR[i].totalCRValue | number:'1.2-2'}}
                        </nz-col>
                        <nz-col [nzSpan]="10">THB</nz-col>
                      </div>
                    </td>
                    <!-- Total CR %-->
                    <td style="background-color: #E7A7B7;">
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          {{totalCAandCR[i].totalCRPercent | number:'1.2-2'}}
                        </nz-col>
                        <nz-col [nzSpan]="10">%</nz-col>
                      </div>
                    </td>
                  </tr>

                  <!-- สรุปท้ายตาราง -->
                  <tr>
                    <!-- No -->
                    <td nzLeft> </td>
                    <!-- Mat Code -->
                    <td nzLeft> </td>
                    <!-- Item Name -->
                    <td nzLeft style="background-color: #EE7272;">
                      <b>
                        Total
                      </b>
                    </td>
                    <!-- Description -->
                    <td> </td>
                    <!-- Unit -->
                    <td> </td>
                    <!-- Last Price -->
                    <td> </td>
                    <!-- Avg Last Price -->
                    <td> </td>
                    <!-- Vendor -->
                    <ng-container *ngFor="let item of summaryCost;index as k">
                      <!-- Last Price -->
                      <td style="background-color: #FED299;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            <b>
                              <u>
                                {{ 0 | number}}
                              </u>
                            </b>
                          </nz-col>
                          <nz-col [nzSpan]="10">
                            <b>
                              THB
                            </b>
                          </nz-col>
                        </div>
                      </td>
                      <!-- First Price -->
                      <td style="background-color:  #81D8DA;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            <b>
                              <u>
                                {{ summaryCost[k].totalFirstPrice | number:'1.2-2'}}
                              </u>
                            </b>
                          </nz-col>
                          <nz-col [nzSpan]="10">
                            <b>
                              THB
                            </b>
                          </nz-col>
                        </div>
                      </td>
                      <!-- Total Price -->
                      <td style="background-color: #ACE4AC;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            <b>
                              <u>
                                {{ summaryCost[k].totalPrice | number:'1.2-2'}}
                              </u>
                            </b>
                          </nz-col>
                          <nz-col [nzSpan]="10">
                            <b>
                              THB
                            </b>
                          </nz-col>
                        </div>
                      </td>
                    </ng-container>
                    <ng-container *ngFor="let item of biddingAllInfo.vendorBiddingInfo;index as l">
                      <!-- Award Vendor-->
                      <td>

                      </td>
                      <!-- CA Value By Vendor-->
                      <td style="background-color: #F9C5BE;">
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            <b>
                              <u>
                                {{ summaryCost[l].totalCA | number:'1.2-2'}}
                              </u>
                            </b>
                          </nz-col>
                          <nz-col [nzSpan]="10">
                            <b>
                              THB
                            </b>
                          </nz-col>
                        </div>
                      </td>
                      <!-- CA % -->
                      <td>
                        <!-- Total CA ({{l+1}}) -->
                      </td>
                      <!-- CR Value By Vendor-->
                      <td style="background-color: #F9C5BE;">
                        <!-- <nz-input-number [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number> -->
                        <div nz-row [nzGutter]="24">
                          <nz-col [nzSpan]="14">
                            <b>
                              <u>
                                {{ summaryCost[l].totalCR | number:'1.2-2'}}
                              </u>
                            </b>
                          </nz-col>
                          <nz-col [nzSpan]="10">
                            <b>
                              THB
                            </b>
                          </nz-col>
                        </div>
                      </td>
                      <!-- CR % -->
                      <td>
                        <!-- Total CR % ({{l+1}}) -->
                      </td>
                    </ng-container>


                    <!-- Grand Total -->

                    <!-- Grand Total CA Value-->
                    <td style="background-color: #E7A596;">
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          <b>
                            <u>
                              {{ AlltotalCAValue | number:'1.2-2'}}
                            </u>
                          </b>
                        </nz-col>
                        <nz-col [nzSpan]="10">
                          <b>
                            THB
                          </b>
                        </nz-col>
                      </div>
                    </td>
                    <!-- Grand Total CA %-->
                    <td style="background-color: #E7A7B7;">
                      <!-- Grand CA % -->
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          <b>
                            <u>
                              {{AlltotalCAPercent | number:'1.2-2'}}
                            </u>
                          </b>
                        </nz-col>
                        <nz-col [nzSpan]="10">
                          <b>
                            %
                          </b>
                        </nz-col>
                      </div>
                    </td>
                    <!-- Grand Total CR Value-->
                    <td style="background-color: #E7A596;">
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          <b>
                            <u>
                              {{ AlltotalCRValue | number:'1.2-2'}}
                            </u>
                          </b>
                        </nz-col>
                        <nz-col [nzSpan]="10">
                          <b>
                            THB
                          </b>
                        </nz-col>
                      </div>
                    </td>
                    <!-- Grand Total CR %-->
                    <td style="background-color: #E7A7B7;">
                      <!-- Grand CR % -->
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">
                          <b>
                            <u>
                              {{ AlltotalCRPercent | number:'1.2-2'}}
                            </u>
                          </b>
                        </nz-col>
                        <nz-col [nzSpan]="10">
                          <b>
                            %
                          </b>
                        </nz-col>
                      </div>
                    </td>
                  </tr>

                </tbody>
              </nz-table>
              <!-- </nz-card> -->
            </div>

          </div>

        </div>

      </div>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>

<ng-template #tplContent>
  <div nz-row class="content-row">
    <div nz-col [nzXs]="24" [nzSm]="24">

      <nz-table class="exchangeRateTable" #exchangeRateTable [nzData]="exchangeRate" nzBordered
        [nzShowPagination]="false" [nzScroll]="{x: '800px', y:'600px'}" [nzFrontPagination]="false" [nzTotal]="0">
        <thead>
          <tr>
            <th nzWidth="60px">Rate Type</th>
            <th nzWidth="60px">From</th>
            <th nzWidth="60px">For To Factor</th>
            <th nzWidth="60px">To</th>
            <th nzWidth="60px">Rate</th>
            <th nzWidth="60px">Effective Date</th>
          </tr>
          <tr *ngFor="let data of exchangeRateTable.data">
            <td>{{data.rateType}}</td>
            <td>{{data.fromCurr}}</td>
            <td>{{data.factor_for_to_curr}}</td>
            <td>{{data.toCurr}}</td>
            <td>{{data.exchangeRate1}}</td>
            <td>{{data.effectiveDate | date:"d MMM yyyy"}}</td>
          </tr>
        </thead>
      </nz-table>

    </div>
  </div>
</ng-template>
