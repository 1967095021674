<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" nzTip="Loading..." [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-header">
        <nz-row>
          <nz-card class="card">
            <nz-row>
              <nz-breadcrumb>
                <nz-breadcrumb-item>
                  <a routerLink="../home">
                    <span nz-icon nzType="home"></span>
                    <span>Home</span>
                  </a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  <span>Work Station</span>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  <a>
                    <span>New Request Contract</span>
                  </a>
                </nz-breadcrumb-item>
              </nz-breadcrumb>
            </nz-row>
          </nz-card>
        </nz-row>

        <nz-row>
          <nz-card class="card" *ngIf=" steps != 0 || contractStatus == 'Return' || contractStatus == 'Draft'">
            <div class="content">
              <div class="main">
                <nz-descriptions nzBordered nzSize="default" [nzColumn]="2">
                  <nz-descriptions-item nzTitle="RCF Document Number " [nzSpan]="1">{{ contractDocumentNumber
                    }}</nz-descriptions-item>
                  <nz-descriptions-item nzTitle="Status" [nzSpan]="1">
                    <nz-tag *ngIf="contractStatus == 'Complete'" [nzColor]="'green'">
                      {{ contractStatus }}
                    </nz-tag>
                    <nz-tag *ngIf="contractStatus == 'Draft'" nzColor="default">
                      {{ contractStatus }}
                    </nz-tag>
                    <nz-tag
                      *ngIf="contractStatus == 'Wait approver intitial approve' || contractStatus == 'Wait approver approve'"
                      [nzColor]="'cyan'">
                      {{ contractStatus }}
                    </nz-tag>
                    <nz-tag *ngIf="contractStatus == 'Sourcing conclusion'" [nzColor]="'geekblue'">
                      Wait for {{ contractStatus }}
                    </nz-tag>
                    <nz-tag *ngIf="
                        contractStatus ==
                          'Wait sourcing approver initial approve' ||
                        contractStatus == 'Wait sourcing approver approve'
                      " [nzColor]="'purple'">
                      {{ contractStatus }}
                    </nz-tag>
                    <nz-tag *ngIf="contractStatus == 'Reject'" [nzColor]="'red'">
                      {{ contractStatus }}
                    </nz-tag>
                    <nz-tag *ngIf="contractStatus == 'Return'" [nzColor]="'orange'">
                      {{ contractStatus }}
                    </nz-tag>
                  </nz-descriptions-item>
                  <nz-descriptions-item nzTitle="Created by" [nzSpan]="1">{{
                    contractCreator
                    }}</nz-descriptions-item>
                  <nz-descriptions-item nzTitle="Create Date" [nzSpan]="1">{{
                    contractCreateDate
                    }}</nz-descriptions-item>
                </nz-descriptions>
              </div>
            </div>
          </nz-card>
        </nz-row>

        <nz-row>
          <nz-card class="card">
            <app-step [steps]="steps" [status]="status"> </app-step>
          </nz-card>
        </nz-row>
      </div>

      <div class="panel-content">
        <div nz-row class="collapse-row">
          <div id="requester-information" nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-requester-info [steps]="steps" [isSubmitted]="isSubmitted"
              [requestContractAllInfo]="requestContractAllInfo"
              (requesterDataChange)="onRequesterInfoFormChange($event)"
              (requesterDetailMDMChange)="onRequesterDetailMDMChange($event)">
            </app-requester-info>
          </div>

          <div id="request-detail" nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-request-detail [steps]="steps" [isSubmitted]="isSubmitted" [listPlant]="listPlant"
              [listContractType]="listContractType" [requestContractAllInfo]="requestContractAllInfo"
              (requestDataChange)="onRequestDetailFormChange($event)" [vendorNameList]="vendorNameList">
            </app-request-detail>
          </div>

          <div id="attachment" nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-attachment [steps]="steps" [requestContractAllInfo]="requestContractAllInfo" [rcfConfig]="rcfConfig"
              (requestAttachmentFileListChange)="
                onRequestAttachmentFileChange($event)
              ">
            </app-attachment>
          </div>

          <div id="technical-committee" nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-people [steps]="steps" [requestContractAllInfo]="requestContractAllInfo"
              [requesterDetailMDM]="requesterDetailMDM" (requestTechnicalCommitteeListChange)="
                onTechnicalCommitteeListChange($event)
              ">
            </app-people>
          </div>

          <div id="sourcing-team" nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-sourcing-team [steps]="steps" [requestContractAllInfo]="requestContractAllInfo"
              (sourcingDataChange)="onSourcingDataFormChange($event)">
            </app-sourcing-team>
          </div>

          <div nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-user-approver [steps]="steps" [requestContractAllInfo]="requestContractAllInfo"
              [requesterDetailMDM]="requesterDetailMDM" [contractValue]="contractValue"
              [contractStatus]="contractStatus" (approveDataChange)="onUserApproveFormChange($event)">
            </app-user-approver>
          </div>
        </div>

        <div nz-row class="collapse-row" *ngIf="steps >= 2">
          <div nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
            <app-conclusion [steps]="steps" [requestContractAllInfo]="requestContractAllInfo"
              (conclusionDataChange)="onConclusionFormChange($event)">
            </app-conclusion>
          </div>

          <div nz-col nzSpan="24" class="collapse-col">
            <div nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
              <app-approver [steps]="steps" [userDetailSSO]="userDetailSSO"
                [requestContractAllInfo]="requestContractAllInfo"
                (sourcingApproverDataChange)="onSourcingApproveFormChange($event)">
              </app-approver>
            </div>
          </div>
        </div>

        <div nz-row class="collapse-row">
          <div nz-col nzSpan="24" class="collapse-col"
            *ngIf="contractStatus != 'Complete' && contractStatus != 'Reject'">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Action Panel'" [nzActive]="true">
                <form nz-form [formGroup]="actionForm">
                  <div nz-row class="content-row">
                    <div nz-col [nzXs]="24" [nzSm]="24" *ngIf="steps != 0 && steps != 2">
                      <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Action</nz-form-label>
                        <nz-form-control [nzXs]="12" [nzSm]="12">
                          <nz-select nzPlaceHolder="Select Action" formControlName="action">
                            <nz-option *ngIf="steps == 0" [nzDisabled]="
                                steps > 0 || contractStatus == 'Return'
                              " nzValue="create" nzLabel="Create"></nz-option>
                            <nz-option *ngIf="steps == 2" [nzDisabled]="steps != 2" nzValue="Conclusion"
                              nzLabel="Conclusion"></nz-option>
                            <nz-option *ngIf="steps != 0 && steps != 2" [nzDisabled]="
                                steps == 0 ||
                                steps == 2 ||
                                contractStatus == 'Reject'
                              " nzValue="Approve" nzLabel="Approve"></nz-option>
                            <nz-option *ngIf="steps != 0 && steps != 2" [nzDisabled]="
                                steps == 0 ||
                                steps == 2 ||
                                contractStatus == 'Reject'
                              " nzValue="Return" nzLabel="Return"></nz-option>
                            <nz-option *ngIf="steps != 0 && steps != 2" [nzDisabled]="
                                steps == 0 ||
                                steps == 2 ||
                                contractStatus == 'Reject'
                              " nzValue="Reject" nzLabel="Reject"></nz-option>
                            <nz-option *ngIf="steps == 0 && contractStatus == 'Return'"
                              [nzDisabled]="contractStatus != 'Return'" nzValue="Creator Revise"
                              nzLabel="Creator Revise"></nz-option>
                          </nz-select>
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24">
                      <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6">Remark</nz-form-label>
                        <nz-form-control [nzXs]="12" [nzSm]="12">
                          <textarea nz-input formControlName="remark" placeholder="Note"
                            [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
                        </nz-form-control>
                      </nz-form-item>
                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24">
                      <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">
                        <div class="div-button-delete" style="padding: 2%">
                          <button *ngIf="contractStatus == 'Draft'" class="btn-delete" nz-button nzType="primary"
                            nzDanger (click)="onDeleteDraft()" disabled="{{ contractStatus != 'Draft' }}">
                            Delete Draft
                          </button>
                        </div>

                        <div class="div-button-save" style="padding: 2%">
                          <button nzSize="default" class="btn-cancel" nz-button nzType="default"
                            (click)="showSaveConfirm()" [disabled]="steps != 0">
                            Save Draft
                          </button>
                        </div>

                        <div class="div-button-submit" style="padding: 2%">
                          <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit"
                            (click)="showSubmitConfirm()" [disabled]="
                              disableSubmit ||
                              contractStatus == 'Reject' ||
                              contractStatus == 'Complete'
                            ">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </nz-collapse-panel>
            </nz-collapse>
          </div>

          <div nz-col nzSpan="24" class="collapse-col">
            <app-activity-log [requestContractAllInfo]="requestContractAllInfo">
            </app-activity-log>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
</nz-layout>
