import { BiddingHeader } from './../../../../../../../api/models/bidding-header';
import { DatePipe } from '@angular/common';
import { MasterPurGroup } from './../../../../../../../api/models/master-pur-group';
import { MasterSourcingTeam, MasterTheArchPurGroupMember, VChembiMstVendorMaster } from '../../../../../../../api/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { endOfMonth, endOfYear, endOfWeek, startOfMonth, startOfYear, startOfWeek } from 'date-fns';

@Component({
  selector: 'app-searching-summary-criteria',
  templateUrl: './searching-summary-criteria.component.html',
  styleUrls: ['./searching-summary-criteria.component.css']
})
export class SearchingSummaryCriteriaComponent implements OnInit {

  @Input() searchingSummaryCriteriaForm!: FormGroup;
  @Input() sourcingTeamList: MasterSourcingTeam[] = [];
  @Input() vendorList: VChembiMstVendorMaster[] = [];
  @Input() purchasingGroupsList: MasterPurGroup[] = [];
  @Input() purGroupList: MasterTheArchPurGroupMember[] = [];

  @Output() onClickSearch = new EventEmitter<boolean>();

  biddingCreateDate: Date[] = [];

  statusList = [
    { value: "Wait for vendor submit price", label: "Wait for vendor submit price" },
    { value: "Wait for summary", label: "Wait for summary" },
    { value: "Finish", label: "Ready to create MEMO" },
  ]

  ranges = {
    'Today': [new Date(), new Date()],
    'This Week': [startOfWeek(new Date()), endOfWeek(new Date())],
    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
    'This Year': [startOfYear(new Date()), endOfYear(new Date())],
    'A Month ago': [new Date(new Date().getFullYear() + "/" + new Date().getMonth() + "/" + new Date().getDate()), new Date()],
    'A Year ago': [new Date(new Date().getFullYear() - 1 + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()), new Date()],
  };

  constructor(
    private datepipe: DatePipe
  ) { }

  ngOnInit(): void {
    this.biddingCreateDate = [new Date(Date.now() - 12096e5), new Date()]
    this.searchingSummaryCriteriaForm.patchValue({
      biddingCreateFromDate: this.biddingCreateDate[0],
      biddingCreateToDate: new Date().setDate(this.biddingCreateDate[1].getDate() + 1)
    })
  }

  onDateChange(result: Date[]): void {
    console.log('From: ', this.datepipe.transform(result[0], "yyyy-MM-dd"), ', to: ', this.datepipe.transform(result[1], "yyyy-MM-dd"));
    this.searchingSummaryCriteriaForm.patchValue({
      biddingCreateFromDate: result[0],
      biddingCreateToDate: result[1].setDate(result[1].getDate() + 1)
    })
  }

  onResetSearch() {
    this.searchingSummaryCriteriaForm.reset();
    this.biddingCreateDate = [];

  }

  onSearch()
  {
    this.onClickSearch.emit(true);
  }


}
