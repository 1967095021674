<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Detail'" [nzActive]="true">
      <form nz-form [formGroup]="biddingDetailForm">
        <div nz-row class="content-row">
          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Incoterm</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">
                <nz-select formControlName="incoTerm" [nzDisabled]="true">
                  <nz-option *ngFor="let item of incotermList" [nzLabel]="item.incoterm1Name!"
                    [nzValue]="item.incoterm1Name!"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Payment Term</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">
                <nz-select formControlName="paymentTerm" [nzDisabled]="true">
                  <nz-option *ngFor="let item of paymentTermList" [nzLabel]="item.paymentTermDesc!"
                    [nzValue]="item.paymentTerm"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Contract period for offer</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">
                <nz-range-picker nzFormat="dd-MMMM-yyyy" [(ngModel)]="contractPeriod"
                  [nzDisabledDate]="disabledContractPeroidDate" (ngModelChange)="onChange($event)"
                  [ngModelOptions]="{ standalone: true }" [nzDisabled]="true">
                </nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24" *ngIf="biddingInfo.header!.isQuotationValidity!">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Quotation validity</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">
                <nz-range-picker
                  nzFormat="dd-MMMM-yyyy"
                  [(ngModel)]="quotationValidityRange"
                  (ngModelChange)="onChangeQuotationValidity($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [nzDisabled]="true"
                >
                </nz-range-picker>

              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24" *ngIf="biddingInfo.header!.isQuotationValidity!">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzNoColon></nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">

                <nz-date-picker
                  nzFormat="dd-MMMM-yyyy"
                  [(ngModel)]="quotationValidityDate"
                  [nzDisabledDate]="disabledQuotationDate"
                  (ngModelChange)="onChangeQuotationValidity($event)"
                  [ngModelOptions]="{ standalone: true }"
                  [nzStatus]="quotationValidityStatus"
                  [nzDisabled]="isSubmitted">
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>


        </div>
      </form>
    </nz-collapse-panel>
  </nz-collapse>
</nz-layout>
