/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MasterBaseOn } from '../models/master-base-on';
import { MasterContractType } from '../models/master-contract-type';
import { MasterCurrency } from '../models/master-currency';
import { MasterIncoterm1 } from '../models/master-incoterm-1';
import { MasterPlant } from '../models/master-plant';
import { MasterPurGroup } from '../models/master-pur-group';
import { MasterSimpleValue } from '../models/master-simple-value';
import { MasterSourcingTeam } from '../models/master-sourcing-team';
import { VChembiMstPaymentTerm } from '../models/v-chembi-mst-payment-term';
import { VChembiMstVendorMaster } from '../models/v-chembi-mst-vendor-master';

@Injectable({
  providedIn: 'root',
})
export class MasterService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMasterGetPlantGet
   */
  static readonly ApiMasterGetPlantGetPath = '/api/Master/GetPlant';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetPlantGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPlantGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterPlant>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetPlantGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterPlant>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetPlantGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPlantGet$Plain(params?: {
  }): Observable<Array<MasterPlant>> {

    return this.apiMasterGetPlantGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterPlant>>) => r.body as Array<MasterPlant>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetPlantGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPlantGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterPlant>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetPlantGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterPlant>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetPlantGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPlantGet$Json(params?: {
  }): Observable<Array<MasterPlant>> {

    return this.apiMasterGetPlantGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterPlant>>) => r.body as Array<MasterPlant>)
    );
  }

  /**
   * Path part for operation apiMasterGetContractTypeGet
   */
  static readonly ApiMasterGetContractTypeGetPath = '/api/Master/GetContractType';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetContractTypeGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetContractTypeGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterContractType>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetContractTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterContractType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetContractTypeGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetContractTypeGet$Plain(params?: {
  }): Observable<Array<MasterContractType>> {

    return this.apiMasterGetContractTypeGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterContractType>>) => r.body as Array<MasterContractType>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetContractTypeGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetContractTypeGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterContractType>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetContractTypeGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterContractType>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetContractTypeGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetContractTypeGet$Json(params?: {
  }): Observable<Array<MasterContractType>> {

    return this.apiMasterGetContractTypeGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterContractType>>) => r.body as Array<MasterContractType>)
    );
  }

  /**
   * Path part for operation apiMasterGetVendorGet
   */
  static readonly ApiMasterGetVendorGetPath = '/api/Master/GetVendor';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetVendorGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<VChembiMstVendorMaster>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetVendorGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VChembiMstVendorMaster>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetVendorGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorGet$Plain(params?: {
  }): Observable<Array<VChembiMstVendorMaster>> {

    return this.apiMasterGetVendorGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VChembiMstVendorMaster>>) => r.body as Array<VChembiMstVendorMaster>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetVendorGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<VChembiMstVendorMaster>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetVendorGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VChembiMstVendorMaster>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetVendorGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorGet$Json(params?: {
  }): Observable<Array<VChembiMstVendorMaster>> {

    return this.apiMasterGetVendorGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VChembiMstVendorMaster>>) => r.body as Array<VChembiMstVendorMaster>)
    );
  }

  /**
   * Path part for operation apiMasterGetVendorStartWithNameGet
   */
  static readonly ApiMasterGetVendorStartWithNameGetPath = '/api/Master/GetVendorStartWithName';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetVendorStartWithNameGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorStartWithNameGet$Plain$Response(params?: {
    Name?: string;
  }): Observable<StrictHttpResponse<Array<VChembiMstVendorMaster>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetVendorStartWithNameGetPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VChembiMstVendorMaster>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetVendorStartWithNameGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorStartWithNameGet$Plain(params?: {
    Name?: string;
  }): Observable<Array<VChembiMstVendorMaster>> {

    return this.apiMasterGetVendorStartWithNameGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VChembiMstVendorMaster>>) => r.body as Array<VChembiMstVendorMaster>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetVendorStartWithNameGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorStartWithNameGet$Json$Response(params?: {
    Name?: string;
  }): Observable<StrictHttpResponse<Array<VChembiMstVendorMaster>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetVendorStartWithNameGetPath, 'get');
    if (params) {
      rb.query('Name', params.Name, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VChembiMstVendorMaster>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetVendorStartWithNameGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetVendorStartWithNameGet$Json(params?: {
    Name?: string;
  }): Observable<Array<VChembiMstVendorMaster>> {

    return this.apiMasterGetVendorStartWithNameGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VChembiMstVendorMaster>>) => r.body as Array<VChembiMstVendorMaster>)
    );
  }

  /**
   * Path part for operation apiMasterGetSourcingTeamGet
   */
  static readonly ApiMasterGetSourcingTeamGetPath = '/api/Master/GetSourcingTeam';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetSourcingTeamGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSourcingTeamGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterSourcingTeam>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetSourcingTeamGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterSourcingTeam>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetSourcingTeamGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSourcingTeamGet$Plain(params?: {
  }): Observable<Array<MasterSourcingTeam>> {

    return this.apiMasterGetSourcingTeamGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterSourcingTeam>>) => r.body as Array<MasterSourcingTeam>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetSourcingTeamGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSourcingTeamGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterSourcingTeam>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetSourcingTeamGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterSourcingTeam>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetSourcingTeamGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSourcingTeamGet$Json(params?: {
  }): Observable<Array<MasterSourcingTeam>> {

    return this.apiMasterGetSourcingTeamGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterSourcingTeam>>) => r.body as Array<MasterSourcingTeam>)
    );
  }

  /**
   * Path part for operation apiMasterGetPurGroupGet
   */
  static readonly ApiMasterGetPurGroupGetPath = '/api/Master/GetPurGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetPurGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPurGroupGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterPurGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetPurGroupGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterPurGroup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetPurGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPurGroupGet$Plain(params?: {
  }): Observable<Array<MasterPurGroup>> {

    return this.apiMasterGetPurGroupGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterPurGroup>>) => r.body as Array<MasterPurGroup>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetPurGroupGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPurGroupGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterPurGroup>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetPurGroupGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterPurGroup>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetPurGroupGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPurGroupGet$Json(params?: {
  }): Observable<Array<MasterPurGroup>> {

    return this.apiMasterGetPurGroupGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterPurGroup>>) => r.body as Array<MasterPurGroup>)
    );
  }

  /**
   * Path part for operation apiMasterGetBaseOnGet
   */
  static readonly ApiMasterGetBaseOnGetPath = '/api/Master/GetBaseOn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetBaseOnGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetBaseOnGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterBaseOn>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetBaseOnGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBaseOn>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetBaseOnGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetBaseOnGet$Plain(params?: {
  }): Observable<Array<MasterBaseOn>> {

    return this.apiMasterGetBaseOnGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterBaseOn>>) => r.body as Array<MasterBaseOn>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetBaseOnGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetBaseOnGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterBaseOn>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetBaseOnGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterBaseOn>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetBaseOnGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetBaseOnGet$Json(params?: {
  }): Observable<Array<MasterBaseOn>> {

    return this.apiMasterGetBaseOnGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterBaseOn>>) => r.body as Array<MasterBaseOn>)
    );
  }

  /**
   * Path part for operation apiMasterGetIncotermGet
   */
  static readonly ApiMasterGetIncotermGetPath = '/api/Master/GetIncoterm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetIncotermGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetIncotermGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterIncoterm1>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetIncotermGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterIncoterm1>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetIncotermGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetIncotermGet$Plain(params?: {
  }): Observable<Array<MasterIncoterm1>> {

    return this.apiMasterGetIncotermGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterIncoterm1>>) => r.body as Array<MasterIncoterm1>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetIncotermGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetIncotermGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterIncoterm1>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetIncotermGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterIncoterm1>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetIncotermGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetIncotermGet$Json(params?: {
  }): Observable<Array<MasterIncoterm1>> {

    return this.apiMasterGetIncotermGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterIncoterm1>>) => r.body as Array<MasterIncoterm1>)
    );
  }

  /**
   * Path part for operation apiMasterGetCurrencyGet
   */
  static readonly ApiMasterGetCurrencyGetPath = '/api/Master/GetCurrency';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetCurrencyGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetCurrencyGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterCurrency>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetCurrencyGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCurrency>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetCurrencyGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetCurrencyGet$Plain(params?: {
  }): Observable<Array<MasterCurrency>> {

    return this.apiMasterGetCurrencyGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterCurrency>>) => r.body as Array<MasterCurrency>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetCurrencyGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetCurrencyGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterCurrency>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetCurrencyGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterCurrency>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetCurrencyGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetCurrencyGet$Json(params?: {
  }): Observable<Array<MasterCurrency>> {

    return this.apiMasterGetCurrencyGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterCurrency>>) => r.body as Array<MasterCurrency>)
    );
  }

  /**
   * Path part for operation apiMasterGetPaymentTermGet
   */
  static readonly ApiMasterGetPaymentTermGetPath = '/api/Master/GetPaymentTerm';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetPaymentTermGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPaymentTermGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<VChembiMstPaymentTerm>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetPaymentTermGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VChembiMstPaymentTerm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetPaymentTermGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPaymentTermGet$Plain(params?: {
  }): Observable<Array<VChembiMstPaymentTerm>> {

    return this.apiMasterGetPaymentTermGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VChembiMstPaymentTerm>>) => r.body as Array<VChembiMstPaymentTerm>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetPaymentTermGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPaymentTermGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<VChembiMstPaymentTerm>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetPaymentTermGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<VChembiMstPaymentTerm>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetPaymentTermGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetPaymentTermGet$Json(params?: {
  }): Observable<Array<VChembiMstPaymentTerm>> {

    return this.apiMasterGetPaymentTermGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<VChembiMstPaymentTerm>>) => r.body as Array<VChembiMstPaymentTerm>)
    );
  }

  /**
   * Path part for operation apiMasterGetSimpleValueGet
   */
  static readonly ApiMasterGetSimpleValueGetPath = '/api/Master/GetSimpleValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetSimpleValueGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterSimpleValue>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetSimpleValueGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterSimpleValue>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetSimpleValueGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueGet$Plain(params?: {
  }): Observable<Array<MasterSimpleValue>> {

    return this.apiMasterGetSimpleValueGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterSimpleValue>>) => r.body as Array<MasterSimpleValue>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetSimpleValueGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterSimpleValue>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetSimpleValueGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterSimpleValue>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetSimpleValueGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueGet$Json(params?: {
  }): Observable<Array<MasterSimpleValue>> {

    return this.apiMasterGetSimpleValueGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterSimpleValue>>) => r.body as Array<MasterSimpleValue>)
    );
  }

  /**
   * Path part for operation apiMasterGetSimpleValueByGroupGet
   */
  static readonly ApiMasterGetSimpleValueByGroupGetPath = '/api/Master/GetSimpleValueByGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetSimpleValueByGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueByGroupGet$Plain$Response(params?: {
    groupName?: string;
  }): Observable<StrictHttpResponse<Array<MasterSimpleValue>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetSimpleValueByGroupGetPath, 'get');
    if (params) {
      rb.query('groupName', params.groupName, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterSimpleValue>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetSimpleValueByGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueByGroupGet$Plain(params?: {
    groupName?: string;
  }): Observable<Array<MasterSimpleValue>> {

    return this.apiMasterGetSimpleValueByGroupGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterSimpleValue>>) => r.body as Array<MasterSimpleValue>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterGetSimpleValueByGroupGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueByGroupGet$Json$Response(params?: {
    groupName?: string;
  }): Observable<StrictHttpResponse<Array<MasterSimpleValue>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterService.ApiMasterGetSimpleValueByGroupGetPath, 'get');
    if (params) {
      rb.query('groupName', params.groupName, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterSimpleValue>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterGetSimpleValueByGroupGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterGetSimpleValueByGroupGet$Json(params?: {
    groupName?: string;
  }): Observable<Array<MasterSimpleValue>> {

    return this.apiMasterGetSimpleValueByGroupGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterSimpleValue>>) => r.body as Array<MasterSimpleValue>)
    );
  }

}
