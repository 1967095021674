import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { SummaryBiddingAllInfo } from '../../../../../../../api/models';

@Component({
  selector: 'app-summary-header',
  templateUrl: './summary-header.component.html',
  styleUrls: ['./summary-header.component.css']
})
export class SummaryHeaderComponent implements OnInit {

  @Input() biddingAllInfo!: SummaryBiddingAllInfo;
  @Input() steps: number = 0;
  documentStatus: string = '';
  documentNumber: string = "";
  documentCreator: string = "";
  documentCreatedDate!: Date;
  start!: Date;
  deadline!: Date;
  vendor: string[] = [];
  isDeadLine: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.documentStatus = this.biddingAllInfo.header?.status!;
    this.documentNumber = this.biddingAllInfo.header?.docNo!;
    this.documentCreator = this.biddingAllInfo.header?.creatorName!;
    this.documentCreatedDate = new Date(this.biddingAllInfo.header?.createDate!);
    this.biddingAllInfo.vendorBiddingInfo?.forEach(item => {
      this.vendor.push(item.detail?.vendorName!);
    })
    this.deadline = new Date(this.biddingAllInfo.header?.deadline!);
    this.start = new Date(this.biddingAllInfo.header?.biddingStartDate!);

    if(moment(this.deadline).diff(moment(new Date()), "days") <= 3 )
    {
      this.isDeadLine = true;
      console.log("Deadline: ", moment(this.deadline).diff(moment(new Date()), "days"));
    }
  }

}
