import { LogComponent } from './pages/log/log.component';
import { SearchingPriceListComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/searching-price-list/searching-price-list.component';
import { SearchingSummaryComponent } from './pages/work-station/bidding-worksheet/summary/searching-summary/searching-summary.component';
import { NewBiddingComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/new-bidding.component';
import { NotFoundComponent } from './pages/_extra/not-found/not-found.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { ApproveBiddingMemoComponent } from './pages/work-station/approve-bidding-memo/approve-bidding-memo.component';
import { RCFComponent } from './pages/work-station/rcf/rcf.component';
import { DashboardComponent } from './pages/work-station/dashboard/dashboard.component';
import { UserManualComponent } from './pages/user-manual/user-manual.component';
import { HomeComponent } from './pages/home/home.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotAuthorizedComponent } from './pages/_extra/not-authorized/not-authorized.component';
import { VendorBiddingComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/vendor-bidding/vendor-bidding.component';
import { SummaryComponent } from './pages/work-station/bidding-worksheet/summary/summary/summary.component';
import { SearchingMemoComponent } from './pages/work-station/bidding-worksheet/create-memo/searching-memo/searching-memo.component';
import { CreateNewMemoComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/create-new-memo.component';


const routes: Routes = [
  { path: '',
    pathMatch: 'full',
    redirectTo: 'home'

  },
  {
    path: 'home',
    component: HomeComponent,
    data: {
      breadcrumb: 'Home'
    }
  },
  {
    path: 'user-manual',
    component: UserManualComponent,
    data: {
      breadcrumb: 'User-Manual'
    }
  },
  {
    path: 'work-station/dashboard',
    component: DashboardComponent,
    data: {
      breadcrumb: 'Dashboard'
    }
  },
  {
    path: 'work-station/request-new-contract',
    component: RCFComponent,
    data: {
      breadcrumb: 'New Request Contract',
      role: [
        "Normal User",
        "Procurement User",
        "Procurement Admin",
      ]
    }
  },
  {
    path: 'bidding',
    component: VendorBiddingComponent,
    data: {
      role: [
        "Vendor",
      ]
    }
  },
  {
    path: 'work-station/bidding-worksheet/bidding-detail',
    component: VendorBiddingComponent,
    data: {
      role: [
        "Vendor",
      ]
    }
  },
  {
    path: 'work-station/bidding-worksheet/request-for-price-list',
    component: SearchingPriceListComponent,
    data: {
      breadcrumb: 'Request for price list',
      role: [
        "Procurement User",
        "Procurement Admin",
      ]
    },

  },
  {
    path: 'work-station/bidding-worksheet/searching-summary',
    component: SearchingSummaryComponent,
    data: {
      breadcrumb: 'Searching Summary Bidding Worksheet',
      role: [
        "Procurement User",
        "Procurement Admin",
      ]
    },

  },
  {
    path: 'work-station/bidding-worksheet/summary',
    component: SummaryComponent,
    data: {
      breadcrumb: 'Summary',
      role: [
        "Procurement User",
        "Procurement Admin",
      ]
    },

  },
  {
    path: 'work-station/bidding-worksheet/request-for-price-list/new-bidding',
    component: NewBiddingComponent,
    data: {
      breadcrumb: 'New Bidding',
      role: [
        "Procurement User",
        "Procurement Admin",
      ]
    },

  },
  {
    path: 'work-station/bidding-worksheet/memo',
    component: SearchingMemoComponent,
    data: {
      breadcrumb: 'Memo',
      role: [
        "Procurement User",
        "Procurement Admin",
      ]
    },

  },
  {
    path: 'work-station/bidding-worksheet/memo/new-memo',
    component: CreateNewMemoComponent,
    data: {
      breadcrumb: 'New Memo',
      role: [
        "Procurement User",
        "Procurement Admin",
      ]
    },

  },
  {
    path: 'approve-bidding-memo',
    component: ApproveBiddingMemoComponent,
    data: {
      breadcrumb: 'Approve Bidding Memo',
      role: [
        "Procurement User",
        "Procurement Admin",
      ]
    }
  },
  {
    path: 'configuration',
    component: ConfigurationComponent,
    data: {
      breadcrumb: 'Configuration',
      role: [
        "Procurement Admin",
      ]
    }
  },
  {
    path: 'log',
    component: LogComponent,
    data: {
      breadcrumb: 'Log',
      role: [
        "Procurement Admin",
      ]
    }
  },
  {
    path: "not-authorized",
    component: NotAuthorizedComponent
  },
  {
    path: "**",
    component: NotFoundComponent
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
