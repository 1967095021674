import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-searching-memo-result',
  templateUrl: './searching-memo-result.component.html',
  styleUrls: ['./searching-memo-result.component.css']
})
export class SearchingMemoResultComponent implements OnInit {

  @Input() searchList: any[] = [];
  constructor() { }

  ngOnInit(): void {
  }

}
