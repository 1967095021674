<nz-layout class="app-layout">

  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Item List Detail'" [nzActive]="true">
      <div nz-row class="row">

        <div class="panel-content">
          <div nz-row class="collapse-row">

            <!-- <div nz-col nzSpan="24" class="collapse-col">
              <div nz-row [nzAlign]="'bottom'">
                <button nz-button (click)="onViewExchangeRate(tplContent)">Current Exchange Rate</button>
              </div>
            </div> -->


            <nz-divider></nz-divider>

            <div nz-col nzSpan="24" class="collapse-col">
              <!-- <div nz-row [nzAlign]="'bottom'">
                <button nz-button (click)="onViewExchangeRate(tplContent)">Current Exchange Rate</button>
              </div> -->
              <nz-card>
                <nz-table #groupingTable [nzData]="biddingAllInfo.vendorBiddingInfo!" nzBordered
                  [nzShowPagination]="false" [nzScroll]="{ x: '3500px', y: '600px' }">
                  <thead>
                    <tr>
                      <th nzLeft rowspan="2" nzWidth="60px">No.</th>
                      <th nzLeft rowspan="2" nzWidth="150px">Material Code</th>
                      <th nzLeft rowspan="2" nzWidth="200px">Item Name</th>
                      <th nzLeft rowspan="2" nzWidth="200px">Description</th>
                      <th nzWidth="60px" rowspan="2">Unit</th>
                      <th nzWidth="150px" rowspan="2">Last price</th>
                      <th nzWidth="150px" rowspan="2">Average last price</th>
                      <th *ngFor="let item of biddingAllInfo.vendorBiddingInfo" colSpan="3"
                        style="background-color: cornsilk;">{{item.detail!.vendorName}}</th>
                      <th nzWidth="150px" *ngFor="let item of biddingAllInfo.vendorBiddingInfo" rowSpan="2">Award vendor
                      </th>
                      <th colSpan="2">CA</th>
                      <th colSpan="2">CR</th>
                    </tr>
                    <tr>
                      <ng-container *ngFor="let item of groupingTable.data">
                        <th style="background-color: burlywood;" rowspan="1">ราคาครั้งก่อน</th>
                        <th style="background-color: cadetblue;" rowspan="1">First unit price</th>
                        <th style="background-color: darkseagreen;" rowspan="1">Final unit price</th>
                      </ng-container>

                      <th rowspan="1">Value</th>
                      <th rowspan="1">%</th>
                      <th rowspan="1">Value</th>
                      <th rowspan="1">%</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of itemList;index as i">
                      <td nzLeft>{{ data.no }}</td>
                      <td nzLeft>{{ data.matCode }}</td>
                      <td nzLeft>{{ data.itemName }}</td>
                      <td nzLeft>{{ data.description }}</td>
                      <td>{{ data.unit }}</td>
                      <td>{{biddingItemListLastPriceAndAvgPrice[i].lastPrice | number}} THB</td>
                      <td>{{biddingItemListLastPriceAndAvgPrice[i].averageLastPice | number}} THB</td>
                      <ng-container *ngFor="let item of groupingTable.data;index as j">
                        <td>
                          {{item.itemList![i].lastPrice | number}} THB
                        </td>
                        <td>
                          {{item.itemList![i].pricePerUnit | number}} THB
                        </td>
                        <td>
                          <nz-input-number-group>
                            <nz-input-number [nzMin]="1" [nzMax]="100" [nzStep]="1"
                              style="width: fit-content"></nz-input-number>
                          </nz-input-number-group>
                        </td>
                      </ng-container>
                      <ng-container *ngFor="let item of biddingAllInfo.vendorBiddingInfo">
                        <td>
                          <nz-input-number-group>
                            <nz-input-number [nzMin]="1" [nzMax]="100" [nzStep]="1">
                            </nz-input-number>
                          </nz-input-number-group>
                        </td>
                      </ng-container>
                      <td>
                        <nz-input-number [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number>
                      </td>
                      <td>
                        <nz-input-number-group [nzSuffix]="'%'">
                          <nz-input-number [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number>
                        </nz-input-number-group>
                      </td>
                      <td>
                        <nz-input-number [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number>
                      </td>
                      <td>
                        <nz-input-number-group [nzSuffix]="'%'">
                          <nz-input-number [nzMin]="1" [nzMax]="100" [nzStep]="1"></nz-input-number>
                        </nz-input-number-group>
                      </td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-card>
            </div>

            <div nz-col [nzXs]="24" [nzSm]="24">
              <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">

              </div>

            </div>

            <div nz-col nzSpan="24" class="collapse-col">

            </div>

          </div>



        </div>

      </div>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>

<ng-template #tplContent>
  <div nz-row class="content-row">
    <div nz-col [nzXs]="24" [nzSm]="24">
      <nz-table class="exchangeRateTable" #exchangeRateTable [nzData]="exchangeRate" nzBordered
        [nzShowPagination]="false">
        <thead>
          <tr>
            <th nzWidth="60px">Rate Type</th>
            <th nzWidth="60px">From</th>
            <th nzWidth="60px">For To Factor</th>
            <th nzWidth="60px">To</th>
            <th nzWidth="60px">Rate</th>
            <th nzWidth="60px">Effective Date</th>
          </tr>
          <tr *ngFor="let data of exchangeRateTable.data">
            <td>{{data.rateType}}</td>
            <td>{{data.fromCurr}}</td>
            <td>{{data.factor_for_to_curr}}</td>
            <td>{{data.toCurr}}</td>
            <td>{{data.exchangeRate1}}</td>
            <td>{{data.effectiveDate | date:"d MMM yyyy"}}</td>
          </tr>
        </thead>
      </nz-table>

    </div>
  </div>
</ng-template>
