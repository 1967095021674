
<nz-layout class="content" >

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Sourcing Team'" [nzActive]="true">
      <form nz-form [formGroup]="sourcingTeamForm" (change)="updateData(sourcingTeamForm.value)">

        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Sourcing Team</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="6">

                <nz-select
                class="select-sourcing-team"
                nzMode="default"
                nzPlaceHolder="Select Sourcing Team"
                [nzShowArrow]="false"
                [(ngModel)]="selectedSourcingTeam"
                (ngModelChange)="selectSourcingTeam($event)"
                [ngModelOptions]="{standalone: true}"
                [nzDisabled]="steps != 0"
              >

              <nz-option *ngFor="let o of sourcingTeamList;let i = index" [nzValue]="i" [nzLabel]="o.sourcingTeam!">
              </nz-option>

              </nz-select>
              </nz-form-control>

            </nz-form-item>

          </div>

        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
