<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" [nzSize]="'large'">

    <nz-card class="card">
      <nz-row>
        <nz-breadcrumb>
          <nz-breadcrumb-item>
            <a routerLink="../home">
              <span nz-icon nzType="home"></span>
              <span>Home</span>
            </a>
          </nz-breadcrumb-item>
          <nz-breadcrumb-item>
            <span>Admin</span>
          </nz-breadcrumb-item>
          <nz-breadcrumb-item>
            <a>
              <span>Configuration</span>
            </a>
          </nz-breadcrumb-item>
        </nz-breadcrumb>
      </nz-row>
    </nz-card>

    <div nz-row class="row">

      <div class="panel-content">

        <nz-collapse>
          <nz-collapse-panel [nzHeader]="'Work Station'" [nzActive]="true">

            <nz-row class="collapse-row">
              <nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
                <app-config-rcf [rcfConfig]="rcfConfig"
                  (configValueChange)="onConfigValueChange($event)"></app-config-rcf>
              </nz-col>

              <nz-col nzSpan="24" class="collapse-col" *ngIf="!isDataLoading">
                <app-config-bidding [biddingConfig]="biddingConfig" [baseOn]="baseOn"
                  [biddingSimpleValue]="biddingSimpleValue"
                  (configValueChange)="onConfigValueChange($event)"></app-config-bidding>
              </nz-col>
            </nz-row>

          </nz-collapse-panel>
        </nz-collapse>

      </div>
    </div>
  </nz-spin>
</nz-layout>
