<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Sourcing Contract'" [nzActive]="true">
      <form nz-form [formGroup]="sourcingContractForm">

        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>Purchasing Groups</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">

                <nz-select nzShowSearch [(ngModel)]="selectedPurGroup" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="onSelectPurGroupChange($event)" [nzDisabled]="steps !== 0" [nzStatus]="selectPurGroupStatus">
                  <nz-option *ngFor="let item of purGroupMember; index as i" [nzLabel]="item.purGroup!" [nzValue]="i">
                  </nz-option>
                </nz-select>

              </nz-form-control>

              <nz-form-label [nzXs]="6" [nzSm]="4" >Name</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="name" >
              </nz-form-control>

            </nz-form-item>

          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="4" >Email</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="email">
              </nz-form-control>

              <nz-form-label [nzXs]="6" [nzSm]="4" >Tel No.</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="telNo">
              </nz-form-control>

            </nz-form-item>

          </div>

        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">* Require</ng-container>
  <ng-container *ngIf="control.hasError('email')">* Invalid Email</ng-container>
  <ng-container *ngIf="control.hasError('pattern') || control.hasError('minlength') || control.hasError('maxlength')">*
    Invalid Phone Number</ng-container>
</ng-template>
