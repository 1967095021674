<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" [nzSize]="'large'">
    <nz-card class="card">
      <nz-row>
        <nz-breadcrumb>
          <nz-breadcrumb-item>
            <a routerLink="../home">
              <span nz-icon nzType="home"></span>
              <span>Home</span>
            </a>
          </nz-breadcrumb-item>
          <nz-breadcrumb-item>
            <span>Work Station</span>
          </nz-breadcrumb-item>
          <nz-breadcrumb-item>
            <a>
              <span>Dashboard</span>
            </a>
          </nz-breadcrumb-item>
        </nz-breadcrumb>
      </nz-row>
    </nz-card>

    <div nz-row class="row">
      <div class="panel-content">
        <div nz-row class="collapse-row">
          <div nz-col nzSpan="24" class="collapse-col">
            <app-searching-criteria (searcingDataChange)="onSearchingFormChange($event)"></app-searching-criteria>
          </div>

          <div nz-col nzSpan="24" class="collapse-col">
            <nz-card nzTitle="Result">
              <nz-segmented class="segment" nzSize="large" [nzOptions]="segmentOptions"
                (nzValueChange)="handleSegmentChange($event)"></nz-segmented>

              <nz-table #SearchResult class="table-dashboard" [nzData]="searchList" [nzScroll]="{ x: '1500px' }"
                [nzBordered]="true" [nzOuterBordered]="true">
                <thead>
                  <tr>
                    <!-- <th nzWidth="20px"></th> -->
                    <th *ngFor="let column of listOfColumn" [(nzSortOrder)]="column.sortOrder" [nzWidth]="column.width"
                      [nzSortFn]="column.sortFn" [nzSortPriority]="column.priority" [nzLeft]="column.nzLeft">
                      {{ column.name }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of SearchResult.data; let i = index" class="editable-row">
                    <td nzLeft>
                      <a [routerLink]="['../request-new-contract']" [queryParams]="{ pk: data.pkRcfheader }"
                        target="_blank">
                        {{ data.docNo }}
                      </a>
                    </td>

                    <td nzLeft>{{ data.createdDate | date: "d MMM yyyy HH:mm" }}</td>
                    <td nzLeft>
                      <nz-tag *ngIf="data.status == 'Draft'" nzColor="default">
                        {{ data.status }}
                      </nz-tag>
                      <nz-tag *ngIf="data.status == 'Complete'" [nzColor]="'green'">
                        {{ data.status }}
                      </nz-tag>
                      <nz-tag *ngIf="
                          data.status == 'Wait approver intitial approve' ||
                          data.status == 'Wait approver approve'
                        " [nzColor]="'cyan'">
                        {{ data.status }}
                      </nz-tag>
                      <nz-tag *ngIf="data.status == 'Sourcing conclusion'" [nzColor]="'geekblue'">
                        Wait for {{ data.status }}
                      </nz-tag>
                      <nz-tag *ngIf="
                          data.status ==
                            'Wait sourcing approver initial approve' ||
                          data.status == 'Wait sourcing approver approve'
                        " [nzColor]="'purple'">
                        {{ data.status }}
                      </nz-tag>
                      <nz-tag *ngIf="data.status == 'Reject'" [nzColor]="'red'">
                        {{ data.status }}
                      </nz-tag>
                      <nz-tag *ngIf="data.status == 'Return'" [nzColor]="'orange'">
                        {{ data.status }}
                      </nz-tag>
                    </td>
                    <td>{{ data.requesterName }}</td>
                    <td>{{ data.sourcingTeamName }}</td>
                    <td>
                      <ng-container *ngIf="data.workType === 'service'">Service</ng-container>
                      <ng-container *ngIf="data.workType === 'product'">Product</ng-container>
                    </td>
                    <td>{{ data.contractName }}</td>
                    <td>
                      <ng-container *ngIf="data.contractPeriod === 0.5">
                        6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 1">
                        1 Year
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 1.5">
                        1 Year 6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 2">
                        2 Year
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 2.5">
                        2 Year 6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 3">
                        3 Year
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 3.5">
                        3 Year 6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 4">
                        4 Year
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 4.5">
                        4 Year 6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 5">
                        5 Year
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 5.5">
                        5 Year 6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 6">
                        6 Year
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 6.5">
                        6 Year 6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 7">
                        7 Year
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 7.5">
                        7 Year 6 Month
                      </ng-container>
                      <ng-container *ngIf="data.contractPeriod === 8">
                        8 Year
                      </ng-container>
                    </td>
                    <!-- <td>{{ data.estimatedContractValueInThb | number }} THB</td> -->
                    <td>
                      <div nz-row [nzGutter]="24">
                        <nz-col [nzSpan]="14">{{ data.estimatedContractValueInThb | number }}</nz-col>
                        <nz-col [nzSpan]="10"> THB </nz-col>
                      </div>
                    </td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                </tbody>
              </nz-table>

              <!-- <div nz-col [nzXs]="24" [nzSm]="24">
                <div nz-row class="div-button" [nzAlign]="'bottom'" [nzJustify]="'end'">
                  <div class="div-button-save" style="padding: 2%">
                    <button nzSize="default" class="btn-cancel" nz-button nzType="default">
                      Auction
                    </button>
                  </div>

                  <div class="div-button-submit" style="padding: 2%">
                    <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit">
                      Bidding
                    </button>
                  </div>
                </div>
              </div> -->
            </nz-card>
          </div>
        </div>
      </div>
    </div>
  </nz-spin>
</nz-layout>
