<nz-layout class="content">
    <nz-collapse>
        <nz-collapse-panel [nzHeader]="'Summary Cost Saving'" [nzActive]="true">
            <div nz-row>

                <div nz-col [nzXs]="24" [nzSm]="24">
                    <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6">Export</nz-form-label>
                        <nz-form-control [nzXs]="6" [nzSm]="1">
                            <button nz-button (click)="exportSummaryCostSavingToJPEG()">
                                <div nz-row [nzAlign]="'middle'" [nzJustify]="'center'">
                                    <div nz-col>
                                        <i nz-icon nzType="picture" nzTheme="outline"></i>
                                        JPEG
                                    </div>
                                </div>
                            </button>
                        </nz-form-control>

                        <nz-form-label [nzXs]="6" [nzSm]="1" nzNoColon></nz-form-label>
                        <nz-form-control [nzXs]="6" [nzSm]="1">
                            <button nz-button (click)="exportSummaryCostSavingToExcel()">
                                <i nz-icon nzType="table" nzTheme="outline"></i>
                                Excel
                            </button>
                        </nz-form-control>

                    </nz-form-item>
                </div>

                <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
                    <nz-table class="summaryCostSaving" id="summaryCostSaving" [nzShowPagination]="false"
                        [nzBordered]="true" nzTableLayout="fixed" nzTemplateMode>
                        <thead>
                            <tr>
                                <th style="background-color: #FED299;" nzWidth="75px">Company</th>
                                <th style="background-color: #FED299;" nzWidth="50px">มูลค่าจัดหา (บาท)</th>
                                <th style="background-color: #FED299;" nzWidth="50px">Cost Avoidance (บาท)</th>
                                <th style="background-color: #FED299;" nzWidth="50px">Cost Reduction (บาท)</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of biddingAllInfo.vendorBiddingInfo;index as number">
                                <td style="text-align: left !important;">{{data.detail?.vendorName}}</td>
                                <td style="text-align: right !important;">{{summaryCost[number].totalPrice |
                                    number:'1.2-2'}}</td>
                                <td style="text-align: right !important;">{{summaryCost[number].totalCA |
                                    number:'1.2-2'}}</td>
                                <td style="text-align: right !important;">{{summaryCost[number].totalCR |
                                    number:'1.2-2'}}</td>
                            </tr>
                            <tr>
                                <td style="background-color: #EE7272;">
                                    <b>
                                        Total
                                    </b>
                                </td>
                                <td style="background-color: #EE7272; text-align: right !important;">
                                    <!-- TotalPrice -->
                                    <b>
                                        {{summaryCostSaving.totalPrice | number:'1.2-2'}}
                                    </b>
                                </td>
                                <td style="background-color: #EE7272; text-align: right !important;">
                                    <!-- Total CA -->
                                    <b>
                                        {{summaryCostSaving.totalCA | number:'1.2-2'}}
                                    </b>
                                </td>
                                <td style="background-color: #EE7272; text-align: right !important;">
                                    <!-- Total CR -->
                                    <b>
                                        {{summaryCostSaving.totalCR | number:'1.2-2'}}
                                    </b>
                                </td>
                            </tr>
                        </tbody>

                    </nz-table>
                </div>


            </div>
        </nz-collapse-panel>
    </nz-collapse>

</nz-layout>
