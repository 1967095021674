<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Request new contract'" [nzActive]="false">
      <form nz-form>

        <div nz-row class="content-row">

          <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

            <nz-table class="table-bidding-worksheet" #configTable [nzScroll]="{ x: 'auto' }" [nzBordered]="true"
              [nzOuterBordered]="true" [nzData]="rcfConfig" [nzShowPagination]="false" [nzFrontPagination]="false">
              <thead>
                <tr>
                  <th nzWidth="60px">Action</th>
                  <th nzWidth="120px">Key</th>
                  <th nzWidth="300px">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of configTable.data; index as i" class="editable-row">
                  <td style="text-align: center;">
                    <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'"
                      (click)="modalEditConfig(tplTitle, tplContent, tplFooter, data.key!, i)"
                      [disabled]="data.key === 'FileIdTemplateItemlist'">

                      <ng-template #tplTitle>
                        <span>Edit Config</span>
                      </ng-template>

                      <ng-template #tplContent>
                        <div nz-row class="content-row">

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Key</nz-form-label>
                              <nz-form-control [nzSm]="12">
                                {{data.key}}
                              </nz-form-control>

                            </nz-form-item>
                          </div>

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Value</nz-form-label>
                              <nz-form-control [nzSm]="12">

                                <input nz-input type="text" [(ngModel)]="changedConfigValue"
                                  [ngModelOptions]="{standalone: true}">

                                <span style="color: red;" *ngIf="data.key === 'AttachmentFileSizeInByte' || data.key === 'OtherFileSizeInByte'">
                                  * Input Value in MB, Example 20
                                </span>

                              </nz-form-control>

                            </nz-form-item>
                          </div>


                        </div>

                      </ng-template>

                      <ng-template #tplFooter let-ref="modalRef">
                        <button nz-button nzDanger (click)="modalDestroy()">Cancel</button>
                        <button nz-button nzType="primary" (click)="onChangeConfigValue(data.key!)">Save</button>
                      </ng-template>

                      <i nz-icon nzType="edit" nzTheme="outline" class="icon-edit">
                      </i>

                    </button>
                  </td>
                  <td>{{data.key}}</td>
                  <td>{{data.value}}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>




        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
