<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Criteria'" [nzActive]="true">
      <form nz-form [formGroup]="searchingForm">

        <div nz-row class="content-row">
          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4">Sourcing Team</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select nzMode="multiple" [nzMaxTagCount]="5" nzMode="multiple" formControlName="sourcingTeam">
                  <nz-option *ngFor="let item of sourcingTeamList" [nzLabel]="item.sourcingTeam!" [nzValue]="item.sourcingTeam"></nz-option>
                </nz-select>
              </nz-form-control>

              <nz-form-label [nzXs]="24" [nzSm]="4">Purchasing Groups</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select nzMode="multiple" [nzMaxTagCount]="5" nzMode="multiple" formControlName="sourcingTeam" formControlName="purchasingGroups">
                  <nz-option *ngFor="let item of purchasingGroupsList" [nzLabel]="item.purGroup!" [nzValue]="item.purGroup"></nz-option>
                </nz-select>
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4">Create Date</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-range-picker [nzRanges]="ranges" nzFormat="d MMM yyyy" [(ngModel)]="createdDate"
                  (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}">
                </nz-range-picker>
              </nz-form-control>

              <nz-form-label [nzXs]="24" [nzSm]="4">Status</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select [nzMaxTagCount]="50" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                  [nzAllowClear]="true" formControlName="status">
                  <nz-option [nzValue]="1" [nzLabel]="'Wait quotation from vendor'"></nz-option>
                  <nz-option [nzValue]="2" [nzLabel]="'Wait TOR from vendor'"></nz-option>
                  <nz-option [nzValue]="3" [nzLabel]="'Already submited'"></nz-option>
                </nz-select>
                <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
              </nz-form-control>

            </nz-form-item>
          </div>

          <!-- <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4">Vendor</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select [nzMaxTagCount]="50" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                  [(ngModel)]="selectedVendor" [ngModelOptions]="{standalone: true}"
                  (ngModelChange)="onChangeVendor($event)" [nzAllowClear]="true">
                  <nz-option *ngFor="let item of listOfVendorData; let i = index" [nzValue]="i" [nzLabel]="item.name1!">
                  </nz-option>
                </nz-select>
                <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
              </nz-form-control>

            </nz-form-item>
          </div> -->

          <div nz-col [nzXs]="24" [nzSm]="24">
            <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">
              <div class="div-button-save" style="padding: 2%;">
                <button nzSize="default" class="btn-cancel" nz-button nzType="default">Reset</button>
              </div>

              <div class="div-button-submit" style="padding: 2%;">
                <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit"
                  (click)="onSearch()">Search</button>
              </div>
            </div>
          </div>

        </div>


      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
