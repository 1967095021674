import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { VendorBiddingInfo } from '../../../../../../../../api/models';


@Component({
  selector: 'app-vendor-bidding-header',
  templateUrl: './vendor-bidding-header.component.html',
  styleUrls: ['./vendor-bidding-header.component.css']
})
export class VendorBiddingHeaderComponent implements OnInit {

  @Input() biddingInfo : VendorBiddingInfo = {};

  documentStatus: string = "";
  documentNumber: string = "";
  documentCreator: string = "";
  documentCreatedDate!: Date;
  documentStartDate!: Date;
  documentDeadline!: Date;
  documentVendorName: string = "";
  isAboutToExpire: boolean = false;

  constructor() { }

  ngOnInit(): void {
    this.documentStartDate = new Date(this.biddingInfo.header?.biddingStartDate!);
    this.documentDeadline = new Date(this.biddingInfo.header?.deadline!);
    this.documentVendorName = this.biddingInfo.detail?.vendorName!;

    if(moment(this.biddingInfo.header?.deadline).diff(moment(new Date()), "days") <= 3 )
    {
      this.isAboutToExpire = true;
    }
  }

}
