<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" nzTip="Loading..." [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-header">
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-card class="card">
              <nz-breadcrumb>
                <nz-breadcrumb-item>
                  <a routerLink="../home">
                    <span nz-icon nzType="home"></span>
                    <span>Home</span>
                  </a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  <span>Admin</span>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  <a>
                    <span>Log</span>
                  </a>
                </nz-breadcrumb-item>
              </nz-breadcrumb>
            </nz-card>

          </div>


        </div>

      </div>

      <div class="panel-content">

        <nz-row class="collapse-row" *ngIf="!isDataLoading">
          <nz-col nzSpan="24" class="collapse-col">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Request new contract'" [nzActive]="false">
                <app-request-contract-log [logData]="requestContractLog"
                  [sourcingTeamList]="sourcingTeamList"></app-request-contract-log>
              </nz-collapse-panel>
            </nz-collapse>
          </nz-col>
        </nz-row>

        <nz-row class="collapse-row" *ngIf="!isDataLoading">
          <nz-col nzSpan="24" class="collapse-col">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Request for pricelist'" [nzActive]="false">
                <app-request-pricelist-log [logData]="requestPricelistLog"
                  [sourcingTeamList]="sourcingTeamList"></app-request-pricelist-log>
              </nz-collapse-panel>
            </nz-collapse>
          </nz-col>
        </nz-row>

        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col">

          </div>
        </div>

        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col">

          </div>
        </div>

        <div nz-row class="collapse-row">


        </div>

      </div>

    </div>

  </nz-spin>
</nz-layout>
