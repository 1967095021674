<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Request Detail'" [nzActive]="true">

      <!-- <form nz-form [formGroup]="requestDetailForm" (change)="updateData(requestDetailForm.value)"> -->
      <form nz-form [formGroup]="requestDetailForm" (change)="updateData(requestDetailForm)">
        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Work Type</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-radio-group formControlName="work_type">
                  <label nz-radio [nzValue]="'product'">Product</label>
                  <label nz-radio [nzValue]="'service'">Service</label>
                </nz-radio-group>


              </nz-form-control>


            </nz-form-item>
          </div>


          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Contract Type</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8" [nzErrorTip]="errorTpl">

                <nz-select class="input-contract-type" nzPlaceHolder="Contract Type, กลุ่มประเภทสินค้า/บริการ"
                  formControlName="contract_type">
                  <nz-option *ngFor="let item of listContractType" [nzLabel]="item.contractTypeName!"
                    [nzValue]="item.contractTypeName">
                  </nz-option>
                </nz-select>

                <i class="icon-tips" nz-icon nzType="question-circle" nzTheme="twotone" nz-tooltip
                  [nzTooltipTitle]="contractTypeTips"></i>
                <ng-template #contractTypeTips>
                  <div class="tips">
                    <p> VMI : [This is Meaning of VMI]</p>
                    <p> Partnership : [This is Meaning of Partnership]</p>
                  </div>
                </ng-template>

              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Contract Detail</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="contract_name" placeholder="Contract Detail, รายละเอียดสัญญา">

              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Product/Service Name</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="product_service_name"
                  placeholder="Product/Service Name, ชื่อสินค้าหรือบริการ">
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Estimated Contract Period</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-select class="input-contract_period" nzPlaceHolder="Contract Period, ระยะเวลาที่คาดว่าจะทำสัญญา"
                  formControlName="contract_period">

                  <nz-option *ngFor="let option of optionContractPeriod" [nzValue]="option.value"
                    [nzLabel]="option.label"></nz-option>

                </nz-select>
              </nz-form-control>
            </nz-form-item>

          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Estimated Contract Value</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="6" [nzErrorTip]="errorTpl">

                <nz-input-number-group>
                  <nz-input-number formControlName="estimated_contract_value"
                    [nzPlaceHolder]="'Estimated Contract Value, ประมาณการมูลค่าสัญญา'" [nzMin]="0" [nzMax]="1000000000"
                    [nzStep]="0" [nzFormatter]="formatterNumber" [nzParser]="parserNumber"
                    style="width: 80%; text-align: left !important;">
                  </nz-input-number>
                  <input nz-input value="THB" [disabled]="true" style="width: 20%;">
                </nz-input-number-group>

              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">

            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Reason For Requesting</nz-form-label>
              <nz-form-control [nzXs]="18" [nzSm]="18" [nzErrorTip]="errorTpl">
                <nz-radio-group formControlName="reason_for_requesting">
                  <label nz-radio [nzValue]="'add_scope'">เพิ่มเติมจำนวนผู้ขาย/ผู้ให้บริการ
                    หรือเพิ่มเติมรายการจากสัญญาที่ใช้ในปัจจุบัน</label>
                  <label nz-radio [nzValue]="'new_contract'">สัญญาใหม่</label>
                </nz-radio-group>
              </nz-form-control>


            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">More Detail (Reason for requesting)</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8">
                <textarea nz-input formControlName="other_note" placeholder="More Detail, รายละเอียดเพิ่มเติม"
                  [nzAutosize]="{ minRows: 4, maxRows: 10 }"></textarea>
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Expected Result</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8" [nzErrorTip]="errorTpl">
                <!-- <nz-input-group [nzSuffix]="'THB'"> -->
                <input nz-input formControlName="expected_result" placeholder="Expected Result, ผลที่คาดว่าจะได้รับ">
                <!-- </nz-input-group> -->
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Applicable for plant</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-select [nzMaxTagCount]="50" [nzMaxTagPlaceholder]="tagPlaceHolder" nzMode="multiple"
                  nzPlaceHolder="Select Plant, สามารถใช้ได้กับพื้นที่ใด" [(ngModel)]="selectedPlant"
                  [ngModelOptions]="{standalone: true}" (ngModelChange)="onChangePlant($event)" [nzAllowClear]="true"
                  [nzStatus]="selectedPlantStatus" [nzDisabled]="steps != 0">

                  <nz-option [nzValue]="'all'" [nzLabel]="'Select All'"></nz-option>
                  <nz-option *ngFor="let item of listPlant; let i = index" [nzValue]="i" [nzLabel]="item.plantName!">
                  </nz-option>
                </nz-select>
                <ng-template #tagPlaceHolder let-selectedList>and {{ selectedList.length }} more selected</ng-template>
              </nz-form-control>
            </nz-form-item>


          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Suggest Vendor</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">
                <div nz-col style="display: flex;">
                  <nz-select nzMode="default" nzPlaceHolder="Search Vendor Name, ค้นหารายชื่อผู้ขาย"
                    [nzShowArrow]="false" nzShowSearch nzServerSearch [(ngModel)]="searchVendor"
                    (ngModelChange)="addRowSuggestVendor($event)" [ngModelOptions]="{standalone: true}"
                    (nzOnSearch)="onSearchVendor($event)" [nzNotFoundContent] [nzDisabled]="steps != 0">

                    <ng-container *ngFor="let o of listOfSuggestVendorData; index as i;">
                      <nz-option *ngIf="!isSearchingVendor" [nzValue]="i" [nzLabel]="o.vendorCode+' : '+o.name1!">
                      </nz-option>
                    </ng-container>

                    <nz-option *ngIf="isSearchingVendor" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Searching...
                    </nz-option>

                  </nz-select>

                </div>

              </nz-form-control>
            </nz-form-item>

            <nz-table class="table-suggest-vendor" nzTableLayout="fixed" #editRowTable nzBordered [nzShowPagination]="false"
              [nzFrontPagination]="false" [nzData]="listOfSuggestVendor" [nzScroll]="{x: '800px', y: '500px'}">
              <thead>
                <tr>
                  <th nzWidth="60px">Action</th>
                  <th nzWidth="80px">Vendor Code</th>
                  <th nzWidth="200px">Vendor Name</th>
                  <th nzWidth="100px">Contact Name</th>
                  <th nzWidth="80px">Tel no.</th>
                  <th nzWidth="100px">Email</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of editRowTable.data" class="editable-row">
                  <td style="text-align: center;">
                    <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                      nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteRow(data.vendorCode!)" nzDanger
                      [disabled]="steps != 0">
                      <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                      </i>

                    </button>

                    <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'"
                      (click)="modalEditSuggestvendor(tplTitle, tplContent, tplFooter, data.vendorCode!)"
                      [disabled]="steps != 0">

                      <ng-template #tplTitle>
                        <span>Edit Suggest Vendor</span>
                      </ng-template>

                      <ng-template #tplContent let-changedName="changedName" let-changedTelNo="changeTelNo"
                        let-changedEmail="changeEmail">
                        <div nz-row class="content-row">

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Vendor Code</nz-form-label>
                              <nz-form-control [nzSm]="12" [nzErrorTip]="errorTpl">
                                {{data.vendorCode}}
                              </nz-form-control>

                            </nz-form-item>
                          </div>

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Vendor Name</nz-form-label>
                              <nz-form-control [nzSm]="12" [nzErrorTip]="errorTpl">
                                {{data.vendorName}}
                              </nz-form-control>

                            </nz-form-item>
                          </div>

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Contact Name</nz-form-label>
                              <nz-form-control [nzSm]="8" [nzErrorTip]="errorTpl">
                                <input nz-input type="text" [(ngModel)]="changedNameSuggestVendor"
                                  [nzStatus]="checkNameStatus" (ngModelChange)="onNameChange($event)"
                                  [ngModelOptions]="{standalone: true}">
                              </nz-form-control>

                            </nz-form-item>
                          </div>

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Tel No</nz-form-label>
                              <nz-form-control [nzSm]="8" [nzErrorTip]="errorTpl">
                                <input nz-input type="text" [(ngModel)]="changedTelnoSuggestVendor"
                                  [nzStatus]="checkTelStatus" (ngModelChange)="onTelChange($event)"
                                  [ngModelOptions]="{standalone: true}">
                              </nz-form-control>

                            </nz-form-item>
                          </div>

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Email</nz-form-label>
                              <nz-form-control [nzSm]="8" [nzErrorTip]="errorTpl">
                                <input nz-input type="text" [(ngModel)]="changedEmailSuggestVendor"
                                  [nzStatus]="checkEmailStatus" (ngModelChange)="onEmailChange($event)"
                                  [ngModelOptions]="{standalone: true}">
                              </nz-form-control>

                            </nz-form-item>
                          </div>

                        </div>

                      </ng-template>

                      <ng-template #tplFooter let-ref="modalRef">
                        <button nz-button nzDanger (click)="modalDestroy()">Cancel</button>
                        <button nz-button nzType="primary"
                          [disabled]="checkTelStatus != '' || checkEmailStatus != '' || checkNameStatus != ''"
                          (click)="changeSuggestVendorDetail(data.vendorCode!)">Save</button>
                      </ng-template>

                      <i nz-icon nzType="edit" nzTheme="outline" class="icon-edit">
                      </i>

                    </button>
                  </td>
                  <td>{{data.vendorCode}}</td>
                  <td>{{data.vendorName}}</td>
                  <td>{{data.vendorContactName}}</td>
                  <td>{{data.vendorTelNo}}</td>
                  <td>{{data.vendorEmail}}</td>
                </tr>
              </tbody>
            </nz-table>
          </div>



        </div>

      </form>

    </nz-collapse-panel>
  </nz-collapse>


</nz-layout>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">* Require</ng-container>
  <!-- <ng-container *ngIf="requestDetailForm.errors?.['required']">* Require </ng-container> -->
</ng-template>
