import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-new-memo-detail',
  templateUrl: './create-new-memo-detail.component.html',
  styleUrls: ['./create-new-memo-detail.component.css']
})
export class CreateNewMemoDetailComponent implements OnInit {

  @Input() memoDetailForm!: FormGroup;
  constructor() { }

  ngOnInit(): void {
  }

}
