import { MasterSimpleValue } from 'src/app/api/models';
import { AuthService } from './../../../../services/auth.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AzureBlobService } from 'src/app/api/services';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MasterConfig } from './../../../../api/models/master-config';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { BiddingFile, MasterBaseOn } from '../../../../api/models';
import { DatePipe } from '@angular/common';
import { CKEditor4 } from 'ckeditor4-angular';

@Component({
  selector: 'app-config-bidding',
  templateUrl: './config-bidding.component.html',
  styleUrls: ['./config-bidding.component.css']
})
export class ConfigBiddingComponent implements OnInit {

  @Input() biddingConfig: MasterConfig[] = [];
  @Input() baseOn: MasterBaseOn[] = [];
  @Input() biddingSimpleValue: MasterSimpleValue[] = [];

  @Output() configValueChange = new EventEmitter<MasterConfig>();

  changedConfigValue: string = "";
  constructor(
    private modal: NzModalService,
    private azureBlobService: AzureBlobService,
    private msg: NzMessageService,
    private authService: AuthService,
    private datepipe: DatePipe) { }

  public config: CKEditor4.Config = {
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
    ]
  }

  ngOnInit(): void {
    this.biddingConfig.forEach(item => {

    })
  }

  onDownloadTemplate() {
    this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: this.biddingConfig.find(item => item.key === "FileIdTemplateItemlist")?.value!,
      fileName: this.biddingConfig.find(item => item.key === "FileNameTemplateItemlist")?.value!,
    }).subscribe(
      response => {
        window.location.assign(response.url!);
      }, err => {
        console.log("Error to Download Template ", err);
      })
  }

  async onUploadChange({ file, fileList }: NzUploadChangeParam): Promise<void> {
    const status = file.status;

    if (status !== 'uploading') {
      console.log(file, fileList);

    }

    if (status === 'uploading') {
      const msgLoad = this.msg.loading(`${file.name} uploading.`, { nzDuration: 0 });
    }

    if (status === 'done') {

      // this.msg.remove(this.msgLoad);
      // this.msg.success(`${file.name} file uploaded successfully.`);
      let response = JSON.parse(file.response);

      console.log("response: ", file.response);

      var objRes: BiddingFile = {
        fileId: response.fileID,
        fileGroup: "ItemListTemplate",
        fileName: response.fileName,
        fileSizeInByte: response.fileSizeinByte,
        lastModifiedDate: response.lastModified,
        modifiedByName: this.authService.currectUserFullName()
      }

      objRes.lastModifiedDate = this.datepipe.transform(objRes.lastModifiedDate, "yyyy-MM-dd HH:mm")!;

      let newConfig: MasterConfig = {
        key: "FileIdTemplateItemlist",
        value: objRes.fileId,
      }

      this.msg.remove();
      this.msg.success("Upload file successfully");
      this.configValueChange.emit(newConfig);

    }
    else if (status === 'error') {

    }
  }

  async downloadFile(fileName: string, fileID: string) {
    console.log("FileName: ", fileName, " FileId ", fileID);

    await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: fileID,
      fileName: fileName,
    }).toPromise().then(
      response => {
        console.log("link : ", response.url);
      }
    )

    return;
  }


  beforeUpload = (file: NzUploadFile): boolean => {

    if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.modal.warning({
        nzTitle: "Invalid file type",
        nzContent: "Only support .xlxs"
      });
      return false;
    }

    return true;
  }

  onUploadFile = (item: NzUploadXHRArgs) => {

    const formData = new FormData();
    formData.append('file', item.file as any); // tslint:disable-next-line:no-any

    return this.azureBlobService.apiAzureBlobUploadBlobPut$Response({
      body: {
        files: item.file as any,
      },

    }).subscribe((e: HttpEvent<any>) => {
      if (e.type === HttpEventType.UploadProgress) {
        if (e.total! > 0) {
          (e as any).percent = e.loaded / e.total! * 100; // tslint:disable-next-line:no-any
        }
        // To process the upload progress bar, you must specify the `percent` attribute to indicate progress.
        item.onProgress!(e, item.file);
      } else if (e instanceof HttpResponse) { /* success */
        console.log("FILE DOCUMENT TOR UPLOADED");
        item.onSuccess!(e.body, item.file, e);
      }
    }, (err) => { /* error */
      item.onError!(err, item.file);
    });


  }


  modalEditConfig(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, key: string, index: number) {
    console.log(this.biddingConfig[index].key);

    this.changedConfigValue = this.biddingConfig[this.biddingConfig.findIndex(item => item.key == key)].value!;

    if (key === "DocumentFileSizeInByte") {
      this.changedConfigValue = (Number(this.changedConfigValue) / 1000000).toString();
    }

    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
    })
  }

  modalDestroy() {
    this.modal.closeAll();
  }

  onChangeConfigValue(key: string) {

    if (key === "FileNameTemplateItemlist") {
      if (!this.changedConfigValue.includes(".xlsx")) {
        this.modal.warning({
          nzTitle: "Warning",
          nzContent: "Template should end with .xlsx",
        })

        return;
      }

    }

    if (key === "DocumentFileSizeInByte") {
      this.changedConfigValue = (Number(this.changedConfigValue) * 1000000).toString();
    }

    let newConfig: MasterConfig = {
      key: key,
      value: this.changedConfigValue
    }
    this.configValueChange.emit(newConfig);

    this.modal.closeAll();
  }

  addSimpleValue(key: string) {

  }
}
