import { environment } from './../../../environments/environment';
import { Component, OnInit } from '@angular/core';
import { BarElement } from 'chart.js';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  status!: string;
  chart: any;


  constructor() {
    this.status = environment.production == true ? "Development" : "Development";

  }

  ngOnInit(): void {
  }

}
