import { async } from '@angular/core/testing';
import { VendorBiddingInfo } from './../../../../../../../../api/models/vendor-bidding-info';
import { NewBiddingService } from './../../../../../../../../api/services/new-bidding.service';
import { MasterConfig } from './../../../../../../../../api/models/master-config';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Guid } from 'guid-typescript';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { FileProperties } from 'src/app/api/models/file-properties';
import { AzureBlobService } from 'src/app/api/services';
import { BiddingFile } from 'src/app/api/models';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-vendor-attachment',
  templateUrl: './vendor-attachment.component.html',
  styleUrls: ['./vendor-attachment.component.css']
})
export class VendorAttachmentComponent implements OnInit {

  @Input() biddingInfo: VendorBiddingInfo = {};
  @Input() biddingConfig: MasterConfig[] = [];
  @Input() isSubmitted: boolean = false;

  @Output() biddingAttachmentChanged = new EventEmitter<BiddingFile[]>();

  constructor(
    private newBiddingService: NewBiddingService,
    private azureBlobService: AzureBlobService,
    private msg: NzMessageService,
    private modal: NzModalService,
    private datepipe: DatePipe
  ) { }

  url: string = "/api/AzureBlob/UploadBlob";
  acceptedFile: string[] = ["image/jpeg,image/jpg,image/png,image/bmp,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/zip,application/x-7z-compressed,text/csv,application/vnd.ms-outloo,application/pdf"];
  acceptedFileType: string = "image/jpeg,image/jpg,image/png,image/bmp,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/zip,application/x-7z-compressed,text/csv,application/vnd.ms-outloo,application/pdf";
  fileListTOR: BiddingFile[] = [];
  attachmentFileList: BiddingFile[] = [];
  documentFileSizeInByte: number = 0;
  documentFileType: string = "";

  // [x: string]: any;

  ngOnInit(): void {
    this.documentFileSizeInByte = Number(this.biddingConfig[this.biddingConfig.findIndex(item => item.key = "DocumentFileSizeInByte")].value);
    this.documentFileType = JSON.parse("[" + this.biddingConfig[this.biddingConfig.findIndex(item => item.key == "DocumentFileType")].value! + "]");

    this.biddingInfo.files!.biddingAttachmentFileList!.forEach(async item => {
      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: item.fileId!,
        fileName: item.fileName!,
      })
        .toPromise()
        .then(
          response => {

            this.attachmentFileList = [...this.attachmentFileList, {
              fileName: item.fileName,
              fileId: item.fileId,
              fileDownloadLink: response.url,
            }]
          }
        )
        .catch(
          error => {

          }
        )

    })

    this.biddingInfo.files?.vendorAttachmentFileList?.forEach(async item => {
      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: item.fileId!,
        fileName: item.fileName!,
      })
        .toPromise()
        .then(
          response => {
            item.fileDownloadLink = response.url;

            this.fileListTOR = [...this.fileListTOR, item];
          }
        )
        .catch(
          error => {

          }
        )
    })
  }

  async onUploadChange({ file, fileList }: NzUploadChangeParam): Promise<void> {
    const status = file.status;
    let msgLoad;
    if (status !== 'uploading') {
      console.log(file, fileList);

    }

    if (status === 'uploading') {
      msgLoad = this.msg.loading(`${file.name} uploading.`, { nzDuration: 0 });
    }

    if (status === 'done') {

      this.msg.remove();
      // this.msg.success(`${file.name} file uploaded successfully.`);
      let response = JSON.parse(file.response);

      console.log("response: ", file.response);
      var objRes: BiddingFile = {
        fileId: response.fileID,
        fileGroup: "VendorAttachment",
        fileName: response.fileName,
        fileSizeInByte: response.fileSizeinByte,
        lastModifiedDate: response.lastModified,
        modifiedByName: this.biddingInfo.detail?.vendorName,
        fkbiddingDetail: this.biddingInfo.detail?.pkbiddingDetail,
        fkbiddingHeader: this.biddingInfo.header?.pkbiddingHeader,
      }

      objRes.lastModifiedDate = this.datepipe.transform(objRes.lastModifiedDate, "yyyy-MM-dd HH:mm")!;
      // objRes.fileGroup = this.fileUploadGroup;
      // objRes.fileSizeinByte = objRes.fileSizeInByte! / 1000000;
      // objRes.lastModified = this.datepipe.transform(objRes.lastModified.split("T")[0],"yyyy-MM-dd " + new Date().toLocaleString('en-GB').split(',')[1])!;
      // objRes.lastModified = this.datepipe.transform(objRes.lastModified, "yyyy-MM-dd HH:mm")!;

      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: objRes.fileId!,
        fileName: objRes.fileName!,
      })
        .toPromise()
        .then(
          response => {
            if (response.url != null) {
              objRes.fileDownloadLink = response.url;
            }
          }

        )
        .catch(
          err => {
            console.log("Get Downlink Failed");
          })

      this.fileListTOR = [
        ...this.fileListTOR,
        objRes
      ]
      this.msg.remove();
      this.msg.success(`${file.name} file uploaded successfully.`);
      console.log("Created file list");

      this.biddingAttachmentChanged.emit(this.fileListTOR);

      console.log(objRes.lastModifiedDate);

      // await this.newBiddingService.apiNewBiddingRequestCreateBiddingFileListPost$Response({
      //   body: {
      //     fileId: objRes.fileId,
      //     fileGroup: objRes.fileGroup,
      //     fileName: objRes.fileName,
      //     fileSizeInByte: objRes.fileSizeInByte,
      //     // lastModifiedDate: objRes.lastModifiedDate,
      //     modifiedByName: objRes.modifiedByName,
      //     fileDownloadLink: objRes.fileDownloadLink,
      //     fkbiddingHeader: objRes.fkbiddingHeader,
      //     fkbiddingDetail: objRes.fkbiddingDetail,
      //     isActive: true,
      //   }
      // })
      // .toPromise()
      // .then(
      //   () => {
      //     // objRes.lastModifiedDate = this.datepipe.transform(objRes.lastModifiedDate, "yyyy-MM-dd HH:mm");
      //     this.fileListTOR = [
      //       ...this.fileListTOR,
      //       objRes
      //     ]
      //     this.msg.remove();
      //     this.msg.success(`${file.name} file uploaded successfully.`);
      //     console.log("Created file list");

      //     this.biddingAttachmentChanged.emit(this.fileListTOR);
      //   }
      // )
      // .catch(
      //   error => {
      //     // this.msg.remove(this.msgLoad);
      //     this.msg.remove();
      //     this.msg.error(`${file.name} file upload failed.`);
      //     console.log("Failed to created file list");
      //     console.log(error);
      //   }
      // )


    }
    else if (status === 'error') {
      // this.msg.remove(this.msgLoad);
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  async downloadFile(fileName: string, fileID: string) {
    console.log("FileName: ", fileName, " FileId ", fileID);

    await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: fileID,
      fileName: fileName,
    }).toPromise().then(
      response => {
        console.log("link : ", response.url);
      }
    )

    return;
  }


  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    console.log("Before Upload");
    console.log("File Type: ", file.type);

    let isFileTypeValid: boolean = false;
    for (let i = 0; i < this.documentFileType[0].length; i++) {
      if (file.type == this.documentFileType[0][i]) {
        isFileTypeValid = true;
        break;
      }
    }

    if (isFileTypeValid == false) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "Invalid File Type"
      });

      return false;
    }

    if (file.size! > this.documentFileSizeInByte) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "File size limit < 20 MB"
      });

      return false;
    }

    return true;
  }

  onUploadFile = (item: NzUploadXHRArgs) => {

    const formData = new FormData();
    formData.append('file', item.file as any); // tslint:disable-next-line:no-any

    return this.azureBlobService.apiAzureBlobUploadBlobPut$Response({
      body: {
        files: item.file as any,
      },

    }).subscribe((e: HttpEvent<any>) => {
      if (e.type === HttpEventType.UploadProgress) {
        if (e.total! > 0) {
          (e as any).percent = e.loaded / e.total! * 100; // tslint:disable-next-line:no-any
        }
        // To process the upload progress bar, you must specify the `percent` attribute to indicate progress.
        item.onProgress!(e, item.file);
      } else if (e instanceof HttpResponse) { /* success */
        console.log("FILE TOR UPLOADED");
        // this.fileUploadGroup = "RequestContractTOR";
        item.onSuccess!(e.body, item.file, e);
        // var body = JSON.parse(JSON.stringify(e)).body;
        // var obj = JSON.parse(body);
        // console.log("response body: ",JSON.parse(obj));

      }
    }, (err) => { /* error */
      item.onError!(err, item.file);
    });


  }

  fileData = (item: NzUploadFile) => {
    item.uid = Guid.create().toString();

    return item;
  }


  deleteItem(fileId: string) {
    console.log(fileId);

    this.fileListTOR = this.fileListTOR.filter(function (item) {
      return item.fileId !== fileId;
    })

    this.biddingAttachmentChanged.emit(this.fileListTOR);

  }

}
