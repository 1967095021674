import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RequestContractDocNoAndLog, MasterSourcingTeam } from 'src/app/api/models';

@Component({
  selector: 'app-request-pricelist-log',
  templateUrl: './request-pricelist-log.component.html',
  styleUrls: ['./request-pricelist-log.component.css']
})
export class RequestPricelistLogComponent implements OnInit {

  @Input() logData: RequestContractDocNoAndLog[] = [];
  @Input() sourcingTeamList: MasterSourcingTeam[] = [];

  searchingForm: FormGroup;
  isDataLoading: boolean = false;

  tempLogData: RequestContractDocNoAndLog[] = [];

  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService) {
    this.searchingForm = fb.group({
      requestContractNo: [""],
      sourcingTeam: [""],
      requesterName: [""],
      date: [[]],
    })
   }

  ngOnInit(): void {
    this.tempLogData = this.logData;
  }

  onClickSearch(event: boolean){
    this.isDataLoading = true;
    console.log("Search");
    if(this.searchingForm.value.requestContractNo !== "")
    {
      this.logData = this.logData.filter(item => item.docNo?.toLowerCase().startsWith((this.searchingForm.value.requestContractNo).toLowerCase()));
    }
    else if(this.searchingForm.value.date.lenght != 0)
    {
      this.logData = this.logData.filter(item => moment(item.logData?.editedDate).valueOf() >= moment(this.searchingForm.value.date[0]).valueOf());
      this.logData = this.logData.filter(item => moment(item.logData?.editedDate).valueOf() <= moment(this.searchingForm.value.date[1]).valueOf());
    }

    this.msg.success("Search Successfully");
    this.isDataLoading = false;
  }

  onClickReset(event: boolean){
    this.msg.info("Reset Successfully");
    this.logData = [];
    this.logData = this.tempLogData;

  }

}
