<nz-layout class="content">
  <div nz-col nzSpan="24" class="collapse-col">
    <nz-card nzTitle="Searching Result">
      <div nz-col [nzXs]="24" [nzSm]="24">
        <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'end'">
          <div class="div-button-new" style="padding: 2%;">

            <button nzSize="default" class="btn-new" nz-button nzType="primary" routerLink="new-memo">
              Create New Memo</button>
          </div>
        </div>
      </div>

      <nz-table class="table-dashboard" #SearchResult [nzData]="searchList" [nzBordered]="true" [nzOuterBordered]="true"
        [nzScroll]="{x: '1200px'}">
        <thead>
          <tr>
            <th nzWidth="100px">Memo No.</th>
            <th nzWidth="100px">Bidding No</th>
            <th nzWidth="100px">Created Date</th>
            <th nzWidth="100px">Approved Date</th>
            <th nzWidth="100px">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tbody>
      </nz-table>



    </nz-card>
  </div>
</nz-layout>
