import { VendorBiddingInfo } from './../../../../../../../../api/models/vendor-bidding-info';
import { MasterSimpleValue } from './../../../../../../../../api/models/master-simple-value';
import { BiddingItemList } from './../../../../../../../../api/models/bidding-item-list';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MasterCurrency, MasterIncoterm1, MasterTheArchCountry, VChembiMstPaymentTerm } from 'src/app/api/models';
import { ItemListStatus } from '../model/itemlistStatus';

@Component({
  selector: 'app-vendor-item-list',
  templateUrl: './vendor-item-list.component.html',
  styleUrls: ['./vendor-item-list.component.css']
})
export class VendorItemListComponent implements OnInit {

  itemlistTableData: BiddingItemList[]  = [];

  @Input() biddingInfo : VendorBiddingInfo= {};
  @Input() countryList: MasterTheArchCountry[] = [];
  @Input() currencyList: MasterCurrency[] = [];
  @Input() rejectReasonList: MasterSimpleValue[] = [];
  @Input() isSubmitted: boolean = false;
  @Input() itemlistStatus: ItemListStatus[] = [];

  currencyListTest: MasterCurrency[] = [];
  @Output() biddingItemlistDataChange = new EventEmitter<BiddingItemList[]>();

  isHide: boolean = false;

  formatterNumber = (value: number): string => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  constructor()
  {
  }


  onHide()
  {
    this.isHide = !this.isHide;
  }
  ngOnInit(): void {

    this.itemlistTableData = this.biddingInfo.itemList!;
    this.itemlistTableData.forEach(item => {
      item.pricePerUnit === null ? item.pricePerUnit = 0 : "";
      item.total === null ? item.total = 0 : "";
      item.deliveryTimes === null ? item.deliveryTimes = 0 : "";
      item.guaranteeShelfLife === null ? item.guaranteeShelfLife = 0 : "";
      item.minimumOrderQuantity === null ? item.minimumOrderQuantity = 0 : "";

      return;
    })

    this.biddingItemlistDataChange.emit(this.itemlistTableData);
  }

  onChange(index: number, value:  string)
  {
    console.log("index: ", index, "event: ", value);
    if(value === 'currency')
    {
      this.itemlistStatus[index].currency.status = "";
    }
    else if(value === 'pricePerUnit')
    {
      this.itemlistStatus[index].pricePerUnit.status = "";
    }
    else if(value === 'total')
    {
      this.itemlistStatus[index].total.status = "";
    }
    else if(value === 'deliveryTimes')
    {
      this.itemlistStatus[index].deliveryTimes.status = ""
    }
    else if(value === 'sourceOfOrigin')
    {
      this.itemlistStatus[index].sourceOfOrigin.status = "";
    }
    else if(value === 'brand')
    {
      this.itemlistStatus[index].brand.status = "";
    }
    else if(value === 'minimumOrderQuantity')
    {
      this.itemlistStatus[index].minimumOrderQuantity.status = "";
    }
    this.biddingItemlistDataChange.emit(this.itemlistTableData);
  }


}
