import { MasterTheArchPurGroupMember } from './../../../../../../../api/models/master-the-arch-pur-group-member';
import { Component, Input, OnInit } from '@angular/core';
import { MasterSourcingTeam } from '../../../../../../../api/models';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-create-new-memo-sourcing-contract',
  templateUrl: './create-new-memo-sourcing-contract.component.html',
  styleUrls: ['./create-new-memo-sourcing-contract.component.css']
})
export class CreateNewMemoSourcingContractComponent implements OnInit {

  @Input() sourcingContractForm!: FormGroup;
  @Input() purchasingGroupsMemberList: MasterTheArchPurGroupMember[] = [];
  @Input() sourcingTeamList: MasterSourcingTeam [] = [];

  selectedPurGroup: MasterTheArchPurGroupMember[] = [];
  constructor() { }

  ngOnInit(): void {
  }

  onSelectPurGroupChange(value : MasterTheArchPurGroupMember) {

  }

}
