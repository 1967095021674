<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Item List'" [nzActive]="true">
      <div nz-row>
        <!-- <button nz-button (click)="onHide()">Hide</button> -->
        <div class="col" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-table class="table-tor" #itemlistTable nzBordered [nzScroll]="{ x: '2000px', y: '600px' }"
            [nzData]="itemlistTableData" [nzFrontPagination]="false">
            <thead>
              <tr>
                <ng-container>
                  <th nzLeft nzWidth="60px">No.</th>
                  <th nzLeft nzWidth="150px">Mat Code</th>
                  <th nzLeft nzWidth="150px">item Name</th>
                  <th nzWidth="250px">Desciption</th>
                </ng-container>

                <!-- <th nzWidth="60px">Unit</th> -->
                <th nzWidth="175px">Quantity</th>
                <th nzWidth="150px">Price Per Unit</th>
                <th nzWidth="100px">Currency</th>
                <th nzWidth="150px">Total</th>
                <th nzWidth="200px">Delivery Times</th>
                <th nzWidth="150px" *ngIf="biddingInfo.header!.isMinimumOrderQuantity">Minimum Order Quantity</th>
                <th nzWidth="175px" *ngIf="biddingInfo.header!.isGuaranteeShelfLife">Guarantee shelf life</th>
                <th nzWidth="150px" *ngIf="biddingInfo.header!.isSourceOfOrigin">Source of Origin</th>
                <th nzWidth="200px" *ngIf="biddingInfo.header!.isBrand">Brand</th>
                <th nzWidth="200px">Note</th>
                <th nzWidth="200px">Reject Reason</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of itemlistTable.data;index as i;">
                <ng-container>
                  <td nzLeft>{{data.no}}</td>
                  <td nzLeft>{{data.matCode}}</td>
                  <td nzLeft>{{data.itemName}}</td>
                  <td>
                    <textarea nz-input [readOnly]="true" [(ngModel)]="data.description" [disabled]="true"
                      [nzAutosize]="{ minRows: 3, maxRows: 6 }">
                    </textarea>
                  </td>
                </ng-container>
                <!-- Quantity -->
                <td>
                  <nz-input-number-group nzCompact>
                    <nz-input-number nz-input [nzReadOnly]="true" [(ngModel)]="data.quantity"
                      [nzFormatter]="formatterNumber" [disabled]="true" style="width: 75px; text-align: center;"></nz-input-number>
                    <input nz-input [readOnly]="true" [(ngModel)]="data.unit" [disabled]="true" style="width: 50px;" />
                  </nz-input-number-group>
                </td>
                <!-- Price per unit -->
                <td>
                  <nz-input-number [(ngModel)]="data.pricePerUnit" [nzMin]="0" [nzMax]="1000000" [nzStep]="100"
                    [nzPrecision]="2" [nzFormatter]="formatterNumber" (ngModelChange)="onChange(i, 'pricePerUnit')"
                    [nzDisabled]="data.rejectReason !== '-' || isSubmitted">
                  </nz-input-number>
                </td>
                <!-- Currency -->
                <td>
                  <nz-select style="width: 75px;" [(ngModel)]="data.currency"
                    [nzDisabled]="data.rejectReason !== '-' || isSubmitted"
                    [nzStatus]="itemlistStatus[i].currency.status" (ngModelChange)="onChange(i, 'currency')">
                    <nz-option *ngFor="let item of currencyList" [nzLabel]="item.currency!" [nzValue]="item.currency!"
                      (ngModelChange)="onChange(i, 'currency')">
                    </nz-option>
                  </nz-select>
                </td>
                <!-- Total -->
                <td>
                  <nz-input-number style="width: fit-content;" [(ngModel)]="data.total" [nzMin]="0" [nzMax]="1000000"
                    [nzPrecision]="2" [nzStep]="1" [nzFormatter]="formatterNumber"
                    (ngModelChange)="onChange(i, 'total')" [nzDisabled]="data.rejectReason !== '-' || isSubmitted"
                    [nzStatus]="itemlistStatus[i].total.status">
                  </nz-input-number>
                </td>
                <!-- Delivery Times -->
                <td>
                  <nz-input-number-group nzCompact>
                    <nz-input-number [(ngModel)]="data.deliveryTimes" [nzMin]="0" [nzMax]="1000000" [nzStep]="1"
                      [nzPrecision]="2" [nzFormatter]="formatterNumber" (ngModelChange)="onChange(i, 'deliveryTimes')"
                      [nzDisabled]="data.rejectReason !== '-' || isSubmitted"
                      [nzStatus]="itemlistStatus[i].deliveryTimes.status" style="width: 60%;">
                    </nz-input-number>
                    <input nz-input [readOnly]="true" [value]="'Day(s)'" [disabled]="true" style="width:40%;" />
                  </nz-input-number-group>
                </td>
                <!-- MinimumOrderQuantity -->
                <td *ngIf="biddingInfo.header!.isMinimumOrderQuantity"
                  style="text-align: center; vertical-align: middle;">
                  <nz-input-number [(ngModel)]="data.minimumOrderQuantity" [nzMin]="0" [nzMax]="1000000" [nzStep]="1"
                    [nzPrecision]="2" [nzFormatter]="formatterNumber"
                    (ngModelChange)="onChange(i, 'minimumOrderQuantity')"
                    [nzDisabled]="data.rejectReason !== '-' || isSubmitted"
                    [nzStatus]="itemlistStatus[i].minimumOrderQuantity.status">
                  </nz-input-number>
                </td>
                <!-- GuaranteeShelfLife -->
                <td *ngIf="biddingInfo.header!.isGuaranteeShelfLife"
                  style="text-align: center; vertical-align: middle;">
                  <nz-input-number [(ngModel)]="data.guaranteeShelfLife" [nzMin]="0" [nzMax]="1000000" [nzStep]="1"
                    [nzPrecision]="2" [nzFormatter]="formatterNumber"
                    (ngModelChange)="onChange(i, 'guaranteeShelfLife')"
                    [nzDisabled]="data.rejectReason !== '-' || isSubmitted">
                  </nz-input-number>
                </td>
                <!-- SourceOfOrigin -->
                <td *ngIf="biddingInfo.header!.isSourceOfOrigin">
                  <nz-select nzShowSearch nzAllowClear style="width: 100%;" [(ngModel)]="data.sourceOfOrigin"
                    [nzDisabled]="data.rejectReason !== '-' || isSubmitted"
                    [nzStatus]="itemlistStatus[i].sourceOfOrigin.status"
                    (ngModelChange)="onChange(i, 'sourceOfOrigin')">
                    <nz-option *ngFor="let item of countryList" [nzLabel]="item.countryCode!"
                      [nzValue]="item.countryCode!" (ngModelChange)="onChange(i, 'sourceOfOrigin')">
                    </nz-option>
                  </nz-select>
                </td>
                <!-- Brand -->
                <td *ngIf="biddingInfo.header!.isBrand">
                  <input nz-input style="width: 150px;" [(ngModel)]="data.brand" (ngModelChange)="onChange(i, 'brand')"
                    [disabled]="data.rejectReason !== '-' || isSubmitted" [nzStatus]="itemlistStatus[i].brand.status">
                </td>
                <!-- Note -->
                <td><textarea nz-input [readOnly]="true" [(ngModel)]="data.note"
                    [nzAutosize]="{ minRows: 3, maxRows: 6 }" [disabled]="true">
                  </textarea>
                </td>
                <td>
                  <nz-select style="width: 150px;" [(ngModel)]="data.rejectReason" [disabled]="isSubmitted">
                    <nz-option *ngFor="let item of rejectReasonList" [nzLabel]="item.value!" [nzValue]="item.value"
                      (ngModelChange)="onChange(i, 'rejectReason')">
                    </nz-option>
                  </nz-select>
                </td>

              </tr>
            </tbody>
          </nz-table>
        </div>

      </div>
    </nz-collapse-panel>
  </nz-collapse>

</nz-layout>
