import { SearchingResultRequestPricelistLogComponent } from './pages/log/component/request-pricelist-log/component/searching-result-request-pricelist-log/searching-result-request-pricelist-log.component';
import { SearchingCriteriaRequestPricelistLogComponent } from './pages/log/component/request-pricelist-log/component/searching-criteria-request-pricelist-log/searching-criteria-request-pricelist-log.component';
import { RequestPricelistLogComponent } from './pages/log/component/request-pricelist-log/request-pricelist-log.component';
import { SearchingCriteriaRequestContractLogComponent } from './pages/log/component/request-contract-log/component/searching-criteria-request-contract-log/searching-criteria-request-contract-log.component';
import { SearchingResultRequestContractLogComponent } from './pages/log/component/request-contract-log/component/searching-result-request-contract-log/searching-result-request-contract-log.component';

import { UserManualComponent } from './pages/user-manual/user-manual.component';
import { NewBiddingComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/new-bidding.component';
import { SearchingPriceListComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/searching-price-list/searching-price-list.component';
import { NzMessageService } from 'ng-zorro-antd/message';
import { environment } from './../environments/environment';
import { AppInitService } from './app-init.service';
import { NgZorroAntdModule } from './ng-zorro-antd.module';
import { ActivityLogComponent } from './pages/work-station/rcf/component/activity-log/activity-log.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, forwardRef, LOCALE_ID, NgModule, Provider } from '@angular/core';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';

import { DatePipe, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { en_GB, NZ_I18N } from 'ng-zorro-antd/i18n';
import { ApiModule } from './api/api.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IconsProviderModule } from './icons-provider.module';
import { NavMenuComponent } from './nav-menu/nav-menu.component';
import { ConfigurationComponent } from './pages/configuration/configuration.component';
import { ApproveBiddingMemoComponent } from './pages/work-station/approve-bidding-memo/approve-bidding-memo.component';
import { SearchingCriteriaComponent } from './pages/work-station/dashboard/component/searching-criteria/searching-criteria.component';
import { DashboardComponent } from './pages/work-station/dashboard/dashboard.component';
import { ApproverComponent } from './pages/work-station/rcf/component/approver/approver.component';
import { AttachmentComponent } from './pages/work-station/rcf/component/attachment/attachment.component';
import { ConclusionComponent } from './pages/work-station/rcf/component/conclusion/conclusion.component';
import { ContractItemComponent } from './pages/work-station/rcf/component/contract-item/contract-item.component';
import { PeopleComponent } from './pages/work-station/rcf/component/people/people.component';
import { RequestDetailComponent } from './pages/work-station/rcf/component/request-detail/request-detail.component';
import { RequesterInfoComponent } from './pages/work-station/rcf/component/requester-info/requester-info.component';
import { SourcingTeamComponent } from './pages/work-station/rcf/component/sourcing-team/sourcing-team.component';
import { UserApproverComponent } from './pages/work-station/rcf/component/user-approver/user-approver.component';
import { ModalResultErrorComponent } from './pages/work-station/rcf/component/_modal/modal-result-error/modal-result-error.component';
import { ModalResultComponent } from './pages/work-station/rcf/component/_modal/modal-result/modal-result.component';
import { StepComponent } from './pages/work-station/rcf/component/_step/step.component';
import { RCFComponent } from './pages/work-station/rcf/rcf.component';
import { NotAuthorizedComponent } from './pages/_extra/not-authorized/not-authorized.component';
import { NotFoundComponent } from './pages/_extra/not-found/not-found.component';
import { SourcingContractComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/sourcing-contract/sourcing-contract.component';
import { BiddingDetailComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/bidding-detail/bidding-detail.component';
import { DocumentComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/document/document.component';
import { ItemListComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/item-list/item-list.component';
import { AddVendorComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/add-vendor/add-vendor.component';
import { StepsBiddingComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/_steps-bidding/steps-bidding.component';
import { HomeComponent } from './pages/home/home.component';
import { VendorBiddingComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/vendor-bidding/vendor-bidding.component';
import { VendorItemListComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/vendor-bidding/component/vendor-item-list/vendor-item-list.component';
import { VendorAttachmentComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/vendor-bidding/component/vendor-attachment/vendor-attachment.component';
import { VendorDetailComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/vendor-bidding/component/vendor-detail/vendor-detail.component';
import { ConfigRcfComponent } from './pages/configuration/component/config-rcf/config-rcf.component';
import { ConfigBiddingComponent } from './pages/configuration/component/config-bidding/config-bidding.component';
import { VendorEmailConfigComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/vendor-bidding/component/vendor-email-config/vendor-email-config.component';
import { SummaryItemListComponent } from './pages/work-station/bidding-worksheet/summary/summary/component/summary-item-list/summary-item-list.component';
import { SummaryAttachmentComponent } from './pages/work-station/bidding-worksheet/summary/summary/component/summary-attachment/summary-attachment.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { CKEditorModule } from 'ckeditor4-angular';
import { SearchingSummaryComponent } from './pages/work-station/bidding-worksheet/summary/searching-summary/searching-summary.component';
import { SearchingSummaryCriteriaComponent } from './pages/work-station/bidding-worksheet/summary/searching-summary/component/searching-summary-criteria/searching-summary-criteria.component';
import { SearchingSummaryResultComponent } from './pages/work-station/bidding-worksheet/summary/searching-summary/component/searching-summary-result/searching-summary-result.component';
import { SummaryComponent } from './pages/work-station/bidding-worksheet/summary/summary/summary.component';
import { SummaryDetailComponent } from './pages/work-station/bidding-worksheet/summary/summary/component/summary-detail/summary-detail.component';
import { NewBiddingActionLogComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/new-bidding-action-log/new-bidding-action-log.component';
import { SearchingPriceListCriteriaComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/searching-price-list/component/searching-price-list-criteria/searching-price-list-criteria.component';
import { SearchingPriceListResultComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/searching-price-list/component/searching-price-list-result/searching-price-list-result.component';
import { SearchingMemoComponent } from './pages/work-station/bidding-worksheet/create-memo/searching-memo/searching-memo.component';
import { SearchingMemoCriteriaComponent } from './pages/work-station/bidding-worksheet/create-memo/searching-memo/component/searching-memo-criteria/searching-memo-criteria.component';
import { SearchingMemoResultComponent } from './pages/work-station/bidding-worksheet/create-memo/searching-memo/component/searching-memo-result/searching-memo-result.component';
import { CreateNewMemoComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/create-new-memo.component';
import { CreateNewMemoRefMemoComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/component/create-new-memo-ref-memo/create-new-memo-ref-memo.component';
import { CreateNewMemoStepsComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/component/create-new-memo-steps/create-new-memo-steps.component';
import { CreateNewMemoSourcingContractComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/component/create-new-memo-sourcing-contract/create-new-memo-sourcing-contract.component';
import { CreateNewMemoDetailComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/component/create-new-memo-detail/create-new-memo-detail.component';
import { CreateNewMemoSummaryBiddingComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/component/create-new-memo-summary-bidding/create-new-memo-summary-bidding.component';
import { CreateNewMemoAttachmentComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/component/create-new-memo-attachment/create-new-memo-attachment.component';
import { CreateNewMemoApproverComponent } from './pages/work-station/bidding-worksheet/create-memo/create-new-memo/component/create-new-memo-approver/create-new-memo-approver.component';
import { NewBiddingHeaderComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/new-bidding-header/new-bidding-header.component';
import { SummaryHeaderComponent } from './pages/work-station/bidding-worksheet/summary/summary/component/summary-header/summary-header.component';
import { VendorBiddingHeaderComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/vendor-bidding/component/vendor-bidding-header/vendor-bidding-header.component';
import { NewBiddingEditEmailComponent } from './pages/work-station/bidding-worksheet/request-for-price-list/component/new-bidding/component/new-bidding-edit-email/new-bidding-edit-email.component';
import { SummaryCostSavingComponent } from './pages/work-station/bidding-worksheet/summary/summary/component/summary-cost-saving/summary-cost-saving.component';
import { SummaryPriceComponent } from './pages/work-station/bidding-worksheet/summary/summary/component/summary-price/summary-price.component';
import { LogComponent } from './pages/log/log.component';
import { ApiInterceptor } from './api-Interceptor';
import { RequestContractLogComponent } from './pages/log/component/request-contract-log/request-contract-log.component';


export function initializeApp(appInitService: AppInitService ) {
  return (): Promise<any> => {
    return appInitService.Init();
  }
}

export const API_INTERCEPTOR_PROVIDER: Provider = {
  provide: HTTP_INTERCEPTORS,
  useExisting: forwardRef(() => ApiInterceptor),
  multi: true
};

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    NavMenuComponent,
    DashboardComponent,
    HomeComponent,
    UserManualComponent,
    RCFComponent,
    ApproveBiddingMemoComponent,
    RequesterInfoComponent,
    RequestDetailComponent,
    AttachmentComponent,
    StepComponent,
    ContractItemComponent,
    PeopleComponent,
    UserApproverComponent,
    ActivityLogComponent,
    ConclusionComponent,
    ConfigurationComponent,
    SourcingTeamComponent,
    NotAuthorizedComponent,
    NotFoundComponent,
    SearchingCriteriaComponent,
    ModalResultComponent,
    ModalResultErrorComponent,
    ApproverComponent,
    SummaryComponent,
    SearchingPriceListComponent,
    SourcingContractComponent,
    BiddingDetailComponent,
    DocumentComponent,
    ItemListComponent,
    AddVendorComponent,
    NewBiddingComponent,
    StepsBiddingComponent,
    VendorBiddingComponent,
    VendorItemListComponent,
    VendorAttachmentComponent,
    VendorDetailComponent,
    ConfigRcfComponent,
    ConfigBiddingComponent,
    VendorEmailConfigComponent,
    SummaryItemListComponent,
    SummaryAttachmentComponent,
    SearchingSummaryComponent,
    SearchingSummaryCriteriaComponent,
    SearchingSummaryResultComponent,
    SummaryDetailComponent,
    NewBiddingActionLogComponent,
    SearchingPriceListCriteriaComponent,
    SearchingPriceListResultComponent,
    SearchingMemoComponent,
    SearchingMemoCriteriaComponent,
    SearchingMemoResultComponent,
    CreateNewMemoComponent,
    CreateNewMemoRefMemoComponent,
    CreateNewMemoStepsComponent,
    CreateNewMemoSourcingContractComponent,
    CreateNewMemoDetailComponent,
    CreateNewMemoSummaryBiddingComponent,
    CreateNewMemoAttachmentComponent,
    CreateNewMemoApproverComponent,
    NewBiddingHeaderComponent,
    SummaryHeaderComponent,
    VendorBiddingHeaderComponent,
    NewBiddingEditEmailComponent,
    SummaryCostSavingComponent,
    SummaryPriceComponent,
    LogComponent,
    RequestContractLogComponent,
    SearchingCriteriaRequestContractLogComponent,
    SearchingResultRequestContractLogComponent,
    RequestPricelistLogComponent,
    SearchingCriteriaRequestPricelistLogComponent,
    SearchingResultRequestPricelistLogComponent


  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    environment.production == true ? [] : ApiModule.forRoot({ rootUrl: environment.API_BACKEND }),
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    IconsProviderModule,
    NgZorroAntdModule,
    AngularEditorModule,
    CKEditorModule,

  ],
  providers: [
    AppInitService,
    ApiInterceptor,
    API_INTERCEPTOR_PROVIDER,
    { provide: LOCALE_ID, useValue: "en-GB" },
    { provide: NZ_I18N, useValue: en_GB, },
    { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppInitService], multi: true},
    { provide: NzMessageService },
    { provide: FormGroupDirective },
    { provide: DatePipe},

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
