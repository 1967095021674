<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Criteria'" [nzActive]="true">
      <form nz-form [formGroup]="searchingForm" (change)="updateData(searchingForm)">
        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4">Requester Name</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <input nz-input formControlName="requester" placeholder="Requester Name">
              </nz-form-control>

              <nz-form-label [nzXs]="24" [nzSm]="4">Sourcing Team</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">

                <nz-select class="select-sourcing-team" nzMode="tags" nzPlaceHolder="Select Sourcing Team"
                  [nzShowArrow]="false" [(ngModel)]="selectedSourcingTeam" (ngModelChange)="selectSourcingTeam($event)"
                  [ngModelOptions]="{standalone: true}">

                  <nz-option *ngFor="let o of sourcingTeamList;let i = index" [nzValue]="o.sourcingTeam!"
                    [nzLabel]="o.sourcingTeam!">
                  </nz-option>

                </nz-select>

              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>

              <nz-form-label [nzXs]="24" [nzSm]="4">Status</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">

                <nz-select class="select-status" nzMode="tags" nzPlaceHolder="Select Status" [nzShowArrow]="false"
                  [(ngModel)]="selectedStatus" (ngModelChange)="selectStatus($event)"
                  [ngModelOptions]="{standalone: true}" [nzCustomTemplate]="optionTemplate">

                  <nz-option *ngFor="let o of statusList;let i = index" [nzValue]="o.value" nzCustomContent
                    [nzLabel]="o.label!">
                    <nz-tag *ngIf="o.label== 'Draft'" nzColor="default">
                      {{o.label}}
                    </nz-tag>
                    <nz-tag *ngIf="o.label== 'Complete'" [nzColor]="'green'">
                      {{o.label}}
                    </nz-tag>
                    <nz-tag *ngIf="o.label == 'Wait approver intitial approve' || o.label == 'Wait approver approve' "
                      [nzColor]="'cyan'">
                      {{o.label}}
                    </nz-tag>
                    <nz-tag *ngIf="o.label == 'Sourcing conclusion' " [nzColor]="'geekblue'">
                      {{o.label}}
                    </nz-tag>
                    <nz-tag
                      *ngIf="o.label == 'Wait sourcing approver initial approve' || o.label == 'Wait sourcing approver approve' "
                      [nzColor]="'purple'">
                      {{o.label}}
                    </nz-tag>
                    <nz-tag *ngIf="o.label == 'Reject' " [nzColor]="'red'">
                      {{o.label}}
                    </nz-tag>
                    <nz-tag *ngIf="o.label == 'Return' " [nzColor]="'orange'">
                      {{o.label}}
                    </nz-tag>
                  </nz-option>

                  <ng-template #optionTemplate let-o>
                    <nz-tag *ngIf="o.nzLabel == 'Draft'" nzColor="default">
                      {{o.nzLabel}}
                    </nz-tag>
                    <nz-tag *ngIf="o.nzLabel == 'Complete'" [nzColor]="'green'">
                      {{o.nzLabel}}
                    </nz-tag>
                    <nz-tag
                      *ngIf="o.nzLabel == 'Wait approver intitial approve' || o.nzLabel == 'Wait approver approve' "
                      [nzColor]="'cyan'">
                      {{o.nzLabel}}
                    </nz-tag>
                    <nz-tag *ngIf="o.nzLabel == 'Sourcing conclusion' " [nzColor]="'geekblue'">
                      {{o.nzLabel}}
                    </nz-tag>
                    <nz-tag
                      *ngIf="o.nzLabel == 'Wait sourcing approver initial approve' || o.nzLabel == 'Wait sourcing approver approve' "
                      [nzColor]="'purple'">
                      {{o.nzLabel}}
                    </nz-tag>
                    <nz-tag *ngIf="o.nzLabel == 'Reject' " [nzColor]="'red'">
                      {{o.nzLabel}}
                    </nz-tag>
                    <nz-tag *ngIf="o.nzLabel == 'Return' " [nzColor]="'orange'">
                      {{o.nzLabel}}
                    </nz-tag>
                  </ng-template>

                </nz-select>

              </nz-form-control>


              <nz-form-label [nzXs]="24" [nzSm]="4">Created Date</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-range-picker [nzRanges]="ranges" nzFormat="d MMM yyyy" [(ngModel)]="createdDate"
                  (ngModelChange)="onChange($event)" [ngModelOptions]="{standalone: true}">
                </nz-range-picker>
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">
              <div class="div-button-save" style="padding: 2%;">
                <button nzSize="default" class="btn-cancel" nz-button nzType="default"
                  (click)="onClickReset()">Reset</button>
              </div>

              <div class="div-button-submit" style="padding: 2%;">
                <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit"
                  (click)="onClickSearch()">Search</button>
              </div>
            </div>
          </div>

        </div>



      </form>
    </nz-collapse-panel>
  </nz-collapse>
</nz-layout>
