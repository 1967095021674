<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="false" [nzSize]="'large'">

    <div nz-row class="row">

      <nz-card nzTitle="User manual">

      </nz-card>

    </div>
  </nz-spin>
</nz-layout>
