<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Criteria'" [nzActive]="false">
      <form nz-form [formGroup]="searchingForm">

        <div nz-row class="content-row">
          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4">Request contract No</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <input nz-input formControlName="requestContractNo">
              </nz-form-control>

              <nz-form-label [nzXs]="24" [nzSm]="4">Date</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-range-picker [nzRanges]="ranges" nzFormat="d MMM yyyy" formControlName="date">
                </nz-range-picker>
              </nz-form-control>

            </nz-form-item>
          </div>




          <div nz-col [nzXs]="24" [nzSm]="24">
            <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">
              <div class="div-button-save" style="padding: 2%;">
                <button nzSize="default" class="btn-cancel" nz-button nzType="default" (click)="onReset()">Reset</button>
              </div>

              <div class="div-button-submit" style="padding: 2%;">
                <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit"
                  (click)="onSearch()">Search</button>
              </div>
            </div>
          </div>

        </div>


      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
