/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BiddingHeader } from '../models/bidding-header';
import { BiddingHeaderAndDetail } from '../models/bidding-header-and-detail';
import { SummaryBidding } from '../models/summary-bidding';
import { SummaryBiddingAllInfo } from '../models/summary-bidding-all-info';
import { SummaryBiddingByVendor } from '../models/summary-bidding-by-vendor';
import { SummaryBiddingItemFromBidding } from '../models/summary-bidding-item-from-bidding';
import { SummaryBiddingItemListLastPriceAndAvg } from '../models/summary-bidding-item-list-last-price-and-avg';
import { SummaryVendorBiddingInfo } from '../models/summary-vendor-bidding-info';

@Injectable({
  providedIn: 'root',
})
export class SummaryBiddingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiSummaryBiddingGetAllBiddingHeaderGet
   */
  static readonly ApiSummaryBiddingGetAllBiddingHeaderGetPath = '/api/SummaryBidding/GetAllBiddingHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetAllBiddingHeaderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetAllBiddingHeaderGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetAllBiddingHeaderGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetAllBiddingHeaderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetAllBiddingHeaderGet$Plain(params?: {
  }): Observable<Array<BiddingHeader>> {

    return this.apiSummaryBiddingGetAllBiddingHeaderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetAllBiddingHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetAllBiddingHeaderGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetAllBiddingHeaderGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetAllBiddingHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetAllBiddingHeaderGet$Json(params?: {
  }): Observable<Array<BiddingHeader>> {

    return this.apiSummaryBiddingGetAllBiddingHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingGetBiddingHeaderByCreatedDateGet
   */
  static readonly ApiSummaryBiddingGetBiddingHeaderByCreatedDateGetPath = '/api/SummaryBidding/GetBiddingHeaderByCreatedDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetBiddingHeaderByCreatedDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeader>> {

    return this.apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetBiddingHeaderByCreatedDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeader>> {

    return this.apiSummaryBiddingGetBiddingHeaderByCreatedDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet
   */
  static readonly ApiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGetPath = '/api/SummaryBidding/GetBiddingHeaderAndDetailByCreatedDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeaderAndDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeaderAndDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeaderAndDetail>> {

    return this.apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeaderAndDetail>>) => r.body as Array<BiddingHeaderAndDetail>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeaderAndDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeaderAndDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeaderAndDetail>> {

    return this.apiSummaryBiddingGetBiddingHeaderAndDetailByCreatedDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeaderAndDetail>>) => r.body as Array<BiddingHeaderAndDetail>)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet
   */
  static readonly ApiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGetPath = '/api/SummaryBidding/GetSummaryBiddingAllInfoByPkBiddingHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Plain$Response(params?: {
    pkBiddingHeader?: string;
  }): Observable<StrictHttpResponse<SummaryBiddingAllInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('pkBiddingHeader', params.pkBiddingHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBiddingAllInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Plain(params?: {
    pkBiddingHeader?: string;
  }): Observable<SummaryBiddingAllInfo> {

    return this.apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBiddingAllInfo>) => r.body as SummaryBiddingAllInfo)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Json$Response(params?: {
    pkBiddingHeader?: string;
  }): Observable<StrictHttpResponse<SummaryBiddingAllInfo>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('pkBiddingHeader', params.pkBiddingHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBiddingAllInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Json(params?: {
    pkBiddingHeader?: string;
  }): Observable<SummaryBiddingAllInfo> {

    return this.apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBiddingAllInfo>) => r.body as SummaryBiddingAllInfo)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingGetItemListLastPriceByMatCodePost
   */
  static readonly ApiSummaryBiddingGetItemListLastPriceByMatCodePostPath = '/api/SummaryBidding/GetItemListLastPriceByMatCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetItemListLastPriceByMatCodePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceByMatCodePost$Plain$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<SummaryBiddingItemListLastPriceAndAvg>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetItemListLastPriceByMatCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SummaryBiddingItemListLastPriceAndAvg>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetItemListLastPriceByMatCodePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceByMatCodePost$Plain(params?: {
    body?: Array<string>
  }): Observable<Array<SummaryBiddingItemListLastPriceAndAvg>> {

    return this.apiSummaryBiddingGetItemListLastPriceByMatCodePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SummaryBiddingItemListLastPriceAndAvg>>) => r.body as Array<SummaryBiddingItemListLastPriceAndAvg>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetItemListLastPriceByMatCodePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceByMatCodePost$Json$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<SummaryBiddingItemListLastPriceAndAvg>>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetItemListLastPriceByMatCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<SummaryBiddingItemListLastPriceAndAvg>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetItemListLastPriceByMatCodePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceByMatCodePost$Json(params?: {
    body?: Array<string>
  }): Observable<Array<SummaryBiddingItemListLastPriceAndAvg>> {

    return this.apiSummaryBiddingGetItemListLastPriceByMatCodePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<SummaryBiddingItemListLastPriceAndAvg>>) => r.body as Array<SummaryBiddingItemListLastPriceAndAvg>)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingGetItemListLastPriceFromBiddingPost
   */
  static readonly ApiSummaryBiddingGetItemListLastPriceFromBiddingPostPath = '/api/SummaryBidding/GetItemListLastPriceFromBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Plain$Response(params?: {
    body?: Array<SummaryVendorBiddingInfo>
  }): Observable<StrictHttpResponse<SummaryBiddingItemFromBidding>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetItemListLastPriceFromBiddingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBiddingItemFromBidding>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Plain(params?: {
    body?: Array<SummaryVendorBiddingInfo>
  }): Observable<SummaryBiddingItemFromBidding> {

    return this.apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBiddingItemFromBidding>) => r.body as SummaryBiddingItemFromBidding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Json$Response(params?: {
    body?: Array<SummaryVendorBiddingInfo>
  }): Observable<StrictHttpResponse<SummaryBiddingItemFromBidding>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetItemListLastPriceFromBiddingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBiddingItemFromBidding>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Json(params?: {
    body?: Array<SummaryVendorBiddingInfo>
  }): Observable<SummaryBiddingItemFromBidding> {

    return this.apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBiddingItemFromBidding>) => r.body as SummaryBiddingItemFromBidding)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingCreateSummaryBiddingPost
   */
  static readonly ApiSummaryBiddingCreateSummaryBiddingPostPath = '/api/SummaryBidding/CreateSummaryBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingCreateSummaryBiddingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingCreateSummaryBiddingPost$Plain$Response(params?: {
    body?: SummaryBidding
  }): Observable<StrictHttpResponse<SummaryBidding>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingCreateSummaryBiddingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBidding>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingCreateSummaryBiddingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingCreateSummaryBiddingPost$Plain(params?: {
    body?: SummaryBidding
  }): Observable<SummaryBidding> {

    return this.apiSummaryBiddingCreateSummaryBiddingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBidding>) => r.body as SummaryBidding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingCreateSummaryBiddingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingCreateSummaryBiddingPost$Json$Response(params?: {
    body?: SummaryBidding
  }): Observable<StrictHttpResponse<SummaryBidding>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingCreateSummaryBiddingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBidding>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingCreateSummaryBiddingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingCreateSummaryBiddingPost$Json(params?: {
    body?: SummaryBidding
  }): Observable<SummaryBidding> {

    return this.apiSummaryBiddingCreateSummaryBiddingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBidding>) => r.body as SummaryBidding)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingSaveSummaryBiddingPost
   */
  static readonly ApiSummaryBiddingSaveSummaryBiddingPostPath = '/api/SummaryBidding/SaveSummaryBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingSaveSummaryBiddingPost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingSaveSummaryBiddingPost$Plain$Response(params?: {
    body?: SummaryBidding
  }): Observable<StrictHttpResponse<SummaryBidding>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingSaveSummaryBiddingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBidding>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingSaveSummaryBiddingPost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingSaveSummaryBiddingPost$Plain(params?: {
    body?: SummaryBidding
  }): Observable<SummaryBidding> {

    return this.apiSummaryBiddingSaveSummaryBiddingPost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBidding>) => r.body as SummaryBidding)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingSaveSummaryBiddingPost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingSaveSummaryBiddingPost$Json$Response(params?: {
    body?: SummaryBidding
  }): Observable<StrictHttpResponse<SummaryBidding>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingSaveSummaryBiddingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBidding>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingSaveSummaryBiddingPost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiSummaryBiddingSaveSummaryBiddingPost$Json(params?: {
    body?: SummaryBidding
  }): Observable<SummaryBidding> {

    return this.apiSummaryBiddingSaveSummaryBiddingPost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBidding>) => r.body as SummaryBidding)
    );
  }

  /**
   * Path part for operation apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet
   */
  static readonly ApiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGetPath = '/api/SummaryBidding/GetSummaryBiddingByFKBiddingHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Plain$Response(params?: {
    FKBiddingHeader?: string;
  }): Observable<StrictHttpResponse<SummaryBiddingByVendor>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('FKBiddingHeader', params.FKBiddingHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBiddingByVendor>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Plain(params?: {
    FKBiddingHeader?: string;
  }): Observable<SummaryBiddingByVendor> {

    return this.apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBiddingByVendor>) => r.body as SummaryBiddingByVendor)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Json$Response(params?: {
    FKBiddingHeader?: string;
  }): Observable<StrictHttpResponse<SummaryBiddingByVendor>> {

    const rb = new RequestBuilder(this.rootUrl, SummaryBiddingService.ApiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('FKBiddingHeader', params.FKBiddingHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<SummaryBiddingByVendor>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Json(params?: {
    FKBiddingHeader?: string;
  }): Observable<SummaryBiddingByVendor> {

    return this.apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<SummaryBiddingByVendor>) => r.body as SummaryBiddingByVendor)
    );
  }

}
