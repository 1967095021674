import { MdmGetApprovalListResponseData } from '../../../../../api/models';
import { MdmGetApprovalListResponse } from '../../../../../api/models';
import { MdmService } from './../../../../../api/services/mdm.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MdmGetMemberInfoResponseData, RcfAllInfo } from '../../../../../api/models';
import { UserDetailSSO } from 'src/app/models/UserDetailSSO';

@Component({
  selector: 'app-approver',
  templateUrl: './approver.component.html',
  styleUrls: ['./approver.component.css']
})
export class ApproverComponent implements OnInit {

  @Input() steps!: number;
  @Input() requestContractAllInfo!: RcfAllInfo[];
  @Input() userDetailSSO!: UserDetailSSO;
  // @Input() requesterDetailMDM!: MdmGetMemberInfoResponseData;

  @Output() sourcingApproverDataChange = new EventEmitter<FormGroup>();

  sourcingApproveForm: FormGroup;
  isLoading: boolean = false;
  isSearching: boolean = false;
  isDataLoading: boolean = false;
  estimatedContractValue: number = 0;

  selectedSM: any;
  selectedPM: any;

  optionListSM: MdmGetMemberInfoResponseData[] = [];
  defaultListSM: MdmGetMemberInfoResponseData[] = [];
  optionListPM: MdmGetMemberInfoResponseData[] = [];
  defaultListPM: MdmGetMemberInfoResponseData[] = [];

  constructor(
    private fb: FormBuilder,
    private mdmService: MdmService,
  ) {

    this.sourcingApproveForm = this.fb.group({
      sm_approver_name: [''],
      sm_approver_email: [''],
      sm_approver_company: [''],
      pm_approver_name: [''],
      pm_approver_email: [''],
      pm_approver_company: [''],

    })
  }

  ngOnInit(): void {

  }

  updateData(event: FormGroup) {
    this.sourcingApproverDataChange.emit(event);
  }

  async ngOnChanges(change: SimpleChanges) {
    this.isLoading = true;
    if (this.steps >= 3) {
      if (change.hasOwnProperty("requestContractAllInfo") && change.requestContractAllInfo.currentValue.length != 0) {
        this.sourcingApproveForm.patchValue({
          sm_approver_name: change.requestContractAllInfo.currentValue[0].sourcingApproverSMName,
          sm_approver_company: change.requestContractAllInfo.currentValue[0].sourcingApproverSMCompany,
          pm_approver_name: change.requestContractAllInfo.currentValue[0].sourcingApproverPMName,
          pm_approver_company: change.requestContractAllInfo.currentValue[0].sourcingApproverPMCompany,
        })

        this.selectedSM = [change.requestContractAllInfo.currentValue[0].sourcingApproverSMName];
        this.selectedPM = [change.requestContractAllInfo.currentValue[0].sourcingApproverPMName]
      }
    }
    if (change.hasOwnProperty("userDetailSSO") && this.steps < 3) {

      await this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json({
        name: change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name
      }).toPromise()
        .then(
          async response => {

            let memberInfo: MdmGetMemberInfoResponseData[] = response.responseData!;
            await this.mdmService.apiMdmApprovalandDelegationListByOrganizationIdPost$Json({
              orgID: memberInfo[0].organizationId?.toString(),
            })
              .toPromise()
              .then(
                responseApprover => {
                  var approverList: MdmGetApprovalListResponseData[] = responseApprover.responseData!;
                  let tempOptionlistSM = [];
                  let tempOptionlistPM = [];
                  let tempSmInfo: any = [];
                  let tempPmInfo: any = [];

                  for (let i = 0; i < approverList.length; i++) {

                    if (approverList[i].organizationLevelId! <= 900) {

                      if (approverList[i].organizationLevelId == 900) {

                        this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                          name: approverList[i].eFullName!,
                        }).subscribe(response => {
                          this.sourcingApproveForm.patchValue({
                            sm_approver_name: approverList[i].eFullName,
                            sm_approver_email: response.body.responseData![0].email,
                            sm_approver_company: response.body.responseData![0].companyName,
                          })

                          this.updateData(this.sourcingApproveForm);
                        })

                        if (approverList[i].eFullName != change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                          tempOptionlistSM.push(approverList[i]);
                        }

                        this.selectedSM = approverList[i].eFullName;
                      }
                      else if (approverList[i].organizationLevelId! <= 900 && approverList[i].eFullName != change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                        tempOptionlistSM.push(approverList[i]);
                      }


                    }

                    if (approverList[i].organizationLevelId == 800) {

                      this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                        name: approverList[i].eFullName!
                      }).subscribe(response => {
                        this.sourcingApproveForm.patchValue({
                          pm_approver_name: approverList[i].eFullName!,
                          pm_approver_email: response.body.responseData![0].email,
                          pm_approver_company: response.body.responseData![0].companyName,
                        })

                        this.updateData(this.sourcingApproveForm);
                      })

                      if (approverList[i].eFullName != change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                        tempOptionlistPM.push(approverList[i]);
                      }

                      this.selectedPM = [approverList[i].eFullName];
                    }
                    else if (approverList[i].organizationLevelId! <= 800 && approverList[i].eFullName != change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                      tempOptionlistPM.push(approverList[i]);
                    }
                  }

                  tempOptionlistSM.forEach(item => {
                    this.optionListSM.push({
                      eFullName: item.eFullName,
                      organizationName: item.organizationNameE,
                    });
                  })

                  this.optionListSM = Array.from(new Set(this.optionListSM.map(item => item.eFullName)))
                    .map(fullName => {
                      return this.optionListSM.find(item => item.eFullName === fullName)!;
                    })


                  //เช็คว่า SM ไม่เป็นคนเดียวกับ Requester ถ้าซ้ำไปเอาคนที่อยู่อีกขั้น
                  if (this.selectedSM == change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                    for (let i = tempOptionlistSM.length - 1; i >= 0; i--) {
                      if (tempOptionlistSM[i].eFullName != change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                        this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                          name: tempOptionlistSM[i].eFullName!,
                        }).subscribe(response => {
                          this.sourcingApproveForm.patchValue({
                            sm_approver_name: response.body.responseData![0].eFullName,
                            sm_approver_email: response.body.responseData![0].email,
                            sm_approver_company: response.body.responseData![0].companyName,
                          })

                          this.updateData(this.sourcingApproveForm);
                        })

                        this.selectedSM = [tempOptionlistSM[i].eFullName];

                        break;
                      }
                    }

                  }

                  //เอา ApproverPM ซ้ำออก
                  tempOptionlistPM.forEach(item => {
                    this.optionListPM.push({
                      eFullName: item.eFullName,
                      organizationName: item.organizationNameE,
                    });
                  })

                  this.optionListPM = Array.from(new Set(this.optionListPM.map(item => item.eFullName)))
                    .map(fullName => {
                      return this.optionListPM.find(item => item.eFullName === fullName)!;
                    })

                  //เช็คว่า PM ไม่เป็นคนเดียวกับ Requester ถ้าซ้ำไปเอาคนที่อยู่อีกขั้น
                  if (this.selectedPM == change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                    for (let i = tempOptionlistPM.length - 1; i >= 0; i--) {
                      if (tempOptionlistPM[i].eFullName != change.userDetailSSO.currentValue.information.first_name + " " + change.userDetailSSO.currentValue.information.last_name) {
                        this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                          name: tempOptionlistPM[i].eFullName!,
                        }).subscribe(response => {
                          this.sourcingApproveForm.patchValue({
                            pm_approver_name: response.body.responseData![0].eFullName,
                            pm_approver_email: response.body.responseData![0].email,
                            pm_approver_company: response.body.responseData![0].companyName,
                          })

                          this.updateData(this.sourcingApproveForm);

                        })

                        this.selectedPM = [tempOptionlistPM[i].eFullName];

                        break;
                      }
                    }

                  }
                  this.defaultListSM = [...this.optionListSM];
                  this.defaultListPM = [...this.optionListPM];
                  this.updateData(this.sourcingApproveForm);
                  this.isLoading = false;

                }
              )

          })

    }
    this.isLoading = false;
  }

  async selectSM(value: number) {

    console.log("prev sm ", this.selectedSM);
    this.selectedSM = [this.optionListSM[value].eFullName];

    this.isDataLoading = true;
    await this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
      name: this.optionListSM[value].eFullName!,
    }).toPromise()
      .then(
        response => {

          this.sourcingApproveForm.patchValue({
            sm_approver_name: this.optionListSM[value].eFullName,
            sm_approver_email: response.body.responseData![0].email,
            sm_approver_company: response.body.responseData![0].companyName,
          })

          this.updateData(this.sourcingApproveForm);

        })
      .catch((err) => {
        console.log(err);
      })

    this.isDataLoading = false;
  }

  async selectPM(value: number) {

    console.log("Selected PM: ", this.optionListPM[value]);
    this.selectedPM = [this.optionListPM[value].eFullName];

    this.isDataLoading = true;
    await this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
      name: this.optionListPM[value].eFullName!,
    }).toPromise()
      .then(
        response => {

          this.sourcingApproveForm.patchValue({
            pm_approver_name: this.optionListPM[value].eFullName,
            pm_approver_email: response.body.responseData![0].email,
            pm_approver_company: response.body.responseData![0].companyName,
          })

          this.updateData(this.sourcingApproveForm);

        })

    this.isDataLoading = false;
  }

  async onSearchSM(value: string) {

    if (value.length >= 3) {
      this.isSearching = true;
      console.log("SearchSM: ", value);
      await this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json({
        name: value
      })
        .toPromise()
        .then(
          response => {

            var tempOptionlistSM = [];
            var memberInfoList: MdmGetMemberInfoResponseData[] = response.responseData!;
            for (var i = 0; i < memberInfoList.length; i++) {
              if (Number(memberInfoList[i].approveLevelId) <= 900) {
                let obj = {
                  eFullName: memberInfoList[i].eFullName,
                  organizationName: memberInfoList[i].organizationName
                }

                tempOptionlistSM.push(obj);

              }
            }

            this.optionListSM = tempOptionlistSM.filter((item) => {
              var isDuplicate = this.optionListSM.includes(
                item.eFullName as MdmGetMemberInfoResponseData
              );

              if (!isDuplicate) {
                this.optionListSM.push(item.eFullName as MdmGetMemberInfoResponseData);
                return true;
              }

              return false;
            });

            console.log("optionListSM: ",this.optionListSM);

          }
        )
        .catch(
          error => {

          }
        )

      this.isSearching = false;
    }
  }

  onExitSearchSM() {
    this.optionListSM = [...this.defaultListSM];
  }

  onExitSearchPM() {
    this.optionListPM = [...this.defaultListPM];
  }

  async onSearchPM(value: string) {
    if (value.length >= 3) {
      this.isSearching = true;

      console.log("SearchPM: ", value);
      await this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json({
        name: value
      })
        .toPromise().then(
          response => {
            var tempOptionlistPM = [];
            var memberInfoList: MdmGetMemberInfoResponseData[] = response.responseData!;
            for (var i = 0; i < memberInfoList.length; i++) {
              if (Number(memberInfoList[i].approveLevelId) <= 800) {
                let obj = {
                  eFullName: memberInfoList[i].eFullName,
                  organizationName: memberInfoList[i].organizationName
                }
                tempOptionlistPM.push(obj);
              }
            }

            this.optionListPM = tempOptionlistPM.filter((item) => {
              var isDuplicate = this.optionListPM.includes(
                item.eFullName as MdmGetMemberInfoResponseData
              );

              if (!isDuplicate) {
                this.optionListPM.push(item.eFullName as MdmGetMemberInfoResponseData);
                return true;
              }

              return false;
            });



          }
        )
      this.isSearching = false;
    }
  }

  redoSM() {

    this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
      name: this.defaultListSM[this.defaultListSM.length - 1].eFullName!
    }).subscribe(response => {
      this.sourcingApproveForm.patchValue({
        sm_approver_name: this.defaultListSM[this.defaultListSM.length - 1].eFullName,
        sm_approver_email: response.body.responseData![0].email,
        sm_approver_company: response.body.responseData![0].companyName,
      })

    })

    this.selectedSM = this.defaultListSM[this.defaultListSM.length - 1].eFullName
    this.optionListSM = [...this.defaultListSM];
    this.updateData(this.sourcingApproveForm);
  }

  redoPM() {

    this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
      name: this.defaultListPM[this.defaultListPM.length - 1].eFullName!
    }).subscribe(response => {
      this.sourcingApproveForm.patchValue({
        pm_approver_name: this.optionListPM[this.defaultListPM.length - 1].eFullName,
        pm_approver_email: response.body.responseData![0].email,
        pm_approver_company: response.body.responseData![0].companyName,
      })

    })

    this.selectedPM = this.optionListPM[this.optionListPM.length - 1].eFullName
    this.optionListPM = [...this.defaultListPM];
    this.updateData(this.sourcingApproveForm);
  }

}
