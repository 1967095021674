<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-header">
        <div nz-row>
          <div nz-col nzSpan="24">
            <nz-card class="card">
              <nz-row>
                <nz-breadcrumb>
                  <nz-breadcrumb-item>
                    <a routerLink="../home">
                      <span nz-icon nzType="home"></span>
                      <span>Home</span>
                    </a>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>
                    <span>Work Station</span>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>
                    <span>Bidding worksheet</span>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>
                    <a>
                      <span>Request for price list</span>
                    </a>
                  </nz-breadcrumb-item>
                </nz-breadcrumb>

              </nz-row>
            </nz-card>
          </div>
        </div>
      </div>


      <div class="panel-content">
        <div nz-row class="collapse-row">

          <div id="searching-price-list-criteria" nz-col nzSpan="24" class="collapse-col">
            <app-searching-price-list-criteria [searchingForm]="searchingForm" [listOfVendorData]="listOfVendorData"
              [sourcingTeamList]="sourcingTeamList" [purchasingGroupsList]="purchasingGroupsList"
              (onClickSearch)="onClickSearch()">
            </app-searching-price-list-criteria>
          </div>

        </div>
      </div>

      <div class="panel-content">
        <div nz-row class="collapse-row">

          <div id="searching-price-list-result" nz-col nzSpan="24" class="collapse-col">
            <app-searching-price-list-result [searchList]="searchList"
              [deadlineWarning]="deadlineWarning"></app-searching-price-list-result>
          </div>

        </div>
      </div>

    </div>
  </nz-spin>
</nz-layout>
