<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="false" [nzSize]="'large'">

    <div nz-row class="row">

      <nz-card nzTitle="Home">
        <div style="display: block">
          <canvas  id="BarChart" >{{chart}}</canvas>
        </div>
      </nz-card>

      <nz-card nzTitle="Announcement">
        <nz-page-header class="site-page-header">
          <!-- <nz-page-header-title>Welcome to Contract Management System</nz-page-header-title> -->
          <nz-page-header-content>
            <div class="content">
              <div class="main">
                <nz-descriptions nzTitle="Welcome to Contract Management System" nzSize="default" [nzColumn]="1">
                  <!-- <nz-descriptions-item nzTitle="Status" [nzSpan]="1"><mark>{{status}}</mark></nz-descriptions-item> -->
                </nz-descriptions>
              </div>
            </div>
          </nz-page-header-content>

        </nz-page-header>
      </nz-card>
    </div>
  </nz-spin>
</nz-layout>
