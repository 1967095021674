<nz-layout class="content" >
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Attachment'" [nzActive]="true">
      <div nz-row>

        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
          <!-- <nz-card nzTitle="{{item.detail!.vendorName}}" *ngFor="let item of biddingAllInfo.vendorBiddingInfo!;index as i"> -->
            <nz-card nzTitle="Bidding Files">
            <!-- <nz-table class="table-tor" #attachmentFileListTable nzBordered [nzData]="biddingAllInfo.vendorBiddingInfo![i].fileList!"
              [nzShowPagination]="false"> -->
              <nz-table class="table-tor" #attachmentFileListTable nzBordered>
              <thead>
                <tr>
                  <th>File Name</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                  </td>
              </tbody>
            </nz-table>
          </nz-card>

        </div>

        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Quotation Attachment</nz-form-label>

            <nz-form-control [nzXs]="6" [nzSm]="12">

              <nz-upload
                nzType="drag"
                [nzMultiple]="true"
                (nzChange)="onUploadChange($event)"
                [nzAction]="url"
                [nzAccept]="documentFileType"
                [nzBeforeUpload]="beforeUpload"
                [nzCustomRequest]="onUploadFile"
                [nzShowUploadList]="false"
                [nzData]="fileData"
                [nzSize]="documentFileSizeInByte"
                [nzOpenFileDialogOnClick]="true"
                [nzMultiple]="true"
                >


                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <p class="ant-upload-hint">Limit file size {{documentFileSizeInByte/1e+6}}MB </p>
              </nz-upload>
            </nz-form-control>

            <i class="icon-tips" nz-icon nzType="question-circle" nzTheme="twotone" nz-tooltip [nzTooltipTitle]="contractTypeTips"></i>
            <ng-template #contractTypeTips >
              <div class="tips">
                <p> TOR : [This is Meaning of TOR]</p>
              </div>
            </ng-template>

          </nz-form-item>
        </div>


        <div class="col" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-table class="table-tor" #fileListQuotationTable nzBordered>
            <thead>
              <tr>
                <th style="width: 5%;">Action</th>
                <th>File Name</th>
                <th>File Size</th>
                <th>Modify Date</th>
                <th>By Vendor</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fileListQuotationTable.data;index as i" class="editable-row">
                <td>
                  <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                    nzPopconfirmTitle="Sure to delete?" nzDanger>

                    <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                    </i>
                  </button>
                </td>
                <td> <a href="">{{data.fileName}}</a></td>
                <td>{{data.fileSizeInByte}} MB</td>
                <td>{{data.lastModifiedDate}}</td>
                <td>{{data.Vendor}}</td>
              </tr>
            </tbody>
          </nz-table>
        </div>

      </div>
    </nz-collapse-panel>
  </nz-collapse>

</nz-layout>
