<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Bidding Worksheet'" [nzActive]="false">
      <form nz-form>

        <div nz-row class="content-row">

          <nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Change Item List Template</nz-form-label>

              <nz-form-control [nzXs]="6" [nzSm]="12">

                <nz-upload nzType="drag" [nzMultiple]="true" (nzChange)="onUploadChange($event)"
                  [nzBeforeUpload]="beforeUpload" [nzCustomRequest]="onUploadFile" [nzShowUploadList]="false"
                  [nzSize]="0" [nzOpenFileDialogOnClick]="true" [nzMultiple]="true">


                  <p class="ant-upload-text">Click or drag file to this area to upload</p>
                </nz-upload>
              </nz-form-control>

            </nz-form-item>

            <button style="margin-bottom: 20px;" nz-button class="btn-dowload" nzType="primary"
              (click)="onDownloadTemplate()">
              Download Template
            </button>

          </nz-col>


          <nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

            <nz-table class="table-bidding-worksheet" #configTable [nzScroll]="{ x: 'auto' }" [nzBordered]="true"
              [nzOuterBordered]="true" [nzData]="biddingConfig" [nzShowPagination]="false" [nzFrontPagination]="false">
              <thead>
                <tr>
                  <th nzWidth="60px">Action</th>
                  <th nzWidth="120px">Key</th>
                  <th nzWidth="300px">Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of configTable.data; index as i" class="editable-row">
                  <td style="text-align: center;">
                    <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'"
                      (click)="modalEditConfig(tplTitle, tplContent, tplFooter, data.key!, i)"
                      [disabled]="data.key === 'FileIdTemplateItemlist'">

                      <ng-template #tplTitle>
                        <span>Edit Config</span>
                      </ng-template>

                      <ng-template #tplContent>
                        <div nz-row class="content-row">

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Key</nz-form-label>
                              <nz-form-control [nzSm]="12">
                                {{data.key}}
                              </nz-form-control>

                            </nz-form-item>
                          </div>

                          <div nz-col [nzXs]="24" [nzSm]="24">
                            <nz-form-item>
                              <nz-form-label [nzSm]="6">Value</nz-form-label>
                              <nz-form-control [nzSm]="12">

                                <input nz-input type="text" [(ngModel)]="changedConfigValue"
                                  [ngModelOptions]="{standalone: true}">

                                <span style="color: red;" *ngIf="data.key === 'DocumentFileSizeInByte'">
                                  * Input Value in MB, Example 20
                                </span>

                                <span style="color: red;" *ngIf="data.key === 'FileNameTemplateItemlist'">
                                  * Should end with .xlsx
                                </span>


                              </nz-form-control>

                            </nz-form-item>
                          </div>


                        </div>

                      </ng-template>

                      <ng-template #tplFooter let-ref="modalRef">
                        <button nz-button nzDanger (click)="modalDestroy()">Cancel</button>
                        <button nz-button nzType="primary" (click)="onChangeConfigValue(data.key!)">Save</button>
                      </ng-template>

                      <i nz-icon nzType="edit" nzTheme="outline" class="icon-edit">
                      </i>

                    </button>
                  </td>
                  <td>{{data.key}}</td>
                  <td>{{data.value}}</td>
                </tr>
              </tbody>
            </nz-table>
          </nz-col>

          <nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Simple Value'" [nzActive]="false">
                <nz-table class="table-bidding-worksheet" #simpleValueTable [nzScroll]="{ x: 'auto' }" [nzBordered]="true"
                  [nzOuterBordered]="true" [nzData]="biddingSimpleValue" [nzShowPagination]="false" [nzFrontPagination]="false">
                  <thead>
                    <tr>
                      <th nzWidth="60px" style="text-align: center;">
                        <nz-row [nzAlign]="'middle'" [nzJustify]="'center'">
                          <nz-col>
                            Action
                          </nz-col>
                        </nz-row>

                        <button nz-button class="btn-checkall" nzType="default" [nzSize]="'small'">
                          <i nz-icon nzType="plus" nzTheme="outline"></i>

                        </button>
                      </th>
                      <th nzWidth="120px">Key</th>
                      <th nzWidth="300px">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of simpleValueTable.data; index as i" class="editable-row">
                      <td style="text-align: center;">
                        <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'" (click)="addSimpleValue(data.key!)">
                          <i nz-icon nzType="edit" nzTheme="outline" class="icon-edit">
                          </i>

                        </button>
                      </td>

                      <td>{{data.key}}</td>
                      <td>{{data.value}}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-collapse-panel>
            </nz-collapse>
          </nz-col>

          <nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Base On'" [nzActive]="false">
                <nz-table class="table-bidding-worksheet" #baseOnTable [nzScroll]="{ x: 'auto' }" [nzBordered]="true"
                  [nzOuterBordered]="true" [nzData]="baseOn" [nzShowPagination]="false" [nzFrontPagination]="false">
                  <thead>
                    <tr>
                      <th nzWidth="60px" style="text-align: center;">
                        <nz-row [nzAlign]="'middle'" [nzJustify]="'center'">
                          <nz-col>
                            Action
                          </nz-col>
                        </nz-row>

                        <button nz-button class="btn-checkall" nzType="default" [nzSize]="'small'">
                          <i nz-icon nzType="check" nzTheme="outline" class="icon-check">
                          </i>

                        </button>
                      </th>
                      <th nzWidth="120px">Key</th>
                      <th nzWidth="300px">Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let data of baseOnTable.data; index as i" class="editable-row">
                      <td style="text-align: center;">
                        <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'">
                          <i nz-icon nzType="plus" nzTheme="outline"></i>

                        </button>
                      </td>

                      <td>{{data.baseOn}}</td>
                      <td>{{data.description}}</td>
                    </tr>
                  </tbody>
                </nz-table>
              </nz-collapse-panel>
            </nz-collapse>
          </nz-col>

        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
