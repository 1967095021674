import { RequestContractDocNoAndLog } from './../../../../../../api/models/request-contract-doc-no-and-log';
import { Rcflog } from './../../../../../../api/models/rcflog';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'app-searching-result-request-contract-log',
  templateUrl: './searching-result-request-contract-log.component.html',
  styleUrls: ['./searching-result-request-contract-log.component.css']
})
export class SearchingResultRequestContractLogComponent implements OnInit {

  @Input() logData: RequestContractDocNoAndLog[] = [];
  constructor() { }

  sortDateFn = (a: RequestContractDocNoAndLog, b: RequestContractDocNoAndLog) =>
    moment(a.logData?.editedDate).valueOf()  - moment(b.logData?.editedDate).valueOf();

  ngOnInit(): void {
  }

}
