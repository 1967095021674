<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Contract committee'" [nzActive]="true">
      <form nz-form [formGroup]="peopleForm">
        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Technical Committee</nz-form-label>
              <nz-form-control [nzXs]="18" [nzSm]="18">

                <div nz-col style="display: flex;">
                  <div nz-col>
                    <nz-select nzMode="default" nzPlaceHolder="Search with name" [nzShowArrow]="false" nzShowSearch
                      nzServerSearch [nzOptionHeightPx]="58" [nzOptionOverflowSize]="4" [(ngModel)]="selectedTechPIC"
                      (ngModelChange)="addTechCommittee($event)" [ngModelOptions]="{standalone: true}"
                      (nzOnSearch)="onSearch($event)" [disabled]="steps != 0" [nzNotFoundContent] style="width: 350px;">
                      <ng-container *ngFor="let o of optionList; index as i;">
                        <nz-option *ngIf="!isLoading" nzCustomContent [nzValue]="i" [nzLabel]="o.committeeName!">
                          <div class="customOption">
                            <h2 class="name">{{o.committeeName}}</h2>
                            <p class="position">{{o.committeePosition}}</p>
                          </div>
                        </nz-option>
                      </ng-container>

                      <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                        <i nz-icon nzType="loading" class="loading-icon"></i>
                        Searching...
                      </nz-option>

                    </nz-select>

                    <i class="icon-tips" nz-icon nzType="question-circle" nzTheme="twotone" nz-tooltip
                      [nzTooltipTitle]="contractTypeTips"></i>
                    <ng-template #contractTypeTips>
                      <div class="tips">
                        <p> โปรดระบุคณะพิจารณาด้าน Technical ในทุกพื้นที่ที่คาดว่าสามารถใช้สัญญาได้</p>
                      </div>
                    </ng-template>

                  </div>

                </div>



              </nz-form-control>
            </nz-form-item>


          </div>

          <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
            <nz-table class="table-technicalcommittee" #techTable nzTableLayout="fixed" [nzShowPagination]="false" nzBordered
              [nzData]="listOfTechCommit" [nzScroll]="{x:'800px', y:'500px'}" [nzFrontPagination]="false">
              <thead>
                <tr>
                  <th nzWidth="80px">Action</th>
                  <th nzWidth="150px">Name</th>
                  <th nzWidth="200px">Position</th>
                  <th nzWidth="100px">Company</th>
                  <th nzWidth="150px">ข้อกำหนด</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let data of techTable.data" class="editable-row">
                  <td style="text-align: center;" class="td-action">
                    <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                      nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteTechRow(data.committeeName!)" nzDanger
                      [disabled]="steps != 0">
                      <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                      </i>
                    </button>

                    <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'"
                      (click)="modalEditSuggestvendor(tplTitle, tplContent, tplFooter, data.committeeName!)"
                      [disabled]="steps != 0">
                      <i nz-icon nzType="edit" nzTheme="outline" class="icon-download">
                      </i>
                    </button>

                    <ng-template #tplTitle>
                      <span>Edit Techinal Committee</span>
                    </ng-template>

                    <ng-template #tplContent>
                      <div nz-row class="content-row">

                        <div nz-col [nzXs]="24" [nzSm]="24">
                          <nz-form-item>
                            <nz-form-label [nzSm]="6">Name</nz-form-label>
                            <nz-form-control [nzSm]="12">
                              {{data.committeeName}}
                            </nz-form-control>

                          </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" [nzSm]="24">
                          <nz-form-item>
                            <nz-form-label [nzSm]="6">Position</nz-form-label>
                            <nz-form-control [nzSm]="12">
                              {{data.committeePosition}}
                            </nz-form-control>

                          </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" [nzSm]="24">
                          <nz-form-item>
                            <nz-form-label [nzSm]="6">Company</nz-form-label>
                            <nz-form-control [nzSm]="12">
                              {{data.committeeCompany}}
                            </nz-form-control>

                          </nz-form-item>
                        </div>

                        <div nz-col [nzXs]="24" [nzSm]="24">
                          <nz-form-item>
                            <nz-form-label [nzSm]="6">ข้อกำหนด</nz-form-label>
                            <nz-form-control [nzSm]="12">
                              <textarea nz-input placeholder="ข้อกำหนด" [(ngModel)]="changedCommitteeTerms"
                                [ngModelOptions]="{standalone: true}" [nzAutosize]="{ minRows: 3, maxRows: 10 }">
                              </textarea>
                            </nz-form-control>

                          </nz-form-item>
                        </div>


                      </div>

                    </ng-template>

                    <ng-template #tplFooter let-ref="modalRef">
                      <button nz-button nzDanger (click)="modalDestroy()">Cancel</button>
                      <button nz-button nzType="primary"
                        (click)="changeTechnicalCommitteeDetail(data.committeeName!)">Save</button>
                    </ng-template>

                    <!-- <i nz-icon nzType="delete" nzTheme="twotone" class="icon-delete" nz-popconfirm nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteItem(data.pathName)" ></i> -->
                  </td>
                  <td>{{data.committeeName}}</td>
                  <td>{{data.committeePosition}}</td>
                  <td>{{data.committeeCompany}}</td>
                  <td>
                    <div class="editable-cell" [hidden]="editId === data.committeeName">
                      {{ data.committeeTerms }}
                    </div>
                  </td>
                </tr>
              </tbody>
            </nz-table>
          </div>

        </div>
      </form>

    </nz-collapse-panel>
  </nz-collapse>

</nz-layout>
