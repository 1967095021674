<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Ref Memo'" [nzActive]="true">
      <form nz-form [formGroup]="refMemoForm">

        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>Ref Memo No.</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">

                <nz-select>
                  <nz-option></nz-option>
                </nz-select>

              </nz-form-control>

            </nz-form-item>

          </div>

        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>
