import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzStatusType } from 'ng-zorro-antd/steps';
import { MasterService } from '../../../../../api/services';
import { MasterSourcingTeam, MasterTheArchPurGroupMember } from './../../../../../api/models';
import { MasterTheArchService } from './../../../../../api/services';

@Component({
  selector: 'app-create-new-memo',
  templateUrl: './create-new-memo.component.html',
  styleUrls: ['./create-new-memo.component.css']
})
export class CreateNewMemoComponent implements OnInit {

  steps: number = 0;
  status: NzStatusType = 'process';
  isDataLoading: boolean = false;

  purchasingGroupsMemberList: MasterTheArchPurGroupMember[] = [];
  sourcingTeamList: MasterSourcingTeam [] = [];

  actionForm: FormGroup;
  refMemoForm: FormGroup;
  sourcingContractForm: FormGroup;
  memoDetailForm: FormGroup;
  approverForm: FormGroup;
  constructor(
    private fb:FormBuilder,
    private masterService: MasterService,
    private masterTheArch: MasterTheArchService
    ) {

    this.actionForm = this.fb.group({
      action: "",
      remark: ""
    })

    this.refMemoForm = this.fb.group({
      memoNo: "",
      pkMemoHeader: ""
    })

    this.sourcingContractForm = this.fb.group({
      purchasingGroups: "",
      sourcingName: "",
      sourcingEmail: "",
      sourcingTelNo: "",
    })

    this.memoDetailForm = this.fb.group({
      projectName: "",
      detailTemplate: "",
      detail: "",
    })

    this.approverForm = this.fb.group({
      approverName: this.fb.array([]),
      approverCompany: this.fb.array([]),
      approverEmail: this.fb.array([]),
    })

  }

  async ngOnInit(): Promise<void> {
    await this.getSourcingTeamFromMaster();
    await this.getPurchasingGroupsFromMasterTheArch();
  }

  async getSourcingTeamFromMaster()
  {
    await this.masterService.apiMasterGetSourcingTeamGet$Json()
      .toPromise()
      .then(
        response => {
          this.sourcingTeamList = response;
        }
      )
  }

  async getPurchasingGroupsFromMasterTheArch()
  {
    await this.masterTheArch.apiMasterTheArchGetPurGroupMemberActiveGet$Json()
      .toPromise()
      .then(
        response => {
          this.purchasingGroupsMemberList = response;
        }
      )
  }

  onDeleteDraft(){
  }

  showSaveConfirm(){

  }

  showSubmitConfirm(){

  }

}
