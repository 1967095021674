<nz-layout class="content">
  <!-- <nz-card class="card" *ngIf=" steps != 0 || documentStatus == 'Draft'"> -->
  <!-- <nz-page-header class="site-page-header">
      <nz-page-header-content> -->
  <div class="content">
    <div class="main">
      <nz-descriptions nzBordered nzSize="default" [nzColumn]="2">

        <nz-descriptions-item nzTitle="RCF Document Number " [nzSpan]="1">{{ documentNumber }}</nz-descriptions-item>
        <nz-descriptions-item nzTitle="Status" [nzSpan]="1">
          <nz-tag *ngIf="documentStatus == 'Finish'" [nzColor]="'green'">
            Ready to create MEMO
          </nz-tag>
          <nz-tag *ngIf="documentStatus == 'Draft'" nzColor="default">
            {{ documentStatus }}
          </nz-tag>
          <nz-tag *ngIf="documentStatus == 'Wait for vendor submit price'" [nzColor]="'cyan'">
            {{ documentStatus }}
          </nz-tag>
          <nz-tag *ngIf="documentStatus == 'Wait for summary'" [nzColor]="'orange'">
            {{ documentStatus }}
          </nz-tag>
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Created by" [nzSpan]="1">
          {{documentCreator}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Created Date" [nzSpan]="1">
          {{documentCreatedDate | date:"d MMM yyyy HH:mm"}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Start Date" [nzSpan]="1">
          {{start | date:"d MMM yyyy"}}
        </nz-descriptions-item>
        <nz-descriptions-item nzTitle="Deadline" [nzSpan]="1">
          <div *ngIf="isDeadLine" style="color: red;">
            {{deadline | date:"d MMM yyyy"}}
          </div>
          <div *ngIf="!isDeadLine">
            {{deadline | date:"d MMM yyyy"}}
          </div>
        </nz-descriptions-item>
      </nz-descriptions>
    </div>
  </div>
</nz-layout>
