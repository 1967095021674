import { MasterTheArchPurGroupMember } from './../../../../../../api/models/master-the-arch-pur-group-member';
import { RequestForPriceListService } from './../../../../../../api/services/request-for-price-list.service';
import { MasterPurGroup } from './../../../../../../api/models/master-pur-group';
import { MasterSourcingTeam } from './../../../../../../api/models/master-sourcing-team';
import { async } from '@angular/core/testing';
import { MasterService } from './../../../../../../api/services/master.service';
import { DatePipe } from '@angular/common';
import { outputAst } from '@angular/compiler';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { endOfMonth, endOfYear, endOfWeek, startOfMonth, startOfYear, startOfWeek } from 'date-fns';
import { BiddingHeader, RequestForPriceListBiddingHeaderAndDetail, VChembiMstVendorMaster } from './../../../../../../api/models';
import * as moment from 'moment';
import { AuthService } from '../../../../../../services/auth.service';

@Component({
  selector: 'app-searching-price-list',
  templateUrl: './searching-price-list.component.html',
  styleUrls: ['./searching-price-list.component.css']
})
export class SearchingPriceListComponent implements OnInit {

  @Input() isDataLoading: boolean = false;
  @Output() searchingDataChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  searchingForm: FormGroup;

  deadlineWarning: boolean[] = [];
  sourcingTeamList: MasterSourcingTeam[] = [];
  purchasingGroupsList: MasterPurGroup[] = [];
  listOfVendorData: VChembiMstVendorMaster[] = [];

  createdDate: Date[] = [];
  ranges = {
    'Today': [new Date(), new Date()],
    'This Week': [startOfWeek(new Date()), endOfWeek(new Date())],
    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
    'This Year': [startOfYear(new Date()), endOfYear(new Date())],
    'A Month ago': [new Date(new Date().getFullYear() + "/" + new Date().getMonth() + "/" + new Date().getDate()), new Date()],
    'A Year ago': [new Date(new Date().getFullYear() - 1 + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()), new Date()],
  };

  selectedVendor: any = [];
  searchList: RequestForPriceListBiddingHeaderAndDetail[] = [];

  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private cmsMasterService: MasterService,
    private requestForPriceListService: RequestForPriceListService,
    private authService: AuthService,
  ) {

    this.searchingForm = this.fb.group({
      sourcingTeam: [[]],
      purchasingGroups: [[]],
      startDate: [""],
      endDate: [""],
      status: [[]],
      vendor: [[]],

    })
  }

  updateData(form: FormGroup) {

  }


  async ngOnInit(): Promise<void> {
    this.isDataLoading = true;

    this.createdDate = [new Date(Date.now() - 12096e5), new Date()]
    await this.getVendorFromMaster();
    await this.getSourcingTeamFromMaster();
    await this.getPurchasingGroupsFromMaster();

    this.isDataLoading = false;
  }

  async getVendorFromMaster() {
    await this.cmsMasterService.apiMasterGetVendorGet$Json$Response()
      .toPromise()
      .then(response => {
        if (response.ok) {
          this.listOfVendorData = response.body;
        }
      })
  }

  async getSourcingTeamFromMaster() {
    await this.cmsMasterService.apiMasterGetSourcingTeamGet$Json()
      .toPromise()
      .then(
        response => {
          this.sourcingTeamList = response;
        }
      )
  }

  async getPurchasingGroupsFromMaster() {
    await this.cmsMasterService.apiMasterGetPurGroupGet$Json()
      .toPromise()
      .then(
        response => {
          this.purchasingGroupsList = response;
        }
      )
  }


  async onClickSearch() {
    console.log(this.searchingForm.value);
    this.isDataLoading = true;

    //ยิง api ไปค้นหาจากวันที่สร้าง
    await this.requestForPriceListService.apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Json({
      startDate: new Date(this.searchingForm.get("startDate")?.value).toJSON(),
      endDate: new Date(this.searchingForm.get("endDate")?.value).toJSON()
    })
      .toPromise()
      .then(
        response => {
          this.searchList = [];

          // Search ตาม Create Date
          if (this.searchingForm.value.purchasingGroups.length === 0
              && this.searchingForm.value.vendor.length === 0
              && this.searchingForm.value.status.length === 0
              && this.searchingForm.value.sourcingTeam.length === 0) {
            console.log("Search date");
            this.searchList = response;
            console.log("Search List: ", this.searchList);
          }
          else {
            console.log("Search filter");
            //Search ตาม SourcingTeam
            if (this.searchingForm.value.sourcingTeam.length > 0) {
              console.log("Search on sourcingTeam");
              var so: string[] = this.searchingForm.value.sourcingTeam;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              tempFilterList = response.filter(function (item) {
                for (var key in so) {
                  if (item.header?.sourcingTeam === so[key]) {
                    console.log(key);
                    return true;
                  }
                }

                return false;
              })

              console.log("tempFilterList: ", tempFilterList);
              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })

            }

            //Search ตาม PurchasingGroups
            if (this.searchingForm.value.purchasingGroups.length > 0) {
              console.log("Search on PurchasingGroups");
              var p: string[] = this.searchingForm.value.purchasingGroups;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              tempFilterList = response.filter(function (item) {
                for (var key in p) {
                  if (item.header?.sourcingPurchasingGroups === p[key]) {
                    return true;
                  }
                }

                return false;
              })

              console.log("tempFilterList: ", tempFilterList);
              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })

            }

            //Search ตาม Status
            if (this.searchingForm.value.status.length > 0) {
              console.log("Search on status");
              var s: string[] = this.searchingForm.value.status;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              tempFilterList = response.filter(function (item) {
                for (var key in s) {
                  if (item.header?.status === s[key]) {
                    return true;
                  }
                }

                return false;
              })

              console.log("tempFilterList: ", tempFilterList);
              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })

            }

            //Search ตาม Vendor
            if (this.searchingForm.value.vendor.length > 0) {

              var v: string[] = this.searchingForm.value.vendor;
              let tempFilterList: RequestForPriceListBiddingHeaderAndDetail[] = [];

              response.forEach(function (item) {
                for (let i = 0; i < item.detail?.length!; i++) {
                  for (var key in v) {
                    if (item.detail![i].vendorName === v[key]) {
                      tempFilterList.push(item);
                      return;
                    }
                  }
                }

              })

              console.log("tempFilterList: ", tempFilterList);
              tempFilterList.forEach(item => {
                this.searchList.push(item);
              })

            }

            //เอา Searchlist ซ้ำออก
            this.searchList = Array.from(new Set(this.searchList.map(item => item.header?.pkbiddingHeader)))
              .map(pk => {
                return this.searchList.find(item => item.header?.pkbiddingHeader === pk)!;
              })

          }

          //Draft เจ้าของดูได้คนเดียว
          this.searchList.filter(item => {
            if (item.header?.status === "Draft") {
              if (this.authService.currentUserFullName() === item.header.creatorName) {
                return true;
              }
              else {
                return false;
              }
            }

            return true;
          })

          //เรียงวันที่สร้าง มาก -> น้อย
          this.searchList = this.searchList.sort((a, b) => {
            return (moment(a.header?.createDate).diff(moment(b.header?.createDate), "days"));
          }).reverse();

          //เปลี่ยนสี Deadline ถ้า Deadline - ปัจจุบัน <= 3 วัน
          this.searchList.forEach(item => {
            if (moment(item.header?.deadline).diff(moment(), "days") <= 3) {
              console.log("red");
              this.deadlineWarning.push(true);
            }
            else {
              console.log("normal");
              this.deadlineWarning.push(false);
            }

          })

        }
      )
      .catch(
        error => {
          console.log(error);
        }
      )

    this.isDataLoading = false;
  }

  onChangeVendor(value: string[]) {

  }

}
