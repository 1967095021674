<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Requester Information'" [nzActive]="true">

      <!-- <form nz-form [formGroup]="requesterInfoForm" (change)="updateData(requesterInfoForm.value)" > -->
      <form nz-form [formGroup]="requesterInfoForm" (change)="updateData(requesterInfoForm)">
        <div nz-row class="content-row">
          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>

              <nz-form-label [nzXs]="24" [nzSm]="4" nzRequired>Requester</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">

                <nz-select nzMode="default" nzPlaceHolder="Search" [nzShowArrow]="false" [nzOptionHeightPx]="58"
                  [nzOptionOverflowSize]="4" nzShowSearch nzServerSearch [(ngModel)]="selectedRequester"
                  (ngModelChange)="selectRequester($event)" [ngModelOptions]="{standalone: true}"
                  (nzOnSearch)="onSearch($event)" [nzDisabled]="!isDisabled">

                  <nz-option [nzLabel]="selectedRequester" [nzValue]="selectedRequester" nzHide></nz-option>

                  <ng-container *ngFor="let o of optionList; index as i;">
                    <nz-option *ngIf="!isLoading" nzCustomContent [nzValue]="i">
                      <div class="customOption">
                        <h2 class="requester-name">{{o.eFullName}}</h2>
                        <p class="requester-org-name">{{o.organizationName}}</p>
                      </div>
                    </nz-option>

                  </ng-container>

                  <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                    <i nz-icon nzType="loading" class="loading-icon"></i>
                    Searching...
                  </nz-option>

                </nz-select>
              </nz-form-control>

              <nz-form-label [nzXs]="24" [nzSm]="4" >Create Date</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <input nz-input value="{{dateFormat}}" [readOnly]="true" [disabled]="true">
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4" nzRequired>Position</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <input nz-input formControlName="position">
              </nz-form-control>
            </nz-form-item>
          </div>

          <nz-collapse [nzBordered]="false">
            <nz-collapse-panel [nzHeader]="" [nzActive]="false">
              <div nz-row class="content-row">
                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="24" [nzSm]="4">Section</nz-form-label>
                    <nz-form-control [nzXs]="24" [nzSm]="8">
                      <input nz-input formControlName="section">
                    </nz-form-control>

                    <nz-form-label [nzXs]="24" [nzSm]="6">Department</nz-form-label>
                    <nz-form-control [nzXs]="24" [nzSm]="8">
                      <input nz-input formControlName="department">
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="24" [nzSm]="4">Division</nz-form-label>
                    <nz-form-control [nzXs]="24" [nzSm]="8">
                      <input nz-input formControlName="division">
                    </nz-form-control>

                    <nz-form-label [nzXs]="24" [nzSm]="6">Company</nz-form-label>
                    <nz-form-control [nzXs]="24" [nzSm]="8">
                      <input nz-input formControlName="company">
                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="24" [nzSm]="4">Cost Center</nz-form-label>
                    <nz-form-control [nzXs]="24" [nzSm]="8">
                      <input nz-input formControlName="cost_center">
                    </nz-form-control>

                    <nz-form-label [nzNoColon]="true" [nzXs]="6" [nzSm]="6"></nz-form-label>
                    <nz-form-control [nzXs]="24" [nzSm]="8">

                    </nz-form-control>

                  </nz-form-item>
                </div>

              </div>
            </nz-collapse-panel>
          </nz-collapse>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4" nzRequired>Tel</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="tel">
              </nz-form-control>

              <nz-form-label [nzXs]="24" [nzSm]="4">Email</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="email">
              </nz-form-control>

            </nz-form-item>
          </div>


        </div>



      </form>
    </nz-collapse-panel>
  </nz-collapse>

</nz-layout>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">* Require</ng-container>
  <ng-container *ngIf="control.hasError('email')">* Invalid Email</ng-container>
  <ng-container *ngIf="control.hasError('pattern') || control.hasError('minlength') || control.hasError('maxlength')">*
    Invalid Phone Number</ng-container>
</ng-template>
