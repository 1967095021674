import { ActivatedRoute } from '@angular/router';
import { MasterBaseOn } from './../../../../../../../../api/models/master-base-on';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { outputAst } from '@angular/compiler';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { VChembiMstPaymentTerm, MasterIncoterm1, BiddingAllInfoAndLog } from '../../../../../../../../api/models';
import { NzStatus } from 'ng-zorro-antd/core/types';

@Component({
  selector: 'app-bidding-detail',
  templateUrl: './bidding-detail.component.html',
  styleUrls: ['./bidding-detail.component.css']
})
export class BiddingDetailComponent implements OnInit {

  @Input() steps: number = 0;
  @Input() biddingAllInfo!: BiddingAllInfoAndLog;
  @Input() biddingDetailForm !: FormGroup;
  @Input() baseOn: MasterBaseOn[] = [];

  @Input() paymentTermList: VChembiMstPaymentTerm[] = [];
  @Input() incotermList: MasterIncoterm1[] = [];

  @Input() contractPeroidInputStatus: NzStatus = "";
  @Input() deadlineInputStatus: NzStatus = "";

  @Output() additionalDetailListChange = new EventEmitter<string[]>();

  contractPeriod: Date[] = [];
  quotationValidityDate: Date[] = [];
  additionalDetailList: string[] = [];
  deadline!: Date;
  isMatrixTemplate: boolean = false;

  detailOption = [
    { value: "Guarantee shelf life", label: "Guarantee shelf life", checked: false, disabled: false },
    { value: "Brand", label: "Brand", checked: false, disabled: false },
    { value: "Source of origin", label: "Source of origin", checked: false, disabled: false },
    { value: "Minimum Order Quantity", label: "Minimum Order Quantity", checked: false, disabled: false },
    { value: "Quotation validity", label: "Quotation validity", checked: false, disabled: false },
  ]

  disabledDate = (current: Date): boolean => current < new Date();

  constructor(
    private datepipe: DatePipe,
    private fb: FormBuilder,
    private route: ActivatedRoute) { }

  ngOnInit(): void {

    const pkDetail = this.route.snapshot.queryParamMap.get('pkHeader');

    //ถ้ามี pk ต่อท้ายจะใส่ข้อมูลให้
    if (pkDetail) {
      this.biddingDetailForm.patchValue({
        projectName: this.biddingAllInfo.header?.projectName,
        contractStartDate: this.biddingAllInfo.header?.contractPeriodStart,
        contractEndDate: this.biddingAllInfo.header?.contractPeriodEnd,
        deadLineDate: this.biddingAllInfo.header?.deadline,
        isMatrixTemplate: this.biddingAllInfo.header?.isMatrixItemList,
        isGuaranteeShelfLife: this.biddingAllInfo.header?.isGuaranteeShelfLife,
        isBrand: this.biddingAllInfo.header?.isBrand,
        isSourceOfOrigin: this.biddingAllInfo.header?.isSourceOfOrigin,
        isMinimumOrderQuantity: this.biddingAllInfo.header?.isMinimumOrderQuantity,
        isQuotationValidity: this.biddingAllInfo.header?.isQuotationValidity,
        baseOn: this.biddingAllInfo.header?.baseOn,
        incoTerm: this.biddingAllInfo.header?.incoterm,
        paymentTerm: this.biddingAllInfo.header?.paymentTerm,
      })
      this.isMatrixTemplate = this.biddingAllInfo.header?.isMatrixItemList!;

      //ถ้า steps != 0 ให้ disable ช่อง additional detail
      if (this.steps !== 0) {
        this.detailOption.forEach(item => {
          item.disabled = true;
        })
      }

      if (this.biddingAllInfo.header?.isGuaranteeShelfLife) {
        this.detailOption[0].checked = true;
        this.additionalDetailList.push("Guarantee shelf life");
      }

      if (this.biddingAllInfo.header?.isBrand) {
        this.detailOption[1].checked = true;
        this.additionalDetailList.push("Brand");
      }

      if (this.biddingAllInfo.header?.isSourceOfOrigin) {
        this.detailOption[2].checked = true;
        this.additionalDetailList.push("Source of origin");
      }

      if (this.biddingAllInfo.header?.isMinimumOrderQuantity) {
        this.detailOption[3].checked = true;
        this.additionalDetailList.push("Minimum Order Quantity");
      }

      if (this.biddingAllInfo.header?.isQuotationValidity) {
        this.additionalDetailList.push("Quotation validity");
        this.detailOption[4].checked = true;
        this.quotationValidityDate[0] = new Date(this.biddingAllInfo.header.quotationValidityFrom!);
        this.quotationValidityDate[1] = new Date(this.biddingAllInfo.header.quotationValidityTo!);
      }

      if (this.biddingAllInfo.header?.contractPeriodStart) {
        console.log("have contractPeriodStart");
        this.contractPeriod[0] = new Date(this.biddingAllInfo.header?.contractPeriodStart!);
      }
      else {
        console.log("not have contractPeriodStart");
        this.contractPeriod[0] = new Date();
      }

      if (this.biddingAllInfo.header?.contractPeriodEnd) {
        this.contractPeriod[1] = new Date(this.biddingAllInfo.header?.contractPeriodEnd!);
      }
      else {
        this.contractPeriod[1] = new Date();
      }

      if (this.biddingAllInfo.header?.deadline) {
        this.deadline = new Date(this.biddingAllInfo.header?.deadline!);
      }

      if (this.biddingAllInfo.header?.quotationValidityFrom!) {
        this.quotationValidityDate[0] = new Date(this.biddingAllInfo.header?.quotationValidityFrom!);
      }

      if (this.biddingAllInfo.header?.quotationValidityTo) {
        this.quotationValidityDate[1] = new Date(this.biddingAllInfo.header?.quotationValidityTo!);
      }

      this.additionalDetailListChange.emit(this.additionalDetailList);
    }

  }

  //ถ้าเป็น matrix template ให้เอาช่องที่ติ๊กใน additional detail ออก
  onMatrixTemplateChange(event: boolean) {
    if (event == true) {
      this.detailOption.forEach(item => {
        item.checked = false;
        item.disabled = true;
      })

      this.biddingDetailForm.patchValue({
        isMatrixTemplate: true,
        isBrand: false,
        isGuaranteeShelfLife: false,
        isSourceOfOrigin: false,
        isMinimumOrderQuantity: false,
        isQuotationValidity: false,
      })
    }
    else {
      this.detailOption.forEach(item => {
        item.disabled = false;
      })

      this.biddingDetailForm.patchValue({
        isMatrixTemplate: false
      })
    }
  }

  //เลือกวันเริ่มสัญญา / สิ้นสุดสัญญา
  onChange(result: Date[]): void {
    this.biddingDetailForm.patchValue({
      contractStartDate: this.datepipe.transform(result[0], "yyyy-MM-dd"),
      contractEndDate: this.datepipe.transform(result[1], "yyyy-MM-dd"),
    })

  }

  //เลือกวันยืนยัน Quotation
  onQuotationValidityDateChange(result: Date[]) {
    this.biddingDetailForm.patchValue({
      quotationValidityFromDate: result[0],
      quotationValidityToDate: result[1],
    })

  }

  //เลือก Deadline
  onChangeDeadline(result: Date): void {
    this.biddingDetailForm.patchValue({
      deadLineDate: this.datepipe.transform(result, "yyyy-MM-dd")
    })
  }

  onCheckQuotation(value: string) {

  }

  //เลือก additional detail จะไปจากใน array ที่เลิอกแล้ว patch value ไปที่ biddingDetail form
  onAdditionalDetailCheckboxChange(value: string[]) {
    value.findIndex(item => item == "Brand") >= 0 ? this.biddingDetailForm.patchValue({ isBrand: true }) : this.biddingDetailForm.patchValue({ isBrand: false });
    value.findIndex(item => item == "Guarantee shelf life") >= 0 ? this.biddingDetailForm.patchValue({ isGuaranteeShelfLife: true }) : this.biddingDetailForm.patchValue({ isGuaranteeShelfLife: false });
    value.findIndex(item => item == "Source of origin") >= 0 ? this.biddingDetailForm.patchValue({ isSourceOfOrigin: true }) : this.biddingDetailForm.patchValue({ isSourceOfOrigin: false });
    value.findIndex(item => item == "Minimum Order Quantity") >= 0 ? this.biddingDetailForm.patchValue({ isMinimumOrderQuantity: true }) : this.biddingDetailForm.patchValue({ isMinimumOrderQuantity: false });
    value.findIndex(item => item == "Quotation validity") >= 0 ? this.biddingDetailForm.patchValue({ isQuotationValidity: true }) : this.biddingDetailForm.patchValue({ isQuotationValidity: false });
    this.additionalDetailListChange.emit(value);
  }

}
