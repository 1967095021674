import { ActivatedRoute } from '@angular/router';
import { async } from '@angular/core/testing';
import { AuthService } from '../../../../../../../../services/auth.service';
import { BiddingFile } from '../../../../../../../../api/models/bidding-file';
import { NewBiddingService } from '../../../../../../../../api/services/new-bidding.service';
import { MasterConfig } from '../../../../../../../../api/models/master-config';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageRef, NzMessageService } from 'ng-zorro-antd/message';
import { AzureBlobService } from 'src/app/api/services';
import { FileProperties } from '../../../../../../../../api/models/file-properties';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Guid } from 'guid-typescript';
import { NzUploadChangeParam, NzUploadFile, NzUploadXHRArgs } from 'ng-zorro-antd/upload';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable, Observer } from 'rxjs';
import { DatePipe } from '@angular/common';
import { BiddingAllInfoAndLog } from '../../../../../../../../api/models';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.css']
})
export class DocumentComponent implements OnInit {

  @Input() steps: number = 0;
  @Input() biddingAllInfo!: BiddingAllInfoAndLog;
  @Input() biddingConfig: MasterConfig[] = [];
  @Output() documentFileListChange = new EventEmitter<BiddingFile[]>();

  [x: string]: any;

  documentFileSizeInByte: number = 0;
  documentFileType: string = "";

  constructor(
    private azureBlobService: AzureBlobService,
    private datepipe: DatePipe,
    private msg: NzMessageService,
    private modal: NzModalService,
    private biddingService: NewBiddingService,
    private authService: AuthService,
    private route: ActivatedRoute) { }

  fileListTOR: BiddingFile[] = [];

  async ngOnInit(): Promise<void> {

    const pkDetail = this.route.snapshot.queryParamMap.get('pkHeader');

    //ถ้ามี pk ต่อท้ายจะใส่ข้อมูลให้
    if (pkDetail) {

      this.biddingAllInfo.files?.forEach(async (item) => {
        if (item.fileGroup === "BiddingDocumentTOR") {
          await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
            fileID: item.fileId!,
            fileName: item.fileName!,
          })
            .toPromise()
            .then(
              response => {
                item.fileDownloadLink = response.url;
                this.fileListTOR = [...this.fileListTOR, item];
                this.documentFileListChange.emit(this.fileListTOR);
              }
            );
        }
      })


    }


  }

  //ดึง config แล้วมาเอาใส่ในตัวแปร
  async ngOnChanges(change: SimpleChanges) {
    if (change.biddingConfig.currentValue.length != 0) {
      console.log(typeof (this.biddingConfig[this.biddingConfig.findIndex(item => item.key == "DocumentFileType")].value));
      this.documentFileSizeInByte = Number(this.biddingConfig[this.biddingConfig.findIndex(item => item.key = "DocumentFileSizeInByte")].value);
      this.documentFileType = JSON.parse("[" + this.biddingConfig[this.biddingConfig.findIndex(item => item.key == "DocumentFileType")].value! + "]");
    }

  }

  //สถานะการอัพโหลด
  async onUploadChange({ file, fileList }: NzUploadChangeParam): Promise<void> {
    const status = file.status;

    //ยังไม่อัพโหลด
    if (status !== 'uploading') {
      console.log(file, fileList);

    }

    //กำลังอัพโหลด
    if (status === 'uploading') {
      const msgLoad = this.msg.loading(`${file.name} uploading.`, { nzDuration: 0 });
    }

    //อัพโหลดเสร็จ
    if (status === 'done') {

      // this.msg.remove(this.msgLoad);
      // this.msg.success(`${file.name} file uploaded successfully.`);
      let response = JSON.parse(file.response);

      //เอา response มาใส่ใน object เพิ่มยิงไปเพิ่ม filelist ใน database
      //fileGroup BiddingDocumentTOR <- file ตอนเริ่มสร้าง new bidding
      var objRes: BiddingFile = {
        fileId: response.fileID,
        fileGroup: "BiddingDocumentTOR",
        fileName: response.fileName,
        fileSizeInByte: response.fileSizeinByte,
        lastModifiedDate: response.lastModified,
        modifiedByName: this.authService.currectUserFullName()
      }

      objRes.lastModifiedDate = this.datepipe.transform(objRes.lastModifiedDate, "yyyy-MM-dd HH:mm")!;

      //ยิงไปเพิ่มใน database
      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: objRes.fileId!,
        fileName: objRes.fileName!,
      }).toPromise()
        .then(
          response => {
            if (response.url != null) {
              objRes.fileDownloadLink = response.url;
            }
          }

        )
        .catch(
          err => {
            console.log("Get Downlink Failed");
          })

      //ผูก file กับ header
      await this.biddingService.apiNewBiddingRequestCreateBiddingFileListPost$Response({
        body: {
          fileId: objRes.fileId,
          fileGroup: objRes.fileGroup,
          fileName: objRes.fileName,
          fileSizeInByte: objRes.fileSizeInByte,
          modifiedByName: objRes.modifiedByName,
          fileDownloadLink: objRes.fileDownloadLink,
          fkbiddingHeader: null,
          isActive: true,
        }
      })
        .toPromise()
        .then(
          response => {
            //เอาใส่ array
            this.fileListTOR = [
              ...this.fileListTOR,
              objRes
            ]

            this.msg.remove(this.msgLoad);
            this.msg.success(`${file.name} file uploaded successfully.`);
            console.log("Created file list");

            this.documentFileListChange.emit(this.fileListTOR);
          }
        )
        .catch(
          err => {
            this.msg.remove(this.msgLoad);
            this.msg.error(`${file.name} file upload failed.`);
            console.log("Failed to created file list");
            console.log(err);
          }
        )


    }
    else if (status === 'error') {
      this.msg.remove(this.msgLoad);
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  //ยิงไปขอ download file
  async downloadFile(fileName: string, fileID: string) {
    console.log("FileName: ", fileName, " FileId ", fileID);

    await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: fileID,
      fileName: fileName,
    })
      .toPromise()
      .then(
        response => {
        }
      )

    return;
  }

  //เช็คไฟล์
  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    console.log("Before Upload");
    console.log("File Type: ", file.type);

    //เช็ค Type
    let isFileTypeValid: boolean = false;
    for (let i = 0; i < this.documentFileType[0].length; i++) {
      if (file.type == this.documentFileType[0][i]) {
        isFileTypeValid = true;
        break;
      }
    }

    if (isFileTypeValid == false) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "Invalid File Type"
      });

      return false;
    }

    //เช็ค Size
    if (file.size! > this.documentFileSizeInByte) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "File size limit < 20 MB"
      });

      return false;
    }

    return true;
  }

  //อัพโหลดไฟล์
  onUploadFile = (item: NzUploadXHRArgs) => {

    const formData = new FormData();
    formData.append('file', item.file as any);

    return this.azureBlobService.apiAzureBlobUploadBlobPut$Response({
      body: {
        files: item.file as any,
      },

    })
      .subscribe((e: HttpEvent<any>) => {
        if (e.type === HttpEventType.UploadProgress) {
          if (e.total! > 0) {
            (e as any).percent = e.loaded / e.total! * 100;
          }
          item.onProgress!(e, item.file);
        } else if (e instanceof HttpResponse) {
          console.log("FILE DOCUMENT TOR UPLOADED");
          item.onSuccess!(e.body, item.file, e);
        }
      }, (err) => { /* error */
        item.onError!(err, item.file);
      });


  }

  //สร้าง Guid
  fileData = (item: NzUploadFile) => {
    item.uid = Guid.create().toString();
    return item;
  }


  // ลบไฟล์จาก Array
  deleteItem(fileId: string) {
    console.log(fileId);

    this.fileListTOR = this.fileListTOR.filter(function (item) {
      return item.fileId !== fileId;
    })

    this.documentFileListChange.emit(this.fileListTOR);

  }
}
