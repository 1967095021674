import { Component, Input, OnInit } from '@angular/core';
import { SummaryBiddingAllInfo } from '../../../../../../../api/models';
import { SummaryCostItemList, SummaryCostSaving } from '../model/SummaryItemList';
import * as XLSX from 'xlsx';
import * as htmlToImage from 'html-to-image';

@Component({
  selector: 'app-summary-cost-saving',
  templateUrl: './summary-cost-saving.component.html',
  styleUrls: ['./summary-cost-saving.component.css']
})
export class SummaryCostSavingComponent implements OnInit {

  @Input() biddingAllInfo: SummaryBiddingAllInfo = {};
  @Input() summaryCost: SummaryCostItemList[] = [];
  @Input() summaryCostSaving!: SummaryCostSaving;

  constructor() { }

  ngOnInit(): void {
  }

  exportSummaryCostSavingToJPEG() {

    var node = document.getElementById('summaryCostSaving');
    var doc: string = this.biddingAllInfo.header?.docNo!;

    //แปลง html to jpeg
    htmlToImage.toJpeg(node!)
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = doc.replace(/-/g, "_") + "_Summary_CostSaving.jpeg";
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });

  }

  exportSummaryCostSavingToExcel() {

    //แปลง html to excel
    var ws = XLSX.utils.table_to_sheet(document.getElementById('summaryCostSaving'));
    var wb = XLSX.utils.book_new();
    var docNo: string = this.biddingAllInfo.header?.docNo?.replace(/"-"/g, "_")!;

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, docNo+"_Summary_CostSaving.xlsx");

  }

}
