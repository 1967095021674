export const environment = {
  production: true,


  //Web URL
  WEB_URL: 'https://chemclcmsprd02.azurewebsites.net',

  API_BACKEND:'https://localhost:7133',

  //SSO
  // APP_GET_USER_DETAIL:'https://sso-dev.scgd.co/api/usermanagement/getUserDetail?application=ncms_dev',
  // APP_LOGIN:'https://sso-dev.scgd.co/login/SCG/ncms_dev',
  // APP_LOGOUT:'https://sso-dev.scgd.co/revoke',
  // APP_LOGOUT_PAGE:'https://accessdev.scg.co.th/adfs/ls/?wa=wsignout1.0',

  // APP_GET_USER_DETAIL:'https://sso.scgd.co/api/usermanagement/getUserDetail?application=ncms_prd',
  // APP_LOGIN:'https://sso.scgd.co/login/SCG/ncms_prd',
  // APP_LOGOUT:'https://sso.scgd.co/revoke',
  // APP_LOGOUT_PAGE:'https://access.scg.co.th/adfs/ls/?wa=wsignout1.0',

  APP_GET_USER_DETAIL: window.location.hostname.includes("qa") ? 'https://sso-dev.scgd.co/api/usermanagement/getUserDetail?application=ncms_dev' : 'https://sso.scgd.co/api/usermanagement/getUserDetail?application=ncms_prd',
  APP_LOGIN: window.location.hostname.includes("qa") ? 'https://sso-dev.scgd.co/login/SCG/ncms_dev' : 'https://sso.scgd.co/login/SCG/ncms_prd',
  APP_LOGOUT: window.location.hostname.includes("qa") ? 'https://sso-dev.scgd.co/revoke' : 'https://sso.scgd.co/revoke',
  APP_LOGOUT_PAGE: window.location.hostname.includes("qa") ? 'https://accessdev.scg.co.th/adfs/ls/?wa=wsignout1.0' : 'https://access.scg.co.th/adfs/ls/?wa=wsignout1.0',

};
