import { MdmGetApprovalListResponseData } from '../../../../../api/models';
import { MdmGetApprovalDelegationUserResponseData } from './../../../../../api/models/mdm-get-approval-delegation-user-response-data';
import { MdmGetApprovalDelegationUserResponse } from './../../../../../api/models/mdm-get-approval-delegation-user-response';
import { UserDetailSSO } from './../../../../../models/UserDetailSSO';
import { MdmService } from './../../../../../api/services/mdm.service';
import { MdmGetMemberInfoResponseData } from './../../../../../api/models/mdm-get-member-info-response-data';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { UserApprover } from './../../rcf.models';
import { constants } from 'buffer';
import { MdmGetApprovalListResponse, RcfAllInfo } from '../../../../../api/models';

@Component({
  selector: 'app-user-approver',
  templateUrl: './user-approver.component.html',
  styleUrls: ['./user-approver.component.css'],
})
export class UserApproverComponent implements OnInit {

  @Input() userApprover!: any;
  @Input() steps!: number;
  @Input() requesterDetailMDM!: MdmGetMemberInfoResponseData;
  @Input() contractValue!: number;
  @Input() contractStatus: string = "";
  @Input() requestContractAllInfo!: RcfAllInfo[];

  @Output() approveDataChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  isDataLoading: boolean = false;
  userApproveForm: FormGroup;
  approver!: UserApprover;
  isLoading: boolean = false;
  isSearching: boolean = false;
  estimatedContractValue: number = 0;


  selectedSM: any;
  selectedPM: any;

  optionListSM: MdmGetMemberInfoResponseData[] = [];
  defaultListSM: MdmGetMemberInfoResponseData[] = [];
  optionListPM: MdmGetMemberInfoResponseData[] = [];
  defaultListPM: MdmGetMemberInfoResponseData[] = [];

  constructor(private fb: FormBuilder, private mdmService: MdmService) {
    this.userApproveForm = this.fb.group({
      sm_approver_name: [''],
      sm_approver_email: [''],
      sm_approver_company: [''],
      pm_approver_name: [''],
      pm_approver_email: [''],
      pm_approver_company: [''],
    });

  }

  ngOnInit(): void {
  }

  async selectSM(value: number) {
    this.selectedSM = [this.optionListSM[value].eFullName];
    this.isLoading = true;

    await this.mdmService
      .apiMdmGetMemberInfoByStartWithNamePost$Json({
        name: this.optionListSM[value].eFullName!,
      })
      .toPromise()
      .then(
        response => {
          this.userApproveForm.patchValue({
            sm_approver_name: this.optionListSM[value].eFullName,
            sm_approver_company: response.responseData![0].companyName,
          });

        })
      .catch(
        err => {
          console.log(err);
        });

    this.updateData(this.userApproveForm);
    this.isLoading = false;
  }

  async selectPM(value: number) {
    this.selectedPM = [this.optionListPM[value].eFullName];
    this.isLoading = true;

    await this.mdmService
      .apiMdmGetMemberInfoByStartWithNamePost$Json({
        name: this.optionListPM[value].eFullName!,
      })
      .toPromise()
      .then(
        response => {
          this.userApproveForm.patchValue({
            pm_approver_name: this.optionListPM[value].eFullName,
            pm_approver_company: response.responseData![0].companyName,
          });

        })
      .catch(
        error => {
          console.log(error);
        }
      );

    this.updateData(this.userApproveForm);
    this.isLoading = false;
  }

  async onSearchSM(value: string) {
    if (value.length >= 3) {

      this.isSearching = true;
      await this.mdmService
        .apiMdmGetMemberInfoByStartWithNamePost$Json({
          name: value,
        })
        .toPromise()
        .then(
          response => {
            var tempOptionlistSM = [];
            var memberInfoList: MdmGetMemberInfoResponseData[] = response.responseData!;
            for (var i = 0; i < memberInfoList.length; i++) {
              if (Number(memberInfoList[i].approveLevelId) <= 900 && memberInfoList[i].eFullName != this.requesterDetailMDM.eFullName) {
                let obj = {
                  eFullName: memberInfoList[i].eFullName,
                  organizationName: memberInfoList[i].organizationName,
                };

                tempOptionlistSM.push(obj);

              }
            }

            this.optionListSM = tempOptionlistSM.filter((item) => {
              var isDuplicate = this.optionListSM.includes(
                item.eFullName as MdmGetMemberInfoResponseData
              );

              if (!isDuplicate) {
                this.optionListSM.push(item.eFullName as MdmGetMemberInfoResponseData);
                return true;
              }

              return false;
            });
          })
        .catch(
          error => {

          }
        );

      this.isSearching = false;
    }
  }

  onExitSearchSM() {
    this.optionListSM = [...this.defaultListSM];
  }

  onExitSearchPM() {
    this.optionListPM = [...this.defaultListPM];
  }

  async onSearchPM(value: string) {
    if (value.length >= 3) {
      await this.mdmService
        .apiMdmGetMemberInfoByStartWithNamePost$Json({
          name: value,
        })
        .toPromise()
        .then(
          response => {

            var tempOptionlistPM = [];
            var memberInfoList: MdmGetMemberInfoResponseData[] = response.responseData!;
            for (var i = 0; i < memberInfoList.length; i++) {
              if (Number(memberInfoList[i].approveLevelId) <= 800 && memberInfoList[i].eFullName != this.requesterDetailMDM.eFullName) {
                let obj = {
                  eFullName: memberInfoList[i].eFullName,
                  organizationName: memberInfoList[i].organizationName,
                };

                tempOptionlistPM.push(obj);

              }
            }

            this.optionListPM = tempOptionlistPM.filter((item) => {
              var isDuplicate = this.optionListPM.includes(
                item.eFullName as MdmGetMemberInfoResponseData
              );

              if (!isDuplicate) {
                this.optionListPM.push(item.eFullName as MdmGetMemberInfoResponseData);
                return true;
              }

              return false;
            });

          });
    }
  }

  async ngOnChanges(change: SimpleChanges) {

    this.isLoading = true;
    if (change.hasOwnProperty('contractValue')) {
      this.estimatedContractValue = change.contractValue.currentValue;
    }
    if (change.hasOwnProperty('requestContractAllInfo') && change.requestContractAllInfo.currentValue.length != 0) {

      this.estimatedContractValue = change.requestContractAllInfo.currentValue[0].estimatedContractValueInTHB;
      if (change.requestContractAllInfo.currentValue[0].estimatedContractValueInTHB < 2000000) {
        this.selectedSM = [change.requestContractAllInfo.currentValue[0].userApproverSMName,];

        this.userApproveForm.patchValue({
          sm_approver_name: change.requestContractAllInfo.currentValue[0].userApproverSMName,
          sm_approver_company: change.requestContractAllInfo.currentValue[0].userApproverSMCompany,
        });

        this.userApproveForm.controls['pm_approver_name'].disable();
        this.userApproveForm.controls['pm_approver_company'].disable();
      }
      else {

        this.selectedSM = [change.requestContractAllInfo.currentValue[0].userApproverSMName];
        this.selectedPM = [change.requestContractAllInfo.currentValue[0].userApproverPMName];
        this.userApproveForm.patchValue({
          sm_approver_name: change.requestContractAllInfo.currentValue[0].userApproverSMName,
          sm_approver_company: change.requestContractAllInfo.currentValue[0].userApproverSMCompany,
          pm_approver_name: change.requestContractAllInfo.currentValue[0].userApproverPMName,
          pm_approver_company: change.requestContractAllInfo.currentValue[0].userApproverPMCompany
        });
      }

      this.updateData(this.userApproveForm);
    }
    if (change.hasOwnProperty('requesterDetailMDM') &&
      change.requesterDetailMDM.currentValue !== undefined &&
      this.steps === 0 &&
      this.contractStatus !== "Return") {


      await this.mdmService
        .apiMdmApprovalandDelegationListByOrganizationIdPost$Json({
          orgID: change.requesterDetailMDM.currentValue.organizationId,
        })
        .toPromise()
        .then(
          response => {
            var approverList: MdmGetApprovalListResponseData[] = response.responseData!;
            let tempOptionlistSM = [];
            let tempOptionlistPM = [];
            let tempSmInfo: any = [];
            let tempPmInfo: any = [];

            for (let i = 0; i < approverList.length; i++) {
              if (approverList[i].organizationLevelId! <= 900) {
                if (approverList[i].organizationLevelId == 900) {
                  this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                    name: approverList[i].eFullName!,
                  })
                    .subscribe(
                      (response) => {
                        this.userApproveForm.patchValue({
                          sm_approver_name: approverList[i].eFullName,
                          sm_approver_email: response.body!.responseData![0].email,
                          sm_approver_company: response.body.responseData![0].companyName,
                        });

                        this.updateData(this.userApproveForm);
                      });

                  if (approverList[i].eFullName != change.requesterDetailMDM.currentValue.eFullName) {
                    tempOptionlistSM.push(approverList[i]);
                  }

                  this.selectedSM = approverList[i].eFullName;
                }
                else if (approverList[i].organizationLevelId! <= 900 && approverList[i].eFullName != change.requesterDetailMDM.currentValue.eFullName) {
                  tempOptionlistSM.push(approverList[i]);
                }
              }

              if (approverList[i].organizationLevelId == 800) {
                this.mdmService
                  .apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                    name: approverList[i].eFullName!,
                  })
                  .subscribe((response) => {
                    this.userApproveForm.patchValue({
                      pm_approver_name: approverList[i].eFullName,
                      pm_approver_email: response.body!.responseData![0].email,
                      pm_approver_company: response.body.responseData![0].companyName,
                    });

                    this.updateData(this.userApproveForm);
                  });

                if (approverList[i].eFullName != change.requesterDetailMDM.currentValue.eFullName) {
                  tempOptionlistPM.push(approverList[i]);
                }

                this.selectedPM = [approverList[i].eFullName];

              }
              else if (approverList[i].organizationLevelId! <= 800 && approverList[i].eFullName != change.requesterDetailMDM.currentValue.eFullName) {
                tempOptionlistPM.push(approverList[i]);
              }
            }


            //เอา ApproverSM ซ้ำออก
            tempOptionlistSM.forEach(item => {
              this.optionListSM.push({
                eFullName: item.eFullName,
                organizationName: item.organizationNameE,
              });
            })

            this.optionListSM = Array.from(new Set(this.optionListSM.map(item => item.eFullName)))
              .map(fullName => {
                return this.optionListSM.find(item => item.eFullName === fullName)!;
              })



            //เช็คว่า SM ไม่เป็นคนเดียวกับ Requester ถ้าซ้ำไปเอาคนที่อยู่อีกขั้น
            if (
              this.selectedSM == change.requesterDetailMDM.currentValue.eFullName
            ) {
              for (let i = tempOptionlistSM.length - 1; i >= 0; i--) {
                if (
                  tempOptionlistSM[i].eFullName !=
                  change.requesterDetailMDM.currentValue.eFullName
                ) {
                  this.mdmService
                    .apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                      name: tempOptionlistSM[i].eFullName!,
                    })
                    .subscribe((response) => {
                      this.userApproveForm.patchValue({
                        sm_approver_name: response.body.responseData![0].eFullName,
                        sm_approver_email: response.body!.responseData![0].email,
                        sm_approver_company: response.body.responseData![0].companyName,
                      });

                      this.updateData(this.userApproveForm);
                    });

                  this.selectedSM = [tempOptionlistSM[i].eFullName];

                  break;
                }
              }
            }

            tempOptionlistPM.forEach(item => {
              this.optionListPM.push({
                eFullName: item.eFullName,
                organizationName: item.organizationNameE,
              });
            })

            this.optionListPM = Array.from(new Set(this.optionListPM.map(item => item.eFullName)))
              .map(fullName => {
                return this.optionListPM.find(item => item.eFullName === fullName)!;
              })

            //เช็คว่า PM ไม่เป็นคนเดียวกับ Requester ถ้าซ้ำไปเอาคนที่อยู่อีกขั้น
            if (
              this.selectedPM == change.requesterDetailMDM.currentValue.eFullName
            ) {
              for (let i = tempOptionlistPM.length - 1; i >= 0; i--) {
                if (
                  tempOptionlistPM[i].eFullName !=
                  change.requesterDetailMDM.currentValue.eFullName
                ) {
                  this.mdmService
                    .apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
                      name: tempOptionlistPM[i].eFullName!,
                    })
                    .subscribe((response) => {
                      this.userApproveForm.patchValue({
                        pm_approver_name: response.body.responseData![0].eFullName,
                        pm_approver_email: response.body!.responseData![0].email,
                        pm_approver_company: response.body.responseData![0].companyName,
                      });

                      this.updateData(this.userApproveForm);
                    });

                  this.selectedPM = [tempOptionlistPM[i].eFullName];

                  break;
                }
              }
            }

            this.defaultListSM = [...this.optionListSM];
            this.defaultListPM = [...this.optionListPM];

            this.updateData(this.userApproveForm);

            this.isLoading = false;
          });
    }
    this.isLoading = false;

  }

  async getMemberInfo(fullName: string) {
    await this.mdmService
      .apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
        name: fullName,
      })
      .toPromise()
      .then((response) => {
        if (response.ok) {
          return response.body;
        }

        return;
      });
  }

  updateData(form: FormGroup) {
    this.approveDataChange.emit(form);
  }

  onChangeSM() { }

  redoSM() {
    this.mdmService
      .apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
        name: this.defaultListSM[this.defaultListSM.length - 1].eFullName!,
      })
      .subscribe((response) => {
        this.userApproveForm.patchValue({
          sm_approver_name: this.defaultListSM[this.defaultListSM.length - 1].eFullName,
          sm_approver_email: response.body!.responseData![0].email,
          sm_approver_company: response.body.responseData![0].companyName,
        });
      });

    this.selectedSM = this.defaultListSM[this.defaultListSM.length - 1].eFullName;
    this.optionListSM = [...this.defaultListSM];
    this.updateData(this.userApproveForm);
  }

  redoPM() {
    this.mdmService
      .apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
        name: this.defaultListPM[this.defaultListPM.length - 1].eFullName!,
      })
      .subscribe((response) => {
        this.userApproveForm.patchValue({
          pm_approver_name: this.optionListPM[this.defaultListPM.length - 1].eFullName,
          pm_approver_email: response.body!.responseData![0].email,
          pm_approver_company: response.body.responseData![0].companyName,
        });
      });

    this.selectedPM = this.optionListPM[this.optionListPM.length - 1].eFullName;
    this.optionListPM = [...this.defaultListPM];
    this.updateData(this.userApproveForm);
  }

}
