<nz-layout class="content">
  <nz-spin [nzSpinning]="isDataLoading" nzTip="Loading..." [nzSize]="'large'">
    <nz-collapse>

      <nz-collapse-panel [nzHeader]="'Add Vendor'" [nzActive]="true">
        <form nz-form>
          <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="4" [nzSm]="6" nzRequired>Vendor</nz-form-label>
              <nz-form-control [nzXs]="8" [nzSm]="8">

                <div nz-col>
                  <nz-select nzMode="default" nzPlaceHolder="Search" [nzShowArrow]="false" nzShowSearch nzServerSearch
                    [(ngModel)]="listOfVendor" (ngModelChange)="addVendor($event)" [ngModelOptions]="{standalone: true}"
                    (nzOnSearch)="onSearch($event)" [nzNotFoundContent] [disabled]="steps != 0">

                    <ng-container *ngFor="let o of optionList; index as i;">
                      <nz-option *ngIf="!isLoading" [nzValue]="i" [nzLabel]="o.vendorCode!+' : '+o.name1">
                        <!-- {{o.vendorCode}} : {{o.name1}} -->
                        {{o.name1}}
                      </nz-option>
                    </ng-container>

                    <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Searching...
                    </nz-option>

                  </nz-select>
                </div>

              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col>
            <nz-card *ngFor="let item of listOfParentVendorData; index as i">
              <nz-table [nzBordered]="true" nzTemplateMode [nzScroll]="{x:'1000px'}">
                <thead>
                  <tr>
                    <th nzWidth="10px"></th>
                    <th nzWidth="20px">Action</th>
                    <th nzWidth="100px">Vendor Name</th>
                    <th nzWidth="100px">Vendor Code</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td [(nzExpand)]="item.expand"></td>
                    <td style="text-align: center;">
                      <button nz-button class="btn-delete" nzType="primary" nz-popconfirm
                        nzPopconfirmTitle="Sure to delete this vendor?" [nz-tooltip]="'Delete vendor'"
                        (nzOnConfirm)="deleteVendor(i)" nzDanger [disabled]="steps != 0">
                        <i nz-icon nzType="usergroup-delete" nzTheme="outline" class="icon-delete"></i>
                      </button>
                    </td>
                    <td>{{item.VendorName}}</td>
                    <td>{{item.VendorCode}}</td>
                  </tr>
                </tbody>
              </nz-table>
              <nz-table [nzBordered]="true" nzTemplateMode *ngIf="item.expand"
                [nzScroll]="{x:'300px' ,y : '300px'}">
                <thead>
                  <tr>
                    <th nzWidth="20px" style="text-align: center;">
                      <button nz-button class="btn-add" nzType="default" (click)="addChildRow(i)"
                        [nz-tooltip]="'Add email'" [disabled]="steps != 0">
                        <i nz-icon nzType="user-add" nzTheme="outline" class="icon-add"></i>
                      </button>
                    </th>
                    <th nzWidth="20px" style="text-align: center;">
                      <nz-row [nzAlign]="'middle'" [nzJustify]="'center'">
                        <nz-col>
                          Send Email
                        </nz-col>

                      </nz-row>

                      <button nz-button class="btn-checkall" nzType="default" [nzSize]="'small'" (click)="checkAll(i)"
                        [disabled]="steps != 0" [nz-tooltip]="'Check all'">
                        <i nz-icon nzType="check" nzTheme="outline" class="icon-check">
                        </i>
                      </button>
                      <button nz-button class="btn-uncheck" nzType="default" [nzSize]="'small'" (click)="unCheckAll(i)"
                        [disabled]="steps != 0" [nz-tooltip]="'Uncheck all'">
                        <i nz-icon nzType="close" nzTheme="outline" class="icon-uncheck">
                        </i>
                      </button>

                    </th>
                    <th nzWidth="100px">Email</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let data of listOfChildrenVendorData[i].vendor;index as j">
                    <td style="text-align: center;">
                      <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                        nzPopconfirmTitle="Sure to delete?" (nzOnConfirm)="deleteChildVendor(i, j)" nzDanger
                        [disabled]="steps != 0">
                        <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                        </i>
                      </button>

                      <button nz-button class="btn-edit" nzType="primary" [nzSize]="'small'"
                        (click)="modalEditConfig(tplTitle, tplContent, tplFooter, i, j)" [disabled]="steps != 0">

                        <ng-template #tplTitle>
                          <span>Edit Config</span>
                        </ng-template>

                        <ng-template #tplContent>
                          <div nz-row class="content-row">

                            <div nz-col [nzXs]="24" [nzSm]="24">
                              <nz-form-item>
                                <nz-form-label [nzSm]="6">Vendor Name</nz-form-label>
                                <nz-form-control [nzSm]="12">
                                  {{item.VendorName}}
                                </nz-form-control>

                              </nz-form-item>
                            </div>

                            <div nz-col [nzXs]="24" [nzSm]="24">
                              <nz-form-item>
                                <nz-form-label [nzSm]="6">Email</nz-form-label>
                                <nz-form-control [nzSm]="12">
                                  <input nz-input type="text" [(ngModel)]="changedEmail"
                                    [ngModelOptions]="{standalone: true}" style="width: 350px;">
                                </nz-form-control>

                              </nz-form-item>
                            </div>


                          </div>

                        </ng-template>

                        <ng-template #tplFooter let-ref="modalRef">
                          <button nz-button nzDanger (click)="modalDestroy()">Cancel</button>
                          <button nz-button nzType="primary" (click)="onChangeEmail(i, j)">Save</button>
                        </ng-template>

                        <i nz-icon nzType="edit" nzTheme="outline" class="icon-edit">
                        </i>

                      </button>
                    </td>
                    <td style="text-align: center;">
                      <label nz-checkbox [nzValue]="data.SendEmail" [(nzChecked)]="data.SendEmail"
                        (nzCheckedChange)="onCheckSendEmailChange()" [nzDisabled]="steps !== 0"></label>
                    </td>
                    <td>{{ data.Email }}</td>
                  </tr>
                </tbody>
              </nz-table>

            </nz-card>
          </div>

          <ng-template #extraTemplate>
            <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
              nzPopconfirmTitle="Sure to delete?" nzDanger>
              <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
              </i>
            </button>
          </ng-template>

        </form>
      </nz-collapse-panel>
    </nz-collapse>


  </nz-spin>
</nz-layout>
