import { MasterSourcingTeam } from './../../../../../api/models/master-sourcing-team';
import { MasterService } from './../../../../../api/services/master.service';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { endOfMonth, endOfYear, endOfWeek, startOfMonth, startOfYear, startOfWeek } from 'date-fns';

@Component({
  selector: 'app-searching-criteria',
  templateUrl: './searching-criteria.component.html',
  styleUrls: ['./searching-criteria.component.css']
})
export class SearchingCriteriaComponent implements OnInit {

  @Output() searcingDataChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();

  searchingForm: FormGroup;
  selectedSourcingTeam: any;
  selectedStatus: any;
  statusList = [
    { value: "Draft", label: "Draft" },
    { value: "Return", label: "Return" },
    { value: "Reject", label: "Reject" },
    { value: "Complete", label: "Complete" },
    { value: "Wait approver intitial approve", label: "Wait approver intitial approve" },
    { value: "Wait approver approve", label: "Wait approver approve" },
    { value: "Sourcing conclusion", label: "Sourcing conclusion" },
    { value: "Wait sourcing approver initial approve", label: "Wait sourcing approver initial approve" },
    { value: "Wait sourcing approver approve", label: "Wait sourcing approver approve" },
  ]
  sourcingTeamList: MasterSourcingTeam[] = [];
  createdDate: Date[] = [];
  ranges = {
    'Today': [new Date(), new Date()],
    'This Week': [startOfWeek(new Date()), endOfWeek(new Date())],
    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
    'This Year': [startOfYear(new Date()), endOfYear(new Date())],
    'A Month ago': [new Date(new Date().getFullYear() + "/" + new Date().getMonth() + "/" + new Date().getDate()), new Date()],
    'A Year ago': [new Date(new Date().getFullYear() - 1 + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()), new Date()],
  };

  constructor(
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private masterService: MasterService
  ) {
    this.searchingForm = this.fb.group({
      sourcing_name: [null],
      sourcing_team: [null],
      requester: [null],
      status: [null],
      startDate: [],
      endDate: [],
      createdDate: [],
    })

    // this.updateData(this.searchingForm);

  }


  ngOnInit(): void {
    //Set Default เป็นย้อนหลัง 14 วันจากปัจจุบัน
    //12096e5 หมายถึง 14 วัน
    this.createdDate = [new Date(Date.now() - 12096e5), new Date()]
    this.searchingForm.patchValue({
      startDate: this.datepipe.transform(this.createdDate[0], "yyyy-MM-dd"),
      endDate: this.datepipe.transform(this.createdDate[1], "yyyy-MM-dd")
    })
    this.getSourcingTeamFromMaster();
  }

  async getSourcingTeamFromMaster() {
    await this.masterService.apiMasterGetSourcingTeamGet$Json$Response()
      .toPromise()
      .then(response => {
        if (response.ok) {
          this.sourcingTeamList = response.body;
          // console.log("Sourcing: ", this.sourcingTeamList);
        }
      })
  }

  updateData(form: FormGroup) {
    this.searcingDataChange.emit(form);
  }

  selectSourcingTeam(value: string) {
    if(value.length == 0)
    {
      this.searchingForm.controls['sourcing_team'].setValue(null);
    }
    else
    {
      this.searchingForm.controls['sourcing_team'].setValue(value);
    }
  }

  selectStatus(value: string) {
    if(value.length == 0)
    {
      this.searchingForm.controls['status'].setValue(null);
    }
    else
    {
      this.searchingForm.controls['status'].setValue(value);
    }
  }

  onClickSearch() {
    if(this.searchingForm.controls['startDate'].value == null || this.searchingForm.controls['endDate'].value == null)
    {
      this.createdDate = [new Date(Date.now() - 12096e5), new Date()]
      this.searchingForm.patchValue({
        startDate: this.datepipe.transform(this.createdDate[0], "yyyy-MM-dd"),
        endDate: this.datepipe.transform(this.createdDate[1], "yyyy-MM-dd")
      })
    }

    this.updateData(this.searchingForm);
  }

  onClickReset()
  {
    this.searchingForm.controls['status'].setValue(null);
    this.searchingForm.controls['sourcing_team'].setValue(null);
    this.searchingForm.controls['startDate'].setValue(null);
    this.searchingForm.controls['endDate'].setValue(null);
    this.createdDate = [];
    this.selectedStatus = [];
    this.selectedSourcingTeam = [];

    this.searchingForm.reset();
    this.updateData(this.searchingForm);
  }

  onChange(result: Date[]): void {
    console.log('From: ', this.datepipe.transform(result[0], "yyyy-MM-dd"), ', to: ', this.datepipe.transform(result[1], "yyyy-MM-dd"));
    this.searchingForm.patchValue({
      startDate: this.datepipe.transform(result[0], "yyyy-MM-dd"),
      endDate: this.datepipe.transform(result[1], "yyyy-MM-dd")
    })

    // this.updateData(this.searchingForm);

  }

}
