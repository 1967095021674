/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { VendorBiddingEmailNotify } from '../models/vendor-bidding-email-notify';
import { VendorBiddingInfo } from '../models/vendor-bidding-info';
import { VendorBiddingSubmit } from '../models/vendor-bidding-submit';

@Injectable({
  providedIn: 'root',
})
export class VendorBiddingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet
   */
  static readonly ApiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGetPath = '/api/VendorBidding/ReadBiddingDetailAndItemListActiveByPkBiddingDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Plain$Response(params?: {
    pkBiddingDetail?: string;
  }): Observable<StrictHttpResponse<VendorBiddingInfo>> {

    const rb = new RequestBuilder(this.rootUrl, VendorBiddingService.ApiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGetPath, 'get');
    if (params) {
      rb.query('pkBiddingDetail', params.pkBiddingDetail, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorBiddingInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Plain(params?: {
    pkBiddingDetail?: string;
  }): Observable<VendorBiddingInfo> {

    return this.apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<VendorBiddingInfo>) => r.body as VendorBiddingInfo)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Json$Response(params?: {
    pkBiddingDetail?: string;
  }): Observable<StrictHttpResponse<VendorBiddingInfo>> {

    const rb = new RequestBuilder(this.rootUrl, VendorBiddingService.ApiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGetPath, 'get');
    if (params) {
      rb.query('pkBiddingDetail', params.pkBiddingDetail, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorBiddingInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Json(params?: {
    pkBiddingDetail?: string;
  }): Observable<VendorBiddingInfo> {

    return this.apiVendorBiddingReadBiddingDetailAndItemListActiveByPkBiddingDetailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VendorBiddingInfo>) => r.body as VendorBiddingInfo)
    );
  }

  /**
   * Path part for operation apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet
   */
  static readonly ApiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGetPath = '/api/VendorBidding/ReadBiddingDetailAndItemListActiveByUniqLink';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Plain$Response(params?: {
    UniqLink?: string;
  }): Observable<StrictHttpResponse<VendorBiddingInfo>> {

    const rb = new RequestBuilder(this.rootUrl, VendorBiddingService.ApiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGetPath, 'get');
    if (params) {
      rb.query('UniqLink', params.UniqLink, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorBiddingInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Plain(params?: {
    UniqLink?: string;
  }): Observable<VendorBiddingInfo> {

    return this.apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<VendorBiddingInfo>) => r.body as VendorBiddingInfo)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Json$Response(params?: {
    UniqLink?: string;
  }): Observable<StrictHttpResponse<VendorBiddingInfo>> {

    const rb = new RequestBuilder(this.rootUrl, VendorBiddingService.ApiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGetPath, 'get');
    if (params) {
      rb.query('UniqLink', params.UniqLink, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<VendorBiddingInfo>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Json(params?: {
    UniqLink?: string;
  }): Observable<VendorBiddingInfo> {

    return this.apiVendorBiddingReadBiddingDetailAndItemListActiveByUniqLinkGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<VendorBiddingInfo>) => r.body as VendorBiddingInfo)
    );
  }

  /**
   * Path part for operation apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost
   */
  static readonly ApiVendorBiddingUpdateBiddingDetailAndBiddingItemListPostPath = '/api/VendorBidding/UpdateBiddingDetailAndBiddingItemList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost$Response(params?: {
    body?: VendorBiddingSubmit
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VendorBiddingService.ApiVendorBiddingUpdateBiddingDetailAndBiddingItemListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost(params?: {
    body?: VendorBiddingSubmit
  }): Observable<void> {

    return this.apiVendorBiddingUpdateBiddingDetailAndBiddingItemListPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiVendorBiddingSendEmailNotifyPost
   */
  static readonly ApiVendorBiddingSendEmailNotifyPostPath = '/api/VendorBidding/SendEmailNotify';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorBiddingSendEmailNotifyPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiVendorBiddingSendEmailNotifyPost$Response(params?: {
    body?: VendorBiddingEmailNotify
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VendorBiddingService.ApiVendorBiddingSendEmailNotifyPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiVendorBiddingSendEmailNotifyPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiVendorBiddingSendEmailNotifyPost(params?: {
    body?: VendorBiddingEmailNotify
  }): Observable<void> {

    return this.apiVendorBiddingSendEmailNotifyPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiVendorBiddingSendEmailTestPost
   */
  static readonly ApiVendorBiddingSendEmailTestPostPath = '/api/VendorBidding/SendEmailTest';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiVendorBiddingSendEmailTestPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingSendEmailTestPost$Response(params?: {
    Form?: string;
    To?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, VendorBiddingService.ApiVendorBiddingSendEmailTestPostPath, 'post');
    if (params) {
      rb.query('Form', params.Form, {});
      rb.query('To', params.To, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiVendorBiddingSendEmailTestPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiVendorBiddingSendEmailTestPost(params?: {
    Form?: string;
    To?: string;
  }): Observable<void> {

    return this.apiVendorBiddingSendEmailTestPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
