import { MasterConfig } from './../../../../../../../../api/models/master-config';
import { VendorBiddingInfo } from './../../../../../../../../api/models/vendor-bidding-info';
import { NewBiddingService } from './../../../../../../../../api/services/new-bidding.service';
import { EmailDetail } from './../../../new-bidding/component/_model/TableVendorData';
import { VendorBiddingService } from './../../../../../../../../api/services';
import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MasterSimpleValue } from 'src/app/api/models';
import { NzMessageService } from 'ng-zorro-antd/message';

@Component({
  selector: 'app-vendor-email-config',
  templateUrl: './vendor-email-config.component.html',
  styleUrls: ['./vendor-email-config.component.css']
})
export class VendorEmailConfigComponent implements OnInit {

  @Input() biddingInfo: VendorBiddingInfo = {};
  @Input() biddingConfig: MasterConfig[] = [];
  @Input() notifyEmailSubjectList: MasterSimpleValue[] = []

  changedEmail: string = "";
  vendorEmailList: EmailDetail[] = [];
  notifySubject: string = "";
  emailTemplateTH: string = "";
  emailTemplateENG: string = "";

  constructor(
    private modal: NzModalService,
    private newBidding:NewBiddingService,
    private vendorBiddingService: VendorBiddingService,
    private msg:NzMessageService,
    ) { }

  ngOnInit(): void {
    console.log("", this.biddingInfo.detail?.vendorEmail);

    for (let i = 0; i < this.biddingInfo.detail?.vendorEmail?.split(",").length!; i++) {
      this.vendorEmailList = [...this.vendorEmailList,
      {
        key: i,
        Email: this.biddingInfo.detail?.vendorEmail?.split(",")[i]!,
        SendEmail: true
      }]
    }

    this.emailTemplateTH = this.biddingInfo.header?.emailMessageTh!;
    this.emailTemplateENG = this.biddingInfo.header?.emailMessageEng!;
  }

  onPreviewEmail(tplContent: TemplateRef<{}>) {
    this.modal.create({
      nzTitle: "Preview Email",
      nzContent: tplContent,
      nzComponentParams: {
        Name: this.biddingInfo.header?.sourcingName,
        Email: this.biddingInfo.header?.sourcingEmail,
        TelNo: this.biddingInfo.header?.sourcingTelNo
      },
      nzWidth: (0.8) * window.innerWidth,

    });
  }

  async sendEmail()
  {

    this.msg.loading("Sending Email");
    var toAddress: string[] = [];
    for(let i = 0; i < this.vendorEmailList.length; i++)
    {
      // toAddress.push(this.vendorEmailList[i].Email)
      if(this.vendorEmailList[i].SendEmail === true)
      {
        toAddress.push(this.vendorEmailList[i].Email);
      }
    }

    console.log("", toAddress);

    let ccEmail:string[] = [];
    ccEmail.push(this.biddingInfo.header?.sourcingEmail!);

    await this.vendorBiddingService.apiVendorBiddingSendEmailNotifyPost({
      body: {
        bodyTH: this.emailTemplateTH,
        bodyENG: this.emailTemplateENG,
        uniqLink: this.biddingInfo.detail?.uniqLink,
        ccAddress: ccEmail,
        itemLists: this.biddingInfo.itemList,
        sourcingEmail: this.biddingInfo.header?.sourcingEmail,
        sourcingName: this.biddingInfo.header?.sourcingName,
        sourcingTelNo: this.biddingInfo.header?.sourcingTelNo,
        subject: this.notifySubject,
        toAddress: toAddress,
        vendorName: this.biddingInfo.detail?.vendorName!,
        pkBiddingDetail: this.biddingInfo.detail?.pkbiddingDetail

      }
    })
      .toPromise()
      .then(
        response => {
          this.msg.success("Sent Notify");
          console.log("", response);
        }
      )
      .catch(
        error => {
          this.msg.error("Server Error");
        }
      )

    // this.newBidding.apiNewBiddingRequestSendEmailToVendorPost({
    //   CC: this.biddingInfo.header?.sourcingEmail!,
    //   To: toAddress.toString(),
    // }).toPromise()
    //   .then(
    //     response => {
    //       console.log("", response)

    //       this.msg.remove();
    //       this.msg.success("Email Sent");

    //     }
    //   )
    //   .catch(
    //     error => {
    //       console.log("", error)


    //       this.msg.remove();
    //       this.msg.error("Failed to send Email");
    //       // this.modal.error({
    //       //   nzTitle: "Error",
    //       //   nzContent: "Send Email Failed"
    //       // })
    //     }
    //   )
  }

  checkAll() {

    this.vendorEmailList.forEach(item => {
      item.SendEmail = true;
    })

  }

  unCheckAll() {

    this.vendorEmailList.forEach(item => {
      item.SendEmail = false;
    })

  }

  addChildVendor() {
    this.vendorEmailList = [...this.vendorEmailList, {

      key: 0,
      Email: "@email.com",
      SendEmail: true
    }]
  }

  deleteChildVendor(index: number) {
    this.vendorEmailList.splice(index, 1);
  }

  modalEditConfig(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, index: number) {
    this.changedEmail = this.vendorEmailList[index].Email,

      this.modal.create({
        nzTitle: tplTitle,
        nzContent: tplContent,
        nzFooter: tplFooter,
      })
  }

  modalDestroy() {
    this.modal.closeAll();
  }

  onCheckSendEmailChange() {

  }

  onChangeEmail(index: number) {
    this.vendorEmailList[index].Email = this.changedEmail;
    this.modalDestroy();
    this.changedEmail = "";
  }

}
