<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Document'" [nzActive]="true">
      <div nz-row>
        <div nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">

          <nz-form-item>
            <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>TOR Attachment</nz-form-label>

            <nz-form-control [nzXs]="6" [nzSm]="12">

              <nz-upload nzType="drag" [nzMultiple]="true" (nzChange)="onUploadChange($event)" [nzAction]="url"
                [nzAccept]="documentFileType" [nzBeforeUpload]="beforeUpload" [nzCustomRequest]="onUploadFile"
                [nzShowUploadList]="false" [nzData]="fileData" [nzSize]="documentFileSizeInByte"
                [nzOpenFileDialogOnClick]="true" [nzMultiple]="true" [nzDisabled]="steps !== 0">


                <p class="ant-upload-text">Click or drag file to this area to upload</p>
                <p class="ant-upload-hint">Limit file size {{documentFileSizeInByte/1e+6}}MB </p>
              </nz-upload>
            </nz-form-control>

            <i class="icon-tips" nz-icon nzType="question-circle" nzTheme="twotone" nz-tooltip
              [nzTooltipTitle]="contractTypeTips"></i>
            <ng-template #contractTypeTips>
              <div class="tips">
                <p> TOR : [This is Meaning of TOR]</p>
              </div>
            </ng-template>

          </nz-form-item>
        </div>

        <div class="col" nz-col nzSpan="24" [nzXs]="24" [nzSm]="24">
          <nz-table class="table-tor" #fileListTORTable [nzBordered]="true" [nzData]="fileListTOR"
            [nzScroll]="{x:'1000px', y:'400px' }" [nzShowPagination]="false" [nzFrontPagination]="false" [nzTotal]="0">
            <thead>
              <tr>
                <th nzWidth="20px">Action</th>
                <th nzWidth="80px">File Name</th>
                <th nzWidth="30px">File Size</th>
                <th nzWidth="30px">Modify Date</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let data of fileListTORTable.data;index as i" class="editable-row">
                <td style="text-align: center;">
                  <button nz-button class="btn-delete" nzType="primary" [nzSize]="'small'" nz-popconfirm
                    nzPopconfirmTitle="Sure to delete?" [nz-tooltip]="'Delete'"
                    (nzOnConfirm)="deleteItem(data.fileId!)" nzDanger [disabled]="steps != 0">

                    <i nz-icon nzType="delete" nzTheme="fill" class="icon-delete">
                    </i>
                  </button>
                  <!-- <button nz-button class="btn-download" nzType="primary" [nzSize]="'small'" ><i nz-icon nzType="download" nzTheme="outline" class="icon-download" ></i></button> -->
                </td>
                <td> <a href="{{data.fileDownloadLink}}" target="_blank">{{data.fileName}}</a></td>
                <td>{{data.fileSizeInByte!/1e+6 | number}} MB</td>
                <td>{{data.lastModifiedDate | date:"d MMM yyyy HH:mm"}}</td>
                <!-- <td></td>
                <td></td> -->
              </tr>
            </tbody>
          </nz-table>
        </div>

      </div>
    </nz-collapse-panel>
  </nz-collapse>

</nz-layout>
