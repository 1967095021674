import { MasterTheArchPurGroupMember, MasterSourcingTeam } from 'src/app/api/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { endOfMonth, endOfYear, endOfWeek, startOfMonth, startOfYear, startOfWeek } from 'date-fns';

@Component({
  selector: 'app-searching-criteria-request-contract-log',
  templateUrl: './searching-criteria-request-contract-log.component.html',
  styleUrls: ['./searching-criteria-request-contract-log.component.css']
})
export class SearchingCriteriaRequestContractLogComponent implements OnInit {

  @Input() searchingForm!: FormGroup;
  @Input() sourcingTeamList: MasterSourcingTeam[] = [];

  @Output() onClickSearch = new EventEmitter<boolean>();
  @Output() onClickReset = new EventEmitter<boolean>();

  ranges = {
    'Today': [new Date(), new Date()],
    'This Week': [startOfWeek(new Date()), endOfWeek(new Date())],
    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
    'This Year': [startOfYear(new Date()), endOfYear(new Date())],
    'A Month ago': [new Date(new Date().getFullYear() + "/" + new Date().getMonth() + "/" + new Date().getDate()), new Date()],
    'A Year ago': [new Date(new Date().getFullYear() - 1 + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()), new Date()],
  };

  createdDate!: Date[];
  constructor() { }

  ngOnInit(): void {
  }

  onSearch(){
    console.log("click Search");
    this.onClickSearch.emit(true);
  }

  onReset(){
    this.onClickReset.emit(true);
  }

  onChange(date: Date[])
  {

  }

}
