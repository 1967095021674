import { BiddingHeaderAndDetail } from './../../../../../../../api/models/bidding-header-and-detail';
import { DatePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { endOfMonth, endOfYear, endOfWeek, startOfMonth, startOfYear, startOfWeek } from 'date-fns';
import * as moment from 'moment';

@Component({
  selector: 'app-searching-summary-result',
  templateUrl: './searching-summary-result.component.html',
  styleUrls: ['./searching-summary-result.component.css']
})
export class SearchingSummaryResultComponent implements OnInit {

  @Input() searchList: BiddingHeaderAndDetail[] = [];
  @Input() deadlineWarning: boolean[] = [];

  sortDateFn = (a: BiddingHeaderAndDetail, b: BiddingHeaderAndDetail) => {
    return (moment(a.header?.createDate).diff(moment(b.header?.createDate), "days"));
  }

  expandSet = new Set<number>();

  onExpandChange(id: number, checked: boolean): void {
    if (checked) {
      this.expandSet.add(id);
    } else {
      this.expandSet.delete(id);
    }
  }
  constructor(private datepipe: DatePipe) { }

  ngOnInit(): void {
  }



}
