import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-approve-bidding-memo',
  templateUrl: './approve-bidding-memo.component.html',
  styleUrls: ['./approve-bidding-memo.component.css']
})
export class ApproveBiddingMemoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
