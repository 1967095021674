<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Bidding Detail'" [nzActive]="true">
      <form nz-form [formGroup]="biddingDetailForm">
        <div nz-row class="content-row">
          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Project Name</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <input nz-input formControlName="projectName">
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Contract Period</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-range-picker nzFormat="d MMM yyyy" [nzDisabledDate]="disabledDate" [(ngModel)]="contractPeriod"
                  (ngModelChange)="onChange($event)" [ngModelOptions]="{ standalone: true }" [nzDisabled]="steps !== 0"
                  [nzStatus]="contractPeroidInputStatus">
                </nz-range-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Deadline</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-date-picker nzFormat="d MMM yyyy" [nzDisabledDate]="disabledDate" [(ngModel)]="deadline"
                  (ngModelChange)="onChangeDeadline($event)" [ngModelOptions]="{ standalone: true }"
                  [nzDisabled]="steps !== 0" [nzStatus]="deadlineInputStatus">
                </nz-date-picker>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Matrix Template</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">
                <nz-switch [(ngModel)]="isMatrixTemplate" (ngModelChange)="onMatrixTemplateChange($event)"
                  [ngModelOptions]="{ standalone: true }" [nzDisabled]="steps !== 0"></nz-switch>

              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6">Additional Option</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="12">
                <nz-checkbox-wrapper ngDefaultControl [(ngModel)]="additionalDetailList"
                  [ngModelOptions]="{ standalone: true }" [disabled]="true"
                  (nzOnChange)="onAdditionalDetailCheckboxChange($event)">
                  <div nz-row>
                    <div nz-col nzSpan="10" *ngFor="let option of detailOption">
                      <label nz-checkbox [nzValue]="option.value" [(nzChecked)]="option.checked"
                        (nzCheckedChange)="onCheckQuotation(option.value!)" [nzDisabled]="option.disabled">
                        {{ option.label }}
                      </label>

                      <nz-range-picker *ngIf="option.label == 'Quotation validity' && option.checked == true"
                        [nzDisabled]="option.label != 'Quotation validity' && option.checked != true || steps !== 0"
                        [nzDisabledDate]="disabledDate" nzFormat="d MMM yyyy" [(ngModel)]="quotationValidityDate"
                        (ngModelChange)="onQuotationValidityDateChange($event)" [ngModelOptions]="{ standalone: true }">
                      </nz-range-picker>
                    </div>

                  </div>
                </nz-checkbox-wrapper>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Base On</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-select formControlName="baseOn">
                  <nz-option *ngFor="let item of baseOn" [nzLabel]="item.description!" [nzValue]="item.baseOn">
                  </nz-option>

                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Incoterm</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-select formControlName="incoTerm">
                  <nz-option *ngFor="let item of incotermList" [nzLabel]="item.incoterm1Name!"
                    [nzValue]="item.incoterm1Name!"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" nzRequired>Payment Term</nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8" [nzErrorTip]="errorTpl">
                <nz-select formControlName="paymentTerm">
                  <nz-option *ngFor="let item of paymentTermList" [nzLabel]="item.paymentTermDesc!"
                    [nzValue]="item.paymentTerm"></nz-option>
                </nz-select>
              </nz-form-control>
            </nz-form-item>
          </div>

        </div>
      </form>
    </nz-collapse-panel>
  </nz-collapse>
</nz-layout>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">* Require</ng-container>
  <ng-container *ngIf="control.hasError('email')">* Invalid Email</ng-container>
  <ng-container *ngIf="control.hasError('pattern') || control.hasError('minlength') || control.hasError('maxlength')">*
    Invalid Phone Number</ng-container>
</ng-template>
