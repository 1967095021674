/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BiddingHeader } from '../models/bidding-header';
import { RequestForPriceListBiddingHeaderAndDetail } from '../models/request-for-price-list-bidding-header-and-detail';

@Injectable({
  providedIn: 'root',
})
export class RequestForPriceListService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiRequestForPriceListGetBiddingHeaderByCreateDateGet
   */
  static readonly ApiRequestForPriceListGetBiddingHeaderByCreateDateGetPath = '/api/RequestForPriceList/GetBiddingHeaderByCreateDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestForPriceListService.ApiRequestForPriceListGetBiddingHeaderByCreateDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeader>> {

    return this.apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestForPriceListService.ApiRequestForPriceListGetBiddingHeaderByCreateDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeader>> {

    return this.apiRequestForPriceListGetBiddingHeaderByCreateDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * Path part for operation apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet
   */
  static readonly ApiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGetPath = '/api/RequestForPriceList/GetBiddingHeaderAndDetailByCreateDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<RequestForPriceListBiddingHeaderAndDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestForPriceListService.ApiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestForPriceListBiddingHeaderAndDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<RequestForPriceListBiddingHeaderAndDetail>> {

    return this.apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestForPriceListBiddingHeaderAndDetail>>) => r.body as Array<RequestForPriceListBiddingHeaderAndDetail>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<RequestForPriceListBiddingHeaderAndDetail>>> {

    const rb = new RequestBuilder(this.rootUrl, RequestForPriceListService.ApiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<RequestForPriceListBiddingHeaderAndDetail>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<RequestForPriceListBiddingHeaderAndDetail>> {

    return this.apiRequestForPriceListGetBiddingHeaderAndDetailByCreateDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<RequestForPriceListBiddingHeaderAndDetail>>) => r.body as Array<RequestForPriceListBiddingHeaderAndDetail>)
    );
  }

}
