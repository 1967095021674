<nz-layout class="content">

  <nz-collapse>

    <nz-collapse-panel [nzHeader]="'Select Approver'" [nzActive]="true">
      <form nz-form [formGroup]="approverForm">

        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-card [nzTitle]="'Parallel Initial Approver All (All Initial Approve)'" [nzSize]="'small'"></nz-card>
            <nz-form-item>
              <nz-form-label [nzXs]="6" [nzSm]="6" [nzNoColon]="true"></nz-form-label>
              <nz-form-control [nzXs]="6" [nzSm]="8">

                <nz-select nzPlaceHolder="Search Name">
                  <nz-option></nz-option>
                </nz-select>

              </nz-form-control>
            </nz-form-item>

          </div>


            <div nz-col [nzXs]="24" [nzSm]="24">
              <nz-card [nzTitle]="'Step by Step Initial (Choose Maximum 10 Steps)'" [nzSize]="'small'">
                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="6" [nzSm]="6" >Initial #1</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>

                    <nz-form-label [nzXs]="6" [nzSm]="4" >Company</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="6" [nzSm]="6" >Initial #2</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>

                    <nz-form-label [nzXs]="6" [nzSm]="4" >Company</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="6" [nzSm]="6" >Initial #3</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>

                    <nz-form-label [nzXs]="6" [nzSm]="4" >Company</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="6" [nzSm]="6" >Initial #i</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>

                    <nz-form-label [nzXs]="6" [nzSm]="4" >Company</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="6" [nzSm]="6" >Approval </nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>

                    <nz-form-label [nzXs]="6" [nzSm]="4" >Company</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>
                  </nz-form-item>
                </div>

                <div nz-col [nzXs]="24" [nzSm]="24">
                  <nz-form-item>
                    <nz-form-label [nzXs]="6" [nzSm]="6" >CC Email</nz-form-label>
                    <nz-form-control [nzXs]="6" [nzSm]="8">

                      <nz-select>
                        <nz-option></nz-option>
                      </nz-select>

                    </nz-form-control>

                  </nz-form-item>
                </div>

              </nz-card>


            </div>


        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>



</nz-layout>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">* Require</ng-container>
  <ng-container *ngIf="control.hasError('email')">* Invalid Email</ng-container>
  <ng-container *ngIf="control.hasError('pattern') || control.hasError('minlength') || control.hasError('maxlength')">*
    Invalid Phone Number</ng-container>
</ng-template>
