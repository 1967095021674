<nz-card [nzTitle]="'Log'">
  <nz-table #logTable nzBordered class="table-log" [nzData]="logData" [nzShowPagination]="true" [nzPageSize]="10"
  [nzShowSizeChanger]="true" [nzPageSizeOptions]="[10, 20, 50, 100]">
  <thead>
    <tr>
      <th nzWidth="60px" [nzShowSort]="true" [nzSortFn]="sortDateFn" [nzSortOrder]="'descend'">Date</th>
      <th nzWidth="100px">Request contract No</th>
      <th nzWidth="100px">Name</th>
      <th nzWidth="100px">Activity</th>
      <th nzWidth="100px">Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let data of logTable.data">
      <td>{{data.logData!.editedDate | date:"d MMM yyyy HH:mm"}}</td>
      <td>{{data.docNo}}</td>
      <td>{{data.logData!.editedByName}}</td>
      <td>{{data.logData!.activity}}</td>
      <td>{{data.logData!.action}}</td>
    </tr>
  </tbody>
</nz-table>
</nz-card>

