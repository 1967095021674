/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MasterTheArchCountry } from '../models/master-the-arch-country';
import { MasterTheArchExchangeRate } from '../models/master-the-arch-exchange-rate';
import { MasterTheArchPurGroupMember } from '../models/master-the-arch-pur-group-member';
import { MasterTheArchVendorEmailList } from '../models/master-the-arch-vendor-email-list';

@Injectable({
  providedIn: 'root',
})
export class MasterTheArchService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiMasterTheArchGetPurGroupMemberActiveGet
   */
  static readonly ApiMasterTheArchGetPurGroupMemberActiveGetPath = '/api/MasterTheArch/GetPurGroupMemberActive';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetPurGroupMemberActiveGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterTheArchPurGroupMember>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetPurGroupMemberActiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchPurGroupMember>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetPurGroupMemberActiveGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveGet$Plain(params?: {
  }): Observable<Array<MasterTheArchPurGroupMember>> {

    return this.apiMasterTheArchGetPurGroupMemberActiveGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchPurGroupMember>>) => r.body as Array<MasterTheArchPurGroupMember>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetPurGroupMemberActiveGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterTheArchPurGroupMember>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetPurGroupMemberActiveGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchPurGroupMember>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetPurGroupMemberActiveGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveGet$Json(params?: {
  }): Observable<Array<MasterTheArchPurGroupMember>> {

    return this.apiMasterTheArchGetPurGroupMemberActiveGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchPurGroupMember>>) => r.body as Array<MasterTheArchPurGroupMember>)
    );
  }

  /**
   * Path part for operation apiMasterTheArchGetPurGroupMemberGet
   */
  static readonly ApiMasterTheArchGetPurGroupMemberGetPath = '/api/MasterTheArch/GetPurGroupMember';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetPurGroupMemberGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberGet$Plain$Response(params?: {
    pkPurGroup?: string;
  }): Observable<StrictHttpResponse<Array<MasterTheArchPurGroupMember>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetPurGroupMemberGetPath, 'get');
    if (params) {
      rb.query('pkPurGroup', params.pkPurGroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchPurGroupMember>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetPurGroupMemberGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberGet$Plain(params?: {
    pkPurGroup?: string;
  }): Observable<Array<MasterTheArchPurGroupMember>> {

    return this.apiMasterTheArchGetPurGroupMemberGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchPurGroupMember>>) => r.body as Array<MasterTheArchPurGroupMember>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetPurGroupMemberGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberGet$Json$Response(params?: {
    pkPurGroup?: string;
  }): Observable<StrictHttpResponse<Array<MasterTheArchPurGroupMember>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetPurGroupMemberGetPath, 'get');
    if (params) {
      rb.query('pkPurGroup', params.pkPurGroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchPurGroupMember>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetPurGroupMemberGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberGet$Json(params?: {
    pkPurGroup?: string;
  }): Observable<Array<MasterTheArchPurGroupMember>> {

    return this.apiMasterTheArchGetPurGroupMemberGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchPurGroupMember>>) => r.body as Array<MasterTheArchPurGroupMember>)
    );
  }

  /**
   * Path part for operation apiMasterTheArchGetCountryGet
   */
  static readonly ApiMasterTheArchGetCountryGetPath = '/api/MasterTheArch/GetCountry';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetCountryGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetCountryGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterTheArchCountry>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetCountryGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchCountry>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetCountryGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetCountryGet$Plain(params?: {
  }): Observable<Array<MasterTheArchCountry>> {

    return this.apiMasterTheArchGetCountryGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchCountry>>) => r.body as Array<MasterTheArchCountry>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetCountryGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetCountryGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterTheArchCountry>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetCountryGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchCountry>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetCountryGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetCountryGet$Json(params?: {
  }): Observable<Array<MasterTheArchCountry>> {

    return this.apiMasterTheArchGetCountryGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchCountry>>) => r.body as Array<MasterTheArchCountry>)
    );
  }

  /**
   * Path part for operation apiMasterTheArchGetVendorEmailListByVendorCodePost
   */
  static readonly ApiMasterTheArchGetVendorEmailListByVendorCodePostPath = '/api/MasterTheArch/GetVendorEmailListByVendorCode';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetVendorEmailListByVendorCodePost$Plain()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMasterTheArchGetVendorEmailListByVendorCodePost$Plain$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<MasterTheArchVendorEmailList>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetVendorEmailListByVendorCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchVendorEmailList>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetVendorEmailListByVendorCodePost$Plain$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMasterTheArchGetVendorEmailListByVendorCodePost$Plain(params?: {
    body?: Array<string>
  }): Observable<Array<MasterTheArchVendorEmailList>> {

    return this.apiMasterTheArchGetVendorEmailListByVendorCodePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchVendorEmailList>>) => r.body as Array<MasterTheArchVendorEmailList>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetVendorEmailListByVendorCodePost$Json()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMasterTheArchGetVendorEmailListByVendorCodePost$Json$Response(params?: {
    body?: Array<string>
  }): Observable<StrictHttpResponse<Array<MasterTheArchVendorEmailList>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetVendorEmailListByVendorCodePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchVendorEmailList>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetVendorEmailListByVendorCodePost$Json$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiMasterTheArchGetVendorEmailListByVendorCodePost$Json(params?: {
    body?: Array<string>
  }): Observable<Array<MasterTheArchVendorEmailList>> {

    return this.apiMasterTheArchGetVendorEmailListByVendorCodePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchVendorEmailList>>) => r.body as Array<MasterTheArchVendorEmailList>)
    );
  }

  /**
   * Path part for operation apiMasterTheArchGetExchangeRatePost
   */
  static readonly ApiMasterTheArchGetExchangeRatePostPath = '/api/MasterTheArch/GetExchangeRate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetExchangeRatePost$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetExchangeRatePost$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterTheArchExchangeRate>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetExchangeRatePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchExchangeRate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetExchangeRatePost$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetExchangeRatePost$Plain(params?: {
  }): Observable<Array<MasterTheArchExchangeRate>> {

    return this.apiMasterTheArchGetExchangeRatePost$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchExchangeRate>>) => r.body as Array<MasterTheArchExchangeRate>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetExchangeRatePost$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetExchangeRatePost$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterTheArchExchangeRate>>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetExchangeRatePostPath, 'post');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterTheArchExchangeRate>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetExchangeRatePost$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetExchangeRatePost$Json(params?: {
  }): Observable<Array<MasterTheArchExchangeRate>> {

    return this.apiMasterTheArchGetExchangeRatePost$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterTheArchExchangeRate>>) => r.body as Array<MasterTheArchExchangeRate>)
    );
  }

  /**
   * Path part for operation apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet
   */
  static readonly ApiMasterTheArchGetPurGroupMemberActiveByPurGroupGetPath = '/api/MasterTheArch/GetPurGroupMemberActiveByPurGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Plain$Response(params?: {
    pkPurGroup?: string;
  }): Observable<StrictHttpResponse<MasterTheArchPurGroupMember>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetPurGroupMemberActiveByPurGroupGetPath, 'get');
    if (params) {
      rb.query('pkPurGroup', params.pkPurGroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterTheArchPurGroupMember>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Plain(params?: {
    pkPurGroup?: string;
  }): Observable<MasterTheArchPurGroupMember> {

    return this.apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<MasterTheArchPurGroupMember>) => r.body as MasterTheArchPurGroupMember)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Json$Response(params?: {
    pkPurGroup?: string;
  }): Observable<StrictHttpResponse<MasterTheArchPurGroupMember>> {

    const rb = new RequestBuilder(this.rootUrl, MasterTheArchService.ApiMasterTheArchGetPurGroupMemberActiveByPurGroupGetPath, 'get');
    if (params) {
      rb.query('pkPurGroup', params.pkPurGroup, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<MasterTheArchPurGroupMember>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Json(params?: {
    pkPurGroup?: string;
  }): Observable<MasterTheArchPurGroupMember> {

    return this.apiMasterTheArchGetPurGroupMemberActiveByPurGroupGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<MasterTheArchPurGroupMember>) => r.body as MasterTheArchPurGroupMember)
    );
  }

}
