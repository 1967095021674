import { MasterConfig } from './../../../../api/models/master-config';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { DatePipe } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalService } from 'ng-zorro-antd/modal';
import { AzureBlobService } from 'src/app/api/services';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-config-rcf',
  templateUrl: './config-rcf.component.html',
  styleUrls: ['./config-rcf.component.css']
})
export class ConfigRcfComponent implements OnInit {

  @Input() rcfConfig: MasterConfig[] = [];
  @Output() configValueChange = new EventEmitter<MasterConfig>();

  changedConfigValue: string = "";

  constructor(private modal: NzModalService,
    private azureBlobService: AzureBlobService,
    private msg: NzMessageService,
    private authService: AuthService,
    private datepipe: DatePipe) { }

  ngOnInit(): void {

  }

  modalEditConfig(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, key: string, index: number) {
    console.log(this.rcfConfig[index].key);

    this.changedConfigValue = this.rcfConfig[this.rcfConfig.findIndex(item => item.key == key)].value!;

    if (key === 'AttachmentFileSizeInByte' || key === 'OtherFileSizeInByte') {

      this.changedConfigValue = (Number(this.changedConfigValue) / 1000000).toString();
    }

    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
    })
  }

  modalDestroy() {
    this.modal.closeAll();
  }

  onChangeConfigValue(key: string) {

    if (key === 'AttachmentFileSizeInByte' || key === 'OtherFileSizeInByte') {
      this.changedConfigValue = (Number(this.changedConfigValue) * 1000000).toString();
    }

    let newConfig: MasterConfig = {
      key: key,
      value: this.changedConfigValue
    }
    this.configValueChange.emit(newConfig);

    this.modal.closeAll();
  }

}
