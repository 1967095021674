import { CKEditor4 } from 'ckeditor4-angular/ckeditor';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-bidding-edit-email',
  templateUrl: './new-bidding-edit-email.component.html',
  styleUrls: ['./new-bidding-edit-email.component.css']
})
export class NewBiddingEditEmailComponent implements OnInit {

  @Input() emailTemplateTH: string = "";
  @Input() emailTemplateENG: string = "";
  @Input() steps: number = 0;

  public editorType: CKEditor4.EditorType = CKEditor4.EditorType.CLASSIC;

  public config: CKEditor4.Config = {
    toolbarGroups: [
      { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
      { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align', 'bidi', 'paragraph'] },
      { name: 'styles', groups: ['styles'] },
      { name: 'colors', groups: ['colors'] },
    ]
  }

  constructor() { }

  ngOnInit(): void {
  }

  onSelectTap() {
    console
  }


}
