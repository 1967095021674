import { NzStatusType } from 'ng-zorro-antd/steps';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-steps-bidding',
  templateUrl: './steps-bidding.component.html',
  styleUrls: ['./steps-bidding.component.css']
})
export class StepsBiddingComponent implements OnInit {

  @Input() steps!: number;
  @Input() status!: NzStatusType;
  constructor() { }

  ngOnInit(): void {
  }

}
