<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" [nzSize]="'large'">

    <div nz-row class="row">
      <div class="panel-header">
        <nz-row>
          <nz-card class="card">
            <nz-row>
              <nz-breadcrumb>
                <nz-breadcrumb-item>

                  <a routerLink="../home">
                    <span nz-icon nzType="home"></span>
                    <span>Home</span>
                  </a>

                </nz-breadcrumb-item>

                <nz-breadcrumb-item>
                  <span>Work Station</span>
                </nz-breadcrumb-item>

                <nz-breadcrumb-item>
                  <span>Bidding worksheet</span>
                </nz-breadcrumb-item>

                <nz-breadcrumb-item>
                  <a>
                    <span>Memo</span>
                  </a>
                </nz-breadcrumb-item>

              </nz-breadcrumb>
            </nz-row>
          </nz-card>
        </nz-row>
      </div>

      <div class="panel-content">
        <div nz-row class="collapse-row">
          <div nz-col nzSpan="24" class="collapse-col">
            <app-searching-memo-criteria [searchingForm]="searchingForm" [sourcingTeamList]="sourcingTeamList"
              [purchasingGroupsList]="purchasingGroupsList"></app-searching-memo-criteria>
          </div>

          <div nz-col nzSpan="24" class="collapse-col">
            <app-searching-memo-result [searchList]="searchList"></app-searching-memo-result>
          </div>


        </div>
      </div>

    </div>
  </nz-spin>
</nz-layout>
