import { SummaryBiddingByVendor } from './../../../../../api/models/summary-bidding-by-vendor';
import { SummaryCostItemList, SummaryCostSaving } from './component/model/SummaryItemList';
import { AuthService } from './../../../../../services/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { MasterConfig } from './../../../../../api/models/master-config';
import { MasterTheArchExchangeRate } from './../../../../../api/models/master-the-arch-exchange-rate';
import { SummaryBiddingAllInfo } from './../../../../../api/models/summary-bidding-all-info';
import { SummaryBiddingItemListLastPriceAndAvg } from './../../../../../api/models/summary-bidding-item-list-last-price-and-avg';
import { ConfigurationService } from './../../../../../api/services/configuration.service';
import { MasterTheArchService } from './../../../../../api/services/master-the-arch.service';

import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { BiddingItemList, MasterCurrency, MasterIncoterm1, MasterPurGroup, MasterSimpleValue, MasterSourcingTeam, MasterTheArchCountry, SummaryBiddingItemFromBidding, SummaryBiddingItemList, SummaryVendorBiddingInfo, VChembiMstPaymentTerm, VChembiMstVendorMaster } from '../../../../../api/models';
import { MasterService, SummaryBiddingService } from '../../../../../api/services';

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})
export class SummaryComponent implements OnInit {

  isDataLoading: boolean = true;

  rejectReasonList: MasterSimpleValue[] = [];
  notifyEmailSubjectList: MasterSimpleValue[] = [];
  currencyList: MasterCurrency[] = [];
  paymentTermList: VChembiMstPaymentTerm[] = [];
  incotermList: MasterIncoterm1[] = [];
  countryList: MasterTheArchCountry[] = [];

  biddingAllInfo!: SummaryBiddingAllInfo;
  biddingDetailForm: FormGroup;
  biddingConfig: MasterConfig[] = [];
  summaryBiddingItemListFromBidding: SummaryBiddingItemFromBidding = {};
  summaryBidding: SummaryBiddingByVendor = {};
  summaryCost: SummaryCostItemList[] = [];
  summaryCostSaving!: SummaryCostSaving;
  itemList: BiddingItemList[] = [];

  exchangeRate: MasterTheArchExchangeRate[] = [];
  biddingItemListLastPriceAndAvgPrice: SummaryBiddingItemListLastPriceAndAvg[] = [];
  sourcingTeamList: MasterSourcingTeam[] = [];
  purchasingGroupsList: MasterPurGroup[] = [];
  vendorList: VChembiMstVendorMaster[] = [];
  matCode: string[] = [];

  documentNo: string = "";
  createdDate!: Date;
  biddingStartDate!: Date;
  deadLine!: Date;
  isWarnExpire: boolean = false;
  disabledSubmit: boolean = false;


  summaryItemList: SummaryBiddingItemList[] = [];

  constructor(
    private auth: AuthService,
    private masterService: MasterService,
    private masterTheArchService: MasterTheArchService,
    private summaryBiddingService: SummaryBiddingService,
    private configService: ConfigurationService,
    private router: Router,
    private route: ActivatedRoute,
    private modal: NzModalService,
    private fb: FormBuilder

  ) {
    this.biddingDetailForm = this.fb.group({
      incoterm: "",
      paymentTerm: "",
    })
  }

  async ngOnInit(): Promise<void> {
    const pkHeader = this.route.snapshot.queryParamMap.get('pkHeader');

    try {
      if (pkHeader != null) {
        await this.getSummaryBiddingAllInfo(pkHeader);
        this.biddingDetailForm.patchValue({
          incoterm: this.biddingAllInfo.header?.incoterm,
          paymentTerm: this.biddingAllInfo.header?.paymentTerm,
        })

        this.documentNo = this.biddingAllInfo.header?.docNo!;
        this.createdDate = new Date(this.biddingAllInfo.header?.createDate!);
        this.biddingStartDate = new Date(this.biddingAllInfo.header?.biddingStartDate!);
        this.deadLine = new Date(this.biddingAllInfo.header?.deadline!);

        await this.getItemListLastPriceAndAvg(this.matCode);
        await this.getSummaryBidding(pkHeader);

        if (this.biddingAllInfo.header?.status !== "Wait for summary") {
          this.disabledSubmit = true;
        }
      }

      await this.getSourcingTeam();
      await this.getPurchasingGroups();
      await this.getVendor();
      await this.getExchangeRate();
      await this.getCurrencyData();
      await this.getPaymentTermData();
      await this.getIncoTermData();
      await this.getCountryDataFromTheArch();
      await this.getSimpleValueData();
      await this.getBiddingConfig();
      await this.getBiddingPriceFromBidding();

    } catch (error) {

      console.log(error);

      this.modal.error({
        nzTitle: "Error",
        nzContent: "Server error, Please try again later.",
        nzOkText: "Close",
        nzCancelText: null,
      })
    }


    this.isDataLoading = false;
  }

  async getCurrencyData() {
    await this.masterService.apiMasterGetCurrencyGet$Json()
      .toPromise()
      .then(
        response => {
          this.currencyList = response;

          for (let i = 0; i < this.currencyList.length; i++) {
            this.currencyList[i].currency = this.currencyList[i].currency?.trimEnd();
          }

        }
      )
  }

  async getPaymentTermData() {
    await this.masterService.apiMasterGetPaymentTermGet$Json()
      .toPromise()
      .then(
        response => {
          this.paymentTermList = response;
        }
      )

  }

  async getIncoTermData() {
    await this.masterService.apiMasterGetIncotermGet$Json()
      .toPromise()
      .then(
        response => {
          this.incotermList = response;
        }
      )

  }

  async getCountryDataFromTheArch() {
    await this.masterTheArchService.apiMasterTheArchGetCountryGet$Json()
      .toPromise()
      .then(
        response => {
          this.countryList = response;
        }
      )

  }

  async getSimpleValueData() {
    await this.masterService.apiMasterGetSimpleValueByGroupGet$Json({
      groupName: "Bidding",
    }).toPromise()
      .then(
        response => {
          var simpleValue: MasterSimpleValue[] = response;

          this.notifyEmailSubjectList = simpleValue.filter(item => item.key == "NotifyEmailSubject");
          this.rejectReasonList = simpleValue.filter(item => item.key == "RejectReason");

        }
      )

  }

  async getSourcingTeam() {
    await this.masterService.apiMasterGetSourcingTeamGet$Json()
      .toPromise()
      .then(
        response => {
          this.sourcingTeamList = response
        }
      )

  }

  async getPurchasingGroups() {
    await this.masterService.apiMasterGetPurGroupGet$Json()
      .toPromise()
      .then(
        response => {
          this.purchasingGroupsList = response
        }
      )

  }

  async getVendor() {
    await this.masterService.apiMasterGetVendorGet$Json()
      .toPromise()
      .then(
        response => {
          this.vendorList = response
        }
      )

  }

  async getSummaryBiddingAllInfo(pkHeader: string) {
    await this.summaryBiddingService.apiSummaryBiddingGetSummaryBiddingAllInfoByPkBiddingHeaderGet$Json({
      pkBiddingHeader: pkHeader,
    })
      .toPromise()
      .then(
        response => {
          this.biddingAllInfo = response;
          this.biddingAllInfo.vendorBiddingInfo![0].itemList?.forEach(item => {
            this.matCode.push(item.matCode!);
          })
          console.log(this.biddingAllInfo);
        }
      )

  }

  async getSummaryBidding(pkHeader: string) {
    if(this.biddingAllInfo.header?.status === "Finish")
    {
      await this.summaryBiddingService.apiSummaryBiddingGetSummaryBiddingByFkBiddingHeaderGet$Json({
        FKBiddingHeader: pkHeader,
      })
        .toPromise()
        .then(response => {
          this.summaryBidding = response;
        })
    }
  }

  async getItemListLastPriceAndAvg(Matcode: string[]) {

    await this.summaryBiddingService.apiSummaryBiddingGetItemListLastPriceByMatCodePost$Json({
      body: Matcode,
    })
      .toPromise()
      .then(
        response => {
          this.biddingItemListLastPriceAndAvgPrice = response;
        }
      )

  }

  async getExchangeRate() {
    await this.masterTheArchService.apiMasterTheArchGetExchangeRatePost$Json()
      .toPromise()
      .then(
        response => {
          this.exchangeRate = response;
        }
      )

  }

  async getBiddingConfig() {
    await this.configService.apiConfigurationGetAllConfigByGroupGet$Json({
      Group: "Bidding"
    }).toPromise()
      .then(
        response => {
          this.biddingConfig = response;
        }
      )
  }

  async getBiddingPriceFromBidding() {
    if (this.biddingAllInfo.header?.status !== "Finish") {
      let itemList: SummaryVendorBiddingInfo[] = [];
      this.biddingAllInfo.vendorBiddingInfo?.forEach(item => {
        itemList.push(item);
      })

      await this.summaryBiddingService.apiSummaryBiddingGetItemListLastPriceFromBiddingPost$Json({
        body: itemList
      })
        .toPromise()
        .then(response => {
          console.log("LastPrice: ", response);
          this.summaryBiddingItemListFromBidding = response;
        })
    }


  }

  onSummaryBiddingItemListDataChange(value: SummaryBiddingItemList[]) {
    this.summaryItemList = value;
  }

  onSummaryCostDataChange(value: SummaryCostItemList[]) {
    this.summaryCost = value;
  }

  onSummaryCostSavingDataChange(value: SummaryCostSaving) {
    this.summaryCostSaving = value;
  }

  onItemlistDataChange(value: BiddingItemList[]) {
    this.itemList = value;
  }

  showSaveConfirm() {

  }

  showSubmitConfirm() {

    var modelSubmit = this.modal.confirm({
      nzTitle: 'Submit',
      nzContent: 'Do you want to submit this form?',
      nzOkText: 'Submit',
      nzCancelText: 'Cancel',
      nzOkLoading: false,
      nzOnOk: () => new Promise(async (resolve, reject) => {

        this.summaryBiddingService.apiSummaryBiddingCreateSummaryBiddingPost$Json({
          body: {
            files: [],
            header: {
              createDate: new Date().toJSON(),
              creatorDomain: this.auth.currentUserDomain(),
              creatorEmail: this.auth.currentUserEmail(),
              creatorName: this.auth.currectUserFullName(),
              docNo: this.biddingAllInfo.header?.docNo,
              exchangeRate: this.exchangeRate.toString(),
              fkbiddingHeader: this.biddingAllInfo.header?.pkbiddingHeader,

            },
            itemList: this.summaryItemList,
          }
        }).toPromise()
          .then(
            response => {
              modelSubmit.updateConfig({
                nzOkLoading: false,
              })

              modelSubmit.close();

              this.modal.success({
                nzTitle: "Complete",
                nzContent: "Do you want to create New MEMO?",
                nzCancelText: "No",
                nzOkText: "Create"
              })
            }
          )
      })
    });
  }

}
