import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { MasterSourcingTeam, MasterPurGroup } from '../../../../../api/models';
import { MasterService } from '../../../../../api/services';

@Component({
  selector: 'app-searching-memo',
  templateUrl: './searching-memo.component.html',
  styleUrls: ['./searching-memo.component.css']
})
export class SearchingMemoComponent implements OnInit {

  isDataLoading: boolean = false;
  searchList: any[] = [];

  searchingForm: FormGroup;
  sourcingTeamList: MasterSourcingTeam[] = [];
  purchasingGroupsList: MasterPurGroup[] = [];

  constructor(
    private fb: FormBuilder,
    private masterService: MasterService,
    ) {
    this.searchingForm = this.fb.group({
      sourcingTeam: [[]],
      purchasingGroups: [[]],
      memoNo:[[]],
      startDate: [],
      endDate: [],
      Status: [[]],
    })
  }

  async ngOnInit(): Promise<void>{
    await this.getSourcingTeamFromMaster();
    await this.getPurchasingGroupsFromMaster();

  }

  async getSourcingTeamFromMaster()
  {
    await this.masterService.apiMasterGetSourcingTeamGet$Json()
      .toPromise()
      .then(
        response => {
          this.sourcingTeamList = response;
        }
      )
  }

  async getPurchasingGroupsFromMaster()
  {
    await this.masterService.apiMasterGetPurGroupGet$Json()
      .toPromise()
      .then(
        response => {
          this.purchasingGroupsList = response;
        }
      )
  }
}
