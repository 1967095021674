/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { MasterBaseOn } from '../models/master-base-on';
import { MasterConfig } from '../models/master-config';
import { MasterSimpleValue } from '../models/master-simple-value';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiConfigurationGetAllConfigGet
   */
  static readonly ApiConfigurationGetAllConfigGetPath = '/api/Configuration/GetAllConfig';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationGetAllConfigGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterConfig>>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationGetAllConfigGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterConfig>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationGetAllConfigGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigGet$Plain(params?: {
  }): Observable<Array<MasterConfig>> {

    return this.apiConfigurationGetAllConfigGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterConfig>>) => r.body as Array<MasterConfig>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationGetAllConfigGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<MasterConfig>>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationGetAllConfigGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterConfig>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationGetAllConfigGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigGet$Json(params?: {
  }): Observable<Array<MasterConfig>> {

    return this.apiConfigurationGetAllConfigGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterConfig>>) => r.body as Array<MasterConfig>)
    );
  }

  /**
   * Path part for operation apiConfigurationGetAllConfigByGroupGet
   */
  static readonly ApiConfigurationGetAllConfigByGroupGetPath = '/api/Configuration/GetAllConfigByGroup';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationGetAllConfigByGroupGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigByGroupGet$Plain$Response(params?: {
    Group?: string;
  }): Observable<StrictHttpResponse<Array<MasterConfig>>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationGetAllConfigByGroupGetPath, 'get');
    if (params) {
      rb.query('Group', params.Group, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterConfig>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationGetAllConfigByGroupGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigByGroupGet$Plain(params?: {
    Group?: string;
  }): Observable<Array<MasterConfig>> {

    return this.apiConfigurationGetAllConfigByGroupGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterConfig>>) => r.body as Array<MasterConfig>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationGetAllConfigByGroupGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigByGroupGet$Json$Response(params?: {
    Group?: string;
  }): Observable<StrictHttpResponse<Array<MasterConfig>>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationGetAllConfigByGroupGetPath, 'get');
    if (params) {
      rb.query('Group', params.Group, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<MasterConfig>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationGetAllConfigByGroupGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationGetAllConfigByGroupGet$Json(params?: {
    Group?: string;
  }): Observable<Array<MasterConfig>> {

    return this.apiConfigurationGetAllConfigByGroupGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<MasterConfig>>) => r.body as Array<MasterConfig>)
    );
  }

  /**
   * Path part for operation apiConfigurationUpdateConfigByKeyPost
   */
  static readonly ApiConfigurationUpdateConfigByKeyPostPath = '/api/Configuration/UpdateConfigByKey';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationUpdateConfigByKeyPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationUpdateConfigByKeyPost$Response(params?: {
    key?: string;
    value?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationUpdateConfigByKeyPostPath, 'post');
    if (params) {
      rb.query('key', params.key, {});
      rb.query('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationUpdateConfigByKeyPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationUpdateConfigByKeyPost(params?: {
    key?: string;
    value?: string;
  }): Observable<void> {

    return this.apiConfigurationUpdateConfigByKeyPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiConfigurationUpdateMasterBaseOnByIdPost
   */
  static readonly ApiConfigurationUpdateMasterBaseOnByIdPostPath = '/api/Configuration/UpdateMasterBaseOnById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationUpdateMasterBaseOnByIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationUpdateMasterBaseOnByIdPost$Response(params?: {
    id?: number;
    value?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationUpdateMasterBaseOnByIdPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationUpdateMasterBaseOnByIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationUpdateMasterBaseOnByIdPost(params?: {
    id?: number;
    value?: string;
  }): Observable<void> {

    return this.apiConfigurationUpdateMasterBaseOnByIdPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiConfigurationAddBaseOnPost
   */
  static readonly ApiConfigurationAddBaseOnPostPath = '/api/Configuration/AddBaseOn';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationAddBaseOnPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConfigurationAddBaseOnPost$Response(params?: {
    body?: MasterBaseOn
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationAddBaseOnPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationAddBaseOnPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConfigurationAddBaseOnPost(params?: {
    body?: MasterBaseOn
  }): Observable<void> {

    return this.apiConfigurationAddBaseOnPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiConfigurationDeleteMasterBaseOnByIdPost
   */
  static readonly ApiConfigurationDeleteMasterBaseOnByIdPostPath = '/api/Configuration/DeleteMasterBaseOnById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationDeleteMasterBaseOnByIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationDeleteMasterBaseOnByIdPost$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationDeleteMasterBaseOnByIdPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationDeleteMasterBaseOnByIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationDeleteMasterBaseOnByIdPost(params?: {
    id?: number;
  }): Observable<void> {

    return this.apiConfigurationDeleteMasterBaseOnByIdPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiConfigurationUpdateMasterSimpleValueByIdPost
   */
  static readonly ApiConfigurationUpdateMasterSimpleValueByIdPostPath = '/api/Configuration/UpdateMasterSimpleValueById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationUpdateMasterSimpleValueByIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationUpdateMasterSimpleValueByIdPost$Response(params?: {
    id?: number;
    value?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationUpdateMasterSimpleValueByIdPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
      rb.query('value', params.value, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationUpdateMasterSimpleValueByIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationUpdateMasterSimpleValueByIdPost(params?: {
    id?: number;
    value?: string;
  }): Observable<void> {

    return this.apiConfigurationUpdateMasterSimpleValueByIdPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiConfigurationAddSimpleValuePost
   */
  static readonly ApiConfigurationAddSimpleValuePostPath = '/api/Configuration/AddSimpleValue';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationAddSimpleValuePost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConfigurationAddSimpleValuePost$Response(params?: {
    body?: MasterSimpleValue
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationAddSimpleValuePostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationAddSimpleValuePost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiConfigurationAddSimpleValuePost(params?: {
    body?: MasterSimpleValue
  }): Observable<void> {

    return this.apiConfigurationAddSimpleValuePost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiConfigurationDeleteMasterSimpleValueByIdPost
   */
  static readonly ApiConfigurationDeleteMasterSimpleValueByIdPostPath = '/api/Configuration/DeleteMasterSimpleValueById';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiConfigurationDeleteMasterSimpleValueByIdPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationDeleteMasterSimpleValueByIdPost$Response(params?: {
    id?: number;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, ConfigurationService.ApiConfigurationDeleteMasterSimpleValueByIdPostPath, 'post');
    if (params) {
      rb.query('id', params.id, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiConfigurationDeleteMasterSimpleValueByIdPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiConfigurationDeleteMasterSimpleValueByIdPost(params?: {
    id?: number;
  }): Observable<void> {

    return this.apiConfigurationDeleteMasterSimpleValueByIdPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

}
