import { NzStatus } from 'ng-zorro-antd/core/types';
import { environment } from './../../../../../../../../../environments/environment';
import { BiddingAllInfoAndLog } from './../../../../../../../../api/models/bidding-all-info-and-log';
import { BiddingAllInfo } from './../../../../../../../../api/models/bidding-all-info';
import { MasterTheArchPurGroupMember } from './../../../../../../../../api/models/master-the-arch-pur-group-member';
import { MasterSourcingTeam } from './../../../../../../../../api/models/master-sourcing-team';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MasterPurGroup } from './../../../../../../../../api/models/master-pur-group';
import { MasterTheArchService } from 'src/app/api/services';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-sourcing-contract',
  templateUrl: './sourcing-contract.component.html',
  styleUrls: ['./sourcing-contract.component.css']
})
export class SourcingContractComponent implements OnInit {

  @Input() steps: number = 0;
  @Input() selectPurGroupStatus: NzStatus = "";
  @Input() biddingAllInfo!: BiddingAllInfoAndLog;
  @Input() sourcingContractForm!: FormGroup;
  @Input() sourcingTeam: MasterSourcingTeam[] = [];
  @Input() purGroup: MasterPurGroup[] = [];
  @Input() purGroupMember: MasterTheArchPurGroupMember[] = [];

  selectedPurGroup: MasterTheArchPurGroupMember = {};

  constructor(
    private fb: FormBuilder,
    private masterTheArchService: MasterTheArchService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    console.log("prd: ",environment.production);

    if(true)
    {
      this.purGroupMember.push({
        employeeName: "Puchite Sangiamking",
        email: "2022trainees48@scg.com",
        purGroup: "Test",
        telephone: "0859150757",
      })
    }

    const pkDetail = this.route.snapshot.queryParamMap.get('pkHeader');

    if (pkDetail) {

      this.selectedPurGroup = this.purGroupMember.findIndex(item => item.purGroup === this.biddingAllInfo.header?.sourcingPurchasingGroups) as MasterTheArchPurGroupMember;

      this.sourcingContractForm.patchValue({
        purchasingGroups: this.biddingAllInfo.header?.sourcingPurchasingGroups,
        purchasingGroupsEmail: this.biddingAllInfo.header?.sourcingPurchasingGroupsEmail,
        name: this.biddingAllInfo.header?.sourcingName,
        email: this.biddingAllInfo.header?.sourcingEmail,
        telNo: this.biddingAllInfo.header?.sourcingTelNo,
      })

    }
  }

  async onSelectPurGroupChange(value: number) {

    this.sourcingContractForm.patchValue({
      sourcingTeam: this.purGroup.find(item => item.purGroup?.trimEnd() === this.purGroupMember[value].purGroup)?.sourcingTeam,
      name: this.purGroupMember[value].employeeName,
      email: this.purGroupMember[value].email,
      telNo: this.purGroupMember[value].telephone,
      purchasingGroups: this.purGroupMember[value].purGroup,
      purchasingGroupsEmail: this.purGroupMember[value].email,
    })

    console.log(this.sourcingContractForm.value);
  }



}
