/* tslint:disable */
/* eslint-disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';
import { Observable } from 'rxjs';
import { map, filter } from 'rxjs/operators';

import { BiddingAllInfoAndLog } from '../models/bidding-all-info-and-log';
import { BiddingDetail } from '../models/bidding-detail';
import { BiddingFile } from '../models/bidding-file';
import { BiddingHeader } from '../models/bidding-header';
import { BiddingRequest } from '../models/bidding-request';
import { NewRequestBiddingDocNoAndLog } from '../models/new-request-bidding-doc-no-and-log';

@Injectable({
  providedIn: 'root',
})
export class NewBiddingService extends BaseService {
  constructor(
    config: ApiConfiguration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * Path part for operation apiNewBiddingRequestGetAllBiddingHeaderGet
   */
  static readonly ApiNewBiddingRequestGetAllBiddingHeaderGetPath = '/api/NewBiddingRequest/GetAllBiddingHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestGetAllBiddingHeaderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllBiddingHeaderGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestGetAllBiddingHeaderGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestGetAllBiddingHeaderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllBiddingHeaderGet$Plain(params?: {
  }): Observable<Array<BiddingHeader>> {

    return this.apiNewBiddingRequestGetAllBiddingHeaderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestGetAllBiddingHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllBiddingHeaderGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestGetAllBiddingHeaderGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestGetAllBiddingHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllBiddingHeaderGet$Json(params?: {
  }): Observable<Array<BiddingHeader>> {

    return this.apiNewBiddingRequestGetAllBiddingHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet
   */
  static readonly ApiNewBiddingRequestGetBiddingHeaderByCreatedDateGetPath = '/api/NewBiddingRequest/GetBiddingHeaderByCreatedDate';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Plain$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestGetBiddingHeaderByCreatedDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Plain(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeader>> {

    return this.apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Json$Response(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<StrictHttpResponse<Array<BiddingHeader>>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestGetBiddingHeaderByCreatedDateGetPath, 'get');
    if (params) {
      rb.query('startDate', params.startDate, {});
      rb.query('endDate', params.endDate, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<BiddingHeader>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Json(params?: {
    startDate?: string;
    endDate?: string;
  }): Observable<Array<BiddingHeader>> {

    return this.apiNewBiddingRequestGetBiddingHeaderByCreatedDateGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<BiddingHeader>>) => r.body as Array<BiddingHeader>)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestCreateBiddingPost
   */
  static readonly ApiNewBiddingRequestCreateBiddingPostPath = '/api/NewBiddingRequest/CreateBidding';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestCreateBiddingPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNewBiddingRequestCreateBiddingPost$Response(params?: {
    body?: BiddingRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestCreateBiddingPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestCreateBiddingPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNewBiddingRequestCreateBiddingPost(params?: {
    body?: BiddingRequest
  }): Observable<void> {

    return this.apiNewBiddingRequestCreateBiddingPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestSaveRequestForPriceListPost
   */
  static readonly ApiNewBiddingRequestSaveRequestForPriceListPostPath = '/api/NewBiddingRequest/SaveRequestForPriceList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestSaveRequestForPriceListPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNewBiddingRequestSaveRequestForPriceListPost$Response(params?: {
    body?: BiddingRequest
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestSaveRequestForPriceListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestSaveRequestForPriceListPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNewBiddingRequestSaveRequestForPriceListPost(params?: {
    body?: BiddingRequest
  }): Observable<void> {

    return this.apiNewBiddingRequestSaveRequestForPriceListPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestCreateBiddingFileListPost
   */
  static readonly ApiNewBiddingRequestCreateBiddingFileListPostPath = '/api/NewBiddingRequest/CreateBiddingFileList';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestCreateBiddingFileListPost()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNewBiddingRequestCreateBiddingFileListPost$Response(params?: {
    body?: BiddingFile
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestCreateBiddingFileListPostPath, 'post');
    if (params) {
      rb.body(params.body, 'application/*+json');
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestCreateBiddingFileListPost$Response()` instead.
   *
   * This method sends `application/*+json` and handles request body of type `application/*+json`.
   */
  apiNewBiddingRequestCreateBiddingFileListPost(params?: {
    body?: BiddingFile
  }): Observable<void> {

    return this.apiNewBiddingRequestCreateBiddingFileListPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestReadBiddingHeaderGet
   */
  static readonly ApiNewBiddingRequestReadBiddingHeaderGetPath = '/api/NewBiddingRequest/ReadBiddingHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestReadBiddingHeaderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingHeaderGet$Plain$Response(params?: {
    pkBiddingHeader?: string;
  }): Observable<StrictHttpResponse<BiddingHeader>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestReadBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('pkBiddingHeader', params.pkBiddingHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BiddingHeader>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestReadBiddingHeaderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingHeaderGet$Plain(params?: {
    pkBiddingHeader?: string;
  }): Observable<BiddingHeader> {

    return this.apiNewBiddingRequestReadBiddingHeaderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BiddingHeader>) => r.body as BiddingHeader)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestReadBiddingHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingHeaderGet$Json$Response(params?: {
    pkBiddingHeader?: string;
  }): Observable<StrictHttpResponse<BiddingHeader>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestReadBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('pkBiddingHeader', params.pkBiddingHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BiddingHeader>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestReadBiddingHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingHeaderGet$Json(params?: {
    pkBiddingHeader?: string;
  }): Observable<BiddingHeader> {

    return this.apiNewBiddingRequestReadBiddingHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BiddingHeader>) => r.body as BiddingHeader)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestReadBiddingDetailGet
   */
  static readonly ApiNewBiddingRequestReadBiddingDetailGetPath = '/api/NewBiddingRequest/ReadBiddingDetail';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestReadBiddingDetailGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingDetailGet$Plain$Response(params?: {
    pkBiddingDetail?: string;
  }): Observable<StrictHttpResponse<BiddingDetail>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestReadBiddingDetailGetPath, 'get');
    if (params) {
      rb.query('pkBiddingDetail', params.pkBiddingDetail, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BiddingDetail>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestReadBiddingDetailGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingDetailGet$Plain(params?: {
    pkBiddingDetail?: string;
  }): Observable<BiddingDetail> {

    return this.apiNewBiddingRequestReadBiddingDetailGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BiddingDetail>) => r.body as BiddingDetail)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestReadBiddingDetailGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingDetailGet$Json$Response(params?: {
    pkBiddingDetail?: string;
  }): Observable<StrictHttpResponse<BiddingDetail>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestReadBiddingDetailGetPath, 'get');
    if (params) {
      rb.query('pkBiddingDetail', params.pkBiddingDetail, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BiddingDetail>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestReadBiddingDetailGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingDetailGet$Json(params?: {
    pkBiddingDetail?: string;
  }): Observable<BiddingDetail> {

    return this.apiNewBiddingRequestReadBiddingDetailGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BiddingDetail>) => r.body as BiddingDetail)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet
   */
  static readonly ApiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGetPath = '/api/NewBiddingRequest/ReadBiddingAllInfoAndLogActiveByPKBiddingHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Plain$Response(params?: {
    pkBiddingDetail?: string;
  }): Observable<StrictHttpResponse<BiddingAllInfoAndLog>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('pkBiddingDetail', params.pkBiddingDetail, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BiddingAllInfoAndLog>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Plain(params?: {
    pkBiddingDetail?: string;
  }): Observable<BiddingAllInfoAndLog> {

    return this.apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<BiddingAllInfoAndLog>) => r.body as BiddingAllInfoAndLog)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Json$Response(params?: {
    pkBiddingDetail?: string;
  }): Observable<StrictHttpResponse<BiddingAllInfoAndLog>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGetPath, 'get');
    if (params) {
      rb.query('pkBiddingDetail', params.pkBiddingDetail, {});
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<BiddingAllInfoAndLog>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Json(params?: {
    pkBiddingDetail?: string;
  }): Observable<BiddingAllInfoAndLog> {

    return this.apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<BiddingAllInfoAndLog>) => r.body as BiddingAllInfoAndLog)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestDisableDraftByPkBiddingHeaderPost
   */
  static readonly ApiNewBiddingRequestDisableDraftByPkBiddingHeaderPostPath = '/api/NewBiddingRequest/DisableDraftByPKBiddingHeader';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestDisableDraftByPkBiddingHeaderPost()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestDisableDraftByPkBiddingHeaderPost$Response(params?: {
    pkBiddingHeader?: string;
  }): Observable<StrictHttpResponse<void>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestDisableDraftByPkBiddingHeaderPostPath, 'post');
    if (params) {
      rb.query('pkBiddingHeader', params.pkBiddingHeader, {});
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: '*/*'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestDisableDraftByPkBiddingHeaderPost$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestDisableDraftByPkBiddingHeaderPost(params?: {
    pkBiddingHeader?: string;
  }): Observable<void> {

    return this.apiNewBiddingRequestDisableDraftByPkBiddingHeaderPost$Response(params).pipe(
      map((r: StrictHttpResponse<void>) => r.body as void)
    );
  }

  /**
   * Path part for operation apiNewBiddingRequestGetAllLogGet
   */
  static readonly ApiNewBiddingRequestGetAllLogGetPath = '/api/NewBiddingRequest/GetAllLog';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestGetAllLogGet$Plain()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllLogGet$Plain$Response(params?: {
  }): Observable<StrictHttpResponse<Array<NewRequestBiddingDocNoAndLog>>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestGetAllLogGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'text',
      accept: 'text/plain'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NewRequestBiddingDocNoAndLog>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestGetAllLogGet$Plain$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllLogGet$Plain(params?: {
  }): Observable<Array<NewRequestBiddingDocNoAndLog>> {

    return this.apiNewBiddingRequestGetAllLogGet$Plain$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NewRequestBiddingDocNoAndLog>>) => r.body as Array<NewRequestBiddingDocNoAndLog>)
    );
  }

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `apiNewBiddingRequestGetAllLogGet$Json()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllLogGet$Json$Response(params?: {
  }): Observable<StrictHttpResponse<Array<NewRequestBiddingDocNoAndLog>>> {

    const rb = new RequestBuilder(this.rootUrl, NewBiddingService.ApiNewBiddingRequestGetAllLogGetPath, 'get');
    if (params) {
    }

    return this.http.request(rb.build({
      responseType: 'json',
      accept: 'text/json'
    })).pipe(
      filter((r: any) => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<NewRequestBiddingDocNoAndLog>>;
      })
    );
  }

  /**
   * This method provides access to only to the response body.
   * To access the full response (for headers, for example), `apiNewBiddingRequestGetAllLogGet$Json$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  apiNewBiddingRequestGetAllLogGet$Json(params?: {
  }): Observable<Array<NewRequestBiddingDocNoAndLog>> {

    return this.apiNewBiddingRequestGetAllLogGet$Json$Response(params).pipe(
      map((r: StrictHttpResponse<Array<NewRequestBiddingDocNoAndLog>>) => r.body as Array<NewRequestBiddingDocNoAndLog>)
    );
  }

}
