import { FormGroup, FormBuilder } from '@angular/forms';
import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { RcfAllInfo } from '../../../../../../app/api/models';

@Component({
  selector: 'app-conclusion',
  templateUrl: './conclusion.component.html',
  styleUrls: ['./conclusion.component.css']
})
export class ConclusionComponent implements OnInit {

  @Input() steps!: number;
  @Input() requestContractAllInfo!: RcfAllInfo[];
  @Output() conclusionDataChange = new EventEmitter<FormGroup>();

  conclusionForm: FormGroup;
  conclusionOption = [
    { label: "Spending มากกว่า 2 ล้านบาท/ปี", value: "spending more than 2 million baht pers years", checked: false },
    { label: "Transaction มากกว่า 12 ครั้ง/ปี", value: "transaction more than 12 times pers years", checked: false },
    { label: "Direct Procurement มากกว่า 2 ครั้ง/ปี", value: "direct_procurement more than 2 times pers years", checked: false },
    { label: "อื่นๆ", value: "other", checked: false },
  ];

  conclusionOther: string | null = "";
  conclusion_reason_select: string[] = [];
  isCheckOther: boolean = false;
  constructor(private fb: FormBuilder) {
    this.conclusionForm = this.fb.group({
      isConclusionsAccept: [null],
      conclusionReason: [null],
      conclusionAcceptReasonOther: [null],
      conclusionDeniedReason: [null],
    })
  }

  ngOnInit(): void {
  }

  updateData(value: any) {

  }

  async ngOnChanges(change: SimpleChanges) {
    if (change.requestContractAllInfo.currentValue.length != 0) {
      this.conclusionForm.controls['isConclusionsAccept'].setValue(change.requestContractAllInfo.currentValue[0].isConclusionsAccept);
      // console.log("Reason: ",change.requestContractAllInfo.currentValue[0].conclusionAcceptReason.split(",").length);
      if (change.requestContractAllInfo.currentValue[0].isConclusionsAccept) {
        let reasonArray: string[] = [];
        if (change.requestContractAllInfo.currentValue[0].conclusionAcceptReason.split(",").length != 0 && change.requestContractAllInfo.currentValue[0].conclusionAcceptReason != null) {
          change.requestContractAllInfo.currentValue[0].conclusionAcceptReason.replace(/other/, "");
          for (let i = 0; i < change.requestContractAllInfo.currentValue[0].conclusionAcceptReason.split(",").length; i++) {

            if (change.requestContractAllInfo.currentValue[0].conclusionAcceptReason.split(",")[i] == this.conclusionOption[i].value) {
              if ((change.requestContractAllInfo.currentValue[0].conclusionAcceptReason.split(",")[i] == "other")) {
                this.isCheckOther = true;
                this.conclusionOther = change.requestContractAllInfo.currentValue[0].conclusionAcceptReasonOther;
              }

              this.conclusionOption[i].checked = true;
            }

          }

          console.log("Reason: ", this.conclusion_reason_select);
        }
        this.conclusionForm.disable();
      }
      else if (change.requestContractAllInfo.currentValue[0].isConclusionsAccept == false) {
        console.log("Conclusion Deniend : ", change.requestContractAllInfo.currentValue[0].conclusionDeniedReason);
        this.conclusionForm.controls['conclusionDeniedReason'].setValue(change.requestContractAllInfo.currentValue[0].conclusionDeniedReason);
        this.conclusionForm.disable();
      }
    }

  }

  onCheckboxChange(value: string[]) {
    this.conclusionForm.controls['conclusionReason'].setValue(value);
    this.conclusionDataChange.emit(this.conclusionForm);
  }

  onCheckOther(value: string) {
    if (value == "other") {
      this.conclusionOther = null;
      if (this.isCheckOther) {
        this.isCheckOther = false;
      }
      else {
        this.isCheckOther = true;
      }
    }

  }

  onConclusionChange() {
    // this.isCheckOther = true;
    if (this.conclusionForm.controls['isConclusionsAccept'].value == true) {
      this.conclusionForm.controls['conclusionDeniedReason'].setValue(null);
    }
    else {
      console.log("Checked false");
      this.conclusionOption.forEach(element => {
        element.checked = false;
      });
      this.conclusion_reason_select = [];
      this.isCheckOther = false;
      this.conclusionForm.controls['conclusionReason'].setValue(null);
      this.conclusionForm.controls['conclusionAcceptReasonOther'].setValue(null);
    }

    this.conclusionDataChange.emit(this.conclusionForm);
  }

  onConclusionOtherChange(value: string) {
    this.conclusionForm.controls['conclusionAcceptReasonOther'].setValue(value);
    this.conclusionDataChange.emit(this.conclusionForm);
  }
  onConclusionReasonChange(value: Event) {
    this.conclusionForm.controls['conclusionDeniedReason'].setValue((value.target as HTMLInputElement).value);
    this.conclusionDataChange.emit(this.conclusionForm);

  }
}
