import { AuthService } from 'src/app/services/auth.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { Rcfheader } from './../../../api/models/rcfheader';
import { UserDetailSSO } from './../../../models/UserDetailSSO';
import { DashboardService } from './../../../api/services/dashboard.service';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { RcfHeaderService } from './../../../api/services/rcf-header.service';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { isCheckDisabled } from 'ng-zorro-antd/core/tree';
import { SearchTableColumn } from './component/model/SearchResultColumn';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css'],
})
export class DashboardComponent implements OnInit {
  searchingForm!: FormGroup;
  segmentOptions = ['RCF', 'Bidding', 'Suggest'];
  pageSize: number[] = [10, 20, 50, 100];
  listOfColumn: SearchTableColumn[] = [
    {
      name: 'RCF No',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.docNo!.localeCompare(b.docNo!)!,
      priority: 1,
      width: '200px',
      nzLeft: true,
    },
    {
      name: 'Create Date',
      sortOrder: 'descend',
      sortFn: (a: Rcfheader, b: Rcfheader) =>
        new Date(a.createdDate!).valueOf() - new Date(b.createdDate!).valueOf(),
      priority: 2,
      width: '150px',
      nzLeft: true,
    },
    {
      name: 'Status',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.status?.localeCompare(b.status!)!,
      priority: false,
      width: '250px',
      nzLeft: true,
    },
    {
      name: 'Requester Name',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.requesterName?.localeCompare(b.requesterName!)!,
      priority: false,
      width: '200px',
      nzLeft: false,
    },
    {
      name: 'Sourcing Team',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.sourcingTeamName?.localeCompare(b.sourcingTeamName!)!,
      priority: false,
      width: '200px',
      nzLeft: false,
    },
    {
      name: 'Work Type',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.workType?.localeCompare(b.workType!)!,
      priority: false,
      width: '150px',
      nzLeft: false,
    },
    {
      name: 'Contract Name',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.creatorName?.localeCompare(b.creatorName!)!,
      priority: false,
      width: '250px',
      nzLeft: false,
    },
    {
      name: 'Estimated Contract Peroid',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.contractPeriod! - b.contractPeriod!,
      priority: 3,
      width: '150px',
      nzLeft: false,
    },
    {
      name: 'Estimated Contract Value',
      sortOrder: null,
      sortFn: (a: Rcfheader, b: Rcfheader) => a.estimatedContractValueInThb! - b.estimatedContractValueInThb!,
      priority: 4,
      width: '200px',
      nzLeft: false,
    },
    {
      name: 'Bidding No',
      sortOrder: null,
      sortFn: null,
      priority: false,
      width: '150px',
      nzLeft: false,
    },
    {
      name: 'Contract No',
      sortOrder: null,
      sortFn: null,
      priority: false,
      width: '150px',
      nzLeft: false,
    },
  ];

  checkedRCF: boolean = false;
  searchList: Rcfheader[] = [];
  isDataLoading: boolean = false;
  userDetailSSO: UserDetailSSO;
  constructor(
    private rcfHeaderservice: RcfHeaderService,
    private dashboardService: DashboardService,
    private modal: NzModalService,
    private datepipe: DatePipe,
    private router: Router,
    private auth: AuthService
  ) {
    this.userDetailSSO = JSON.parse(localStorage.getItem('user_data') || '{}');
  }

  ngOnInit(): void { }

  handleSegmentChange(e: number): void {
    console.log(e);
  }

  async onSearchingFormChange(form: FormGroup) {

    this.isDataLoading = true;
    await this.dashboardService
      .apiDashboardGetByCriteriaGet$Json$Response({
        startDate: form.value.startDate,
        endDate: form.value.endDate,
      })
      .toPromise()
      .then((response) => {
        if (response.ok) {
          let obj = [];
          // console.log("Response all search: ",response.body);
          for (let i = 0; i < response.body.length; i++) {

            if (this.auth.currentUserRole() !== 'Procurement Admin') {
              if (response.body[i].status == 'Draft') {
                if (
                  response.body[i].requesterName ===
                  this.auth.currentUserFullName()
                ) {
                  obj.push(response.body[i]);
                }
              } else {
                obj.push(response.body[i]);
              }
            } else {
              obj.push(response.body[i]);
            }
          }

          this.searchList = obj;
          // console.log("Status ", form.value.status);
          let searchingFileterList: Rcfheader[] = [];
          if (
            form.value.status == null &&
            form.value.sourcing_team == null &&
            form.value.requester == null
          ) {
            // console.log("SEARCHING ALL");
            this.searchList = obj;

            this.searchList.forEach((item) => {
              if (item.status == 'Complete') {
                item = Object.assign(item, { checked: false, disabled: false });
              } else {
                item = Object.assign(item, { checked: false, disabled: true });
              }
            });

            console.log('Search list ', this.searchList);

            this.isDataLoading = false;
          } else {
            if (form.value.status && form.value.status.length != 0) {
              // console.log("SEARCHING ON STATUS");
              let searchingFileterResult: Rcfheader[] = [];

              for (let i = 0; i < form.value.status.length; i++) {
                let tempSearcingList: Rcfheader[] = obj;
                for (let j = 0; j < tempSearcingList.length; j++) {
                  if (form.value.status[i] == tempSearcingList[j].status) {
                    searchingFileterResult.push(tempSearcingList[j]);
                  }
                }
              }
              searchingFileterList = searchingFileterList.concat(
                searchingFileterResult
              );
            }
            if (
              form.value.sourcing_team &&
              form.value.sourcing_team.length != 0
            ) {
              // console.log("SEARCHING ON SOURCING TEAM");
              let searchingFileterResult: Rcfheader[] = [];
              for (let i = 0; i < form.value.sourcing_team.length; i++) {
                let tempSearcingList: Rcfheader[] = obj;
                for (let j = 0; j < tempSearcingList.length; j++) {
                  if (form.value.sourcing_team[i] == tempSearcingList[j].sourcingTeamName) {
                    searchingFileterResult.push(tempSearcingList[j]);
                  }
                }
              }
              searchingFileterList = searchingFileterList.concat(searchingFileterResult);
            }
            if (form.value.requester) {
              // console.log("SEARCHING ON Requester");
              let searchingFileterResult: Rcfheader[] = [];
              searchingFileterResult = obj.filter((item) => item.requesterName?.toLowerCase().startsWith(form.value.requester.toLowerCase()));
              searchingFileterList = searchingFileterList.concat(searchingFileterResult);
            }

            this.searchList = searchingFileterList;

            this.searchList = Array.from(
              new Set(this.searchList.map((item) => item.pkRcfheader))
            ).map((pk) => {
              return this.searchList.find((item) => item.pkRcfheader === pk)!;
            });

            this.searchList.forEach((item) => {
              if (item.status == 'Complete') {
                item = Object.assign(item, { checked: false, disabled: false });
              } else {
                item = Object.assign(item, { checked: false, disabled: true });
              }
            });

            this.isDataLoading = false;
          }
        }
      });
  }

  onCheckedChange(event: boolean, index: number) {
  }

  goToPageRcfByPk(value: string) {
    this.router.navigate(['../rcf'], { queryParams: { pk: value } });
  }
}
