/* tslint:disable */
/* eslint-disable */
import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationParams } from './api-configuration';

import { AuthenticationService } from './services/authentication.service';
import { AzureBlobService } from './services/azure-blob.service';
import { ConfigurationService } from './services/configuration.service';
import { DashboardService } from './services/dashboard.service';
import { MasterService } from './services/master.service';
import { MasterTheArchService } from './services/master-the-arch.service';
import { MdmService } from './services/mdm.service';
import { NewBiddingService } from './services/new-bidding.service';
import { OidcService } from './services/oidc.service';
import { RcfHeaderService } from './services/rcf-header.service';
import { RequestContractAllService } from './services/request-contract-all.service';
import { RequestForPriceListService } from './services/request-for-price-list.service';
import { SummaryBiddingService } from './services/summary-bidding.service';
import { VendorBiddingService } from './services/vendor-bidding.service';

/**
 * Module that provides all services and configuration.
 */
@NgModule({
  imports: [],
  exports: [],
  declarations: [],
  providers: [
    AuthenticationService,
    AzureBlobService,
    ConfigurationService,
    DashboardService,
    MasterService,
    MasterTheArchService,
    MdmService,
    NewBiddingService,
    OidcService,
    RcfHeaderService,
    RequestContractAllService,
    RequestForPriceListService,
    SummaryBiddingService,
    VendorBiddingService,
    ApiConfiguration
  ],
})
export class ApiModule {
  static forRoot(params: ApiConfigurationParams): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: params
        }
      ]
    }
  }

  constructor( 
    @Optional() @SkipSelf() parentModule: ApiModule,
    @Optional() http: HttpClient
  ) {
    if (parentModule) {
      throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
    }
    if (!http) {
      throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
      'See also https://github.com/angular/angular/issues/20575');
    }
  }
}
