import { BiddingAllInfo } from './../../../../../../../api/models/bidding-all-info';
import { MasterTheArchExchangeRate } from './../../../../../../../api/models/master-the-arch-exchange-rate';
import { NzModalService } from 'ng-zorro-antd/modal';
import { BiddingItemList } from './../../../../../../../api/models/bidding-item-list';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { SummaryBiddingAllInfo, SummaryBiddingByVendor, SummaryBiddingItemFromBidding, SummaryBiddingItemList, SummaryBiddingItemListLastPriceAndAvg } from './../../../../../../../../app/api/models';
import { SummaryCostItemList, SummaryCostSaving, Total } from '../model/SummaryItemList';
import * as htmlToImage from 'html-to-image';
import * as XLSX from 'xlsx'

@Component({
  selector: 'app-summary-item-list',
  templateUrl: './summary-item-list.component.html',
  styleUrls: ['./summary-item-list.component.css']
})
export class SummaryItemListComponent implements OnInit {

  @ViewChild('summaryPrice') table!: ElementRef;

  @Input() biddingAllInfo: SummaryBiddingAllInfo = {};
  @Input() biddingItemListLastPriceAndAvgPrice: SummaryBiddingItemListLastPriceAndAvg[] = [];
  @Input() exchangeRate: MasterTheArchExchangeRate[] = [];
  @Input() summaryBiddingItemListFromBidding: SummaryBiddingItemFromBidding = {};
  @Input() summaryBidding: SummaryBiddingByVendor = {};

  @Output() summaryBiddingItemListDataChange = new EventEmitter<SummaryBiddingItemList[]>();
  @Output() summaryCostDataChange = new EventEmitter<SummaryCostItemList[]>();
  @Output() summaryCostSavingDataChange = new EventEmitter<SummaryCostSaving>();
  @Output() itemListDataChange = new EventEmitter<BiddingItemList[]>();

  summaryItemList: SummaryBiddingItemList[] = [];
  itemList: BiddingItemList[] = [];
  vendorItemList: BiddingItemList[] = [];
  summaryCost: SummaryCostItemList[] = [];
  summaryCostSaving: SummaryCostSaving;
  width: string = '600px';
  totalCAandCR: Total[] = [];
  AlltotalCAValue: number = 0;
  AlltotalCAPercent: number = 0;
  AlltotalCRValue: number = 0;
  AlltotalCRPercent: number = 0;

  colorCode = ["#E5E7E9", "#D7DBDD", "#CACFD2", "#CACFD2", "#CACFD2", "#BDC3C7", "#A6ACAF"]

  formatterNumber = (value: number): string => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  constructor(
    private modal: NzModalService,
  ) {
    this.summaryCostSaving = {
      totalCA: 0,
      totalCR: 0,
      totalPrice: 0,
    }
  }


  ngOnInit(): void {

    this.biddingAllInfo.vendorBiddingInfo![0].itemList?.forEach(item => {
      this.itemList = [...this.itemList, item];

      this.totalCAandCR.push({
        totalCAValue: 0,
        totalCAPercent: 0,
        totalCRValue: 0,
        totalCRPercent: 0,
      })

    })

    let index = 0;
    this.biddingAllInfo.vendorBiddingInfo?.forEach(item => {
      this.summaryCost.push({
        item: [],
        totalFirstPrice: 0,
        totalAvgPrice: 0,
        totalLastPrice: 0,
        totalPrice: 0,
        totalCA: 0,
        totalCR: 0,
      })

      let j = 0;

      item.itemList?.forEach(itemlist => {

        if (!itemlist.lastPrice) {
          itemlist.lastPrice = 0;
        }

        if (itemlist.pricePerUnit) {

          if (itemlist.currency !== 'THB') {

            if (itemlist.pricePerUnit) {

              itemlist.pricePerUnit = itemlist.pricePerUnit! * (this.exchangeRate[this.exchangeRate.findIndex(exchange =>
                exchange.fromCurr === itemlist.currency)] || 0).exchangeRate1!;

              itemlist.pricePerUnit = Number(itemlist.pricePerUnit.toFixed(2));

              this.summaryCost[index].totalPrice += itemlist.pricePerUnit;
              this.summaryCost[index].totalFirstPrice += itemlist.pricePerUnit;
              this.summaryCost[index].totalLastPrice += itemlist.lastPrice!;

              this.summaryCost[index].item.push({
                finalPricePerUnit: itemlist.pricePerUnit,
                allocation: 0,
                allocationLeft: 100,
                allocationStatus: "",
                costAvoidancePercent: 0,
                costAvoidanceValue: 0,
                costReductionPercent: 0,
                costReductionValue: 0,
              })
            }
          }
          else {

            this.summaryCost[index].totalPrice += itemlist.pricePerUnit;
            this.summaryCost[index].totalFirstPrice += itemlist.pricePerUnit;
            this.summaryCost[index].totalLastPrice += itemlist.lastPrice!;

            this.summaryCost[index].item.push({
              finalPricePerUnit: Number(itemlist.pricePerUnit!.toFixed(2)),
              allocation: 0,
              allocationLeft: 100,
              allocationStatus: "",
              costAvoidancePercent: 0,
              costAvoidanceValue: 0,
              costReductionPercent: 0,
              costReductionValue: 0,
            })
          }
        }
        else {

          this.summaryCost[index].item.push({
            finalPricePerUnit: 0,
            allocation: 0,
            allocationLeft: 100,
            allocationStatus: "",
            costAvoidancePercent: 0,
            costAvoidanceValue: 0,
            costReductionPercent: 0,
            costReductionValue: 0,
          })
        }
        j++;
      })
      index++;
    })

    this.summaryCost.forEach(cost => {
      this.summaryCostSaving.totalCA += cost.totalCA;
      this.summaryCostSaving.totalCR += cost.totalCR;
      this.summaryCostSaving.totalPrice += cost.totalPrice;
    })

    this.summaryCostDataChange.emit(this.summaryCost);
    this.summaryCostSavingDataChange.emit(this.summaryCostSaving);
    this.itemListDataChange.emit(this.itemList);
    this.summaryBiddingItemListDataChange.emit(this.summaryItemList);

    if (this.biddingAllInfo.header?.status === "Finish") {
      for (let i = 0; i < this.summaryCost.length; i++) {
        for (let j = 0; j < this.summaryCost[i].item.length; j++) {
          this.summaryCost[i].item[j].allocation = this.summaryBidding.vendors![i].itemList![j].allocationAward!;
          this.summaryCost[i].item[j].finalPricePerUnit = this.summaryBidding.vendors![i].itemList![j].finalPerPriceUnitInThb!;
          this.onChangeValue(i, j);
        }
      }
    }
  }

  onItemListValueChange(vendor: number, index: number) {
    this.summaryCost[vendor].totalPrice = 0;
    this.summaryCost[vendor].item.forEach(item => {
      this.summaryCost[vendor].totalPrice += item.finalPricePerUnit;
    })

    this.onChangeValue(vendor, index);
  }

  onViewExchangeRate(tplContent: TemplateRef<{}>,) {

    this.modal.create({
      nzTitle: 'Exchange Rate',
      nzContent: tplContent,
      nzOkText: null,
      nzWidth: (0.5) * window.innerWidth,

    })
  }

  onChangeValue(vendor: number, index: number) {
    //Key : Mat code & Item Name & Unit & Vendor (ใช้หาราคาครั้งก่อน และราคาครั้งสุดท้าย)​

    //CA
    //CA = Avg (ราคาครั้งก่อน แต่ละ Vendor) – Final unit price​
    //CA Value = CA*%Allocation​
    //CA % =(((Final Unit Price-CA Value) / Final Unit Price)*100)​

    //CR
    //CR = if (avg last price = 0), Min (First unit price แต่ละ Vendor) – Final Unit prices , (avg last price – final unit price) ​
    //CR Value = CR*%Allocation​
    //CR % =(((Final Unit Price-CR Value)/Final Unit Price)*100)​

    // Avg(LastPrice per vendor) - Final price per vendor
    let allocation: number = 0;
    let tempAllocation: number = 0;

    // allocation + ตามที่กรอก ถ้าไม่ได้กรอก = 0
    this.summaryCost.forEach(item => {
      allocation += item.item[index].allocation ? item.item[index].allocation! : 0;

      if (allocation < 100) {
        tempAllocation += item.item[index].allocation ? item.item[index].allocation! : 0;
      }

    });

    let tempAllocationLeft = (100 - allocation);

    //กรอก allocation เกิน 100
    this.summaryCost.forEach((item, i) => {
      if (tempAllocationLeft < 0) {

        if (item.item[index].allocation! > 0) {

          this.summaryCost[vendor].item[index].allocationStatus = "error";
          item.item[index].allocationLeft = 0;
        }
      }
      else {
        this.summaryCost[vendor].item[index].allocationStatus = "";
        item.item[index].allocationLeft = tempAllocationLeft;
      }
    });

    //ถ้า Avg Price = 0; -> CA = LastPrice
    if (this.biddingItemListLastPriceAndAvgPrice[index].averageLastPice === 0) {

      let Allocation: number = this.summaryCost[vendor].item[index].allocation!;
      let FirstPrice: number = this.biddingAllInfo.vendorBiddingInfo![vendor].itemList![index].pricePerUnit!;
      let FinalPrice: number = this.summaryCost[vendor].item[index].finalPricePerUnit;

      //CA = Final Price
      let CA: number = FinalPrice;
      //CA Value = CA * Allocate/100
      this.summaryCost[vendor].item[index].costAvoidanceValue = (CA * (Allocation / 100));
      //CA % = ((Final Price-CA Value)/Final Price)*100
      this.summaryCost[vendor].item[index].costAvoidancePercent = (((FinalPrice - this.summaryCost[vendor].item[index].costAvoidanceValue!) / FinalPrice) * 100);
    }
    //ถ้า Avg Price != 0 -> CA = AVG - LastPrice
    else {
      //CA = (0 = Avg LastPrice)
      let ca: number = (0 - this.summaryCost[vendor].item[index].finalPricePerUnit);
      //CA Value = CA * Allocate/100
      this.summaryCost[vendor].item[index].costAvoidanceValue = ca * (this.summaryCost[vendor].item[index].allocation! / 100);
      //CA % = ((Final Price-CA Value)/Final Price)*100
      this.summaryCost[vendor].item[index].costAvoidancePercent = (((this.summaryCost[vendor].item[index].finalPricePerUnit - this.summaryCost[vendor].item[index].costAvoidanceValue!) / this.summaryCost[vendor].item[index].finalPricePerUnit!) * 100);
    }

    //ถ้า Avg LastPrice = 0 , หา Min First Price
    if (this.biddingItemListLastPriceAndAvgPrice[index].averageLastPice === 0) {

      let Allocation: number = this.summaryCost[vendor].item[index].allocation!;
      let FirstPrice: number = this.biddingAllInfo.vendorBiddingInfo![vendor].itemList![index].pricePerUnit!;
      let FinalPrice: number = this.summaryCost[vendor].item[index].finalPricePerUnit;

      // CR = First Price - Final Price
      let CR: number = FirstPrice - FinalPrice;
      // CR Value = CR * allocate/100
      this.summaryCost[vendor].item[index].costReductionValue = (CR * (Allocation / 100));
      // CR % = ((Final Price - CR Value)/ Final Price) * 100
      this.summaryCost[vendor].item[index].costReductionPercent = (((FinalPrice - this.summaryCost[vendor].item[index].costReductionValue!) / FinalPrice) * 100);
    }
    else {

      let Allocation: number = this.summaryCost[vendor].item[index].allocation!;
      let FinalPrice: number = this.summaryCost[vendor].item[index].finalPricePerUnit;
      let AvgLastPrice: number = this.biddingItemListLastPriceAndAvgPrice[index].averageLastPice!;

      // CR = Avg LastPrice - Final Price
      let CR: number = AvgLastPrice - FinalPrice;
      // CR Value = CR * allocate/100
      this.summaryCost[vendor].item[index].costReductionValue = (CR * (Allocation / 100));
      // CR % = ((Final Price - CR Value)/ Final Price) * 100
      this.summaryCost[vendor].item[index].costReductionPercent = (((FinalPrice - this.summaryCost[vendor].item[index].costReductionValue!) / FinalPrice) * 100);
    }

    this.summaryCost[vendor].totalCA = 0;
    this.summaryCost[vendor].totalCR = 0;

    //คิด Total CA, CR
    this.summaryCost[vendor].item.forEach((item, i) => {
      this.summaryCost[vendor].totalCA += item.costAvoidanceValue!;
      this.summaryCost[vendor].totalCR += item.costReductionValue!;
    })

    //เปลี่ยน totalCA, CR = 0
    this.totalCAandCR.forEach(item => {
      item.totalCAValue = 0;
      item.totalCAPercent = 0;
      item.totalCRValue = 0;
      item.totalCRPercent = 0;
    })

    //คิด Grand total CA, CR
    this.summaryCost.forEach(item => {
      item.item.forEach(list => {
        this.totalCAandCR[index].totalCAValue! += list.costAvoidanceValue!;
        this.totalCAandCR[index].totalCAPercent! += list.costAvoidancePercent!;

        this.totalCAandCR[index].totalCRValue! += list.costReductionValue!;
        this.totalCAandCR[index].totalCRPercent! += list.costReductionPercent!;
      })
    })

    this.AlltotalCAValue = 0;
    this.AlltotalCAPercent = 0;
    this.AlltotalCRValue = 0;
    this.AlltotalCRPercent = 0;

    this.totalCAandCR.forEach(item => {
      this.AlltotalCAValue += item.totalCAValue!;
      this.AlltotalCAPercent += item.totalCAPercent!;
      this.AlltotalCRValue += item.totalCRValue!;
      this.AlltotalCRPercent += item.totalCRPercent!;
    })

    //Grand total CA, CR % คิดโดย Sum(%)/total
    this.AlltotalCAPercent = (this.AlltotalCAPercent / this.totalCAandCR.length);
    this.AlltotalCRPercent = (this.AlltotalCRPercent / this.totalCAandCR.length);

    this.summaryItemList = [];

    //แปลงให้ตรงตาม Table
    for (let i = 0; i < this.summaryCost.length; i++) {
      for (let j = 0; j < this.itemList.length; j++) {
        this.summaryItemList = [...this.summaryItemList, {
          allocationAward: this.summaryCost[i].item[j].allocation,
          finalPerPriceUnitInThb: this.summaryCost[i].item[j].finalPricePerUnit,
          itemName: this.itemList[j].itemName,
          matCode: this.itemList[j].matCode,
          unit: this.itemList[j].unit,
          quantity: this.itemList[j].quantity,
          vendorCode: this.biddingAllInfo.vendorBiddingInfo![i].itemList![j].vendorCode,
          vendorName: this.biddingAllInfo.vendorBiddingInfo![i].itemList![j].vendorName,
        }]
      }

    }

    this.summaryCostSaving = {
      totalCA: 0,
      totalCR: 0,
      totalPrice: 0,
    }

    this.summaryCost.forEach(cost => {
      this.summaryCostSaving.totalCA += cost.totalCA;
      this.summaryCostSaving.totalCR += cost.totalCR;
      this.summaryCostSaving.totalPrice += cost.totalPrice;
    })

    this.summaryCostDataChange.emit(this.summaryCost);
    this.summaryCostSavingDataChange.emit(this.summaryCostSaving);
    this.itemListDataChange.emit(this.itemList);
    this.summaryBiddingItemListDataChange.emit(this.summaryItemList);

  }

  redoFinalPrice(vendor: number) {
    this.summaryCost[vendor].item.forEach((item, index) => {
      let tempPrice = this.biddingAllInfo.vendorBiddingInfo![vendor].itemList![index].pricePerUnit ? this.biddingAllInfo.vendorBiddingInfo![vendor].itemList![index].pricePerUnit : 0;
      item.finalPricePerUnit = tempPrice!;
    })
  }

}
