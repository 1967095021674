import { NzMessageService } from 'ng-zorro-antd/message';
import { Plant } from './../../../../../api/models/plant';
import { SuggestVendor } from './../../../../../api/models/suggest-vendor';
import { MasterPlant } from './../../../../../api/models/master-plant';
import { MasterService } from './../../../../../api/services/master.service';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { RCF, RequestDetail } from './../../rcf.models';
import { Input, Output, Component, OnInit, EventEmitter, ViewChild, TemplateRef, SimpleChanges } from '@angular/core';
import { MasterContractType, RcfAllInfo, VChembiMstVendorMaster } from '../../../../../api/models';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzStatus } from 'ng-zorro-antd/core/types';


@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.css']
})
export class RequestDetailComponent implements OnInit {

  @Input() requesterInformation!: RCF;
  @Input() requestContractAllInfo!: RcfAllInfo[];
  @Input() steps!: number;
  @Input() isSubmitted!: boolean;
  @Input() listPlant: MasterPlant[] = [];
  @Input() listContractType: MasterContractType[] = [];
  @Input() vendorNameList: VChembiMstVendorMaster[] = [];

  // @Input() listOfSuggestVendorData: VChembiMstVendorMaster[] = [];
  // @Input() requestDetailForm!: FormGroup;

  // @Output() requestDataChange: EventEmitter<RequestDetail> = new EventEmitter<RequestDetail>();
  @Output() requestDataChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() RequestDetailFormValid = new EventEmitter<any>();
  @Output() contractValueChange = new EventEmitter<number>();

  requestDetailForm!: FormGroup;
  isVisible = false;
  listOfOption: string[] = [];
  listOfSelectedValue = [];
  listOfSuggestVendor: SuggestVendor[] = [];
  listOfSuggestVendorData: VChembiMstVendorMaster[] = [];
  filteredOptions!: any;
  numberOfSuggestVendor: number = 0;
  editId: string | null = null;
  changedNameSuggestVendor: string = "NAME";
  changedTelnoSuggestVendor: string = "Tel No.";
  changedEmailSuggestVendor: string = "Email";
  listOfSelectedPlant!: string[];

  numberOfAllPlant: number = 0;
  selectedPlant: any;
  searchVendor: any[] = [];
  searchingStatus: NzStatus = '';
  selectedPlantStatus: NzStatus = '';

  checkNameStatus: NzStatus = '';
  checkTelStatus: NzStatus = '';
  checkEmailStatus: NzStatus = '';


  optionContractPeriod = [
    { value: 0.5, label: "6 Month" },
    { value: 1, label: "1 Years" },
    { value: 1.5, label: "1 Years 6 Month" },
    { value: 2, label: "2 Years" },
    { value: 2.5, label: "2 Years 6 Month" },
    { value: 3, label: "3 Years" },
    { value: 3.5, label: "3 Years 6 Month" },
    { value: 4, label: "4 Years" },
    { value: 4.5, label: "4 Years 6 Month" },
    { value: 5, label: "5 Years" },
    { value: 5.5, label: "5 Years 6 Month" },
    { value: 6, label: "6 Years" },
    { value: 6.5, label: "6 Years 6 Month" },
    { value: 7, label: "7 Years" },
    { value: 7.5, label: "7 Years 6 Month" },
    { value: 8, label: "8 Years" },
  ]

  isDisabled: boolean = false;
  isSearchingVendor: boolean = false;
  formatter = (value: number): string => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  // parser = (value: string): string => value!.replace(/\$\s?|(,*)/g, '');

  constructor(
    private fb: FormBuilder,
    private cmsMasterService: MasterService,
    private modal: NzModalService,
    private msg: NzMessageService
  ) {

    this.requestDetailForm = this.fb.group({
      work_type: ['', [Validators.required]],
      contract_type: ['', [Validators.required]],
      contract_name: ['', [Validators.required]],
      product_service_name: ['', [Validators.required]],
      contract_period: ['', [Validators.required]],
      estimated_contract_value: ['', [Validators.required, Validators.pattern(/[0-9]/)]],
      reason_for_requesting: ['', [Validators.required]],
      other_note: [''],
      expected_result: [''],
      plant: new FormControl([], [Validators.required]),
      suggest_vendor: ['']
    });
    this.filteredOptions = [];
  }


  formatterNumber = (value: number): string => value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  parserNumber = (value: string): string => value.replace(/\$\s?|(,*)/g, '');

  ngOnInit(): void {
    this.updateData(this.requestDetailForm);

  }

  onSearch(value: string) {


  }

  async onSearchVendor(value: string) {
    if (value.length >= 1) {
      this.listOfSuggestVendorData = [];

      if (value.match(/[0-9]/)) {
        let count = 0;
        this.listOfSuggestVendorData = this.vendorNameList.filter(item => {
          if (count <= 20 && item.vendorCode?.startsWith(value)) {
            count++;
            return true;
          }

          return false;

        })
      }
      else {
        let count = 0;
        this.listOfSuggestVendorData = this.vendorNameList.filter(item => {
          if (count <= 20 && item.name1?.toLowerCase().startsWith(value.toLowerCase())) {
            count++;
            return true;
          }

          return false;
        });
      }

    }
  }
  async ngOnChanges(change: SimpleChanges) {
    if (change.hasOwnProperty("isSubmitted") && change.isSubmitted.isFirstChange() == false) {
      if (this.selectedPlant === undefined) {
        this.selectedPlantStatus = 'error';
      }
      else if (this.selectedPlant !== undefined) {
        if (this.selectedPlant.length == 0) {
          this.selectedPlantStatus = 'error';
        }
      }
      else {
        this.selectedPlantStatus = '';
      }

      for (let inner in this.requestDetailForm.controls) {
        this.requestDetailForm.get(inner)!.markAsDirty();
        this.requestDetailForm.get(inner)!.updateValueAndValidity();
      }

    }
    if (change.hasOwnProperty("listPlant") && change.listPlant.currentValue !== null) {
      this.numberOfAllPlant = change.listPlant.currentValue.length;
    }
    if (change.hasOwnProperty("requestContractAllInfo") && change.requestContractAllInfo.currentValue.length != 0) {
      this.requestDetailForm.patchValue({
        work_type: [change.requestContractAllInfo.currentValue[0].workType],
        contract_type: [change.requestContractAllInfo.currentValue[0].contractType],
        contract_name: change.requestContractAllInfo.currentValue[0].contractName,
        product_service_name: change.requestContractAllInfo.currentValue[0].productServiceName,
        estimated_contract_value: change.requestContractAllInfo.currentValue[0].estimatedContractValueInTHB,
        expected_result: change.requestContractAllInfo.currentValue[0].expectedResults,
      })

      this.requestDetailForm.controls['work_type'].setValue(change.requestContractAllInfo.currentValue[0].workType);
      this.requestDetailForm.controls['contract_type'].setValue(change.requestContractAllInfo.currentValue[0].contractType);
      this.requestDetailForm.controls['contract_period'].setValue(change.requestContractAllInfo.currentValue[0].contractPeriod);
      this.requestDetailForm.controls['reason_for_requesting'].setValue(change.requestContractAllInfo.currentValue[0].reasonForRequesting);

      if (typeof (change.requestContractAllInfo.currentValue[0].otherNote) !== "string" || change.requestContractAllInfo.currentValue[0].otherNote !== null) {
        this.requestDetailForm.controls['other_note'].setValue(change.requestContractAllInfo.currentValue[0].otherNote.toString());
      }
      else {
        this.requestDetailForm.controls['other_note'].setValue(change.requestContractAllInfo.currentValue[0].otherNote);
      }

      this.selectedPlant = [];
      let tempSelectedPlant: string[] = [];
      for (let i = 0; i < change.requestContractAllInfo.currentValue[0].plant.length; i++) {
        tempSelectedPlant.push(change.requestContractAllInfo.currentValue[0].plant[i].plantCode);
        this.selectedPlant = [...this.selectedPlant, this.listPlant.findIndex(item => item.plantCode == tempSelectedPlant[i])];
      }

      let tempObjSuggestVendor: SuggestVendor[] = [];
      for (let i = 0; i < change.requestContractAllInfo.currentValue[0].suggestVendor.length; i++) {
        tempObjSuggestVendor.push(change.requestContractAllInfo.currentValue[0].suggestVendor[i]);
        this.numberOfSuggestVendor++;
      }

      this.listOfSuggestVendor = tempObjSuggestVendor;

      if (change.requestContractAllInfo.currentValue[0].status != "Return" && change.requestContractAllInfo.currentValue[0].status != "Draft") {
        this.requestDetailForm.disable();
      }
      else if (change.requestContractAllInfo.currentValue[0].status == "Return" || change.requestContractAllInfo.currentValue[0].status == "Draft") {

        // this.requestDetailForm.controls['plant'].setValue(this.selectedPlant);

        let obj = [];
        for (let i = 0; i < tempSelectedPlant.length; i++) {
          obj.push(this.listPlant[this.listPlant.findIndex(item => item.plantCode == tempSelectedPlant[i])]);
        }

        obj = obj.filter(function (item) {
          return item !== undefined;
        })

        this.requestDetailForm.controls['plant'].setValue(obj);

        this.requestDetailForm.patchValue({
          suggest_vendor: this.listOfSuggestVendor
        });

        this.requestDetailForm.updateValueAndValidity();
        this.updateData(this.requestDetailForm);
      }

    }
  }

  updateData(form: FormGroup) {
    this.requestDataChange.emit(form);
    this.RequestDetailFormValid.emit(this.requestDetailForm.valid);
  }

  onChangePlant(value: string[]) {
    let obj: MasterPlant[] = [];
    let tempSelectedPlant: MasterPlant[] = [];

    const foundAll = value.find(item => item == 'all');

    this.selectedPlant = this.selectedPlant.filter(function (item: string) {
      return item !== "all";
    })
    if (foundAll === 'all' && this.selectedPlant.length == this.listPlant.length) {

      this.selectedPlant = [];
    }
    else if (foundAll === 'all') {

      this.selectedPlant = [];
      for (let i = 0; i < this.listPlant.length; i++) {
        obj.push(this.listPlant[i]);
        tempSelectedPlant.push(obj[i]);
        this.selectedPlant.push(i);

      }

    }
    else {
      value.forEach(index => {
        obj.push(this.listPlant[Number(index)]);
      });
    }

    this.requestDetailForm.controls['plant'].setValue(obj);
    this.updateData(this.requestDetailForm);

  }

  startEdit(id: string): void {
    this.editId = id;

    if (this.editId !== null) {
      this.editId = null;
    }
    this.modal.create({

    });
  }

  modalEditSuggestvendor(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, vendorCode: string) {
    this.changedNameSuggestVendor = this.listOfSuggestVendor[this.listOfSuggestVendor.findIndex(item => item.vendorCode == vendorCode)].vendorContactName!;
    this.changedTelnoSuggestVendor = this.listOfSuggestVendor[this.listOfSuggestVendor.findIndex(item => item.vendorCode == vendorCode)].vendorTelNo!;
    this.changedEmailSuggestVendor = this.listOfSuggestVendor[this.listOfSuggestVendor.findIndex(item => item.vendorCode == vendorCode)].vendorEmail!;
    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzWidth: (0.5)*window.innerWidth,
    })
  }

  onNameChange(value: string) {

    if (value.length >= 200) {
      this.checkNameStatus = 'error';
    }
    else {
      this.checkNameStatus = '';
    }
  }

  onTelChange(value: string) {

    if (!value.match(/((0)(\d{1,2}\-?\d{3}\-?\d{4}))/) || value.length < 10 || value.length > 10) {
      this.checkTelStatus = 'error';
    }
    else {
      this.checkTelStatus = '';
    }
  }

  onEmailChange(value: string) {
    if (!value.match(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      this.checkEmailStatus = 'error';
    }
    else {
      this.checkEmailStatus = '';
    }
  }

  changeSuggestVendorDetail(vendorCode: string) {

    // if (!this.changedTelnoSuggestVendor.match(/((0)(\d{1,2}\-?\d{3}\-?\d{4}))/) || this.changedTelnoSuggestVendor.length < 10 || this.changedTelnoSuggestVendor.length > 10) {
    //   this.checkTelStatus = 'error';
    //   return;
    // }


    this.listOfSuggestVendor[this.listOfSuggestVendor.findIndex(item => item.vendorCode == vendorCode)].vendorContactName = this.changedNameSuggestVendor;
    this.listOfSuggestVendor[this.listOfSuggestVendor.findIndex(item => item.vendorCode == vendorCode)].vendorTelNo = this.changedTelnoSuggestVendor;
    this.listOfSuggestVendor[this.listOfSuggestVendor.findIndex(item => item.vendorCode == vendorCode)].vendorEmail = this.changedEmailSuggestVendor;

    this.checkNameStatus = '';
    this.checkTelStatus = '';
    this.checkEmailStatus = '';
    this.msg.success("Save change!");
    this.updateData(this.requestDetailForm);
    this.modalDestroy();
  }

  modalDestroy() {
    this.modal.closeAll();
    this.changedNameSuggestVendor = "NAME";
    this.changedTelnoSuggestVendor = "Tel No";
    this.changedEmailSuggestVendor = "EMAIL";
  }

  stopEdit(): void {
    this.editId = null;
  }

  addRow(): void {
    this.numberOfSuggestVendor++;

    this.listOfSuggestVendor = [
      ...this.listOfSuggestVendor,
      {
        // id: `${this.numberOfSuggestVendor}`,
        vendorCode: "VENDOR CODE",
        vendorName: "VENDOR NAME",
        vendorContactName: "NAME",
        vendorTelNo: "TEL",
        vendorEmail: "EMAIL"
      }
    ];

  }

  addRowSuggestVendor(value: number) {

    // var obj: VChembiMstVendorMaster[] = this.listOfSuggestVendorData.filter(item => item.name1?.startsWith(name));
    var isDuplicate = false;

    //เช็คว่า มี vendor ซ้ำไหม เช็คโดยใช้ vendor code
    for (let i = 0; i < this.listOfSuggestVendor.length; i++) {
      if (this.listOfSuggestVendor[i].vendorCode == this.listOfSuggestVendorData[value].vendorCode) {
        isDuplicate = true;
      }
    }

    if (isDuplicate == false) {

      this.listOfSuggestVendor = [
        ...this.listOfSuggestVendor,
        {
          // id: `${this.numberOfSuggestVendor}`,
          vendorCode: this.listOfSuggestVendorData[value].vendorCode!,
          vendorName: this.listOfSuggestVendorData[value].name1!,
          vendorContactName: "NAME",
          vendorTelNo: "TEL",
          vendorEmail: "EMAIL"

        }
      ];
      this.numberOfSuggestVendor++;

    }
    else {
      this.showErrorDuplicateSuggestVendor();
    }

    this.searchVendor = [];
    this.listOfSuggestVendorData = []
    this.filteredOptions = [];
    this.requestDetailForm.patchValue({
      suggest_vendor: this.listOfSuggestVendor
    });
    this.updateData(this.requestDetailForm);
  }

  deleteRow(vendorCode: string) {
    // this.listOfSuggestVendor = this.listOfSuggestVendor.filter(item => item.id !== id);
    this.listOfSuggestVendor = this.listOfSuggestVendor.filter(item => item.vendorCode !== vendorCode);
    this.numberOfSuggestVendor--;
  }

  editRow(id: string) {
    if (this.editId == id) {
      this.editId = null;
    }
    else {
      this.editId = id;
    }

  }


  onChange(vendor_name: string) {

    try {
      for (let i = 0; i < this.listOfSuggestVendorData.length; i++) {
        if ((this.listOfSuggestVendorData[i].name1) === vendor_name && this.editId != null) {
          // this.listOfSuggestVendor[Number(this.editId) - 1].name = this.listOfSuggestVendorData[i].name1!;
          this.listOfSuggestVendor[Number(this.editId) - 1].vendorName = this.listOfSuggestVendorData[i].name1;

          return;
        }
      }

    } catch (error) {

    }

  }

  //Search Vendor
  //ค้นหา Vendor input string 3 ตัวขึ้นไปเอามาแสดงใน select 20 ชื่อ
  onInput(event: string): void {

    let count = 0;
    if (event.length >= 3) {
      this.filteredOptions = this.listOfSuggestVendorData.filter(
        function (item) {
          if (count < 20 && item.name1?.toLowerCase().startsWith(event.toLowerCase())) {
            count++;
            return true;
          }
          return false;
        }, { count: 0 }
      ).concat(
        this.listOfSuggestVendorData.filter(
          function (item) {
            if (count < 20 && item.vendorCode?.startsWith(event)) {
              count++;
              return true;
            }
            return false;
          }, { count: 0 }
        )
      )

      if (this.filteredOptions.length == 0) {
        this.searchingStatus = 'error';
      }
      else {
        this.searchingStatus = '';
      }
    }
    else {
      this.searchingStatus = '';
    }

  }


  formatterValueNumber(event: Event) {

    let rawValue = this.requestDetailForm.get('estimated_contract_value')?.value.replace(/,/g, "");

    this.requestDetailForm.controls['estimated_contract_value'].setValue(rawValue.replace(/\B(?=(\d{3})+(?!\d))/g, ','))
    this.updateData(this.requestDetailForm);
  }

  showErrorDuplicateSuggestVendor(): void {
    this.modal.error({
      nzTitle: 'Error',
      nzContent: 'Duplicate Suggest Vendor'
    });
  }
}

