import { Guid } from 'guid-typescript';
import { DatePipe } from '@angular/common';
import { Component, OnInit, Output, TemplateRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalService, NzModalRef } from 'ng-zorro-antd/modal';
import { NzStatusType } from 'ng-zorro-antd/steps';
import { MasterSourcingTeam } from '../../../../../../api/models';
import { MasterService } from '../../../../../../api/services';
import { BiddingAllInfoAndLog } from './../../../../../../api/models/bidding-all-info-and-log';
import { BiddingFile } from './../../../../../../api/models/bidding-file';
import { BiddingItemList } from './../../../../../../api/models/bidding-item-list';
import { MasterBaseOn } from './../../../../../../api/models/master-base-on';
import { MasterConfig } from './../../../../../../api/models/master-config';
import { MasterCurrency } from './../../../../../../api/models/master-currency';
import { MasterIncoterm1 } from './../../../../../../api/models/master-incoterm-1';
import { MasterPurGroup } from './../../../../../../api/models/master-pur-group';
import { MasterTheArchPurGroupMember } from './../../../../../../api/models/master-the-arch-pur-group-member';
import { MasterTheArchVendorEmailList } from './../../../../../../api/models/master-the-arch-vendor-email-list';
import { VChembiMstPaymentTerm } from './../../../../../../api/models/v-chembi-mst-payment-term';
import { VChembiMstVendorMaster } from './../../../../../../api/models/v-chembi-mst-vendor-master';
import { Vendor } from './../../../../../../api/models/vendor';
import { ConfigurationService } from './../../../../../../api/services/configuration.service';
import { MasterTheArchService } from './../../../../../../api/services/master-the-arch.service';
import { NewBiddingService } from './../../../../../../api/services/new-bidding.service';
import { AuthService } from './../../../../../../services/auth.service';
import { ChildVendorData, ParentVendorData } from './component/_model/TableVendorData';
import { NzStatus } from 'ng-zorro-antd/core/types';


@Component({
  selector: 'app-new-bidding',
  templateUrl: './new-bidding.component.html',
  styleUrls: ['./new-bidding.component.css']
})
export class NewBiddingComponent implements OnInit {

  @Output() biddingConfig: MasterConfig[] = [];
  @Output() itemListData: BiddingItemList[] = [];

  biddingAllinfo!: BiddingAllInfoAndLog;
  steps: number = 0;

  sourcingContractForm: FormGroup;
  biddingDetailForm: FormGroup;
  actionForm: FormGroup;
  documentFileList: BiddingFile[] = [];

  vendorEmailList: ChildVendorData[] = [];
  allEmail: string[] = [];
  vendorList: ParentVendorData[] = [];
  listOfEmailVendor: MasterTheArchVendorEmailList[] = [];

  contractPeroidInputStatus: NzStatus = "";
  deadlineInputStatus: NzStatus = "";
  selectPurGroupStatus: NzStatus = "";

  sourcingTeam: MasterSourcingTeam[] = [];
  baseOn: MasterBaseOn[] = [];
  purGroup: MasterPurGroup[] = [];
  purGroupMember: MasterTheArchPurGroupMember[] = [];
  currencyList: MasterCurrency[] = [];
  paymentTermList: VChembiMstPaymentTerm[] = [];
  incotermList: MasterIncoterm1[] = [];
  vendorNameList: VChembiMstVendorMaster[] = [];


  emailTemplateTH: string = "";
  emailTemplateENG: string = "";
  itemListTableHTML: string = "";

  additionalDetailList: string[] = [];
  isDataLoading: boolean = false;
  worksheetStatus = "";
  documentStatus: string = "";
  documentNumber: string = "";
  documentCreator: string = "";
  documentCreateDate!: Date;
  status: NzStatusType = 'process';

  tabs = ["TH", "ENG"]

  constructor(
    private newBiddingService: NewBiddingService,
    private configService: ConfigurationService,
    private modal: NzModalService,
    private fb: FormBuilder,
    private datepipe: DatePipe,
    private authService: AuthService,
    private masterService: MasterService,
    private masterTheArch: MasterTheArchService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.sourcingContractForm = this.fb.group({
      sourcingTeam: [""],
      purchasingGroups: ["", [Validators.required]],
      purchasingGroupsEmail: [""],
      name: [{ value: '', disabled: true }, , [Validators.required]],
      email: [{ value: '', disabled: true }, , [Validators.email, Validators.required]],
      telNo: [{ value: '', disabled: true }, , [Validators.pattern(/((0)(\d{1,2}\-?\d{3}\-?\d{4}))/), Validators.required, Validators.minLength(10), Validators.maxLength(10)]],
    })

    this.biddingDetailForm = this.fb.group({
      projectName: ["", [Validators.required]],
      contractStartDate: ["", [Validators.required]],
      contractEndDate: ["", [Validators.required]],
      deadLineDate: ["", [Validators.required]],
      isMatrixTemplate: false,
      isGuaranteeShelfLife: false,
      isBrand: false,
      isSourceOfOrigin: false,
      isMinimumOrderQuantity: false,
      isQuotationValidity: false,
      quotationValidityFromDate: new Date(),
      quotationValidityToDate: new Date(),
      baseOn: ["", [Validators.required]],
      incoTerm: ["", [Validators.required]],
      paymentTerm: ["", [Validators.required]]
    })

    this.actionForm = this.fb.group({
      remark: "",
    })
  }

  async ngOnInit(): Promise<void> {

    this.isDataLoading = true;
    const pkDetail = this.route.snapshot.queryParamMap.get('pkHeader');

    try {

      if (pkDetail) {
        await this.getNewBiddingAllInfo(pkDetail);

        //เช็ค steps
        if (this.biddingAllinfo.header?.status === "Wait for vendor submit price") {
          this.steps = 1;
          this.documentStatus = "Wait for vendor submit price";
          this.sourcingContractForm.disable();
          this.biddingDetailForm.disable();

        }
        else if (this.biddingAllinfo.header?.status === "Wait for summary") {
          this.steps = 2;
          this.documentStatus = "Wait for summary";
          this.sourcingContractForm.disable();
          this.biddingDetailForm.disable();
        }
        else if (this.biddingAllinfo.header?.status === "Finish") {
          this.steps = 3;
          this.documentStatus = "Finish";
          this.sourcingContractForm.disable();
          this.biddingDetailForm.disable();
        }
        else {
          // this.documentStatus = "Draft";
          this.steps = 0;
        }

        this.documentCreateDate = new Date(this.biddingAllinfo.header?.createDate!);
        this.documentNumber = this.biddingAllinfo.header?.docNo!;
        this.documentCreator = this.biddingAllinfo.header?.creatorName!;
        this.worksheetStatus = this.biddingAllinfo.header?.status!;
      }

      await this.getConfigData();
      this.emailTemplateTH = this.biddingConfig.find(item => item.key === "EmailTemplateTH")?.value!;
      this.emailTemplateENG = this.biddingConfig.find(item => item.key === "EmailTemplateENG")?.value!;

      await this.getSourcingTeam();
      await this.getPurGroup();
      await this.getPurGroupMembersFromTheArch();
      await this.getBaseOn();
      await this.getCurrencyData();
      await this.getIncotermData();
      await this.getPaymentTermData();
      await this.getVendorList();
    }
    catch (error) {

      this.modal.error({
        nzTitle: "Error",
        nzContent: "Cannot get Data from server"
      })
    }

    this.isDataLoading = false;
  }

  async getNewBiddingAllInfo(pk: string) {
    await this.newBiddingService.apiNewBiddingRequestReadBiddingAllInfoAndLogActiveByPkBiddingHeaderGet$Json({
      pkBiddingDetail: pk
    })
      .toPromise()
      .then(
        response => {
          console.log(response);

          this.biddingAllinfo = response;

          if (this.biddingAllinfo.header?.status === "Wait Vendor Submit Price") {
            this.steps = 1;
          }
          else if (this.biddingAllinfo.header?.status === "Finished") {
            this.steps = 2;
          }
          else {
            this.steps = 0;
          }
        }
      )
      .catch(
        error => {
          console.log(error);
          this.router.navigate(["**"]);
        }
      )

  }

  async getBaseOn() {
    await this.masterService.apiMasterGetBaseOnGet$Json()
      .toPromise()
      .then(
        response => {
          this.baseOn = response;
          console.log("BaseOn");
        }
      )
  }

  async getPurGroup() {
    await this.masterService.apiMasterGetPurGroupGet$Json()
      .toPromise()
      .then(
        response => {
          this.purGroup = response;
          console.log("PurGroup");
        }
      )

  }

  async getPurGroupMembersFromTheArch() {
    await this.masterTheArch.apiMasterTheArchGetPurGroupMemberActiveGet$Json()
      .toPromise()
      .then(
        response => {
          this.purGroupMember = response;
        }
      )
  }

  async getSourcingTeam() {
    await this.masterService.apiMasterGetSourcingTeamGet$Json()
      .toPromise()
      .then(
        response => {
          this.sourcingTeam = response;
          console.log("Sourcing Team");
        }
      )

  }

  async getConfigData() {
    await this.configService.apiConfigurationGetAllConfigByGroupGet$Json({
      Group: "Bidding",
    })
      .toPromise()
      .then(
        response => {
          this.biddingConfig = response;
          console.log("Config");
        }
      )

  }

  async getCurrencyData() {
    await this.masterService.apiMasterGetCurrencyGet$Json()
      .toPromise()
      .then(
        response => {
          this.currencyList = response;
          console.log("Currency");
        }
      )

  }

  async getIncotermData() {
    await this.masterService.apiMasterGetIncotermGet$Json()
      .toPromise()
      .then(
        response => {
          this.incotermList = response;

          // Sort ตามตัวอักษร
          this.incotermList.sort(function (a, b) {
            return a.incoterm1Name?.localeCompare(b.incoterm1Name!)!;
          })
          console.log("Incoterm");
        }
      )
  }

  async getPaymentTermData() {
    await this.masterService.apiMasterGetPaymentTermGet$Json()
      .toPromise()
      .then(
        response => {

          this.paymentTermList = response;
          // Sort ตามตัวอักษร
          this.paymentTermList.sort(function (a, b) {
            return a.paymentTermDesc?.toLowerCase().localeCompare(b.paymentTermDesc?.toLowerCase()!)!;
          })
          console.log("PaymentTerm");
        }
      )
  }

  async getVendorList() {
    await this.masterService.apiMasterGetVendorGet$Json()
      .toPromise()
      .then(
        response => {
          this.vendorNameList = response;
        }
      )
  }

  async onGetVendorEmailList(vendorCode: string[]) {
    await this.getVendorEmailList(vendorCode);
  }

  async getVendorEmailList(vendorCode: string[]) {
    this.listOfEmailVendor = [];
    await this.masterTheArch.apiMasterTheArchGetVendorEmailListByVendorCodePost$Json({
      body: vendorCode
    })
      .toPromise()
      .then(
        response => {
          this.listOfEmailVendor = response;
          console.log("Vendor Email List:", this.listOfEmailVendor);
        }
      )

  }

  handleSegmentChange(e: number): void {
    console.log(e);
  }

  onDeleteDraft() {

  }

  onPreviewEmail(tplContent: TemplateRef<{}>) {
    this.modal.create({
      nzTitle: "Preview Email",
      nzContent: tplContent,
      nzComponentParams: {
        Name: this.sourcingContractForm.getRawValue().name,
        Email: this.sourcingContractForm.getRawValue().email,
        TelNo: this.sourcingContractForm.getRawValue().telNo,
      },
      nzWidth: (0.7) * window.innerWidth,

    });
  }

  showSaveConfirm() {
    var modelSubmit: NzModalRef = this.modal.confirm({
      nzTitle: 'Save',
      nzContent: 'Do you want to save this form?',
      nzOkText: 'Save',
      nzCancelText: 'Cancel',
      nzOkLoading: false,
      nzOnOk: () => new Promise(async (resolve, reject) => {

        //อัพเดตปุ่ม submit ให้โหลด
        modelSubmit.updateConfig({
          nzOkLoading: true,
        })

        //แปลง array vendor กับ array vendor email ให้เป็น object

        let allVendorList: Vendor[] = [];
        let arrayEmail: string[] = [];

        for (let i = 0; i < this.vendorList.length; i++) {
          arrayEmail = [];
          for (let j = 0; j < this.vendorEmailList[i].vendor.length; j++) {
            arrayEmail.push(this.vendorEmailList[i].vendor[j].Email);
          }

          allVendorList.push({
            vendorName: this.vendorList[i].VendorName,
            vendorCode: this.vendorList[i].VendorCode,
            vendorEmail: arrayEmail.toString()
          })

        }


        //แปลงวันที่อัพโหลดไฟล์ให้เป็น json
        this.documentFileList.forEach(
          item => {
            item.lastModifiedDate = new Date(item.lastModifiedDate!).toJSON();
          }
        )

        this.newBiddingService.apiNewBiddingRequestSaveRequestForPriceListPost({
          body: {
            biddingAllInfo: {
              header: {
                pkbiddingHeader: this.biddingAllinfo ? this.biddingAllinfo.header?.pkbiddingHeader : Guid.EMPTY,
                biddingStartDate: new Date().toJSON(),
                createDate: new Date().toJSON(),
                creatorDomain: this.authService.currentUserDomain(),
                creatorEmail: this.authService.currentUserEmail(),
                creatorName: this.authService.currectUserFullName(),
                contractPeriodStart: new Date(this.biddingDetailForm.value.contractStartDate).toJSON(),
                contractPeriodEnd: new Date(this.biddingDetailForm.value.contractEndDate).toJSON(),
                baseOn: this.biddingDetailForm.value.baseOn,
                deadline: new Date(this.biddingDetailForm.value.deadLineDate).toJSON(),
                docNo: "",
                isMatrixItemList: this.biddingDetailForm.value.isMatrixTemplate,
                projectName: this.biddingDetailForm.value.projectName,
                sourcingTeam: this.sourcingContractForm.value.sourcingTeam,
                sourcingName: this.sourcingContractForm.getRawValue().name,
                sourcingEmail: this.sourcingContractForm.getRawValue().email,
                sourcingTelNo: this.sourcingContractForm.getRawValue().telNo,
                sourcingPurchasingGroups: this.sourcingContractForm.value.purchasingGroups,
                sourcingPurchasingGroupsEmail: this.sourcingContractForm.value.purchasingGroupsEmail,
                incoterm: this.biddingDetailForm.value.incoTerm,
                paymentTerm: this.biddingDetailForm.value.paymentTerm,
                remark: this.actionForm.value.remark,
                isBrand: this.biddingDetailForm.value.isBrand,
                isGuaranteeShelfLife: this.biddingDetailForm.value.isGuaranteeShelfLife,
                isMinimumOrderQuantity: this.biddingDetailForm.value.isMinimumOrderQuantity,
                isQuotationValidity: this.biddingDetailForm.value.isQuotationValidity,
                quotationValidityFrom: new Date(this.biddingDetailForm.value.quotationValidityFromDate).toJSON(),
                quotationValidityTo: new Date(this.biddingDetailForm.value.quotationValidityToDate).toJSON(),
                isSourceOfOrigin: this.biddingDetailForm.value.isSourceOfOrigin,
                status: "",
                refContractNo: "",
                isActive: true,

              },
              detail: {

              },
              files: this.documentFileList,
              itemList: this.itemListData,
              vendorList: allVendorList,
            },
            emailContent: {
              bodyTH: this.emailTemplateTH,
              bodyENG: this.emailTemplateENG,
              toAddress: [],
              ccAddress: [],

            }
          }
        })
          .toPromise()
          .then(
            response => {

              modelSubmit.updateConfig({
                nzOkLoading: false,
              })

              modelSubmit.close();

              var pkheader: string = JSON.parse(JSON.stringify(response));

              this.modal.success({
                nzTitle: "Complete",
                nzContent: "Save Success",
                nzOkText: "Close",
                nzOnOk: () => {

                  if (!this.biddingAllinfo) {
                    this.router
                      .navigate(['../work-station/bidding-worksheet/request-for-price-list/new-bidding'], {
                        queryParams: { pkHeader: pkheader.replace(/"/g, "") },
                      })
                      .then(() => {
                        window.location.reload();
                      })
                  }
                }
              })
            }
          )
          .catch(
            error => {

              modelSubmit.updateConfig({
                nzOkLoading: false,
              })

              modelSubmit.close();

              this.modal.error({
                nzTitle: "Error",
                nzContent: "Server error, Please try again later."
              })
            }
          )

      })
    })
  }

  showSubmitConfirm() {

    const modelSubmit = this.modal.confirm({
      nzTitle: 'Submit',
      nzContent: 'Do you want to submit this form?',
      nzOkText: 'Submit',
      nzCancelText: 'Cancel',
      nzOkLoading: false,
      nzOnOk: () => new Promise(async (resolve, reject) => {

        if (!this.sourcingContractForm.valid || !this.biddingDetailForm.valid) {

          modelSubmit.updateConfig({
            nzOkLoading: false,
          })

          modelSubmit.close();

          this.modal.warning({
            nzTitle: "Error",
            nzContent: "Please fill all required fields",
            nzOkText: "Close",
            nzCancelText: null,
            nzOnOk: () => {

              if (this.sourcingContractForm.value.purchasingGroups === "") {
                this.selectPurGroupStatus = "error";
              }

              if (this.biddingDetailForm.value.contractStartDate === "" || this.biddingDetailForm.value.contractEndDate === "") {
                this.contractPeroidInputStatus = "error";
              }

              if (this.biddingDetailForm.value.deadLineDate === "") {
                this.deadlineInputStatus = "error";
              }

              for (let inner in this.sourcingContractForm.controls) {
                this.sourcingContractForm.get(inner)?.markAsDirty()
                this.sourcingContractForm.get(inner)?.updateValueAndValidity();
              }
              for (let inner in this.biddingDetailForm.controls) {
                this.biddingDetailForm.get(inner)?.markAsDirty();
                this.biddingDetailForm.get(inner)?.updateValueAndValidity();
              }

              document.getElementById('sourcing-contract')?.scrollIntoView();
            }
          })
          return;
        }
        if (this.biddingDetailForm.value.contractStartDate === "" || this.biddingDetailForm.value.contractEndDate === "") {

          modelSubmit.updateConfig({
            nzOkLoading: false,
          })

          modelSubmit.close();

          this.modal.warning({
            nzTitle: "Error",
            nzContent: "Please select Contract Peroid Date",
            nzOkText: "Close",
            nzCancelText: null,
            nzOnOk: () => {

              this.contractPeroidInputStatus = "error";
              document.getElementById('sourcing-contract')?.scrollIntoView();
            }
          })
          return;
        }
        if (this.biddingDetailForm.value.deadLineDate == "") {

          modelSubmit.updateConfig({
            nzOkLoading: false,
          })

          modelSubmit.close();

          this.modal.warning({
            nzTitle: "Error",
            nzContent: "Please select Dateline Date",
            nzOkText: "Close",
            nzCancelText: null,
            nzOnOk: () => {

              this.deadlineInputStatus = "error";
              document.getElementById('sourcing-contract')?.scrollIntoView();
            }
          })
          return;
        }

        if (this.documentFileList.length === 0) {

          modelSubmit.updateConfig({
            nzOkLoading: false,
          })

          modelSubmit.close();

          this.modal.warning({
            nzTitle: "Error",
            nzContent: "Please upload TOR",
            nzOkText: "Close",
            nzCancelText: null,
            nzOnOk: () => {
              document.getElementById('document')?.scrollIntoView();
            }
          })
          return;
        }
        if (this.itemListData.length === 0) {

          modelSubmit.updateConfig({
            nzOkLoading: false,
          })

          modelSubmit.close();

          this.modal.warning({
            nzTitle: "Error",
            nzContent: "Please upload item list",
            nzOkText: "Close",
            nzCancelText: null,
            nzOnOk: () => {
              document.getElementById('item-list')?.scrollIntoView();
            }

          })
          return;
        }
        if (this.vendorList.length < 2) {

          modelSubmit.updateConfig({
            nzOkLoading: false,
          })

          modelSubmit.close();

          this.modal.warning({
            nzTitle: "Error",
            nzContent: "Please add more vendor",
            nzOkText: "Close",
            nzCancelText: null,
            nzOnOk: () => {
              document.getElementById('add-vendor')?.scrollIntoView();
            }

          })
          return;
        }

        try {
          this.vendorList.forEach((item, index, arr) => {

            this.vendorEmailList.forEach((email, j) => {

              if (email.vendor.length === 0) {

                modelSubmit.updateConfig({
                  nzOkLoading: false,
                })

                modelSubmit.close();

                this.modal.warning({
                  nzTitle: "Email is empty",
                  nzContent: "Please add email for vendor " + arr[j].VendorName,
                  nzOkText: "Close",
                })

                throw new Error("Email is empty");
              }
              else {

                let countSendEmail: number = 0;
                email.vendor.forEach(list => {
                  if (list.SendEmail) {
                    countSendEmail++;
                  }

                })

                if (countSendEmail === 0) {

                  modelSubmit.updateConfig({
                    nzOkLoading: false,
                  })

                  modelSubmit.close();

                  this.modal.warning({
                    nzTitle: "Email is empty",
                    nzContent: "Please send email at least 1 for vendor " + arr[j].VendorName,
                    nzOkText: "Close",
                  })

                  throw new Error("Email is empty");
                }
              }

            })
          });
        } catch (error) {

          if (!((error as Error).message === "Email is empty")) {
            throw error;
          }

          return;
        }


        let allVendorList: Vendor[] = [];
        let arrayEmail: string[] = [];

        for (let i = 0; i < this.vendorList.length; i++) {
          arrayEmail = [];
          for (let j = 0; j < this.vendorEmailList[i].vendor.length; j++) {
            arrayEmail.push(this.vendorEmailList[i].vendor[j].Email);
          }

          allVendorList.push({
            vendorName: this.vendorList[i].VendorName,
            vendorCode: this.vendorList[i].VendorCode,
            vendorEmail: arrayEmail.toString()
          })

        }


        this.documentFileList.forEach(
          item => {
            item.lastModifiedDate = new Date(item.lastModifiedDate!).toJSON();
          }
        )

        this.newBiddingService.apiNewBiddingRequestCreateBiddingPost({
          body: {
            action: this.biddingAllinfo ? "From Draft" : "",
            biddingAllInfo: {
              header: {
                pkbiddingHeader: this.biddingAllinfo ? this.biddingAllinfo.header?.pkbiddingHeader : Guid.EMPTY,
                biddingStartDate: new Date().toJSON(),
                createDate: new Date().toJSON(),
                creatorDomain: this.authService.currentUserDomain(),
                creatorEmail: this.authService.currentUserEmail(),
                creatorName: this.authService.currectUserFullName(),
                contractPeriodStart: new Date(this.biddingDetailForm.value.contractStartDate).toJSON(),
                contractPeriodEnd: new Date(this.biddingDetailForm.value.contractEndDate).toJSON(),
                baseOn: this.biddingDetailForm.value.baseOn,
                deadline: new Date(this.biddingDetailForm.value.deadLineDate).toJSON(),
                docNo: "",
                isMatrixItemList: this.biddingDetailForm.value.isMatrixTemplate,
                projectName: this.biddingDetailForm.value.projectName,
                sourcingName: this.sourcingContractForm.getRawValue().name,
                sourcingTeam: this.sourcingContractForm.value.sourcingTeam,
                sourcingEmail: this.sourcingContractForm.getRawValue().email,
                sourcingTelNo: this.sourcingContractForm.getRawValue().telNo,
                sourcingPurchasingGroups: this.sourcingContractForm.value.purchasingGroups,
                sourcingPurchasingGroupsEmail: this.sourcingContractForm.value.purchasingGroupsEmail,
                incoterm: this.biddingDetailForm.value.incoTerm,
                paymentTerm: this.biddingDetailForm.value.paymentTerm,
                isBrand: this.biddingDetailForm.value.isBrand,
                isGuaranteeShelfLife: this.biddingDetailForm.value.isGuaranteeShelfLife,
                isMinimumOrderQuantity: this.biddingDetailForm.value.isMinimumOrderQuantity,
                isQuotationValidity: this.biddingDetailForm.value.isQuotationValidity,
                quotationValidityFrom: new Date(this.biddingDetailForm.value.quotationValidityFromDate).toJSON(),
                quotationValidityTo: new Date(this.biddingDetailForm.value.quotationValidityToDate).toJSON(),
                isSourceOfOrigin: this.biddingDetailForm.value.isSourceOfOrigin,
                remark: this.actionForm.value.remark,
                status: "Bidding",
                refContractNo: "",
                isActive: true,
              },
              detail: {

              },
              files: this.documentFileList,
              itemList: this.itemListData,
              vendorList: allVendorList,
            },
            emailContent: {
              bodyTH: this.emailTemplateTH,
              bodyENG: this.emailTemplateENG,
              toAddress: [],
              ccAddress: [],

            }
          }
        })
          .toPromise()
          .then(
            response => {

              modelSubmit.updateConfig({
                nzOkLoading: false,
              })

              modelSubmit.close();

              var pkheader: string = JSON.parse(JSON.stringify(response));

              this.modal.success({
                nzTitle: "Complete",
                nzContent: "Successfully Created Request for price list",
                nzOnOk: () => {
                  this.router
                    .navigate(['../work-station/bidding-worksheet/request-for-price-list/new-bidding'], {
                      queryParams: { pkHeader: pkheader.replace(/"/g, "") },
                    })
                    .then(() => {
                      window.location.reload();
                    })
                }
              })
            }
          )
          .catch(
            error => {

              console.error(error);

              this.modal.error({
                nzTitle: "Error",
                nzContent: "Server Error, Please try again later.",
                nzOkText: "Close",
              })
            }
          )

      }
      )
    })


  }

  //event emitter ของ AdditionalDetail
  onAdditionalDetailListChange(value: string[]) {
    this.additionalDetailList = value;
    console.log("onAdditionalDetailListChange: ", value);
  }

  //table item list ตอน preview email
  onItemListDataChange(value: BiddingItemList[]) {
    this.itemListData = value;

    var tempItemListTableHTML = "<table><tr><th>No.</th><th>Mat Code</th><th>Item Name</th><th>Desciption</th><th>Unit</th><th>Qty</th><th>Price per Unit</th><th>Currency</th><th>Total</th><th>Delivery Times</th></tr>";

    this.itemListData.forEach(item => {
      tempItemListTableHTML += "<tr>";
    })

    tempItemListTableHTML += "</table>";

    this.itemListTableHTML = tempItemListTableHTML;

  }

  onDocumentFileListChange(value: BiddingFile[]) {
    this.documentFileList = value;
  }

  onVendorEmailChange(value: ChildVendorData[]) {
    this.vendorEmailList = value;

    this.allEmail = [];

    for (let i = 0; i < this.vendorList.length; i++) {
      let arrayEmail = [];
      for (let j = 0; j < this.vendorEmailList[i].vendor.length; j++) {
        arrayEmail.push(this.vendorEmailList[i].vendor[j].Email);
      }

      this.allEmail.push(arrayEmail.toString());

    }

  }

  onVendorListChange(value: ParentVendorData[]) {
    this.vendorList = value;
    console.log("onVendorListChange: ", value);
  }


}
