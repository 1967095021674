import { MasterSimpleValue } from 'src/app/api/models';
import { MasterBaseOn } from './../../api/models/master-base-on';
import { MasterService } from './../../api/services/master.service';
import { MasterConfig } from './../../api/models/master-config';
import { ConfigurationService } from './../../api/services/configuration.service';
import { Component, OnInit } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-configuration',
  templateUrl: './configuration.component.html',
  styleUrls: ['./configuration.component.css']
})
export class ConfigurationComponent implements OnInit {


  isDataLoading: boolean = false;
  rcfConfig: MasterConfig[] = [];
  biddingConfig: MasterConfig[] = [];

  baseOn: MasterBaseOn[] = [];
  biddingSimpleValue: MasterSimpleValue[] = [];

  constructor(
    private configService: ConfigurationService,
    private modal: NzModalService,
    private masterService: MasterService,
  ) {
  }

  async ngOnInit(): Promise<void> {

    this.isDataLoading = true;

    await this.getRCFConfig();
    await this.getMasterBaseOn();
    await this.getMasterSimpleValue();

    this.isDataLoading = false;
  }

  async getRCFConfig() {

    this.configService.apiConfigurationGetAllConfigGet$Json$Response()
      .subscribe(response => {
        let allConfig: MasterConfig[] = response.body;
        this.rcfConfig = allConfig.filter(item => item.group == "RCF");
        this.biddingConfig = allConfig.filter(item => item.group == "Bidding");
      }, error => {

      }, () => {
      })

  }

  async getMasterBaseOn(){
    await this.masterService.apiMasterGetBaseOnGet$Json()
      .toPromise()
      .then(
        response => {
          this.baseOn = response;
        }
      )

  }

  async getMasterSimpleValue(){
    await this.masterService.apiMasterGetSimpleValueGet$Json()
      .toPromise()
      .then(
        response => {
          let tempSimpleValue: MasterSimpleValue[] = response;
          this.biddingSimpleValue = tempSimpleValue.filter(item => item.group === "Bidding");
        }
      )

  }

  onConfigValueChange(newConfig: MasterConfig) {

    this.isDataLoading = true;
    console.log("New Config Value: ", newConfig);
    this.configService.apiConfigurationUpdateConfigByKeyPost$Response({
      key: newConfig.key!,
      value: newConfig.value!
    }).subscribe(response => {

    }, err => {
      this.isDataLoading = false;

      const modalResult = this.modal.success({
        nzTitle: "Error",
        nzContent: "Please try again later",
      })

    }, () => {
      this.isDataLoading = false;

      const modalResult = this.modal.success({
        nzTitle: "Complete",
        nzContent: "Config Updated",
      })

      modalResult.afterClose.subscribe(() => this.getRCFConfig());
    })
  }

}
