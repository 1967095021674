import { NewRequestBiddingDocNoAndLog } from './../../api/models/new-request-bidding-doc-no-and-log';
import { RequestContractDocNoAndLog } from './../../api/models/request-contract-doc-no-and-log';
import { BiddingLog } from './../../api/models/bidding-log';
import { MasterService, RequestContractAllService } from '../../api/services';
import { NewBiddingService } from './../../api/services/new-bidding.service';
import { Rcflog } from './../../api/models/rcflog';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { MasterSourcingTeam } from '../../api/models';

@Component({
  selector: 'app-log',
  templateUrl: './log.component.html',
  styleUrls: ['./log.component.css']
})
export class LogComponent implements OnInit {

  requestContractLog: RequestContractDocNoAndLog[] = [];
  requestPricelistLog: NewRequestBiddingDocNoAndLog[] = [];

  sourcingTeamList: MasterSourcingTeam[] = [];

  searchingLogCriteriaForm: FormGroup;
  isDataLoading: boolean = false;
  constructor(
    private fb: FormBuilder,
    private newBiddingService: NewBiddingService,
    private rcfService: RequestContractAllService,
    private masterService: MasterService,
  ) {
    this.searchingLogCriteriaForm = fb.group({
      docNo: [""],
      type: [""],
      date: [[]],
      sourcingTeam: [[]],
      purGroups: [[]],
    })
  }

  async ngOnInit(): Promise<void> {
    this.isDataLoading = true;

    await this.getRequestContractLog();
    await this.getRequestPricelistLog();
    await this.getSourcingTeamList();

    this.isDataLoading = false;
  }

  async getRequestContractLog() {
    await this.rcfService.apiRcfAllServiceGetAllLogGet$Json()
      .toPromise()
      .then(response => {
        this.requestContractLog = response;
        this.requestContractLog.reverse();
      })
  }

  async getRequestPricelistLog() {
    await this.newBiddingService.apiNewBiddingRequestGetAllLogGet$Json()
      .toPromise()
      .then(response => {
        this.requestPricelistLog = response;
        this.requestPricelistLog.reverse();
      })
  }

  async getSourcingTeamList() {
    await this.masterService.apiMasterGetSourcingTeamGet$Json()
      .toPromise()
      .then(response => {
        this.sourcingTeamList = response;
      })
  }

}
