<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" nzTip="Loading..." [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-header">
        <div nz-row>
          <div nz-col nzSpan="24">

            <nz-card class="card">
              <nz-row>
                <nz-breadcrumb>
                  <nz-breadcrumb-item>
                    <a routerLink="../home">
                      <span nz-icon nzType="home"></span>
                      <span>Home</span>
                    </a>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>
                    <span>Work Station</span>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>
                    <span>Bidding worksheet</span>
                  </nz-breadcrumb-item>
                  <nz-breadcrumb-item>
                    <a>
                      <span>Summary</span>
                    </a>
                  </nz-breadcrumb-item>
                </nz-breadcrumb>
              </nz-row>
            </nz-card>

          </div>

          <div nz-col class="panel-header-col" nzSpan="24">
            <nz-card class="card" *ngIf="!isDataLoading">
              <app-summary-header [biddingAllInfo]="biddingAllInfo"></app-summary-header>
            </nz-card>
          </div>

        </div>

      </div>

      <div class="panel-content">

        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col">
            <app-summary-item-list [biddingAllInfo]="biddingAllInfo"
              [biddingItemListLastPriceAndAvgPrice]="biddingItemListLastPriceAndAvgPrice" [exchangeRate]="exchangeRate"
              [summaryBiddingItemListFromBidding]="summaryBiddingItemListFromBidding"
              [summaryBidding]="summaryBidding"
              (summaryBiddingItemListDataChange)="onSummaryBiddingItemListDataChange($event)"
              (summaryCostDataChange)="onSummaryCostDataChange($event)"
              (summaryCostSavingDataChange)="onSummaryCostSavingDataChange($event)"
              (itemListDataChange)="onItemlistDataChange($event)">
            </app-summary-item-list>
          </div>
        </div>

        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col">
            <app-summary-price [biddingAllInfo]="biddingAllInfo" [summaryCost]="summaryCost"
              [summaryCostSaving]="summaryCostSaving" [itemList]="itemList"></app-summary-price>
          </div>
        </div>

        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col">
            <app-summary-cost-saving [biddingAllInfo]="biddingAllInfo" [summaryCost]="summaryCost"
              [summaryCostSaving]="summaryCostSaving"></app-summary-cost-saving>
          </div>
        </div>

        <div nz-row class="collapse-row" *ngIf="!isDataLoading">
          <div nz-col nzSpan="24" class="collapse-col">
            <app-summary-attachment [biddingAllInfo]="biddingAllInfo" [biddingConfig]="biddingConfig">
            </app-summary-attachment>
          </div>
        </div>

        <div nz-row class="collapse-row">

          <div id="action-panel" nz-col nzSpan="24" class="collapse-col">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Action Panel'" [nzActive]="true">

                <form nz-form>
                  <div nz-row class="content-row">

                    <div nz-col [nzXs]="24" [nzSm]="24">

                      <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6">Remark</nz-form-label>
                        <nz-form-control [nzXs]="12" [nzSm]="12">
                          <textarea nz-input placeholder="Note" [nzAutosize]="{ minRows: 3, maxRows: 10 }"></textarea>
                        </nz-form-control>
                      </nz-form-item>

                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24">
                      <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">

                        <!-- <div class="div-button-save" style="padding: 2%">
                          <button nzSize="default" class="btn-cancel" nz-button nzType="default"
                            (click)="showSaveConfirm()" [disabled]="true">
                            Save
                          </button>
                        </div> -->

                        <div class="div-button-save" style="padding: 2%">
                          <button nzSize="default" class="btn-cancel" nz-button nzType="primary"
                            (click)="showSubmitConfirm()" [disabled]="disabledSubmit">
                            Submit
                          </button>
                        </div>

                      </div>
                    </div>
                  </div>
                </form>
              </nz-collapse-panel>
            </nz-collapse>

          </div>

        </div>

      </div>

    </div>

  </nz-spin>
</nz-layout>
