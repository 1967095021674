<nz-layout class="content">
  <nz-collapse>
    <nz-collapse-panel [nzHeader]="'Criteria'" [nzActive]="true">
      <form nz-form [formGroup]="searchingSummaryCriteriaForm">
        <div nz-row class="content-row">

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>
              <nz-form-label [nzXs]="24" [nzSm]="4">Sourcing Team</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select [nzMaxTagCount]="5" nzMode="multiple" formControlName="sourcingTeam">
                  <nz-option *ngFor="let item of sourcingTeamList" [nzLabel]="item.sourcingTeam!"
                    [nzValue]="item.sourcingTeam"></nz-option>
                </nz-select>
              </nz-form-control>

              <nz-form-label [nzXs]="24" [nzSm]="4">Purchasing Groups</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select [nzMaxTagCount]="5" nzMode="multiple" formControlName="purchasingGroups">
                  <nz-option *ngFor="let item of purGroupList" [nzLabel]="item.purGroup!" [nzValue]="item.purGroup">
                  </nz-option>
                </nz-select>
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>

              <nz-form-label [nzXs]="24" [nzSm]="4">Bidding No</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <input nz-input formControlName="biddingNo">
              </nz-form-control>


              <nz-form-label [nzXs]="24" [nzSm]="4">Create Date</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-range-picker [nzRanges]="ranges" nzFormat="d MMM y" [(ngModel)]="biddingCreateDate"
                  (ngModelChange)="onDateChange($event)" [ngModelOptions]="{standalone: true}">

                </nz-range-picker>
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <nz-form-item>

              <nz-form-label [nzXs]="24" [nzSm]="4">Status</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select [nzMaxTagCount]="5" nzMode="multiple" formControlName="status">
                  <nz-option *ngFor="let o of statusList" [nzValue]="o.value" [nzLabel]="o.label" nzCustomContent>
                    <nz-tag *ngIf="o.value === 'Wait for vendor submit price'" [nzColor]="'cyan'">
                      {{ o.label }}
                    </nz-tag>
                    <nz-tag *ngIf="o.value === 'Wait for summary'" [nzColor]="'orange'">
                      {{ o.label }}
                    </nz-tag>
                    <nz-tag *ngIf="o.value === 'Finish'" [nzColor]="'green'">
                      {{ o.label }}
                    </nz-tag>
                  </nz-option>

                </nz-select>
              </nz-form-control>


              <nz-form-label [nzXs]="24" [nzSm]="4">Vendor</nz-form-label>
              <nz-form-control [nzXs]="24" [nzSm]="8">
                <nz-select [nzMaxTagCount]="5" nzMode="multiple" formControlName="vendor">
                  <nz-option *ngFor="let item of vendorList" [nzLabel]="item.name1!" [nzValue]="item.name1"></nz-option>
                </nz-select>
              </nz-form-control>

            </nz-form-item>
          </div>

          <div nz-col [nzXs]="24" [nzSm]="24">
            <div nz-row class="div-button" [nzAlign]="'bottom'" [nzJustify]="'center'">
              <div class="div-button-save" style="padding: 2%">
                <button nzSize="default" class="btn-reset" nz-button nzType="default" (click)="onResetSearch()">
                  Reset
                </button>
              </div>

              <div class="div-button-submit" style="padding: 2%">
                <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit"
                  (click)="onSearch()">
                  Search
                </button>
              </div>
            </div>
          </div>

        </div>

      </form>
    </nz-collapse-panel>
  </nz-collapse>
</nz-layout>
