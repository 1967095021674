import { BiddingItemList } from './../../../../../../../../api/models/bidding-item-list';
import { MasterConfig } from './../../../../../../../../api/models/master-config';
import { itemList } from '../_model/itemlistData';
import { NzMessageService } from 'ng-zorro-antd/message';
import { AzureBlobService } from 'src/app/api/services';
import { HttpEvent, HttpEventType, HttpResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Guid } from 'guid-typescript';
import { NzUploadChangeParam, NzUploadXHRArgs, NzUploadFile } from 'ng-zorro-antd/upload';
import { BiddingAllInfoAndLog, FileProperties } from './../../../../../../../../api/models';
import * as XLSX from 'xlsx';
import { NzModalService } from 'ng-zorro-antd/modal';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-item-list',
  templateUrl: './item-list.component.html',
  styleUrls: ['./item-list.component.css']
})
export class ItemListComponent implements OnInit {

  @Input() steps: number = 0;
  @Input() biddingAllInfo!: BiddingAllInfoAndLog;
  @Input() additionalDetailList: string[] = [];
  @Input() biddingConfig: MasterConfig[] = [];
  @Output() itemlistTableDataChange = new EventEmitter<BiddingItemList[]>();

  formatterNumber = (value: number): string => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  url: string = "/api/AzureBlob/UploadBlob";
  acceptedFile: string = "image/jpeg,image/jpg,image/png,image/bmp,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/zip,application/x-7z-compressed,text/csv,application/vnd.ms-outloo,application/pdf";

  isGuaranteeShelfLifeChecked: boolean = false
  isBrandChecked: boolean = false;
  isSourceOfOriginChecked: boolean = false;
  isMOQChecked: boolean = false;
  isQuotationValidityChecked: boolean = false;
  quotationValidityStartDate: Date = new Date();
  quotationValidityEndDate: Date = new Date();

  itemlistTableData: BiddingItemList[] = [];
  constructor(
    private azureBlobService: AzureBlobService,
    private msg: NzMessageService,
    private modal: NzModalService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {

    const pkDetail = this.route.snapshot.queryParamMap.get('pkHeader');

    if(pkDetail)
    {
      this.biddingAllInfo.itemList?.forEach(item => {
        this.itemlistTableData = [...this.itemlistTableData, item];
      })
      this.itemlistTableDataChange.emit(this.itemlistTableData);
    }
  }

  //ยิงไป download template
  onDownloadTemplate(){
    this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: this.biddingConfig.find(item => item.key === "FileIdTemplateItemlist")?.value!,
      fileName: this.biddingConfig.find(item => item.key === "FileNameTemplateItemlist")?.value!,
    }).subscribe(
      response => {
        window.location.assign(response.url!);
    }, err => {
      console.log("Error to Download Template ",err);
    })
  }

  //เช็คไฟล์ ให้เอา .xlxs อย่างเดียว
  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {

    if (file.type !== "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.modal.error({
        nzTitle: "Invalid file type",
        nzContent: "Only support .xlxs"
      });
      return false;
    }

    console.log("Begin Reading File");
    //อ่านไฟล์
    const reader: FileReader = new FileReader();
    reader.readAsArrayBuffer(file as any);
    reader.onload = (item) => {

      try {

        //แปลงใส่ jsonObj
        const data = new Uint8Array(item.target?.result as any);
        const workbook = XLSX.read(data, { type: "array" });

        let jsonObj: Array<any> = XLSX.utils.sheet_to_json(
          workbook.Sheets[workbook.SheetNames[0]],
          { raw: false }
        );

        if (false) {
          let nObj = [];
          console.log("jsonObj: ", jsonObj);
          for (let i = 0; i < jsonObj.length; i++) {

          }
        }
        else {

          this.itemlistTableData = [];
          console.log("additionalDetailList: ",this.additionalDetailList);

          //ดันช่อง array ให้ข้ามช่อง example ใน template
          jsonObj.shift();
          jsonObj.shift();

          jsonObj.map((item, index)=> {
            let obj: BiddingItemList;

            //ช่อง quantity ต้องเป็นตัวเลขเท่านั้น
            if(isNaN(parseFloat(item['*Quantity'])))
            {
              if(item['*Quantity'])
              {
                throw new Error("Invalid Quantity format at row "+(index+4)+" ("+ item['*Quantity'] +")");
              }
              else
              {
                throw new Error("Quantity should not be empty at Row "+(index+4));
              }

            }

            obj = {
              no : index+1,
              matCode : item['*Mat Code'] ? item['*Mat Code'] : null,
              itemName : item['*Item Name'] ? item['*Item Name'] : null,
              description : item['Description'] ? item['Description'] : null,
              unit : item['*Unit'] ? item['*Unit'] : null,
              quantity : item['*Quantity'] ?  parseFloat(item['*Quantity'].replace(/(,*)/g, '')) : null,
              note : item['Note'] ? item['Note'] : null,
            }

            this.itemlistTableData = [
              ...this.itemlistTableData, obj
            ]

          })

          this.itemlistTableDataChange.emit(this.itemlistTableData);
        }

      }
      catch (err) {

        this.modal.error({
          nzTitle: "Error",
          nzContent: (err as Error).message,
        })

        console.log("Error Read Xlxs: ", err);
      }

    };

    return true;
  };

  deleteItem(fileID: string, fileGroup: string) {

  }

}
