import { NzStatusType } from 'ng-zorro-antd/steps';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-new-memo-steps',
  templateUrl: './create-new-memo-steps.component.html',
  styleUrls: ['./create-new-memo-steps.component.css']
})
export class CreateNewMemoStepsComponent implements OnInit {

  @Input() steps: number = 0;
  @Input() status: NzStatusType = 'process';
  constructor() { }

  ngOnInit(): void {
  }

}
