<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-content" *ngIf="!isDataLoading">
        <div nz-row class="collapse-row">
          <nz-col nzSpan="24" class="collapse-col">

            <app-searching-criteria-request-contract-log [searchingForm]="searchingForm"
              [sourcingTeamList]="sourcingTeamList"
              (onClickSearch)="onClickSearch($event)"
              (onClickReset)="onClickReset($event)"
              >
            </app-searching-criteria-request-contract-log>
          </nz-col>

          <nz-col nzSpan="24" class="collapse-col">
            <app-searching-result-request-contract-log [logData]="logData">

            </app-searching-result-request-contract-log>
          </nz-col>


        </div>
      </div>

    </div>
  </nz-spin>
</nz-layout>
