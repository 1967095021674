import { BiddingItemList } from './../../../../../../../api/models/bidding-item-list';
import { Component, Input, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SummaryBiddingAllInfo } from '../../../../../../../api/models';
import { SummaryCostItemList, SummaryCostSaving } from '../model/SummaryItemList';
import * as XLSX from 'xlsx';
import * as htmlToImage from 'html-to-image';
import * as moment from 'moment';

@Component({
  selector: 'app-summary-price',
  templateUrl: './summary-price.component.html',
  styleUrls: ['./summary-price.component.css']
})
export class SummaryPriceComponent implements OnInit {

  @Input() biddingAllInfo: SummaryBiddingAllInfo = {};
  @Input() summaryCost: SummaryCostItemList[] = [];
  @Input() summaryCostSaving!: SummaryCostSaving;
  @Input() itemList: BiddingItemList[] = [];

  constructor() { }

  ngOnInit(): void {

  }


  exportSummaryPriceToJPEG() {
    var node = document.getElementById('summaryPrice');
    var doc: string = this.biddingAllInfo.header?.docNo!;

    //แปลง html to jpeg
    htmlToImage.toJpeg(node!)
      .then((dataUrl) => {
        var link = document.createElement("a");
        link.download = doc.replace(/-/g, "_") + "_Summary_Price";
        link.href = dataUrl;
        link.click();
      })
      .catch(function (error) {
        console.error('oops, something went wrong!', error);
      });
  }

  exportSummaryPriceToExcel() {

    //แปลง html to excel
    var ws = XLSX.utils.table_to_sheet(document.getElementById('summaryPrice'));
    var wb = XLSX.utils.book_new();
    var docNo: string = this.biddingAllInfo.header?.docNo?.replace(/"-"/g, "_")!;

    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, docNo+"_Summary_Price.xlsx");

  }


}
