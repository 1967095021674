<nz-layout class="content">
  <div nz-col nzSpan="24" class="collapse-col">

    <nz-card nzTitle="Searching result">

      <div nz-col [nzXs]="24" [nzSm]="24">
        <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'end'">
          <div class="div-button-new" style="padding: 2%">
            <button nzSize="default" class="btn-new" nz-button nzSize="default" nzType="primary"
              routerLink="new-bidding">
              Create New Bidding
            </button>
          </div>
        </div>
      </div>

      <nz-table class="table-dashboard" #SearchResult [nzData]="searchList" [nzBordered]="true"
        [nzOuterBordered]="false" [nzShowSizeChanger]="true" [nzPageSize]="10" [nzPageSizeOptions]="[10, 20, 50, 100]"
        [nzScroll]="{ x: '1000px' }">
        <thead>
          <tr>
            <th nzWidth="20px"></th>
            <th nzWidth="100px">Bidding No</th>
            <th nzShowSort nzSortOrder="descend" [nzSortFn]="sortDateFn" nzWidth="80px">Created Date</th>
            <th nzWidth="80px">Bidding Date</th>
            <th nzWidth="80px">Bidding Deadline</th>
            <th nzWidth="100px">Status</th>
            <th nzWidth="60px">Pur Group</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let data of SearchResult.data; index as i">
            <tr class="editable-row">
              <td [nzExpand]="expandSet.has(i)" (nzExpandChange)="onExpandChange(i, $event)"></td>
              <td>
                <a [routerLink]="['../request-for-price-list/new-bidding']"
                  [queryParams]="{ pkHeader: data.header?.pkbiddingHeader }" target="_blank">
                  {{ data.header?.docNo }}
                </a>
              </td>
              <td>
                {{ data.header?.createDate | date: "d MMM y" }}
              </td>
              <td>
                <div *ngIf="data.header?.status !== 'Draft'">
                  {{ data.header?.biddingStartDate | date: "d MMM y" }}
                </div>
              </td>
              <td>
                <ng-container *ngIf="data.header?.status !== 'Draft'">
                  <div *ngIf="deadlineWarning[i]" style="color: red;">
                    {{ data.header?.deadline | date: "d MMM y"}}
                  </div>
                  <div *ngIf="!deadlineWarning[i]">
                    {{ data.header?.deadline | date: "d MMM y"}}
                  </div>
                </ng-container>
              </td>
              <td>
                <nz-tag *ngIf="data.header?.status === 'Wait for vendor submit price'" [nzColor]="'cyan'">
                  {{ data.header?.status }}
                </nz-tag>
                <nz-tag *ngIf="data.header?.status === 'Draft'" nzColor="default">
                  {{ data.header?.status }}
                </nz-tag>
                <nz-tag *ngIf="data.header?.status === 'Wait for summary'" [nzColor]="'orange'">
                  {{ data.header?.status }}
                </nz-tag>
                <nz-tag *ngIf="data.header?.status === 'Finish'" [nzColor]="'green'">
                  Ready to create MEMO
                </nz-tag>
              </td>
              <td>
                {{ data.header?.sourcingPurchasingGroups }}
              </td>
            </tr>
            <tr [nzExpand]="expandSet.has(i)">
              <nz-table #innerTable [nzData]="data.detail!" nzSize="small" [nzShowPagination]="false"
                [nzScroll]="{ x: '300px' }">
                <thead>
                  <tr>
                    <th nzWidth="50px">Vendor Name</th>
                    <th nzWidth="10px">No. of Notify</th>
                    <th nzWidth="10px">Status</th>
                  </tr>
                </thead>
        <tbody>
          <tr *ngFor="let data of data.detail">
            <td>
              <ng-container *ngIf="searchList[i].header!.status !== 'Draft'">
                <a [routerLink]="['../bidding-detail']" [queryParams]="{ pkDetail: data?.pkbiddingDetail }"
                  target="_blank">
                  {{ data.vendorName }}
                </a>
              </ng-container>

              <ng-container *ngIf="searchList[i].header!.status === 'Draft'">
                {{ data.vendorName }}
              </ng-container>
            </td>
            <td>{{ data.noOfNotify }}</td>
            <td>
              <nz-tag *ngIf="data.status == 'Unsubmitted'" nzColor="red">
                {{ data.status }}
              </nz-tag>
              <nz-tag *ngIf="data.status == 'Submitted'" nzColor="green">
                {{ data.status }}
              </nz-tag>
            </td>
          </tr>
        </tbody>
      </nz-table>
      </tr>
      </ng-container>
      </tbody>
      </nz-table>
    </nz-card>
  </div>
</nz-layout>
