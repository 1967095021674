import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { AuthenticationService } from './../api/services/authentication.service';
import { async } from '@angular/core/testing';
import { MdmGetMemberInfoResponseData } from './../api/models/mdm-get-member-info-response-data';
import { UserDetailMDM } from './../models/UserDetailMDM';
import { UserDetailSSO } from './../models/UserDetailSSO';
import { MdmService } from './../api/services/mdm.service';
import { environment } from './../../environments/environment';
import { Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { MdmGetMemberAndRoleResponseData } from '../api/models';
import { Location } from '@angular/common';
import { filter, pairwise } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private token!: string | null;
  private isLogin: boolean = false;
  private userRole: string = "";

  private userInfoSSO!: UserDetailSSO;
  private userInfoMDM!: MdmGetMemberInfoResponseData[];

  previousUrl: string = "";
  currentUrl: string = "";
  constructor(
    private http: HttpClient,
    private mdmService: MdmService,
    private ssoService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location) {

    if (localStorage.getItem("access_token") === null || localStorage.getItem("access_token") === '') {
      localStorage.setItem("access_token", window.location.search.replace("?", "&"));
    }

  }

  isUserLoggedIn(): boolean {
    return this.isLogin;
  }

  currentRoute(): string {
    return this.router.url;
  }

  currentUserRole(): string {
    return this.userRole;
  }

  currentUserInfoMDM(): MdmGetMemberInfoResponseData[] {
    return this.userInfoMDM;
  }

  currentUserDetailSSO(): UserDetailSSO {
    return this.userInfoSSO;
  }

  currentUserFullName(): string {
    return this.userInfoSSO.information.first_name + " " + this.userInfoSSO.information.last_name;
  }

  currentUserDomain(): string {
    return this.userInfoMDM[0].domain!;
  }

  currentUserEmail(): string {
    return this.userInfoMDM[0].email!;
  }

  currectUserFullName(): string {
    return this.userInfoMDM[0].eFullName!;
  }


  public async checkToken(): Promise<boolean> {
    return true;
  }

  public async checkLogin(): Promise<boolean> {
    if (localStorage.getItem("access_token") === null || localStorage.getItem("access_token") === '') {
      console.log("[Auth Service] : NO TOKEN");
      this.isLogin = false;
      localStorage.clear();
      window.location.assign(environment.APP_LOGIN);
      return false;
    }
    else {
      this.token = localStorage.getItem("access_token");

      await this.http.get(environment.APP_GET_USER_DETAIL + this.token)
        .toPromise()
        .then(
          response => {
            this.userInfoSSO = JSON.parse(JSON.stringify(response));
            localStorage.setItem("user_data", JSON.stringify(this.userInfoSSO));

            console.log("[Auth] : VALID USER");
            this.isLogin = true;

            const pk = this.route.snapshot.queryParamMap.get("pk");

            if (window.location.search.startsWith("?access_token")) {

              this.router.navigateByUrl(localStorage.getItem("prevURL")?.toString()!);
            }

            return true;

          })
        .catch(
          (err) => {
            this.isLogin = false;
            console.log(err);
            localStorage.clear();
            window.location.assign(environment.APP_LOGIN);
            console.log("[Auth] : UNAUTHORIZED");
          })

      return false;
    }

  }

  public logOut() {
    if (localStorage.getItem("access_token") === null || localStorage.getItem("access_token") === '') {
      window.location.assign(environment.APP_LOGIN);
    }
    else {
      const tokenLogout = localStorage.getItem("access_token")?.split("&")[1].split("=")[1];
      const headers = { 'Authorization': 'Bearer ' + tokenLogout };
      this.http.delete(environment.APP_LOGOUT, { headers })
        .subscribe({
          next: (response: any) => {
            console.log(response);
            localStorage.clear();
            window.location.assign(environment.APP_LOGOUT_PAGE);
          },
          error: (err) => {
            console.log(err);
          },

        })
    }

  }


  async getUserInfoDataFromMDM() {

    var user_data: UserDetailSSO = JSON.parse(localStorage.getItem("user_data") || "{}");
    this.userInfoSSO = user_data;

    // if (localStorage.getItem("UserInfo") && localStorage.getItem("UserInfo") !== "") {
    //   this.userInfoMDM = JSON.parse(JSON.stringify(localStorage.getItem("UserInfo")))!;
    //   return;
    // }

    await this.mdmService.apiMdmGetMemberInfoByEmailPost$Json({
      email: user_data.email,
    })
      .toPromise()
      .then(
        response => {
          this.userInfoMDM = response.responseData!;
          // localStorage.setItem("UserInfo", JSON.stringify(this.userInfoMDM));
        })
      .catch(
        err => {
          this.userInfoMDM = [];
          console.log("Invalid or Not SCG User");
        })
  }

  async getUserRoleDataFromMDM() {

    // console.log("[Auth] Check User Role: ", this.userInfoMDM);
    if (this.userInfoMDM.length == 0) {
      window.location.search.startsWith("?pklink")
        ? this.userRole = "Vendor"
        : this.userRole = "Normal User";

      if (this.userRole === "Normal User") {
        this.userInfoMDM.push({});
      }
      return;
    }

    if (localStorage.getItem("UserRole") && localStorage.getItem("UserRole") !== "") {
      this.userRole = localStorage.getItem("UserRole")!;
      return;
    }

    await this.mdmService.apiMdmMembersAndRolesInfoByAdAccountPost$Json({
      domain: this.userInfoMDM[0].domain!,
      adAccount: this.userInfoMDM[0].adAccount!,
    })
      .toPromise()
      .then(
        response => {

          var mdmUserRole: MdmGetMemberAndRoleResponseData = response.responseData!;

          if (mdmUserRole && mdmUserRole.roles) {
            this.userRole = mdmUserRole.roles[0].roleName!;
          }
          else {
            if (this.userInfoMDM[0].adAccount == "2022trainees48") {
              this.userRole = "Procurement Admin";
              localStorage.setItem("UserRole", "Procurement Admin");
              return;
            }
            else {
              this.userRole = "Normal User";
              localStorage.setItem("UserRole", "Normal User");
              return;
            }
          }

        })
      .catch(
        error => {
          this.userRole = "Normal User"
        }
      )
  }
}
