<nz-layout class="content">
  <nz-spin [nzSpinning]="isLoading" nzTip="Loading..." [nzSize]="'default'">
    <nz-collapse>
      <nz-collapse-panel [nzHeader]="'Select Approver'" [nzActive]="true">
        <!-- <form nz-form [formGroup]="userApproveForm" (change)="updateData(userApproveForm.value)"> -->
        <form nz-form [formGroup]="userApproveForm" (change)="updateData(userApproveForm)">
          <div nz-row class="content-row">
            <div nz-col [nzXs]="24" [nzSm]="24">
              <nz-form-item>
                <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>SM User Approve</nz-form-label>
                <nz-form-control [nzXs]="6" [nzSm]="8">

                  <nz-select nzMode="default" nzPlaceHolder="Search" [nzShowArrow]="false" [nzOptionHeightPx]="58"
                    [nzOptionOverflowSize]="4" nzShowSearch nzServerSearch [(ngModel)]="selectedSM"
                    (ngModelChange)="selectSM($event)" [ngModelOptions]="{standalone: true}"
                    (nzOnSearch)="onSearchSM($event)" [disabled]="steps != 0">

                    <nz-option [nzLabel]="selectedSM" [nzValue]="selectedSM" nzHide></nz-option>

                    <ng-container *ngFor="let o of optionListSM; index as i;">
                      <nz-option *ngIf="!isSearching" nzCustomContent [nzValue]="i">
                        <div class="customOption">
                          <h2 class="requester-name">{{o.eFullName}}</h2>
                          <p class="requester-org-name">{{o.organizationName}}</p>
                        </div>
                      </nz-option>

                    </ng-container>

                    <nz-option *ngIf="isSearching" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Searching...
                    </nz-option>

                  </nz-select>

                </nz-form-control>

                <button nz-button class="btn-redo" nzType="primary" [nzSize]="'default'" nz-popconfirm
                  nzPopconfirmTitle="Change SM to Default?" (nzOnConfirm)="redoSM()" nzDanger [disabled]="steps != 0">
                  <i nz-icon nzType="undo" nzTheme="outline" class="icon-redo"></i>

                </button>

                <nz-form-label [nzXs]="6" [nzSm]="4">Company</nz-form-label>
                <nz-form-control [nzXs]="6" [nzSm]="8">

                  {{userApproveForm.get('sm_approver_company')?.value}}
                </nz-form-control>

              </nz-form-item>


            </div>





            <div *ngIf="estimatedContractValue >= 2000000" nz-col [nzXs]="24" [nzSm]="24">
              <nz-form-item>
                <nz-form-label [nzXs]="6" [nzSm]="4" nzRequired>PM User Approve</nz-form-label>
                <nz-form-control [nzXs]="6" [nzSm]="8">

                  <nz-select nzMode="default" nzPlaceHolder="Search" [nzShowArrow]="false" [nzOptionHeightPx]="58"
                    [nzOptionOverflowSize]="4" nzShowSearch nzServerSearch [(ngModel)]="selectedPM"
                    (ngModelChange)="selectPM($event)" [ngModelOptions]="{standalone: true}"
                    (nzOnSearch)="onSearchPM($event)" [nzDisabled]="steps != 0">

                    <nz-option [nzLabel]="selectedPM" [nzValue]="selectedPM" nzHide></nz-option>

                    <ng-container *ngFor="let o of optionListPM; index as i;">
                      <nz-option *ngIf="!isLoading" nzCustomContent [nzValue]="i">
                        <div class="customOption">
                          <h2 class="requester-name">{{o.eFullName}}</h2>
                          <p class="requester-org-name">{{o.organizationName}}</p>
                        </div>
                      </nz-option>

                    </ng-container>

                    <nz-option *ngIf="isLoading" nzDisabled nzCustomContent>
                      <i nz-icon nzType="loading" class="loading-icon"></i>
                      Searching...
                    </nz-option>

                  </nz-select>

                </nz-form-control>

                <button nz-button class="btn-redo" nzType="primary" [nzSize]="'default'" nzDanger nz-popconfirm
                  nzPopconfirmTitle="Change PM to Default?" (nzOnConfirm)="redoPM()" [disabled]="steps != 0">
                  <i nz-icon nzType="undo" nzTheme="outline" class="icon-redo"></i>

                </button>

                <nz-form-label [nzXs]="6" [nzSm]="4">Company</nz-form-label>
                <nz-form-control [nzXs]="6" [nzSm]="8">

                  {{userApproveForm.get('pm_approver_company')?.value}}
                </nz-form-control>
              </nz-form-item>

            </div>


          </div>

        </form>
      </nz-collapse-panel>
    </nz-collapse>
  </nz-spin>


</nz-layout>
