import { MasterConfig } from './../../../../../api/models/master-config';
import { FileProperties } from './../../../../../api/models/file-properties';
import { UserDetailSSO } from './../../../../../models/UserDetailSSO';
import { environment } from './../../../../../../environments/environment';
import { AzureBlobService } from './../../../../../api/services/azure-blob.service';
import { HttpRequest, HttpClient, HttpEventType, HttpEvent, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BlockBlobClient, ContainerClient } from '@azure/storage-blob';
import { async } from '@angular/core/testing';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzUploadChangeParam, NzUploadXHRArgs, NzUploadFile } from 'ng-zorro-antd/upload';
import { Url } from 'url';
import { read, utimes } from 'fs';
import { Guid } from 'guid-typescript';
import { NzModalService } from 'ng-zorro-antd/modal';
import { toBase64String } from '@angular/compiler/src/output/source_map';
import { concatMap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { RequestContractAllService } from './../../../../../api/services';
import { fileList } from '../_model/fileListModel';
import { RcfAllInfo } from './../../../../../api/models/rcf-all-info';


@Component({
  selector: 'app-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.css']
})
export class AttachmentComponent implements OnInit {

  @Input() requestContractAllInfo!: RcfAllInfo[];
  @Input() steps!: number;
  @Input() rcfConfig: MasterConfig[] = [];
  @Output() requestAttachmentFileListChange = new EventEmitter<fileList[]>();

  url: string = "/api/AzureBlob/UploadBlob";
  // acceptedFile: string = "image/jpeg,image/jpg,image/png,image/bmp,image/gif,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.presentation,text/plain,application/zip,application/x-7z-compressed,text/csv,application/vnd.ms-outloo,application/pdf";
  fileListTOR: fileList[] = [];
  fileListTORTest: FileProperties[] = [];
  fileListTORData: any[] = [];

  fileListOther: fileList[] = [];
  fileListOtherData: fileList[] = [];
  fileListALL: fileList[] = [];
  fileUploadGroup: string = "";
  userDetailSSO!: UserDetailSSO;

  attachmentFileSizeInByte: number = 0;
  attachmentFileType: string = "";

  constructor(
    private msg: NzMessageService,
    private http: HttpClient,
    private azureBlobService: AzureBlobService,
    private rcfAllService: RequestContractAllService,
    private modal: NzModalService,
    private datepipe: DatePipe,

  ) {
    this.userDetailSSO = JSON.parse(localStorage.getItem("user_data") || "{}");
  }

  ngOnInit(): void {
  }

  // updateFilelist(value: FileList[])
  // {
  //   // this.requestAttachmentFileListChange.emit(value);
  // }

  setMediaUploadHeaders = (file: NzUploadFile) => {
    return {
      "Accept": "*/*",
      // "Content-Type": "multipart/form-data; boundary=WebAppBoundary",
      "Content-Type": "multipart/form-data;",
    }
  };

  async ngOnChanges(change: SimpleChanges) {

    if (change.hasOwnProperty("rcfConfig") && change.rcfConfig.currentValue.length != 0) {
      this.attachmentFileSizeInByte = Number(this.rcfConfig[this.rcfConfig.findIndex(item => item.key == "AttachmentFileSizeInByte")].value);
      this.attachmentFileType = JSON.parse("[" + this.rcfConfig[this.rcfConfig.findIndex(item => item.key == "AttachmentFileType")].value! + "]");
    }

    if (change.hasOwnProperty("requestContractAllInfo") && change.requestContractAllInfo.currentValue.length != 0) {

      for (let i = 0; i < change.requestContractAllInfo.currentValue[0].file.length; i++) {
        if (change.requestContractAllInfo.currentValue[0].file[i].fileGroup == "RequestContractTOR") {

          await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
            fileID: change.requestContractAllInfo.currentValue[0].file[i].fileId,
            fileName: change.requestContractAllInfo.currentValue[0].file[i].fileName,
          }).toPromise().then(
            response => {
              if (response.url != null) {

                let obj: fileList = {
                  fileDownload: response.url,
                  fileID: change.requestContractAllInfo.currentValue[0].file[i].fileId,
                  fileName: change.requestContractAllInfo.currentValue[0].file[i].fileName,
                  fileGroup: change.requestContractAllInfo.currentValue[0].file[i].fileGroup,
                  // lastModified: change.requestContractAllInfo.currentValue[0].file[i].lastModifiedDate.split("T")[0]+" "+change.requestContractAllInfo.currentValue[0].file[i].lastModifiedDate.split("T")[1],
                  lastModified: this.datepipe.transform(change.requestContractAllInfo.currentValue[0].file[i].lastModifiedDate, "yyyy-MM-dd HH:mm")!,
                  fileSizeinByte: change.requestContractAllInfo.currentValue[0].file[i].fileSizeInByte.toFixed(2),
                }

                // this.fileListTOR.push(obj);
                this.fileListTOR = [...this.fileListTOR, obj];
              }

            }
          )
        }
        else {
          // this.fileListOther.push(change.requestContractAllInfo.currentValue[0].file[i]);

          await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
            fileID: change.requestContractAllInfo.currentValue[0].file[i].fileId,
            fileName: change.requestContractAllInfo.currentValue[0].file[i].fileName,
          }).toPromise().then(
            response => {
              if (response.url != null) {
                let obj: fileList = {
                  fileDownload: response.url,
                  fileID: change.requestContractAllInfo.currentValue[0].file[i].fileId,
                  fileName: change.requestContractAllInfo.currentValue[0].file[i].fileName,
                  fileGroup: change.requestContractAllInfo.currentValue[0].file[i].fileGroup,
                  // lastModified: change.requestContractAllInfo.currentValue[0].file[i].lastModifiedDate.split("T")[0]+" "+change.requestContractAllInfo.currentValue[0].file[i].lastModifiedDate.split("T")[1],
                  lastModified: this.datepipe.transform(change.requestContractAllInfo.currentValue[0].file[i].lastModifiedDate, "yyyy-MM-dd HH:mm")!,
                  fileSizeinByte: change.requestContractAllInfo.currentValue[0].file[i].fileSizeInByte.toFixed(2),
                }

                // this.fileListOther.push(obj);
                this.fileListOther = [...this.fileListOther, obj];

              }

            }
          )
        }
      }

      this.updateFileList();
    }

  }

  async onUploadChange({ file, fileList }: NzUploadChangeParam): Promise<void> {
    const status = file.status;

    if (status !== 'uploading') {


    }

    if (status === 'uploading') {
      this.msg.loading(`${file.name} uploading.`, { nzDuration: 0 })
    }
    if (status === 'done') {

      // this.msg.remove(this.msgLoad);
      // this.msg.success(`${file.name} file uploaded successfully.`);
      console.log("Download ", file.linkProps);
      var objRes: fileList = JSON.parse(file.response);
      objRes.fileGroup = this.fileUploadGroup;
      objRes.fileSizeinByte = objRes.fileSizeinByte / 1000000;
      // objRes.lastModified = this.datepipe.transform(objRes.lastModified.split("T")[0],"yyyy-MM-dd " + new Date().toLocaleString('en-GB').split(',')[1])!;
      objRes.lastModified = this.datepipe.transform(objRes.lastModified, "yyyy-MM-dd HH:mm")!;

      await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
        fileID: objRes.fileID,
        fileName: objRes.fileName,
      }).toPromise().then(
        response => {
          if (response.url != null) {
            objRes.fileDownload = response.url
          }
        }
      )
      await this.rcfAllService.apiRcfAllServiceUploadFileListPost({
        body: {
          fileId: objRes.fileID,
          fileName: objRes.fileName,
          fileSizeInByte: objRes.fileSizeinByte,
          modifiedByName: this.userDetailSSO.information.first_name + " " + this.userDetailSSO.information.last_name,
          fileGroup: this.fileUploadGroup,
          isActive: true,
        },
      })
        .toPromise()
        .then(
          response => {
            console.log("Upload filelist to DB: ", response);
          }
        )
        .catch(
          err => {
            console.log("Upload filelist to DB [FAILED]", err);
          }
        )

      if (this.fileUploadGroup == "RequestContractTOR") {
        // this.fileListTOR.push(objRes);
        this.fileListTOR = [...this.fileListTOR, objRes];
      }
      else if (this.fileUploadGroup == "RequestContractOther") {
        // this.fileListOther.push(objRes);
        this.fileListOther = [...this.fileListOther, objRes];
      }

      this.msg.remove();
      this.msg.success(`${file.name} file uploaded successfully.`);
      this.updateFileList();

      // this.getFileListTOR();
      // this.getFileListOther();

    }
    else if (status === 'error') {
      this.msg.remove();
      this.msg.error(`${file.name} file upload failed.`);
    }
  }

  async downloadFile(fileName: string, fileID: string) {
    console.log("FileName: ", fileName, " FileId ", fileID);

    await this.azureBlobService.apiAzureBlobDownloadFileGet$Response({
      fileID: fileID,
      fileName: fileName,
    }).toPromise().then(
      response => {
        console.log("link : ", response.url);
      }
    )

    return;
  }

  beforeUpload = (file: NzUploadFile, fileList: NzUploadFile[]): boolean => {
    console.log("Before Upload");
    console.log("File Type: ", file.type);

    let isFileTypeValid: boolean = false;
    for (let i = 0; i < this.attachmentFileType[0].length; i++) {
      if (file.type == this.attachmentFileType[0][i]) {
        isFileTypeValid = true;
        break;
      }
    }

    if (isFileTypeValid == false) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "Invalid File Type"
      });

      return false;
    }

    if (file.size! > this.attachmentFileSizeInByte) {
      this.modal.error({
        nzTitle: "Upload Failed",
        nzContent: "File size limit < 20 MB"
      });

      return false;
    }

    return true;
  }

  fileData = (item: NzUploadFile) => {
    item.uid = Guid.create().toString();

    return item;
  }

  onUploadFile = (item: NzUploadXHRArgs) => {
    const formData = new FormData();
    formData.append('file', item.file as any); // tslint:disable-next-line:no-any

    return this.azureBlobService.apiAzureBlobUploadBlobPut$Response({
      body: {
        files: item.file as any,
      },

    }).subscribe((e: HttpEvent<any>) => {
      if (e.type === HttpEventType.UploadProgress) {
        if (e.total! > 0) {
          (e as any).percent = e.loaded / e.total! * 100; // tslint:disable-next-line:no-any
        }
        // To process the upload progress bar, you must specify the `percent` attribute to indicate progress.
        item.onProgress!(e, item.file);
      } else if (e instanceof HttpResponse) { /* success */
        console.log("FILE TOR UPLOADED");
        this.fileUploadGroup = "RequestContractTOR";
        item.onSuccess!(e.body, item.file, e);
        // var body = JSON.parse(JSON.stringify(e)).body;
        // var obj = JSON.parse(body);
        // console.log("response body: ",JSON.parse(obj));

      }
    }, (err) => { /* error */
      item.onError!(err, item.file);
    });


  }

  onUploadFileOther = (item: NzUploadXHRArgs) => {

    const formData = new FormData();
    formData.append('file', item.file as any); // tslint:disable-next-line:no-any

    return this.azureBlobService.apiAzureBlobUploadBlobPut$Response({
      body: {
        files: item.file as any,
      },

    }).subscribe((e: HttpEvent<any>) => {

      if (e.type === HttpEventType.UploadProgress) {
        if (e.total! > 0) {
          (e as any).percent = e.loaded / e.total! * 100; // tslint:disable-next-line:no-any
        }
        // To process the upload progress bar, you must specify the `percent` attribute to indicate progress.
        item.onProgress!(e, item.file);
      } else if (e instanceof HttpResponse) { /* success */
        console.log("FILE OTHER UPLOADED");
        this.fileUploadGroup = "RequestContractOther";
        item.onSuccess!(e.body, item.file, e);

      }
    }, (err) => { /* error */
      item.onError!(err, item.file);
    });


  }

  getFileListTOR() {
    this.azureBlobService.apiAzureBlobGetBlobListByDocNoGet$Json({
      docNo: "PMM-TEST_TO_UPLOAD" + "/TOR",
    }).subscribe({
      next: (response) => {
        this.fileListTOR = [];
        response.forEach((item) => {
          var fileName = item.split('/')[2];
          // let obj: fileList = {
          //   pathName: item,
          //   name: fileName,
          // };
          // this.fileListTOR.push(obj);
          console.log(this.fileListTOR);
        })
      }
    });
  }

  getFileListOther() {
    this.azureBlobService.apiAzureBlobGetBlobListByDocNoGet$Json({
      docNo: "PMM-TEST_TO_UPLOAD" + "/Other",
    }).subscribe({
      next: (response) => {
        this.fileListOther = [];
        response.forEach((item) => {
          console.log(this.fileListOther);
        })
      }
    });
  }
  deleteItem(fileID: string, fileGroup: string) {
    if (fileGroup == "RequestContractTOR") {
      this.fileListTOR = this.fileListTOR.filter(function (item) {
        return item.fileID !== fileID;
      })
    }
    else if (fileGroup == "RequestContractOther") {
      this.fileListOther = this.fileListOther.filter(function (item) {
        return item.fileID !== fileID;
      })
    }
    else {
      this.msg.error("File not found");
    }

    this.updateFileList();

  }
  updateFileList() {
    this.fileListALL = [];

    for (let i = 0; i < this.fileListTOR.length; i++) {
      this.fileListALL.push(this.fileListTOR[i]);
    }

    for (let i = 0; i < this.fileListOther.length; i++) {
      this.fileListALL.push(this.fileListOther[i]);
    }

    this.requestAttachmentFileListChange.emit(this.fileListALL);

  }

}


