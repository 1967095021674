<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" nzTip="Loading..." [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-header">
        <nz-row>
          <nz-card class="card">
            <nz-breadcrumb>
              <nz-breadcrumb-item>
                <a routerLink="../home">
                  <span nz-icon nzType="home"></span>
                  <span>Home</span>
                </a>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <span>Work Station</span>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <span>Bidding worksheet</span>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <a>
                  <span>Request for price list</span>
                </a>
              </nz-breadcrumb-item>
              <nz-breadcrumb-item>
                <a>
                  <span>New bidding request</span>
                </a>
              </nz-breadcrumb-item>
            </nz-breadcrumb>
          </nz-card>
        </nz-row>

      <nz-row>
        <nz-card class="card" *ngIf="!isDataLoading && steps != 0" >
          <app-new-bidding-header [biddingAllInfo]="biddingAllinfo" [steps]="steps"> </app-new-bidding-header>
        </nz-card>
      </nz-row>

      <nz-row>
        <nz-card class="card" *ngIf="!isDataLoading">
          <app-steps-bidding [steps]="steps" [status]="status">
          </app-steps-bidding>
        </nz-card>
      </nz-row>

      </div>

      <div class="panel-content" *ngIf="!isDataLoading">
        <div nz-row class="collapse-row">

          <div id="sourcing-contract" nz-col nzSpan="24" class="collapse-col">
            <app-sourcing-contract [sourcingContractForm]="sourcingContractForm" [sourcingTeam]="sourcingTeam"
              [purGroup]="purGroup" [purGroupMember]="purGroupMember" [biddingAllInfo]="biddingAllinfo" [steps]="steps"
              [selectPurGroupStatus]="selectPurGroupStatus">
            </app-sourcing-contract>
          </div>

        </div>

        <div nz-row class="collapse-row">

          <div id="bidding-detail" nz-col nzSpan="24" class="collapse-col">
            <app-bidding-detail (additionalDetailListChange)="onAdditionalDetailListChange($event)"
              [biddingDetailForm]="biddingDetailForm" [baseOn]="baseOn" [incotermList]="incotermList"
              [paymentTermList]="paymentTermList" [biddingAllInfo]="biddingAllinfo" [steps]="steps"
              [contractPeroidInputStatus]="contractPeroidInputStatus" [deadlineInputStatus]="deadlineInputStatus">
            </app-bidding-detail>
          </div>

        </div>

        <div nz-row class="collapse-row">

          <div id="document" nz-col nzSpan="24" class="collapse-col">
            <app-document [biddingConfig]="biddingConfig" (documentFileListChange)="onDocumentFileListChange($event)"
              [biddingAllInfo]="biddingAllinfo" [steps]="steps">
            </app-document>
          </div>

        </div>

        <div nz-row class="collapse-row">

          <div id="item-list" nz-col nzSpan="24" class="collapse-col">
            <app-item-list [additionalDetailList]="additionalDetailList" [biddingConfig]="biddingConfig"
              (itemlistTableDataChange)="onItemListDataChange($event)" [biddingAllInfo]="biddingAllinfo"
              [steps]="steps">
            </app-item-list>
          </div>

        </div>

        <div nz-row class="collapse-row">

          <div id="add-vendor" nz-col nzSpan="24" class="collapse-col">
            <app-add-vendor (vendorListChange)="onVendorListChange($event)"
              (vendorEmailChange)="onVendorEmailChange($event)" (vendorCode)="getVendorEmailList($event)"
              [vendorNameList]="vendorNameList" [biddingAllInfo]="biddingAllinfo" [steps]="steps">
            </app-add-vendor>
          </div>

        </div>

        <div nz-row class="collapse-row">

          <div id="edit-email" nz-col nzSpan="24" class="collapse-col">
            <app-new-bidding-edit-email [emailTemplateTH]="emailTemplateTH" [emailTemplateENG]="emailTemplateENG"
              [steps]="steps"></app-new-bidding-edit-email>
          </div>

        </div>


        <div nz-row class="collapse-row">

          <div id="action-panel" nz-col nzSpan="24" class="collapse-col">
            <nz-collapse>
              <nz-collapse-panel [nzHeader]="'Action Panel'" [nzActive]="true">


                <form nz-form [formGroup]="actionForm">
                  <div nz-row class="content-row">

                    <div nz-col [nzXs]="24" [nzSm]="24">

                      <nz-form-item>
                        <nz-form-label [nzXs]="6" [nzSm]="6">Remark</nz-form-label>
                        <nz-form-control [nzXs]="12" [nzSm]="12">
                          <textarea nz-input placeholder="Note" [nzAutosize]="{ minRows: 3, maxRows: 10 }"
                            formControlName="remark"></textarea>
                        </nz-form-control>
                      </nz-form-item>

                    </div>

                    <div nz-col [nzXs]="24" [nzSm]="24">
                      <div nz-row class="div-button" [nzAlign]="'middle'" [nzJustify]="'center'">
                        <div class="div-button-delete" style="padding: 2%" *ngIf="worksheetStatus === 'Draft'">
                          <button class="btn-delete" nz-button nzType="primary" nzDanger (click)="onDeleteDraft()">
                            Delete Draft
                          </button>
                        </div>

                        <div class="div-button-save" style="padding: 2%" *ngIf="steps == 0">
                          <button nzSize="default" class="btn-cancel" nz-button nzType="default"
                            (click)="showSaveConfirm()">
                            Save Draft
                          </button>
                        </div>

                        <div class="div-button-preview" style="padding: 2%">
                          <button class="btn-preview" nz-button nzType="dashed" (click)="onPreviewEmail(tplContent)">
                            Preview Email
                          </button>
                        </div>

                        <div class="div-button-submit" style="padding: 2%">
                          <button nzSize="default" class="btn-submit" nz-button nzType="primary" type="submit"
                            (click)="showSubmitConfirm()" [disabled]="steps != 0">
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </nz-collapse-panel>
            </nz-collapse>

            <ng-template #tplContent let-params>

              <div nz-row class="content-row">

              </div>

              <nz-tabset [nzAnimated]="false">
                <nz-tab nzTitle="TH">
                  <html>

                  <head>
                    <style>
                      table {
                        font-family: arial, sans-serif;
                        border-collapse: collapse;
                        width: 100%;
                      }

                      td,
                      th {
                        border: 1px solid #dddddd;
                        text-align: left;
                        padding: 8px;
                      }
                    </style>
                  </head>

                  <body>
                    <div>
                      <h4>เรียน แผนกการตลาด/ฝ่ายขาย,</h4>

                      <div>
                        <div [innerHTML]="emailTemplateTH">
                        </div>
                      </div>

                      <br>

                      <table>
                        <tr>
                          <th>No.</th>
                          <th>Material Code</th>
                          <th>Item Name</th>
                          <th>Desciption</th>
                          <th>Quantity</th>
                          <th>Unit</th>
                        </tr>
                        <tr *ngFor="let item of itemListData">
                          <td>{{item.no}}</td>
                          <td>{{item.matCode}}</td>
                          <td>{{item.itemName}}</td>
                          <td>{{item.description}}</td>
                          <td>{{item.quantity}}</td>
                          <td>{{item.unit}}</td>
                        </tr>

                      </table>

                      <br>
                      <a href="">คลิกเพื่อเปิดเอกสาร</a>
                      <br>

                      <p>&emsp; หากต้องการข้อมูลเพิ่มเติมโปรดติดต่อ {{params.Name}}, E-Mail:
                        {{params.Email}}, Tel. {{params.TelNo}}
                      </p>
                      <p>
                        <strong>หมายเหตุ: บริษัท เอสซีจีเคมิคอลส์ จำกัด
                          ขอสงวนสิทธิ์ในการพิจารณาการสั่งซื้อตามที่บริษัทเห็นสมควรแต่เพียงผู้เดียว​
                        </strong>
                      </p>
                      <br>
                      <p>
                        <strong>
                          ขอแสดงความนับถือ
                        </strong>
                      </p>
                      <img width="200" height="50"
                        src="https://image.makewebeasy.net/makeweb/0/oF1Vfw37q/DefaultData/SCGC.jpg">

                      <br>
                      <br>
                      <p>This service was provided by Request for Price List System, SCG Chemicals
                        Your comments and suggestions are welcome. © 2018 SCG Chemicals PLC, All Rights Reserved.
                      </p>
                    </div>
                  </body>


                  </html>
                </nz-tab>

                <nz-tab nzTitle="ENG">
                  <html>

                  <head>
                    <style>
                      table {
                        font-family: arial, sans-serif;
                        border-collapse: collapse;
                        width: 100%;
                      }

                      td,
                      th {
                        border: 1px solid #dddddd;
                        text-align: left;
                        padding: 8px;
                      }

                      /* tr:nth-child(even) {
                        background-color: #ede4e5;
                      } */
                    </style>
                  </head>

                  <body>
                    <div>
                      <h4>Dear Sale/Marketing Department,</h4>

                      <br>
                      <div>
                        <div [innerHTML]="emailTemplateENG">
                        </div>
                      </div>
                      <br>

                      <table>
                        <tr>
                          <th>No.</th>
                          <th>Material Code</th>
                          <th>Item Name</th>
                          <th>Desciption</th>
                          <th>Quantity</th>
                          <th>Unit</th>
                        </tr>
                        <tr *ngFor="let item of itemListData">
                          <td>{{item.no}}</td>
                          <td>{{item.matCode}}</td>
                          <td>{{item.itemName}}</td>
                          <td>{{item.description}}</td>
                          <td>{{item.quantity}}</td>
                          <td>{{item.unit}}</td>
                        </tr>

                      </table>

                      <br>
                      <a href="">Click to open this document</a>
                      <br>

                      <p>&emsp; If you require further information, please contact {{params.Name}}, E-Mail:
                        {{params.Email}}, Tel. {{params.TelNo}}0
                      </p>
                      <p>
                        <strong>Remark: SCG Chemicals PLC reserves the right to award to the bidder that presents the
                          best value
                          to
                          the
                          company as determined solely by the company in its absolute discretion
                        </strong>
                      </p>

                      <br>
                      <p>
                        <strong>
                          Yours Sincerely,
                        </strong>
                      </p>

                      <img width="200" height="50"
                        src="https://image.makewebeasy.net/makeweb/0/oF1Vfw37q/DefaultData/SCGC.jpg">
                      <br>
                      <br>
                      <p>This service was provided by Request for Quotation System, SCG Chemicals Your comments and
                        suggestions
                        are
                        welcome. © 2018 SCG Chemicals PLC, All Rights Reserved.</p>
                    </div>
                  </body>


                  </html>
                </nz-tab>
              </nz-tabset>

            </ng-template>

          </div>

          <nz-row class="collapse-row">
            <div id="action-log" nz-col nzSpan="24" class="collapse-col">
              <app-new-bidding-action-log [biddingAllInfo]="biddingAllinfo"></app-new-bidding-action-log>
            </div>

          </nz-row>

        </div>


      </div>

    </div>

  </nz-spin>
</nz-layout>
