<nz-layout class="app-layout">
  <nz-spin [nzSpinning]="isDataLoading" [nzSize]="'large'">

    <div nz-row class="row">

      <div class="panel-header">
        <nz-row>
          <nz-card class="card">
            <nz-row>
              <nz-breadcrumb>
                <nz-breadcrumb-item>
                  <a routerLink="../home">
                    <span nz-icon nzType="home"></span>
                    <span>Home</span>
                  </a>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  <span>Work Station</span>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  <span>Bidding worksheet</span>
                </nz-breadcrumb-item>
                <nz-breadcrumb-item>
                  <a>
                    <span>Summary</span>
                  </a>
                </nz-breadcrumb-item>
              </nz-breadcrumb>
            </nz-row>
          </nz-card>
        </nz-row>

      </div>

      <div class="panel-content">
        <div nz-row class="collapse-row">
          <div nz-col nzSpan="24" class="collapse-col">
            <app-searching-summary-criteria
              [searchingSummaryCriteriaForm]="searchingSummaryCriteriaForm"
              [sourcingTeamList]="sourcingTeamList"
              [purchasingGroupsList]="purchasingGroupsList"
              [vendorList]="vendorList"
              [purGroupList]="purGroupList"
              (onClickSearch)="onClickSearch()"
              >
            </app-searching-summary-criteria>
          </div>

          <div nz-col nzSpan="24" class="collapse-col">
            <app-searching-summary-result
             [searchList]="searchList"
             [deadlineWarning]="deadlineWarning"
             ></app-searching-summary-result>
          </div>


        </div>
      </div>

    </div>
  </nz-spin>
</nz-layout>
