import { MdmGetMemberInfoResponseData } from './api/models/mdm-get-member-info-response-data';
import { UserDetailMDM } from './models/UserDetailMDM';
import { UserDetailSSO } from './models/UserDetailSSO';

import { Component, OnInit } from '@angular/core';
import { environment } from '../environments/environment';
import { AuthService } from './services/auth.service';
import { NzMenuItemDirective } from 'ng-zorro-antd/menu';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  isCollapsed: boolean = false;
  isSiderCollapsed: boolean = false;
  userDetailSSO!: UserDetailSSO;
  userDetailMDM!: MdmGetMemberInfoResponseData[];

  webUrl: string;
  userRole!: string;
  userFullName!: string;
  width: string = "calc(100% - 250px)";
  padding: string = "250px";
  subMenuOpenedlist: string[] = [];
  subMenu_WorkStation: boolean = false;
  subMenu_ContractList: boolean = false;
  subMenu_ManageContract: boolean = false;
  subMenu_AP: boolean = false;
  subMenu_Admin: boolean = false;
  subMenu_BiddingWorksheet: boolean = false;
  isSelectedSummary: boolean = false;

  openSubMenu: { [name: string]: boolean } = {
    WorkStation: false,
    ContractList: false,
    ManageContract: false,
    AP: false,
    Admin: false,
  };

  constructor(
    private authService: AuthService,
    private router: Router) {

    this.webUrl = environment.WEB_URL;
  }

  ngOnInit(): void {

    this.userRole = this.authService.currentUserRole();

    if (this.authService.currentUserRole() === "Vendor") {
      setTimeout(() => {
        this.isSiderCollapsed = true;
        this.onCollapsedChange(true);
      }, 2000);
    }
    else {
      this.userFullName = this.authService.currentUserFullName();
      this.isSiderCollapsed = false;
      this.onCollapsedChange(false);
    }


    if (window.location.pathname.includes("summary") || window.location.pathname.includes("bidding-detail")) {
      setTimeout(() => {
        this.isSiderCollapsed = true;
        this.onCollapsedChange(true);
      }, 2000);
    }
    else {
      this.isSiderCollapsed = false;
      this.onCollapsedChange(false);
    }

    if (window.location.pathname.includes("work-station")) {
      this.openSubMenu["WorkStation"] = true;
    }

    if (window.location.pathname.includes("bidding-worksheet")) {
      this.subMenu_BiddingWorksheet = true;

    }

    this.userDetailSSO = JSON.parse(localStorage.getItem("user_data") || "{}");
    console.log("[App] Role : ", this.userRole);

  }

  logOut() {
    this.authService.logOut();
  }

  openHandler(value: string): void {
    for (const key in this.openSubMenu) {
      if (key !== value) {
        this.openSubMenu[key] = false;
      }
    }
  }

  onCollapsedChange(value: boolean): void {
    if (value) {
      this.width = "calc(100% - 80px)";
      this.padding = "80px";
    }
    else {
      this.width = "calc(100% - 250px)";
      this.padding = "250px";
    }
  }

}
