import { VChembiMstVendorMaster } from 'src/app/api/models';
import { MasterConfig } from './../../../api/models/master-config';
import { ConfigurationService } from './../../../api/services/configuration.service';
import { MasterContractType } from './../../../api/models/master-contract-type';
import { Guid } from 'guid-typescript';
import { environment } from './../../../../environments/environment';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzStatusType } from 'ng-zorro-antd/steps';
import {
  Conclusion,
  Header,
  Log,
  MasterPlant,
  Plant,
  RequestContractAll,
  SuggestVendor,
  TechnicalCommittee,
} from '../../../api/models';
import { AuthService } from '../../../services/auth.service';
import { FileProperties } from './../../../api/models/file-properties';
import { MdmGetMemberInfoResponseData } from './../../../api/models/mdm-get-member-info-response-data';
import { PostEvaluation } from './../../../api/models/post-evaluation';
import { RcfAllInfo } from './../../../api/models/rcf-all-info';
import { MasterService } from './../../../api/services/master.service';
import { MdmService } from './../../../api/services/mdm.service';
import { RcfHeaderService } from './../../../api/services/rcf-header.service';
import { RequestContractAllService } from './../../../api/services/request-contract-all.service';
import { UserDetailSSO } from './../../../models/UserDetailSSO';
import { ModalResultComponent } from './component/_modal/modal-result/modal-result.component';
import { fileList } from './component/_model/fileListModel';
import {
  RCF,
  RequestDetail,
  RequestInformation,
  SourcingApprover,
  UserApprover,
} from './rcf.models';
import { Console, debug } from 'console';

@Component({
  selector: 'app-rcf',
  templateUrl: './rcf.component.html',
  styleUrls: ['./rcf.component.css'],
})
export class RCFComponent implements OnInit {
  rcfForm!: FormGroup;
  rcfData!: RCF;

  request_information!: RequestInformation;
  requesterInfoFormGroup!: FormGroup;
  requestDetailFormGroup!: FormGroup;
  request_detail!: RequestDetail;
  user_approver!: UserApprover;
  sourcingApprover!: SourcingApprover;
  sourcingTeam!: FormGroup;

  contractDocumentNumber!: string;
  contractCreator!: string;
  contractCreateDate!: string;
  contractStatus!: string;
  contractRejectByName!: string;
  contractReturnByName!: string;

  actionForm: FormGroup;
  requesterInfoForm!: RCF;
  steps: number;
  status: NzStatusType = 'process';
  checkButtonCancle: boolean;
  checkButtonSubmit: boolean;
  isVisible = false;
  isOkLoading = false;
  isConfirm: boolean = false;
  disableSubmit: boolean = false;

  isRequesterInfoFormValid: boolean = false;
  isRequestDetailFormValid: boolean = false;

  requesterDetailMDM!: MdmGetMemberInfoResponseData;

  contractValue!: number;
  requestContractAllInfo: RcfAllInfo[] = [];
  requestContractData!: RequestContractAll;
  requestContractHeader!: Header;
  requestConclusion!: Conclusion;
  requestContractPlant: Plant[] = [];
  requestContractFile: FileProperties[] = [];
  requestContractItem: FileProperties[] = [];
  requestContractAllFile: FileProperties[] = [];
  requestContractSuggestVendor: SuggestVendor[] = [];
  requestContractTechnialCommittee: TechnicalCommittee[] = [];
  requestContractPostEvaluation: PostEvaluation[] = [];
  requestContractLog!: Log;

  isSubmitted: boolean = false;
  isSpinning: boolean = true;
  isDataLoading!: boolean;
  userDetailSSO!: UserDetailSSO;
  userInfoMDM: MdmGetMemberInfoResponseData[] = [];
  userFullname: string;

  listPlant: MasterPlant[] = [];
  vendorNameList: VChembiMstVendorMaster[] = [];
  listContractType: MasterContractType[] = [];
  listOfSuggestVendorData: SuggestVendor[] = [];

  rcfConfig: MasterConfig[] = [];

  constructor(
    private fb: FormBuilder,
    private rcfAllService: RequestContractAllService,
    private configService: ConfigurationService,
    private rcfHeaderService: RcfHeaderService,
    private cmsMasterService: MasterService,
    private mdmService: MdmService,
    private modal: NzModalService,
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private datepipe: DatePipe
  ) {
    this.actionForm = this.fb.group({
      action: [''],
      remark: [''],
    });

    this.steps = 0;
    this.isDataLoading = true;
    this.checkButtonSubmit = false;
    this.checkButtonCancle = false;
    this.userDetailSSO = JSON.parse(localStorage.getItem('user_data') || '{}');
    this.userFullname =
      this.userDetailSSO.information.first_name +
      ' ' +
      this.userDetailSSO.information.last_name;
    this.userInfoMDM = this.authService.currentUserInfoMDM();
  }

  async ngOnInit(): Promise<void> {
    const pk = this.route.snapshot.queryParamMap.get('pk');
    this.getRcfInfo(pk);

    await this.getPlantFromMaster();
    await this.getContractTypeFromMaster();
    await this.getConfigData();
    await this.getVendorFromMaster();
  }

  async getVendorFromMaster() {
    await this.cmsMasterService
      .apiMasterGetVendorGet$Json()
      .toPromise()
      .then((response) => {
        this.vendorNameList = response;
      })
      .catch((error) => { });
  }
  async getPlantFromMaster() {
    await this.cmsMasterService
      .apiMasterGetPlantGet$Json()
      .toPromise()
      .then((response) => {
        this.listPlant = response;

        this.listPlant = Array.from(
          new Set(this.listPlant.map((item) => item.plantName))
        ).map((name) => {
          return this.listPlant.find((item) => item.plantName === name)!;
        });

        this.listPlant = this.listPlant.filter(function (item) {
          return (
            item.plantName != 'Siam Mitsui PTA' &&
            item.plantName != 'REPCO-IS' &&
            item.plantName != 'TPRC'
          );
        });
      })
      .catch((err) => {
        this.modal.error({
          nzTitle: 'Server Error',
          nzContent: 'Please try again leter',
          nzClosable: false,
          nzOkText: 'Close',
        });
        console.log('[Request Detail] Error on getPlantfromMaster: ', err);
      });
  }

  async getContractTypeFromMaster() {
    await this.cmsMasterService
      .apiMasterGetContractTypeGet$Json()
      .toPromise()
      .then((response) => {
        this.listContractType = response;

        //Remove Other and - from ContractType
        this.listContractType = this.listContractType.filter(function (item) {
          return (
            item.contractTypeName !== 'Other' && item.contractTypeName !== '-'
          );
        });
      })
      .catch((err) => {
        this.modal.error({
          nzTitle: 'Server Error',
          nzContent: 'Please try again leter',
          nzClosable: false,
          nzOkText: 'Close',
        });
        console.log('[Request Detail] Error on getContractfromMaster: ', err);
      });
  }

  async getConfigData() {
    await this.configService
      .apiConfigurationGetAllConfigByGroupGet$Json({
        Group: 'RCF',
      })
      .toPromise()
      .then((response) => {
        this.rcfConfig = response;
      })
      .catch((error) => {
        console.log('[RCF] Error on GetConfig', error);
      });
  }

  async getRcfInfo(pk: string | null) {
    this.isDataLoading = true;

    if (pk !== null) {
      this.disableSubmit = true;

      await this.rcfAllService
        .apiRcfAllServiceGetAllRequestInfoByFkGet$Json$Response({
          PK: pk,
        })
        .toPromise()
        .then(
          (response) => {
            if (response.ok) {
              this.requestContractAllInfo = response.body;

              console.log('requestContractAllInfo: ', this.requestContractAllInfo);
              this.contractDocumentNumber = this.requestContractAllInfo[0].docNo!;
              this.contractStatus = this.requestContractAllInfo[0].status!;
              this.contractCreateDate = this.datepipe.transform(this.requestContractAllInfo[0].createdDate!, 'dd MMMM yyyy HH:mm')!;
              this.contractCreator = this.requestContractAllInfo[0].creatorName!;

              //ถ้า RCF IsActive == false ไปหน้า Not found
              if (this.requestContractAllInfo[0].isActive == false) {
                this.router.navigate(['**']);
              }

              if (this.requestContractAllInfo[0].status == 'Draft') {
                this.steps = 0;
                this.actionForm.controls['action'].setValue('create');
                this.disableSubmit = false;
              }
              else if (this.requestContractAllInfo[0].status === 'Wait approver intitial approve') {

                this.steps = 1;
                this.status = 'process';
                this.actionForm.controls['action'].setValue('Approve');

                //User Approve SM กด Submit ได้
                if (this.requestContractAllInfo[0].userApproverSMName === this.authService.currentUserFullName()) {
                  this.disableSubmit = false;
                }

                if (this.authService.currentUserRole() === 'Procurement Admin') {
                  this.disableSubmit = false;
                }
              }
              else if (this.requestContractAllInfo[0].status === 'Wait approver approve') {
                this.steps = 1;
                this.status = 'process';
                this.actionForm.controls['action'].setValue('Approve');

                if (this.requestContractAllInfo[0].userApproverPMName == this.authService.currectUserFullName()) {
                  this.disableSubmit = false;
                }

                if (this.authService.currentUserRole() === 'Procurement Admin') {
                  this.disableSubmit = false;
                }

              }
              else if (this.requestContractAllInfo[0].status === 'Sourcing conclusion') {
                this.steps = 2;
                this.status = 'process';
                this.actionForm.controls['action'].setValue('Conclusion');

                //Soucing กด Submit ได้
                if (this.authService.currentUserRole() === 'Procurement User' || this.authService.currentUserRole() === 'Procurement Admin') {
                  this.disableSubmit = false;
                }
              }
              else if (this.requestContractAllInfo[0].status === 'Wait sourcing approver initial approve') {
                this.actionForm.controls['action'].setValue('Approve');
                this.steps = 3;
                this.status = 'process';

                //Sourcing Approve SM กด Submit ได้
                if (this.requestContractAllInfo[0].sourcingApproverSMName === this.authService.currectUserFullName()) {
                  this.disableSubmit = false;
                }

                if (this.authService.currentUserRole() === 'Procurement Admin') {
                  this.disableSubmit = false;
                }
              }
              else if (this.requestContractAllInfo[0].status === 'Wait sourcing approver approve') {
                this.actionForm.controls['action'].setValue('Approve');
                this.steps = 3;
                this.status = 'process';

                //Sourcing Approve PM กด Submit ได้
                if (this.requestContractAllInfo[0].sourcingApproverPMName === this.authService.currectUserFullName()) {
                  this.disableSubmit = false;
                }

                if (this.authService.currentUserRole() === 'Procurement Admin') {
                  this.disableSubmit = false;
                }
              }
              else if (this.requestContractAllInfo[0].status === 'Complete') {
                this.steps = 5;
                this.disableSubmit = true;

              }
              else if (this.requestContractAllInfo[0].status === 'Return') {
                this.rcfAllService
                  .apiRcfAllServiceGetLogByRcfPkGet$Json$Response({
                    RCFPk: this.requestContractAllInfo[0].pK_RCFHeader,
                  })
                  .subscribe((response) => {
                    if (response.ok) {
                      if (
                        response.body[response.body.length - 1].activity === 'Wait approver intitial approve' ||
                        response.body[response.body.length - 1].activity === 'Wait approver approve'
                      ) {
                        this.steps = 0;
                        this.status = 'process';
                        this.contractReturnByName =
                          response.body[response.body.length - 1].editedByName!;
                      } else if (
                        response.body[response.body.length - 1].activity === 'Wait sourcing approver initial approve' ||
                        response.body[response.body.length - 1].activity === 'Wait sourcing approver approve'
                      ) {
                        this.steps = 0;
                        this.status = 'process';
                        this.contractReturnByName =
                          response.body[response.body.length - 1].editedByName!;
                      }
                    }
                    this.actionForm.controls['action'].setValue('Creator Revise');

                    //Creator กด Submit ได้
                    if (this.requestContractAllInfo[0].creatorName === this.authService.currentUserFullName()) {
                      this.disableSubmit = false;
                    }
                    //Requester กด Submit ได้
                    else if (this.requestContractAllInfo[0].requesterName === this.authService.currectUserFullName()) {
                      this.disableSubmit = false;
                    }

                    if (this.authService.currentUserRole() == 'Procurement Admin') {
                      this.disableSubmit = false;
                    }
                  });

              } else if (this.requestContractAllInfo[0].status == 'Reject') {
                this.rcfAllService
                  .apiRcfAllServiceGetLogByRcfPkGet$Json$Response({
                    RCFPk: this.requestContractAllInfo[0].pK_RCFHeader,
                  })
                  .subscribe((response) => {
                    if (response.ok) {
                      if (
                        response.body[response.body.length - 1].activity === 'Wait approver intitial approve' ||
                        response.body[response.body.length - 1].activity === 'Wait approver approve'
                      ) {
                        this.steps = 1;
                        this.status = 'error';
                        this.contractRejectByName = response.body[response.body.length - 1].editedByName!;
                      } else if (
                        response.body[response.body.length - 1].activity === 'Wait sourcing approver initial approve' ||
                        response.body[response.body.length - 1].activity === 'Wait sourcing approver approve'
                      ) {
                        this.steps = 3;
                        this.status = 'error';
                        this.contractRejectByName = response.body[response.body.length - 1].editedByName!;
                      }
                    }
                  });

                this.disableSubmit = true;
              }


              setTimeout(() => {
                this.isDataLoading = false;
              }, 3000);
            }
          })
        .catch((err) => {
          this.router.navigate(['**']);
        });
    } else {
      setTimeout(() => {
        this.isDataLoading = false;
        this.actionForm.controls['action'].setValue('create');
      }, 2000);
    }
  }

  onDeleteDraft() {
    this.modal.confirm({
      nzTitle: 'Delete',
      nzContent: 'Do you want to delete this draft?',
      nzOkText: 'Confirm',
      nzCancelText: 'Cancel',
      nzOnOk: () => {
        const pk = this.route.snapshot.queryParamMap.get('pk');
        this.rcfAllService
          .apiRcfAllServiceDeleteRcfDraftByPkrcfHeaderPost$Response({
            PKRCFHeader: pk!,
          })
          .toPromise()
          .then((response) => {
            if (response.ok) {
              this.modal.success({
                nzTitle: 'Complete',
                nzContent: 'Draft has been delete',
                nzOnOk: () => {
                  this.router.navigate(['../work-station/dashboard']);
                },
              });
            }
          });
      },
    });
  }

  async onSubmit() {
    console.log("onSubmit: ", this.request_detail);
    this.request_detail.estimated_contract_value = Number(
      this.request_detail.estimated_contract_value.toString().replace(/,/g, '')
    );
    this.requestContractHeader = {
      docNo: '',
      status: this.contractStatus == 'Return' ? 'Return' : '',
      creatorDomain: this.userInfoMDM[0].domain,
      creatorName: this.userInfoMDM[0].eFullName,
      creatorEmail: this.userInfoMDM[0].email,
      requesterDomain: this.requesterDetailMDM.domain,
      requesterEmail: this.requesterDetailMDM.email,
      requesterTelno: this.request_information.tel,
      requesterName: this.request_information.requester,
      requesterPosition: this.request_information.position,
      requesterDepartment: this.request_information.department,
      requesterDivision: this.request_information.division,
      requesterCostCenter: this.request_information.cost_center,
      workType: this.request_detail.work_type,
      contractType: this.request_detail.contract_type,
      contractName: this.request_detail.contract_name,
      productServiceName: this.request_detail.product_service_name,
      contractPeriod: Number(this.request_detail.contract_period),
      estimatedContractValueInTHB: this.request_detail.estimated_contract_value,
      reasonForRequesting: this.request_detail.reason_for_requesting,
      otherNote: this.request_detail.other_note,
      expectedResults: this.request_detail.expected_result,
      userApproverSMName: this.user_approver.sm_approver_name,
      userApproverSMEmail: this.user_approver.sm_approver_email,
      userApproverSMCompany: this.user_approver.sm_approver_company,
      userApproverPMName: this.user_approver.pm_approver_name,
      userApproverPMEmail: this.user_approver.pm_approver_email,
      userApproverPMCompany: this.user_approver.pm_approver_company,
      userApproverDMName: null,
      userApproverDMCompany: null,
      sourcingApproverSMName: null,
      sourcingApproverSMCompany: null,
      sourcingApproverPMName: null,
      sourcingApproverPMCompany: null,
      isConclusionsAccept: null,
      conclusionAcceptReason: null,
      conclusionAcceptReasonOther: null,
      conclusionDeniedReason: null,
      sourcingTeamName: this.sourcingTeam.value.sourcingTeam,
      sourcingTeamDepartment: this.sourcingTeam.value.sourcingTeamDepartment,
      isActive: true,
    };

    if (
      this.request_detail.suggest_vendor != null &&
      this.request_detail.plant != null
    ) {
      for (let i = 0; i < this.request_detail.suggest_vendor.length; i++) {
        let obj: SuggestVendor = {};
        obj = {
          vendorCode: this.request_detail.suggest_vendor[i].vendorCode,
          vendorName: this.request_detail.suggest_vendor[i].vendorName,
          vendorContactName:
            this.request_detail.suggest_vendor[i].vendorContactName,
          vendorEmail: this.request_detail.suggest_vendor[i].vendorEmail,
          vendorTelNo: this.request_detail.suggest_vendor[i].vendorTelNo,
        };
        this.requestContractSuggestVendor.push(obj);
      }

      this.request_detail.plant.forEach((item) => {
        let obj: Plant = {};
        Object.assign(obj, item);
        this.requestContractPlant.push(obj);
      });
    }

    this.requestContractLog = {
      action: this.actionForm.get('action')?.value,
      remark: this.actionForm.get('remark')?.value,
    };

    this.requestContractAllFile = [];
    for (let i = 0; i < this.requestContractFile.length; i++) {
      this.requestContractAllFile.push(this.requestContractFile[i]);
    }

    for (let i = 0; i < this.requestContractItem.length; i++) {
      this.requestContractAllFile.push(this.requestContractItem[i]);
    }

    this.requestContractData = {
      header: this.requestContractHeader,
      plant: this.requestContractPlant,
      suggestvendor: this.requestContractSuggestVendor,
      technicalCommittees: this.requestContractTechnialCommittee,
      postEvaluations: this.requestContractPostEvaluation,
      files: this.requestContractAllFile,
      log: this.requestContractLog,
      remark: this.actionForm.get('remark')?.value,
    };

    // console.log("[SUBMITED] requestContractData: ", JSON.parse(JSON.stringify(this.requestContractData)));
  }

  async onSaveDraft() {
    // console.log("************SaveDraft************");

    // console.log("sourcing team " + typeof (this.sourcingTeam));
    this.request_detail.estimated_contract_value = Number(
      this.request_detail.estimated_contract_value.toString().replace(/,/g, '')
    );
    this.requestContractHeader = {
      docNo: '',
      status: 'Draft',
      creatorDomain: this.userInfoMDM[0].domain,
      creatorName: this.userInfoMDM[0].eFullName,
      creatorEmail: this.userInfoMDM[0].email,
      requesterDomain: this.requesterDetailMDM.domain,
      requesterEmail: this.requesterDetailMDM.email,
      requesterTelno: this.request_information.tel,
      requesterName: this.request_information.requester,
      requesterPosition: this.request_information.position,
      requesterDepartment: this.request_information.department,
      requesterDivision: this.request_information.division,
      requesterCostCenter: this.request_information.cost_center,
      workType: this.request_detail.work_type,
      contractType: this.request_detail.contract_type,
      contractName: this.request_detail.contract_name,
      productServiceName: this.request_detail.product_service_name,
      contractPeriod: Number(this.request_detail.contract_period),
      estimatedContractValueInTHB: this.request_detail.estimated_contract_value,
      reasonForRequesting: this.request_detail.reason_for_requesting,
      otherNote: this.request_detail.other_note,
      expectedResults: this.request_detail.expected_result,
      userApproverSMName: this.user_approver.sm_approver_name,
      userApproverSMCompany: this.user_approver.sm_approver_company,
      userApproverSMEmail: this.user_approver.sm_approver_email,
      userApproverPMName: this.user_approver.pm_approver_name,
      userApproverPMEmail: this.user_approver.sm_approver_email,
      userApproverPMCompany: this.user_approver.pm_approver_company,
      userApproverDMName: null,
      userApproverDMCompany: null,
      sourcingApproverSMName: null,
      sourcingApproverSMCompany: null,
      sourcingApproverPMName: null,
      sourcingApproverPMCompany: null,
      isConclusionsAccept: null,
      conclusionAcceptReason: null,
      conclusionAcceptReasonOther: null,
      conclusionDeniedReason: null,
      sourcingTeamName: this.sourcingTeam.value.sourcingTeam,
      sourcingTeamDepartment: this.sourcingTeam.value.sourcingTeamDepartment,
      isActive: true,
    };

    if (
      this.request_detail.suggest_vendor != null &&
      this.request_detail.plant != null
    ) {
      for (let i = 0; i < this.request_detail.suggest_vendor.length; i++) {
        let obj: SuggestVendor = {};
        obj = {
          vendorCode: this.request_detail.suggest_vendor[i].vendorCode,
          vendorName: this.request_detail.suggest_vendor[i].vendorName,
          vendorContactName:
            this.request_detail.suggest_vendor[i].vendorContactName,
          vendorEmail: this.request_detail.suggest_vendor[i].vendorEmail,
          vendorTelNo: this.request_detail.suggest_vendor[i].vendorTelNo,
        };
        this.requestContractSuggestVendor.push(obj);
      }

      this.request_detail.plant.forEach((item) => {
        let obj: Plant = {};
        Object.assign(obj, item);
        this.requestContractPlant.push(obj);
      });
    }

    this.requestContractLog = {
      action: this.actionForm.get('action')?.value,
      remark: this.actionForm.get('remark')?.value,
    };

    this.requestContractAllFile = [];
    for (let i = 0; i < this.requestContractFile.length; i++) {
      this.requestContractAllFile.push(this.requestContractFile[i]);
    }

    for (let i = 0; i < this.requestContractItem.length; i++) {
      this.requestContractAllFile.push(this.requestContractItem[i]);
    }

    this.requestContractData = {
      header: this.requestContractHeader,
      plant: this.requestContractPlant,
      suggestvendor: this.requestContractSuggestVendor,
      technicalCommittees: this.requestContractTechnialCommittee,
      postEvaluations: this.requestContractPostEvaluation,
      files: this.requestContractAllFile,
      log: this.requestContractLog,
    };

    // console.log("[SAVE DRAFT] requestContractData: ", JSON.parse(JSON.stringify(this.requestContractData)));
  }

  //ดัก Emitter Form จาก Requester Info
  onRequesterInfoFormChange(event: FormGroup) {
    this.requesterInfoFormGroup = event;
    this.request_information = event.value;
    this.isRequesterInfoFormValid = event.valid;
    // console.log("isRequesterInfoFormValid : ", event.valid);
  }

  //ดัก Emitter MDM จาก Requester Detail
  onRequesterDetailMDMChange(event: MdmGetMemberInfoResponseData) {
    this.requesterDetailMDM = event;
    // console.log("Requester Change : ", event);
  }

  //ดัก Emitter Form จาก Requester Detail
  onRequestDetailFormChange(event: FormGroup) {
    console.log("onRequestDetailFormChange: ", event.value);
    this.request_detail = event.value;
    this.contractValue = this.request_detail.estimated_contract_value;
    this.isRequestDetailFormValid = event.valid;
  }

  //ดัก Emitter จาก Attachment
  onRequestAttachmentFileChange(event: fileList[]) {
    this.requestContractFile = event;
    // console.log("onRequestAttachmentFileChange : ", this.requestContractFile);
  }

  onTechnicalCommitteeListChange(event: TechnicalCommittee[]) {
    this.requestContractTechnialCommittee = event;
    // console.log("onTechnicalCommitteeListChange : ", this.requestContractTechnialCommittee);
  }

  onRequestContractItemChange(event: fileList[]) {
    this.requestContractItem = event;
    // console.log("onRequestContractItemChange :", this.requestContractItem);
  }

  onSourcingDataFormChange(event: FormGroup) {
    this.sourcingTeam = event;
    // console.log("onSourcingDataFormChange :", this.sourcingTeam.value);
  }

  onSourcingApproveFormChange(event: FormGroup) {
    this.sourcingApprover = event.value;
    // console.log("[sourcing Approver], ", event.value);
  }

  onUserApproveFormChange(event: FormGroup) {
    this.user_approver = event.value;
    // console.log("onUserApproveFormChange : ", event.value);
  }

  onConclusionFormChange(event: FormGroup) {
    this.requestConclusion = event.value;
    // console.log("onConclusionFormChange : ", event.value);
  }

  showSubmitConfirm(): void {
    const modelSubmit = this.modal.confirm({
      nzTitle: 'Submit',
      nzContent: 'Do you want to submit this form?',
      nzOkText: 'Submit',
      nzCancelText: 'Cancel',
      nzOkLoading: this.isOkLoading,
      nzOnOk: () =>
        new Promise(async (resolve, reject) => {
          //Steps อยู่ที่ Create New Request Contract
          if (this.steps == 0) {
            //Check เบอร์โทร
            if (
              this.request_information.tel == '' ||
              this.request_information.tel == null ||
              this.isRequesterInfoFormValid == false
            ) {
              this.isOkLoading = false;
              modelSubmit.updateConfig({
                nzOkLoading: this.isOkLoading,
              });

              modelSubmit.close();

              this.modal.error({
                nzTitle: 'Submit Failed',
                nzContent: 'Please fill Requester Tel No.',
                nzOnOk: () => {
                  document
                    .getElementById('requester-information')
                    ?.scrollIntoView();
                },
              });

              this.isSubmitted = true;
              return;
            }
            //Check Request Detail ว่า Form Validate ครบไหม
            if (this.isRequestDetailFormValid == false) {
              console.log('on Requester Detail Error : ', this.request_detail);

              this.isOkLoading = false;
              modelSubmit.updateConfig({
                nzOkLoading: this.isOkLoading,
              });

              modelSubmit.close();

              this.modal.error({
                nzTitle: 'Submit Failed',
                nzContent: 'Please fill all Require Field in Requester Detail',
                nzOnOk: () => {
                  document.getElementById('request-detail')?.scrollIntoView();
                },
              });

              this.isSubmitted = true;
              return;
            }
            //Check ว่าเลือก Plant ไหม
            if (this.request_detail.plant.length == 0) {
              this.isOkLoading = false;
              modelSubmit.updateConfig({
                nzOkLoading: this.isOkLoading,
              });

              modelSubmit.close();

              this.modal.error({
                nzTitle: 'Submit Failed',
                nzContent: 'Please select Applicable for plant',
                nzOnOk: () => {
                  document.getElementById('request-detail')?.scrollIntoView();
                },
              });

              this.isSubmitted = true;
              return;
            }
            //Check ว่าได้ Insert File ไหม
            if (this.requestContractFile != undefined) {
              if (this.requestContractFile.length == 0) {
                this.isOkLoading = false;
                modelSubmit.updateConfig({
                  nzOkLoading: this.isOkLoading,
                });

                modelSubmit.close();

                this.modal.error({
                  nzTitle: 'Submit Failed',
                  nzContent: 'Please add TOR Attachment',
                  nzOnOk: () => {
                    document.getElementById('attachment')?.scrollIntoView();
                  },
                });

                this.isSubmitted = true;
                return;
              } else {
                const findTOR = this.requestContractFile.find(
                  (item) => item.fileGroup === 'RequestContractTOR'
                );

                if (findTOR == null || findTOR == undefined) {
                  this.isOkLoading = false;
                  modelSubmit.updateConfig({
                    nzOkLoading: this.isOkLoading,
                  });

                  modelSubmit.close();

                  this.modal.error({
                    nzTitle: 'Submit Failed',
                    nzContent: 'Please add TOR Attachment',
                    nzOnOk: () => {
                      document.getElementById('attachment')?.scrollIntoView();
                    },
                  });

                  this.isSubmitted = true;
                  return;
                }
              }
            }
            // console.log("Sourcing Team ", this.sourcingTeam);
            if (
              this.sourcingTeam.value.sourcingTeam == null ||
              this.sourcingTeam == undefined
            ) {
              this.isOkLoading = false;
              modelSubmit.updateConfig({
                nzOkLoading: this.isOkLoading,
              });

              modelSubmit.close();

              this.modal.error({
                nzTitle: 'Submit Failed',
                nzContent: 'Please select sourcing team',
                nzOnOk: () => {
                  document.getElementById('sourcing-team')?.scrollIntoView();
                },
              });

              this.isSubmitted = true;
              return;
            }

            this.onSubmit();

            console.log('Contract Status:', this.contractStatus);
            console.log('Contract data ', this.requestContractData);
            if (this.contractStatus == 'Return') {
              this.requestContractData.header?.status == 'Return';
              this.requestContractData.header!.pK_RCFHeader = this.requestContractAllInfo[0].pK_RCFHeader;
              console.log('Change Contract Status ', this.requestContractData);
            }

            await this.rcfAllService
              .apiRcfAllServiceCreateRcfPost$Response({
                body: this.requestContractData,
              })
              .toPromise()
              .then((response) => {
                if (response.ok) {
                  let PKRCFHeader: string = JSON.parse(
                    JSON.stringify(response.body)
                  );

                  this.isOkLoading = false;
                  modelSubmit.updateConfig({
                    nzOkLoading: this.isOkLoading,
                  });

                  modelSubmit.close();

                  const modalResult = this.modal.success({
                    nzTitle: 'Complete',
                    nzContent: 'Successfully Created New Request Contract',
                    nzClosable: false,
                    nzOkText: 'Close',
                    nzCancelText: null,
                  });

                  modalResult.afterClose.subscribe((result) =>
                    this.router
                      .navigate(['../work-station/request-new-contract'], {
                        queryParams: { pk: PKRCFHeader.replace(/"/g, '') },
                      })
                      .then(() => {
                        window.location.reload();
                      })
                  );
                }
              })
              .catch((err) => {
                this.isOkLoading = false;
                modelSubmit.updateConfig({
                  nzOkLoading: this.isOkLoading,
                });

                modelSubmit.close();

                console.log('[Submit] Error ', err);
                this.modal.error({
                  nzTitle: 'Submit Failed',
                  nzContent: 'Something went wrong',
                  nzOkText: 'Close',
                  nzCancelText: null,
                });
              });
          }
          //Step อยู่ที่ Sourcing conclusion
          else if (this.steps == 2) {
            if (typeof this.requestConclusion == 'undefined') {
              this.modal.error({
                nzTitle: 'Error',
                nzContent: 'Please select conclusion',
                nzOkText: 'Close',
                nzCancelText: null,
              });

              this.isOkLoading = false;
              modelSubmit.updateConfig({
                nzOkLoading: false,
              });

              modelSubmit.close();
              return;
            } else if (this.requestConclusion.isConclusionsAccept == true) {
              const isOtherChecked = this.requestConclusion.conclusionReason
                ?.toString()
                .indexOf('other');
              if (
                this.requestConclusion.conclusionReason == null ||
                this.requestConclusion.conclusionReason!.length === 0
              ) {
                this.modal.error({
                  nzTitle: 'Error',
                  nzContent: 'Please select reason',
                  nzOkText: 'Close',
                  nzCancelText: null,
                });

                this.isOkLoading = false;
                modelSubmit.updateConfig({
                  nzOkLoading: false,
                });

                modelSubmit.close();
                return;
              } else if (
                isOtherChecked != -1 &&
                this.requestConclusion.conclusionAcceptReasonOther == null
              ) {
                this.modal.error({
                  nzTitle: 'Error',
                  nzContent: 'Please fill other reason',
                  nzOkText: 'Close',
                  nzCancelText: null,
                });

                this.isOkLoading = false;
                modelSubmit.updateConfig({
                  nzOkLoading: false,
                });

                modelSubmit.close();
                return;
              }
            } else if (this.requestConclusion.isConclusionsAccept == false) {
              if (
                this.requestConclusion.conclusionDeniedReason == '' ||
                this.requestConclusion.conclusionDeniedReason == null
              ) {
                this.modal.error({
                  nzTitle: 'Error',
                  nzContent: 'Please fill denied reason',
                  nzOkText: 'Close',
                  nzCancelText: null,
                });

                this.isOkLoading = false;
                modelSubmit.updateConfig({
                  nzOkLoading: false,
                });

                modelSubmit.close();
                return;
              }
            }

            //Step ของ Approver
            if (
              this.actionForm.controls['action'].value == 'Return' ||
              this.actionForm.controls['action'].value == 'Reject'
            ) {
              if (
                this.actionForm.controls['remark'].value == '' ||
                this.actionForm.controls['remark'].value == null
              ) {
                this.modal.error({
                  nzTitle: 'Error',
                  nzContent: 'Please fill Remark',
                  nzClosable: false,
                  nzOkText: 'Close',
                });

                return;
              }
            }

            await this.rcfAllService
              .apiRcfAllServiceUpdateNewRequestContractPost$Response({
                PKRCFHeader: this.requestContractAllInfo[0].pK_RCFHeader,
                body: {
                  sourcingApproverSMName: this.sourcingApprover.sm_approver_name,
                  sourcingApproverSMCompany: this.sourcingApprover.sm_approver_company,
                  sourcingApproverPMName: this.sourcingApprover.pm_approver_name,
                  sourcingApproverPMCompany: this.sourcingApprover.pm_approver_company,
                  isConclusionsAccept: this.requestConclusion.isConclusionsAccept,
                  conclusionAcceptReasonOther: this.requestConclusion.conclusionAcceptReasonOther,
                  conclusionDeniedReason: this.requestConclusion.conclusionDeniedReason,
                  conclusionReason: this.requestConclusion.conclusionReason?.toString(),
                  log: {
                    action: this.actionForm.controls['action'].value,
                    activity: '',
                    editedByName: this.authService.currectUserFullName(),
                    editedDate: new Date().toJSON(),
                    remark: this.actionForm.controls['remark'].value,
                  },
                },
              })
              .toPromise()
              .then((response) => {
                if (response.ok) {
                  let PKRCFHeader: string = JSON.parse(
                    JSON.stringify(response.body)
                  );

                  modelSubmit.updateConfig({
                    nzOkLoading: false,
                  });

                  modelSubmit.close();

                  const modalResult = this.modal.success({
                    nzTitle: 'Conclusion Complete',
                    nzContent: 'Returning to dashboard',
                    nzOkText: 'Close',
                    nzCancelText: null,
                    nzClosable: false,
                  });

                  modalResult.afterClose.subscribe((result) =>
                    this.router.navigate(['../work-station/dashboard'])
                  );
                }
              })
              .catch((err) => {
                console.log('[Submit] Error ', err);

                this.modal.error({
                  nzTitle: 'Server Error',
                  nzContent: 'Please try agian later',
                });
              });

            modelSubmit.updateConfig({
              nzOkLoading: false,
            });

            modelSubmit.close();
          } else {
            if (
              this.actionForm.controls['action'].value == 'Reject' ||
              this.actionForm.controls['action'].value == 'Return'
            ) {
              if (this.actionForm.controls['remark'].value == '') {
                this.isOkLoading = false;
                modelSubmit.updateConfig({
                  nzOkLoading: this.isOkLoading,
                });

                modelSubmit.close();

                this.modal.error({
                  nzTitle: 'Error',
                  nzContent: 'Please fill remark',
                });

                return;
              }
            }

            this.rcfAllService
              .apiRcfAllServiceUpdateNewRequestContractPost$Response({
                PKRCFHeader: this.requestContractAllInfo[0].pK_RCFHeader,
                body: {
                  conclusionAcceptReasonOther: '',
                  conclusionDeniedReason: '',
                  conclusionReason: '',
                  isConclusionsAccept: null,
                  sourcingApproverPMCompany: '',
                  sourcingApproverPMName: '',
                  sourcingApproverSMCompany: '',
                  sourcingApproverSMName: '',
                  log: {
                    action: this.actionForm.controls['action'].value,
                    activity: '',
                    editedByName: this.authService.currectUserFullName(),
                    editedDate: new Date().toJSON(),
                    remark: this.actionForm.controls['remark'].value,
                  },
                },
              })
              .toPromise()
              .then((response) => {
                if (response.ok) {
                  let PKRCFHeader: string = JSON.parse(
                    JSON.stringify(response.body)
                  );

                  this.isOkLoading = false;
                  modelSubmit.updateConfig({
                    nzOkLoading: this.isOkLoading,
                  });

                  modelSubmit.close();

                  const modalResult = this.modal.success({
                    nzTitle: 'Action Complete',
                    nzContent: 'Returning to dashboard',
                    nzOkText: 'Close',
                    nzCancelText: null,
                    nzClosable: false,
                  });

                  modalResult.afterClose.subscribe((result) =>
                    this.router.navigate(['../work-station/dashboard'])
                  );
                }
              });
          }
        }).catch((err) => {
          this.isOkLoading = false;
          modelSubmit.updateConfig({
            nzOkLoading: this.isOkLoading,
          });

          modelSubmit.close();
        }),
    });
  }

  showSaveConfirm(): void {
    const modalSave: NzModalRef = this.modal.warning({
      nzTitle: 'Save',
      nzContent: '<p>Do you want to save?</p>',
      nzOkText: 'Confirm',
      nzCancelText: 'Cancel',
      nzOkLoading: this.isOkLoading,
      nzOnOk: () =>
        new Promise(async (resolve, reject) => {
          this.onSubmit();
          this.requestContractData.header!.status = 'Draft';

          if (this.requestContractAllInfo[0] != undefined) {
            this.requestContractData.header!.pK_RCFHeader = this.requestContractAllInfo[0].pK_RCFHeader;
          }

          await this.rcfAllService
            .apiRcfAllServiceCreateRcfPost$Response({
              body: this.requestContractData,
            })
            .toPromise()
            .then((response) => {
              if (response.ok) {
                let PKRCFHeader: string = JSON.parse(
                  JSON.stringify(response.body)
                );

                console.log('[Save Draft] PKRCFHeader: ', PKRCFHeader);
                modalSave.updateConfig({
                  nzOkLoading: this.isOkLoading,
                });

                modalSave.close();

                const modalResult = this.modal.success({
                  nzTitle: 'Complete',
                  nzContent: 'Save Draft Complete',
                  nzOkText: 'Close',
                  nzCancelText: null,
                  nzClosable: false,
                  // nzOnOk: () => { this.router.navigate(['dashboard']) }
                });

                modalResult.afterClose.subscribe((result) =>
                  this.router
                    .navigate(['../work-station/request-new-contract'], {
                      queryParams: { pk: PKRCFHeader.replace(/"/g, '') },
                    })
                    .then(() => {
                      window.location.reload();
                    })
                );
              }
            })
            .catch((err) => {
              this.isOkLoading = false;
              modalSave.updateConfig({
                nzOkLoading: this.isOkLoading,
              });

              modalSave.close();

              console.log('[Save Draft Error]', err);
              this.modal.error({
                nzTitle: 'Save Failed',
                nzContent: 'Something went wrong',
              });
            });
          resolve();
        }),
    });
  }
}
