import { DatePipe } from '@angular/common';
import { Rcflog } from './../../../../../api/models/rcflog';
import { RequestContractAllService } from 'src/app/api/services';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { RcfAllInfo } from './../../../../../api/models';

@Component({
  selector: 'app-activity-log',
  templateUrl: './activity-log.component.html',
  styleUrls: ['./activity-log.component.css']
})
export class ActivityLogComponent implements OnInit {

  @Input() requestContractAllInfo!: RcfAllInfo[];

  logData: Rcflog[] = [];
  constructor(
    private rcfAllService:RequestContractAllService,
    private datepipe:DatePipe
  ) { }

  ngOnInit(): void {
  }

  async ngOnChanges(change: SimpleChanges) {
    if (change.hasOwnProperty("requestContractAllInfo") && change.requestContractAllInfo.currentValue.length != 0)
    {
      await this.rcfAllService.apiRcfAllServiceGetLogByRcfPkGet$Json$Response({
        RCFPk: change.requestContractAllInfo.currentValue[0].pK_RCFHeader
      }).toPromise()
        .then(response => {
          if(response.ok)
          {
            // console.log("Response Log",response.body);
            this.logData = response.body;

            for(let i=0; i<this.logData.length; i++)
            {
              this.logData[i].editedDate =  this.datepipe.transform(this.logData[i].editedDate, "yyyy-MM-dd HH:mm");
              // this.logData[i].editedDate = this.logData[i].editedDate?.split("T")[0]+" "+this.logData[i].editedDate?.split("T")[1].split(".")[0];
            }

            this.logData.reverse();
          }

          // console.log("Log data: ",this.logData);
        })
    }
  }

}
