import { BiddingHeader } from './../../../../../../../../api/models/bidding-header';
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Form, FormGroup } from '@angular/forms';
import { endOfMonth, endOfYear, endOfWeek, startOfMonth, startOfYear, startOfWeek } from 'date-fns';
import { MasterPurGroup, MasterSourcingTeam, VChembiMstVendorMaster } from './../../../../../../../../api/models';
import * as moment from 'moment';

@Component({
  selector: 'app-searching-price-list-criteria',
  templateUrl: './searching-price-list-criteria.component.html',
  styleUrls: ['./searching-price-list-criteria.component.css']
})
export class SearchingPriceListCriteriaComponent implements OnInit {

  @Input() searchingForm!: FormGroup;
  @Input() listOfVendorData: VChembiMstVendorMaster[] = [];
  @Input() sourcingTeamList: MasterSourcingTeam[] = [];
  @Input() purchasingGroupsList: MasterPurGroup[] = [];

  @Output() onClickSearch = new EventEmitter<boolean>();


  selectedVendor: any = [];
  createdDate: Date[] = [];

  statusList = [
    { value: "Draft", label: "Draft" },
    { value: "Wait for vendor submit price", label: "Wait for vendor submit price" },
    { value: "Wait for summary", label: "Wait for summary" },
    { value: "Finish", label: "Ready to create MEMO" },
  ]

  ranges = {
    'Today': [new Date(), new Date()],
    'This Week': [startOfWeek(new Date()), endOfWeek(new Date())],
    'This Month': [startOfMonth(new Date()), endOfMonth(new Date())],
    'This Year': [startOfYear(new Date()), endOfYear(new Date())],
    'A Month ago': [new Date(new Date().getFullYear() + "/" + new Date().getMonth() + "/" + new Date().getDate()), new Date()],
    'A Year ago': [new Date(new Date().getFullYear() - 1 + "/" + (new Date().getMonth() + 1) + "/" + new Date().getDate()), new Date()],
  };

  constructor(private datepipe: DatePipe) { }


  ngOnInit(): void {
    //ตั้ง Default 14 วันที่แล้ว - วันนี้
    this.createdDate = [new Date(Date.now() - 12096e5), new Date()];
    this.searchingForm.patchValue({
      startDate: this.createdDate[0],
      endDate: moment(this.createdDate[1]).add(1, "days"),
    })

  }

  onChange(result: Date[]): void {
    if (result.length === 0) {
      this.createdDate = [];

    }
    else {
      this.searchingForm.patchValue({
        startDate: result[0],
        endDate: moment(result[1]),
      })
    }
  }

  onChangeVendor(value: string[]) {
    this.searchingForm.patchValue({
      vendor: value,
    })
  }

  onSearch() {
    this.onClickSearch.emit();
  }

  onReset()
  {
    this.searchingForm.patchValue({
      sourcingTeam: [[]],
      purchasingGroups: [[]],
      startDate: [""],
      endDate: [""],
      status: [[]],
      vendor: [[]],
    })

    this.selectedVendor = [];
    this.createdDate = [];
  }
}
