import { Router, ActivatedRoute, RoutesRecognized } from '@angular/router';
import { AuthService } from './services/auth.service';
import { Injectable } from '@angular/core';
import { filter, pairwise } from 'rxjs/operators';

@Injectable()
export class AppInitService {

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  Init() {


    return new Promise<void>(async (resolve, reject) => {

      console.log("[Init] is Bidding: ", window.location.search.startsWith("?pklink"));
      // window.location.search.startsWith("?pklink") ? this.router.navigate(['bidding']): "";
      if (window.location.search.startsWith("?pklink")) {

        console.log("Navigate to Bidding Page");
        this.router.navigate(['bidding'], { queryParams: { pklink: window.location.search.split("=")[1] } });

        await this.authService.getUserInfoDataFromMDM();
        await this.authService.getUserRoleDataFromMDM();
        resolve();
      }
      else {
        await this.authService.checkLogin();
      }

      console.log("[Init] is User Login: ", this.authService.isUserLoggedIn());

      if (this.authService.isUserLoggedIn()) {

        await this.authService.getUserInfoDataFromMDM();
        await this.authService.getUserRoleDataFromMDM();

        resolve();
      }

    });
  }
}
