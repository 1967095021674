import { NewRequestBiddingDocNoAndLog } from './../../../../../../api/models/new-request-bidding-doc-no-and-log';
import { Component, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { RequestContractDocNoAndLog } from 'src/app/api/models';

@Component({
  selector: 'app-searching-result-request-pricelist-log',
  templateUrl: './searching-result-request-pricelist-log.component.html',
  styleUrls: ['./searching-result-request-pricelist-log.component.css']
})
export class SearchingResultRequestPricelistLogComponent implements OnInit {

  @Input() logData: NewRequestBiddingDocNoAndLog[] = [];
  constructor() { }

  sortDateFn = (a: NewRequestBiddingDocNoAndLog, b: NewRequestBiddingDocNoAndLog) =>
    moment(a.logData?.editedDate).valueOf()  - moment(b.logData?.editedDate).valueOf();


  ngOnInit(): void {
  }

}
