import { TechnicalCommittee } from './../../../../../api/models/technical-committee';
import { MdmGetMemberInfoResponseData } from './../../../../../api/models/mdm-get-member-info-response-data';
import { UserDetailMDM, MDMGetMemberInfoResponseBase } from './../../../../../models/UserDetailMDM';
import { MdmService } from './../../../../../api/services/mdm.service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, TemplateRef } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { MdmGetMemberInfoResponse } from './../../../../../api/models/mdm-get-member-info-response';
import { RcftechnicalCommittee } from './../../../../../api/models/rcftechnical-committee';
import { debuglog } from 'util';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { RcfAllInfo } from './../../../../../api/models/rcf-all-info';

interface PIC {
  id: number;
  name: string;
  position: string;
  company: string;
  term: string;
}

@Component({
  selector: 'app-people',
  templateUrl: './people.component.html',
  styleUrls: ['./people.component.css']
})
export class PeopleComponent implements OnInit {

  @Input() userDetailmdm!: MdmGetMemberInfoResponse;
  @Input() requesterDetailMDM!: MdmGetMemberInfoResponseData;
  @Input() steps!: number;
  @Input() requestContractAllInfo!: RcfAllInfo[];

  @Output() requestTechnicalCommitteeListChange = new EventEmitter<TechnicalCommittee[]>();

  peopleForm: FormGroup;
  listOfTechCommit: TechnicalCommittee[] = [];
  listOfTechCommitData: TechnicalCommittee[] = [];
  selectedTechPIC: TechnicalCommittee[] = [];
  changedCommitteeTerms: string = "ข้อกำหนด";
  listOfTechCommittee: TechnicalCommittee[] = [];

  filteredTechOptions: TechnicalCommittee[] = [];
  numberOfTechCommit: number = 0;

  subject = new Subject<Object>();
  searchChange$ = new BehaviorSubject('');
  optionList: TechnicalCommittee[] = [];
  searchingList: MdmGetMemberInfoResponseData[] = []
  responseData: MdmGetMemberInfoResponseData[] = [];
  memberInOrg: MdmGetMemberInfoResponseData[] = [];
  selectedUser?: string;
  isLoading = false;

  editId: string | null = null;

  constructor(
    private fb: FormBuilder,
    private mdmService: MdmService,
    private modal: NzModalService,
    private msg: NzMessageService
  ) {
    this.peopleForm = this.fb.group({

    })
  }

  ngOnInit(): void {

  }

  updateData(event: TechnicalCommittee[]) {
    this.requestTechnicalCommitteeListChange.emit(event);
  }

  async ngOnChanges(change: SimpleChanges) {
    if (change.hasOwnProperty("requestContractAllInfo") && change.requestContractAllInfo.currentValue.length != 0) {
      this.listOfTechCommit = [...change.requestContractAllInfo.currentValue[0].technicalCommittees];

      if (change.requestContractAllInfo.currentValue[0].status === "Return") {
        this.updateData(this.listOfTechCommit);
      }
    }
  }


  //Search Techcomiitee by Org ID
  async onSearch(value: string): Promise<void> {
    // this.searchingList = [];
    let number: number = 0;
    if (value.length >= 3) {
      this.isLoading = true;
      await this.mdmService.apiMdmGetMemberInfoByStartWithNamePost$Json$Response({
        name: value
      }).toPromise()
        .then(response => {
          if (response.ok) {
            this.optionList = [];
            for (let i = 0; i < response.body.responseData!?.length; i++) {
              let obj: TechnicalCommittee = {
                committeeName: response.body.responseData![i].eFullName,
                committeePosition: response.body.responseData![i].positionName,
                committeeCompany: response.body.responseData![i].companyName,
                committeeTerms: "",
              }

              this.optionList.push(obj);
            }

            this.isLoading = false;
          }
        })
    }

  }

  startEdit(name: string): void {
    this.editId = name;
  }

  stopEdit(): void {
    this.editId = null;
  }

  editRow(name: string) {
    if (this.editId == name) {
      this.editId = null;
    }
    else {
      this.editId = name;
    }

  }



  modalDestroy() {
    this.modal.closeAll();
    this.changedCommitteeTerms = "ข้อกำหนด";
  }

  changeTechnicalCommitteeDetail(name: string) {

    this.listOfTechCommit[this.listOfTechCommit.findIndex(item => item.committeeName == name)].committeeTerms = this.changedCommitteeTerms;
    this.msg.success("Save change!");
    this.updateData(this.listOfTechCommit);
    this.modalDestroy();
  }

  addTechCommittee(value: number) {

    var isDuplicate = false;

    this.listOfTechCommit.forEach(item => {
      if (item.committeeName === this.optionList[value].committeeName) {
        isDuplicate = true;
      }
    })

    if (isDuplicate === false) {
      let obj: TechnicalCommittee = {
        committeeName: this.optionList[value].committeeName,
        committeePosition: this.optionList[value].committeePosition,
        committeeCompany: this.optionList[value].committeeCompany,
        committeeTerms: "",
      }

      this.selectedTechPIC = [...this.listOfTechCommit, obj];
      this.numberOfTechCommit++;

      this.listOfTechCommit = [...this.selectedTechPIC];
      this.selectedTechPIC = [];
      this.optionList = [];

      this.updateData(this.listOfTechCommit);

    }
    else {
      this.selectedTechPIC = [];
      this.optionList = [];

      this.modal.error({
        nzTitle: "Error",
        nzContent: "Duplicate Technical Committee"
      })
    }

  }

  deleteTechRow(value: string) {

    this.listOfTechCommit = this.listOfTechCommit.filter(item => {
      return item.committeeName !== value
    });

    this.numberOfTechCommit--;

    this.updateData(this.listOfTechCommit);
  }


  modalEditSuggestvendor(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, committeeName: string) {

    this.changedCommitteeTerms = this.listOfTechCommit[this.listOfTechCommit.findIndex(item => item.committeeName == committeeName)].committeeTerms!;

    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
      nzWidth: (0.5)*window.innerWidth,
    })
  }


}
