import { ActivatedRoute } from '@angular/router';
import { MasterTheArchVendorEmailList } from './../../../../../../../../api/models/master-the-arch-vendor-email-list';
import { MasterTheArchService } from './../../../../../../../../api/services/master-the-arch.service';
import { NzModalService } from 'ng-zorro-antd/modal';
import { VChembiMstVendorMaster } from '../../../../../../../../api/models/v-chembi-mst-vendor-master';
import { MasterService } from '../../../../../../../../api/services/master.service';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { ChildVendorData, ParentVendorData } from '../_model/TableVendorData';
import { BiddingAllInfoAndLog } from '../../../../../../../../api/models';

@Component({
  selector: 'app-add-vendor',
  templateUrl: './add-vendor.component.html',
  styleUrls: ['./add-vendor.component.css']
})
export class AddVendorComponent implements OnInit {

  @Input() steps: number = 0;
  @Input() biddingAllInfo!: BiddingAllInfoAndLog;
  @Input() vendorNameList: VChembiMstVendorMaster[] = [];
  @Output() vendorCode = new EventEmitter<string[]>();
  @Output() vendorListChange = new EventEmitter<ParentVendorData[]>();
  @Output() vendorEmailChange = new EventEmitter<ChildVendorData[]>();

  listOfParentVendorData: ParentVendorData[] = [];
  numberOfVendorData: number = 0;

  listOfChildrenVendorData: ChildVendorData[] = [];
  numberOfChildrenVendorData: number[][] = [];

  isLoading: boolean = false;
  isDataLoading: boolean = false;
  optionList: VChembiMstVendorMaster[] = [];
  listOfEmailVendor: MasterTheArchVendorEmailList[] = [];
  listOfVendor: any[] = [];

  changedEmail: string = "";
  constructor(
    private cmsMasterService: MasterService,
    private modal: NzModalService,
    private masterTheArchService: MasterTheArchService,
    private route: ActivatedRoute) { }

  ngOnInit(): void {
    const pkDetail = this.route.snapshot.queryParamMap.get('pkHeader');

    //ถ้ามี pk ต่อท้ายจะใส่ข้อมูลให้
    if (pkDetail) {
      this.biddingAllInfo.detail?.forEach(item => {
        this.listOfParentVendorData = [...this.listOfParentVendorData, {
          expand: true,
          key: this.numberOfVendorData,
          VendorCode: item.vendorCode!,
          VendorName: item.vendorName!,
        }]

        this.listOfChildrenVendorData = [...this.listOfChildrenVendorData, {
          vendor: []
        }]

        item.vendorEmail?.split(",").forEach(email => {
          console.log(email);
          this.listOfChildrenVendorData[this.numberOfVendorData].vendor.push({
            Email: email,
            key: this.numberOfVendorData,
            SendEmail: true,
          })

        })
        this.numberOfVendorData++;
      })

      this.vendorListChange.emit(this.listOfParentVendorData);
      this.vendorEmailChange.emit(this.listOfChildrenVendorData);

    }

  }

  //Edit email ของ vendor ให้แสดงใน modal
  modalEditConfig(tplTitle: TemplateRef<{}>, tplContent: TemplateRef<{}>, tplFooter: TemplateRef<{}>, parentKey: number, childKey: number) {
    this.changedEmail = this.listOfChildrenVendorData[parentKey].vendor[childKey].Email;

    this.modal.create({
      nzTitle: tplTitle,
      nzContent: tplContent,
      nzFooter: tplFooter,
    })
  }

  modalDestroy() {
    this.modal.closeAll();
  }

  //เลือก ส่ง/ ไม่ส่งอีเมล
  onCheckSendEmailChange() {
    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }

  //กด Save ตอนแก้ไขอีเมล
  onChangeEmail(parentKey: number, childKey: number) {
    this.listOfChildrenVendorData[parentKey].vendor[childKey].Email = this.changedEmail;
    this.modal.closeAll();

    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }

  //เพิ่มอีเมลในแต่ละ vendor
  addChildRow(value: number) {
    let templistOfChildVendorData = [...this.listOfChildrenVendorData];

    //push อีเมลเข้าไปใน array ที่เก็บ vendor email
    templistOfChildVendorData[value].vendor.push({
      key: value,
      Email: "vendor"+[value]+"@email.com",
      SendEmail: true,
    })

    this.listOfChildrenVendorData = [...templistOfChildVendorData];

    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }

  async addVendor(value: number) {

    this.isDataLoading = true;
    let vendor = this.optionList[value];

    console.log(vendor);

    //เช็ค vendor ซ้ำจาก vendor code
    for (let i = 0; i < this.listOfParentVendorData.length; i++) {
      if (this.listOfParentVendorData[i].VendorCode === vendor.vendorCode) {
        this.modal.error({
          nzTitle: "Error",
          nzContent: "Duplicate vendor",
        })

        this.isDataLoading = false;
        this.listOfVendor = [];
        this.listOfEmailVendor = [];
        return;
      }
    }

    let vendorCode: string[] = [];
    vendorCode.push(vendor.vendorCode!);

    //เอา vendor code ยิงไปขออีเมลที่ the arch
    await this.masterTheArchService.apiMasterTheArchGetVendorEmailListByVendorCodePost$Json({
      body: vendorCode,
    })
      .toPromise()
      .then(
        response => {

          //push ชื่อ vendor
          this.listOfParentVendorData = [...this.listOfParentVendorData, {
            key: this.numberOfVendorData,
            expand: true,
            VendorName: vendor.name1!,
            VendorCode: vendor.vendorCode!,
          }]

          this.listOfEmailVendor = response;
          this.listOfChildrenVendorData.push({
            vendor: []
          });

          //push อีเมล vendor
          this.listOfEmailVendor.forEach(item => {
            this.listOfChildrenVendorData[this.numberOfVendorData].vendor.push({
              key: this.numberOfVendorData,
              Email: item.email!,
              SendEmail: false
            })
          })

        }
      )
      .catch(
        error => {
          console.error("Error on GetVendorEmailList: ", error);
        }
      )


    this.isDataLoading = false;
    this.optionList = [];
    this.listOfVendor = [];
    this.listOfEmailVendor = [];
    this.numberOfVendorData++;

    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }

  //ลบ Vendor ออกจาก array
  deleteVendor(value: number) {

    this.listOfParentVendorData.splice(value, 1);
    this.listOfChildrenVendorData.splice(value, 1);

    this.numberOfVendorData--;

    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }

  //ติ๊กช่อง send email all
  checkAll(value: number) {
    this.listOfChildrenVendorData[value].vendor.forEach(item => {
      item.SendEmail = true;
    })

    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }

  //ติ๊กช่อง unsend email all
  unCheckAll(value: number) {
    this.listOfChildrenVendorData[value].vendor.forEach(item => {
      item.SendEmail = false;
    })

    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }


  //ลบอีเมลของ vendor แต่ละอัน
  deleteChildVendor(parentKey: number, childKey: number) {
    this.listOfChildrenVendorData[parentKey].vendor.splice(childKey, 1);

    this.vendorListChange.emit(this.listOfParentVendorData);
    this.vendorEmailChange.emit(this.listOfChildrenVendorData);
  }

  //ค้นหาชื่อ vendor หาจาก vendor name กับ vendor code
  onSearch(value: string) {

    if (value.length >= 1) {
      this.optionList = [];

      if (value.match(/[0-9]/)) {
        let count = 0;
        this.optionList = this.vendorNameList.filter(item => {
          //หา vendor code จาก startwith
          if (count <= 20 && item.vendorCode?.startsWith(value)) {
            count++;
            return true;
          }

          return false;

        })
      }
      else {
        let count = 0;
        this.optionList = this.vendorNameList.filter(item => {
          //หา vendor name จาก startwith โดยแปลงเป็น lowercase ก่อน
          if (count <= 20 && item.name1?.toLowerCase().startsWith(value.toLowerCase())) {
            count++;
            return true;
          }

          return false;
        });
      }

    }

  }
}
