<nz-layout class="app-layout">

  <nz-row>
    <nz-col nzSpan="24">
      <nz-steps [nzCurrent]="steps" [nzStatus]="status" [nzSize]="'small'">

        <nz-step nzTitle="New Request Contract"></nz-step>

        <nz-step nzTitle="Approver Approve" ></nz-step>

        <nz-step nzTitle="Sourcing Conclusion"></nz-step>

        <nz-step nzTitle="Sourcing Approver Approve" ></nz-step>

        <nz-step nzTitle="Finish"></nz-step>

      </nz-steps>
    </nz-col>
  </nz-row>

</nz-layout>
